<template>
  <CrudCard>
    <template #content>
      <div v-if="hasNotSelectedCountry()">
        Please select a country
      </div>
      <div v-else>
        <SelectField
          v-model="$store.state.form.country_id"
          label="Country"
          name="country_id"
          placeholder="Select a Country"
          :items="countries"
          :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
        />

        <InputField
          v-model="$store.state.form.name"
          label="Name"
          name="name"
        />

        <InputField
          v-model="$store.state.form.title"
          label="Title"
          name="title"
          :required="false"
        />

        <InputField
          v-model="$store.state.form.start_at"
          label="Start date"
          name="start_at"
          type="datetime-local"
          step="any"
          help="Start date needed to be shown on platform."
          :required="false"
        />

        <InputField
          v-model="$store.state.form.end_at"
          label="End date"
          name="end_at"
          type="datetime-local"
          step="any"
          help="No end date needed to be shown on platform."
          :required="false"
        />

        <InputField
          v-model="$store.state.form.url"
          label="URL"
          name="url"
          help="Only use routes"
          :required="false"
        >
          <template #extra>
            <DefaultButton @click.prevent="routesModalOpen = true">
              <span class="material-icons md-18">fork_right</span>
              Available routes
            </DefaultButton>
          </template>
        </InputField>

        <SelectField
          v-model="$store.state.form.type"
          label="Type"
          name="type"
          placeholder="Select a type"
          :items="suggestionTypes"
        />

        <div
          v-if="hasTypeIds"
          class="grid gap-1 md:gap-4 items-start sm:grid-cols-6 py-6 first:pt-0 last:pb-0"
        >
          <div class="col-span-2">
            <div class="flex justify-between sm:justify-start">
              <label class="block font-medium text-gray-700 mt-px pt-2">
                Type items
              </label>
            </div>
          </div>
          <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
            <div class="space-y-4">
              <VueDraggableNext
                class="space-y-2"
                :list="$store.state.form.types"
                handle=".drag-handle"
                @change="orderTypes"
              >
                <div
                  v-for="(type, index) in $store.state.form.types"
                  :key="`textual-conditions-${index}`"
                  class="list-group-item flex items-start flex-col"
                >
                  <div class="w-full flex-row inline-flex items-center">
                    <div class="w-full">
                      <MultiSelectField
                        v-if="hasTypeIds"
                        :key="`types_for_${$store.state.form.type}_${type.model_id}`"
                        v-model="type.model_id"
                        :label="null"
                        name="types"
                        placeholder="Select item"
                        mode="single"
                        :can-clear="false"
                        :resolve-on-load="type.model_id > 0"
                        :min-chars="2"
                        :delay="150"
                        :loading="isLoadingTypes[index] ?? false"
                        :options="async (query) => await fetchTypes(index, type.model_id, query)"
                      />
                    </div>

                    <DefaultButton
                      type="button"
                      classes="text-white bg-indigo-600 hover:bg-indigo-700 h-full"
                      title="Remove type"
                      @click="removeType(index)"
                    >
                      <span class="material-icons md-18">remove</span>
                    </DefaultButton>

                    <DefaultButton
                      type="button"
                      classes="drag-handle text-white bg-indigo-600 hover:bg-indigo-700 h-full"
                      title="Drag type"
                    >
                      <span class="material-icons md-18">drag_indicator</span>
                    </DefaultButton>
                  </div>
                </div>
              </VueDraggableNext>

              <div
                class="flex justify-end"
                :class="{ 'mt-6': $store.state.form.types.length > 0 }"
              >
                <DefaultButton
                  type="button"
                  classes="text-white bg-indigo-600 hover:bg-indigo-700 !ml-0"
                  title="Add condition"
                  @click="addType"
                >
                  <span class="material-icons md-18">add</span>
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>

        <SelectField
          v-model="$store.state.form.block_type"
          label="Webshop block type"
          name="block_type"
          placeholder="Select a block type"
          :items="parseBlockTypes(blockTypes)"
          @change="() => $store.state.form.billboard_pages = []"
        />

        <MultiSelectField
          v-model="$store.state.form.exclude_advertiser_types"
          label="Exclude advertiser types"
          name="exclude_advertiser_types"
          placeholder="Select advertiser type"
          mode="tags"
          :required="false"
          :options="parseAdvertiserTypes(advertiserTypes)"
        />

        <MultiSelectField
          v-if="$store.state.form.block_type === 'billboard_only'"
          v-model="$store.state.form.billboard_pages"
          label="Show billboard on pages"
          name="billboard_pages"
          placeholder="Select billboard pages"
          mode="tags"
          :required="false"
          :options="billboardPages"
        />
      </div>
    </template>
  </CrudCard>

  <RoutesModal
    v-model:open="routesModalOpen"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import {ref} from 'vue';
import axios from 'axios';
import {VueDraggableNext} from 'vue-draggable-next';
import RoutesModal from '../../Components/RoutesModal.vue';

export default {
  components: {
    RoutesModal,
    VueDraggableNext,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },

    suggestion: {
      type: Object,
      required: false,
      default: null,
    },

    suggestionTypes: {
      type: Object,
      required: true,
    },

    advertiserTypes: {
      type: Object,
      required: true,
    },

    blockTypes: {
      type: Array,
      required: true,
    },

    billboardPages: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let types = props.suggestion?.types ?? [];
    types = types.sort((a,b)=> (a.sort > b.sort ? 1 : -1));

    const form = useForm({
      country_id: props.suggestion?.country_id ?? null,
      name: props.suggestion?.name ?? '',
      title: props.suggestion?.title ?? '',
      start_at: props.suggestion?.start_at ?? null,
      end_at: props.suggestion?.end_at ?? null,
      url: props.suggestion?.url ?? null,
      type: props.suggestion?.type ?? null,
      types: types,
      block_type: props.suggestion?.block_type,
      exclude_advertiser_types: props.suggestion?.exclude_advertiser_types ?? [],
      billboard_pages: props.suggestion?.billboard_pages ?? [],
    });

    useStore().commit('form/setForm', form);

    return {
      routesModalOpen: ref(false),
      isLoadingTypes: ref([]),
    };
  },

  computed: {
    hasTypeIds() {
      return ['webshop_category', 'webshop_theme', 'manual_webshop'].includes(this.$store.state.form.type);
    },
  },

  watch: {
    '$store.state.form.type': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.state.form.types = [];
      }
    },
  },

  mounted() {
    if (!this.suggestion && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }
  },

  methods: {
    parseAdvertiserTypes(advertiserTypes) {
      return advertiserTypes.reduce((accumulator, advertiserType) => {
        accumulator[advertiserType.id] = advertiserType.name;
        return accumulator;
      }, {});
    },

    async fetchTypes(index, modelId, query) {
      this.isLoadingTypes[index] = true;

      switch (this.$store.state.form.type) {
        case 'webshop_category':
          if (query === null && modelId > 0) {
            return await axios.get(this.route('admin.api.webshop-category.find', {id: modelId}))
              .then(({data}) => {
                this.isLoadingTypes[index] = false;
                return data;
              });
          }
          return await axios.get(this.route('admin.api.webshop-category.search.name', {name: query}))
            .then(({data}) => {
              this.isLoadingTypes[index] = false;
              return data;
            });
        case 'webshop_theme':
          if (query === null && modelId > 0) {
            return await axios.get(this.route('admin.api.webshop-theme.find', {id: modelId}))
              .then(({data}) => {
                this.isLoadingTypes[index] = false;
                return data;
              });
          }
          return await axios.get(this.route('admin.api.webshop-theme.search.name', {name: query}))
            .then(({data}) => {
              this.isLoadingTypes[index] = false;
              return data;
            });
        case 'manual_webshop':
          if (query === null && modelId > 0) {
            return await axios.get(this.route('admin.api.webshop.find', {id: modelId}))
              .then(({data}) => {
                this.isLoadingTypes[index] = false;
                return data;
              });
          }
          return await axios.get(this.route('admin.api.webshop.search.name', {name: query}))
            .then(({data}) => {
              this.isLoadingTypes[index] = false;
              return data;
            });
      }

      return [];
    },

    parseBlockTypes(blockTypes) {
      return blockTypes.map((type) => ({id: type, name: this.toTitleCase(type)}));
    },

    addType() {
      this.$store.state.form.types.push({
        id: null,
        model_id: null,
      });

      this.orderTypes();
    },

    removeType(index) {
      this.$store.state.form.types.splice(index, 1);

      this.orderTypes();
    },

    orderTypes() {
      this.$store.state.form.types = Object.entries(this.$store.state.form.types).map(([key, value]) => ({
        id: value.id,
        model_id: value.model_id,
        sort: parseInt(key) + 1,
      }));
    },
  },
};
</script>
