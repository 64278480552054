<template>
  <CrudIndex
    :title="`Survey responses: #${memberId}`"
    :columns="['Mailing', 'Clickout', 'Status', 'Is rewarded', 'Participation reward value', 'Clicked at', 'Completed at', 'Duration']"
    :data="surveyResponses.data"
    :actions="false"
    :paginator="surveyResponses.meta.pagination"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.members.edit', memberId)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Member
      </InertiaLink>
    </template>

    <template #mailing="{ row }">
      <PromotionActionLink
        action-type=""
        :action-name="row.mailing.promotion_action_name"
        :action-url="row.mailing.promotion_action_url"
      />
    </template>

    <template #clickout="{ item }">
      <a
        :href="route('admin.members.clickouts.show', [memberId, item.id])"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        Clickout #{{ item.id }}
      </a>
    </template>

    <template #clicked_at="{ row }">
      {{ row.clickout.created_at }}
    </template>

    <template #completed_at="{ row }">
      {{ row.created_at }}
    </template>
  </CrudIndex>
</template>

<script>
import PromotionActionLink from '../../Components/PromotionActionLink.vue';

export default {
  components: {PromotionActionLink},
  props: {
    memberId: {
      type: Number,
      required: true,
    },

    surveyResponses: {
      type: Object,
      required: true,
    },
  },
};
</script>
