<template>
  <CrudCreate
    title="Cashback disapprove reason - Create"
    sub-title="Cashback disapprove reasons"
    :index-route="route('admin.cashback-disapprove-reasons.index')"
    :store-route="route('admin.cashback-disapprove-reasons.store')"
  >
    <template #cards>
      <ReasonForm />
    </template>
  </CrudCreate>
</template>

<script>
import ReasonForm from './Form.vue';

export default {
  components: {
    ReasonForm,
  },
};
</script>
