<template>
  <CrudIndex
    title="Tell A Friend stats"
    :columns="['Country', 'Member', 'Clicks', 'Pending', 'Approved', 'Logins', 'Disapproved', 'Last click date']"
    :order-columns="{
      'Member': 'referrer_member_id',
      'Clicks': 'clickCount',
      'Pending': 'pendingCount',
      'Approved': 'approvedCount',
      'Logins': 'loginCount',
      'Disapproved': 'disapprovedCount',
      'Last click date': 'lastClickDate',
    }"
    :order="order"
    :actions="true"
    :data="tellAFriendClicks.data"
    :paginator="tellAFriendClicks.meta.pagination"
    :filters="[
      {
        type: 'relation',
        label: 'Members',
        value: 'referrerMember',
        multiple: true,
        api: route('admin.api.tell-a-friend-stats.member.filter', {filter: filter}),
      },
      {
        type: 'date-range',
        operator: 'click-date',
        label: 'Date',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Click date', value: 'click-date'}]
      },
    ]"
    :menu-items="[
      {name: (item) => item.id in extraDetails && extraDetails[item.id].visible ? 'Hide details' : 'Show details', action: (item) => toggleDetails(item.id), enabled: true},
    ]"
    :additional-item-data="extraDetails"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.member-activity')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Member activity
      </InertiaLink>
    </template>

    <template #member="{ row}">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }} {{ row.first_name }}
      </a>
    </template>

    <template #additional-item-data="{ item }">
      <table class="min-w-full divide-y divide-gray-200 ml-4">
        <thead>
          <tr>
            <th
              v-for="(_, column) in item.data"
              :key="column"
              class="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(value, _) in item.data"
              :key="_"
              class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top"
            >
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </CrudIndex>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    tellAFriendClicks: {
      type: Object,
      required: true,
    },

    filter: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      extraDetails: {},
    };
  },

  methods: {
    toggleDetails(referrerMemberId) {
      if (referrerMemberId in this.extraDetails) {
        this.extraDetails[referrerMemberId].visible = !this.extraDetails[referrerMemberId].visible;
        return;
      }

      this.extraDetails[referrerMemberId] = {
        visible: true,
        loading: true,
        data: null,
      };

      axios.get(this.route('admin.api.tell-a-friend-stats.member.details', referrerMemberId))
        .then(({data}) => {
          this.extraDetails[referrerMemberId].data = data;
          this.extraDetails[referrerMemberId].loading = false;
        });
    },
  },
};
</script>
