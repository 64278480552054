<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <CrudIndex
    v-else
    title="Mailing filter files"
    create-route-path="admin.mailing-filter-files.create"
    edit-route-path="admin.mailing-filter-files.edit"
    delete-route-path="admin.mailing-filter-files.destroy"
    :columns="['id', 'name', 'created at']"
    :actions="true"
    :data="mailingFilterFiles.data"
    :paginator="mailingFilterFiles.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
    ]"
    :order-columns="{
      'id': 'id',
      'name': 'name',
      'created at': 'created_at',
    }"
    :order="order"
  />
</template>

<script>
export default {
  props: {
    mailingFilterFiles: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
