<template>
  <CrudEdit
    title="Survey Waves - Edit"
    sub-title="Survey Waves"
    :index-route="route('admin.survey-waves.index', survey.id)"
    :update-route="route('admin.survey-waves.update', [survey.id, surveyWave.id])"
  >
    <template #form>
      <SurveyWaveForm
        :survey-wave="surveyWave"
      />
    </template>
  </CrudEdit>
</template>

<script>
import SurveyWaveForm from './Form.vue';

export default {
  components: {
    SurveyWaveForm,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },

    surveyWave: {
      type: Object,
      required: true,
    },
  },
};
</script>
