<template>
  <CrudIndex
    title="Totals"
    :columns="['Search Count', 'Unique Members']"
    :data="totalResults"
    :actions="false"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}],
      },
      {
        type: 'dropdown',
        operator: 'type',
        label: 'Type',
        value: 'type',
        items: [
          {name: 'With match', value: 'with_match'},
          {name: 'No match', value: 'no_match'},
        ],
        multiple: false,
      }
    ]"
  />

  <CrudIndex
    title="Searches"
    :columns="['Search Term', 'Search Count', 'Search Results', 'Members']"
    :order-columns="{
      'Search Term': 'query',
      'Search Count': 'search_count',
      'Search Results': 'max_webshop_count',
      'Members': 'member_count',
    }"
    :order="order"
    :data="webshopSearchResults.data"
    :paginator="webshopSearchResults.meta.pagination"
    :actions="false"
  />

  <SiteHead title="Webshop search statistics" />
</template>

<script>
export default {
  props: {
    totalResults: {
      type: Array,
      required: true,
    },

    webshopSearchResults: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
