import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {backoffice} from '@rocketcode/backoffice';
import lib from '@rocketcode/backoffice';
import { ZiggyVue } from 'ziggy';
import Layout from './Layouts/Default.vue';
import CountrySelectorPlugin from './Plugins/CountrySelectorPlugin';
import helpers from './helpers';
import '../sass/app.scss';

backoffice(
  (name) => {
    return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', { eager: true }))
      .then(page => {
        if (page.default.layout === undefined && !name.startsWith('Auth/')) {
          page.default.layout = Layout;
        }
        return page;
      });
  },
  (Vue) => {
    Vue.use(lib)
      .mixin(helpers)
      .use(CountrySelectorPlugin)
      .use(ZiggyVue);
  },
);
