<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <div v-else>
    <CrudIndex
      title="Commissionmodels review"
      :columns="['ID', 'Commission model ID', 'Commission model description', 'Reason', 'Context', 'Program', 'Webshops', 'Date']"
      :actions="true"
      :menu-items="[
        {name: 'Done', action: (item) => destroy(item.id), enabled: true},
      ]"
      :data="reviews.data"
      :paginator="reviews.meta.pagination"
      :can-select-rows="true"
    >
      <template #selected-actions="{ selected, disabled, selectedIds }">
        <DefaultButton
          :classes="(disabled ? 'bg-gray-400 text-gray-800 cursor-not-allowed disabled' : 'text-white bg-indigo-600 hover:bg-indigo-700')"
          :disabled="disabled"
          @click="disabled ? null : destroySelected(selectedIds)"
        >
          Done ({{ selected }})
        </DefaultButton>
      </template>

      <template #context="{ item }">
        <ul v-if="item">
          <li
            v-for="(line, index) of item.split(/\n/)"
            :key="index"
          >
            {{ line }}
          </li>
        </ul>
      </template>
      <template #program="{ row }">
        <a
          :href="route('admin.programs.edit', row.program_id)"
          class="text-indigo-600 hover:text-indigo-900"
          target="_blank"
          @click.stop
        >
          {{ row.program_name }} (#{{ row.program_id }})
        </a>
      </template>

      <template #webshops="{ row }">
        <ul>
          <li
            v-for="webshopId of row.webshop_ids"
            :key="webshopId"
          >
            <a
              :href="route('admin.webshops.edit', webshopId)"
              class="text-indigo-600 hover:text-indigo-900"
              target="_blank"
              @click.stop
            >
              #{{ webshopId }}
            </a>
          </li>
        </ul>
      </template>
    </CrudIndex>
  </div>
</template>

<script>
export default {
  props: {
    reviews: {
      type: Object,
      required: true,
    },
  },

  methods: {
    destroy(reviewId) {
      this.$inertia.delete(this.route('admin.commission-models-review.destroy', [reviewId]));
    },

    destroySelected(reviewIds) {
      this.$inertia.post(this.route('admin.commission-models-review.destroy-selected'), {
        review_ids: reviewIds,
      });
    },
  },
};
</script>
