<template>
  <HeadlessDialog
    as="div"
    class="fixed z-10 inset-0 overflow-y-auto"
    :open="open"
    @close="close"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <HeadlessDialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >&#8203;</span>

      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
        <div class="bg-white">
          <table
            class="min-w-full divide-y divide-gray-200"
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Variables
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(name, key) in variables"
                :key="key"
                class="even:bg-gray-50 hover:bg-gray-100 focus-within:bg-gray-100"
              >
                <td
                  class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <DefaultButton
          classes="text-white bg-indigo-600 hover:bg-indigo-700"
          @click="close"
        >
          Close
        </DefaultButton>
      </div>
    </div>
  </HeadlessDialog>
</template>

<script>
import {Dialog as HeadlessDialog, DialogOverlay as HeadlessDialogOverlay} from '@headlessui/vue';

export default {
  components: {HeadlessDialog, HeadlessDialogOverlay},

  props: {
    open: {
      type: Boolean,
      required: true,
    },

    variables: {
      type: Array,
      required: true,
    },
  },

  emits: ['update:open'],

  data() {
    return {
      loading: false,
    };
  },


  methods: {
    close() {
      this.$emit('update:open', false);
    },
  },
};
</script>
