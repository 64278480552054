<template>
  <CrudCard>
    <template #content>
      <CurrencyAndPointsField
        v-model="$store.state.form.value"
        :options="currencyOptions"
        :points-conversion-ratio="pointsConversionRatio"
        label="Cashback"
        name="value"
        help="The amount the member will receive"
        :commission-models="commissionModels"
        :cashback-percentage="cashbackPercentage"
      />

      <TextareaField
        v-model="$store.state.form.note"
        label="Note"
        name="note"
        help="Members won’t see this text."
      />
    </template>
  </CrudCard>

  <Clickout :clickout="clickout" />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import Clickout from '../../Components/Clickout.vue';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';

export default {
  components: {
    Clickout,
    CurrencyAndPointsField,
  },

  props: {
    cashback: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    clickout: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },

    commissionModels: {
      type: Object,
      required: false,
      default: () => {},
    },

    cashbackPercentage: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },

  setup(props) {
    // fetch currency from the current cashback (update) or from the clickout (create)
    let currency = props.cashback.currency ? props.cashback.currency.iso : props.clickout.member.currency.iso;

    const currencyOptions = {
      currency: currency,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    const form = useForm({
      value: props.cashback.value ?? null,
      note: null,
      clickout: props.clickout.id,
    });

    useStore().commit('form/setForm', form);

    return {currencyOptions};
  },
};
</script>
