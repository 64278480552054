<template>
  <CrudEdit
    title="Notification Bar"
    sub-title="Notification Bar"
    :index-route="route('admin.notification-bars.index')"
    :update-route="route('admin.notification-bars.update', notificationBar.id)"
  >
    <template #form>
      <NotificationBarForm
        :notification-bar="notificationBar"
        :tenant-main-color="tenantMainColor"
        :countries="countries"
        :authentication-status="authenticationStatus"
        :subscription-types="subscriptionTypes"
      />
    </template>
  </CrudEdit>
</template>

<script>
import NotificationBarForm from './Form.vue';

export default {
  components: {NotificationBarForm},

  props: {
    notificationBar: {
      type: Object,
      required: true,
    },

    tenantMainColor: {
      type: String,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },

    authenticationStatus: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
