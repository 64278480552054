<template>
  <CrudEdit
    title="Free action category - Edit"
    sub-title="Free action category"
    :index-route="route('admin.free-action-categories.index')"
    :update-route="route('admin.free-action-categories.update', category.id)"
  >
    <template #form>
      <CategoryForm
        :category="category"
      />
    </template>
  </CrudEdit>
</template>

<script>
import CategoryForm from './Form.vue';

export default {
  components: {CategoryForm},

  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>
