<template>
  <CrudCard>
    <template #content>
      <SelectField
        v-model="$store.state.form.country_id"
        label="Country"
        name="country_id"
        placeholder="Select a Country"
        :items="countries"
        :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
      />

      <MultiSelectField
        v-model="$store.state.form.type"
        label="Type"
        name="type"
        placeholder="Select type"
        mode="single"
        :options="types"
        @change="(event) => switchType(event)"
      />

      <InputField
        v-model="$store.state.form.title"
        label="Title"
        name="title"
      />

      <MediaField
        v-model="$store.state.form.media_id"
        label="Media"
        name="media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :crop-config="{
          enabled: true,
          aspectRatio: 16 / 9,
        }"
        media-key="puzzles"
      />

      <InputField
        v-model="$store.state.form.question"
        label="Question"
        name="question"
      />

      <CurrencyAndPointsField
        v-model="$store.state.form.reward"
        :options="currencyOptions"
        :points-conversion-ratio="countryPointsConversionRatio"
        :minimum-value="0.01"
        :maximum-value="0.10"
        label="Reward"
        name="reward"
      />

      <CurrencyAndPointsField
        v-model="$store.state.form.main_reward"
        :options="currencyOptions"
        :points-conversion-ratio="countryPointsConversionRatio"
        :minimum-value="0.01"
        :maximum-value="50"
        label="Main Reward"
        name="main_reward"
      />

      <InputField
        v-model="$store.state.form.start_at"
        label="Start datetime"
        name="start_at"
        type="datetime-local"
        step="1"
      />

      <InputField
        v-model="$store.state.form.end_at"
        label="End datetime"
        name="end_at"
        type="datetime-local"
        step="1"
        :required="false"
      />
    </template>
  </CrudCard>

  <CrudCard v-if="$store.state.form.type === 'radio'">
    <template #header>
      <div class="px-2">
        <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6">
          <div class="col-span-4">
            Answers
          </div>
          <div class="col-span-2">
            Is Correct
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <InputField
        v-for="(_, i) in $store.state.form.answers"
        :key="`answer-${i}`"
        v-model="$store.state.form.answers[i].answer"
        :label="`Answer #${i + 1}`"
        :name="`answer[${i}][answer]`"
      >
        <template #extra>
          <div class="grid grid-cols-2">
            <CheckboxField
              :model-value="$store.state.form.correct_answer === i"
              :name="`answer[${i}][is_correct]`"
              :required="false"
              @change="$store.state.form.correct_answer = i"
            />

            <i
              v-if="$store.state.form.answers.length > 2"
              class="far fa-times hover:cursor-pointer text-red-500"
              @click="removeAnswer(i)"
            />
          </div>
        </template>
      </InputField>
    </template>

    <template #footer>
      <div class="px-2">
        <DefaultButton @click.prevent="$store.state.form.answers.push({answer: '', is_correct: false})">
          <i class="far fa-plus mr-2" />
          Add Answer
        </DefaultButton>
      </div>
    </template>
  </CrudCard>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';

export default {
  components: {
    CurrencyAndPointsField,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },

    types: {
      type: Array,
      required: true,
    },

    puzzle: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const currencyOptions = ref({
      currency: props.puzzle?.country?.currency?.iso || 'EUR',
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    });
    const countryPointsConversionRatio = ref('1');

    const answers = props.puzzle ? props.puzzle.answers : null;
    const correctAnswer = answers ? answers.findIndex((answer) => answer.is_correct) : null;

    const form = useForm({
      country_id: props.puzzle ? props.puzzle.country_id : null,
      type: props.puzzle ? props.puzzle.type : null,
      title: props.puzzle ? props.puzzle.title : null,
      media_id: props.puzzle ? props.puzzle.media_id : null,
      question: props.puzzle ? props.puzzle.question : null,
      reward: props.puzzle ? props.puzzle.reward : 0.01,
      main_reward: props.puzzle ? props.puzzle.main_reward : 10,
      start_at: props.puzzle ? props.puzzle.start_at : null,
      end_at: props.puzzle ? props.puzzle.end_at : null,
      correct_answer: correctAnswer,
      answers,
    });

    useStore().commit('form/setForm', form);

    return {currencyOptions, countryPointsConversionRatio};
  },

  watch: {
    '$store.state.form.country_id': function(newCountryId) {
      this.updateCountry(newCountryId);
    },
  },

  mounted() {
    if (!this.puzzle && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }

    if (this.puzzle) {
      this.updateCountry(this.puzzle.country_id);
    }

  },

  methods: {
    updateCountry(newCountryId) {
      newCountryId = parseInt(newCountryId);
      const newCountry = this.countries.find((country) => country.id === newCountryId);
      this.currencyOptions.currency = newCountry.currency.iso;
      this.countryPointsConversionRatio = newCountry.settings.pointsConversionRatio;
    },

    switchType(type) {
      if (type === 'radio') {
        this.$store.state.form.answers = this.puzzle ? this.puzzle.answers : [
          {answer: '', is_correct: true},
          {answer: '', is_correct: false},
          {answer: '', is_correct: false},
          {answer: '', is_correct: false},
          {answer: '', is_correct: false},
          {answer: '', is_correct: false},
        ];
        this.$store.state.form.correct_answer = this.$store.state.form.answers.findIndex((answer) => answer.is_correct);

        return;
      }

      this.$store.state.form.answers = null;
      this.$store.state.form.correct_answer = null;
    },

    removeAnswer(index) {
      this.$store.state.form.answers.splice(index, 1);

      if (this.$store.state.form.correct_answer === index) {
        this.$store.state.form.correct_answer = null;
      }

      if (this.$store.state.form.correct_answer > index) {
        this.$store.state.form.correct_answer = this.$store.state.form.correct_answer - 1;
      }
    },
  },
};
</script>
