<template>
  <div class="pt-4">
    <MultiSelectField
      v-model="$store.state.form.country_ids"
      label="Active countries"
      name="country_ids"
      placeholder="Select countries"
      mode="tags"
      :options="availableCountries"
      :required="false"
    />

    <InputField
      v-model="$store.state.form.question"
      label="Question"
      name="question"
      help="In English"
    />

    <EditorField
      v-model="$store.state.form.answer"
      label="Answer"
      name="answer"
      help="In English<br>
<br>
Replace tags:<br>
Routes: <code>:route[frontend.index]</code><br>
Setting: <code>:setting[cashback_max_cashback]</code><br>
TaF setting: <code>:taf-setting[bonus]</code><br>
Common: <code>:common[webshopCount]</code><br>
<br>
Formatting:<br>
#currency: <code>:setting[key#currency]</code><br>
#number: <code>:setting[key#number]</code><br>"
    >
      <template #extra>
        <DefaultButton @click.prevent="routesModalOpen = true">
          <span class="material-icons md-18">fork_right</span>
          Available routes
        </DefaultButton>
      </template>
    </EditorField>


    <MultiSelectField
      v-model="$store.state.form.page_types"
      label="Page types"
      name="page_types"
      placeholder="Select a page type"
      :options="pageTypes"
      mode="tags"
      :required="false"
    />

    <CheckboxField
      v-model="$store.state.form.is_enabled"
      label="Enabled"
      name="is_enabled"
      :required="false"
    />
  </div>

  <RoutesModal
    v-model:open="routesModalOpen"
  />
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import RoutesModal from '../../../Components/RoutesModal.vue';
import EditorField from '../../../Components/Form/EditorField.vue';

export default {
  components: {
    RoutesModal,
    EditorField,
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },

    countries: {
      type: Object,
      required: true,
    },

    pageTypes: {
      type: Array,
      required: true,
    },

  },

  setup(props) {
    const form = useForm({
      country_ids: props.item ? props.item.country_ids : null,
      page_types: props.item ? props.item.page_types.map((value) => (value.page_type)) : null,
      question: props.item ? props.item.question : null,
      answer: props.item ? props.item.answer : null,
      is_enabled: props.item ? props.item.is_enabled : false,
    });

    useStore().commit('form/setForm', form);

    return {
      availableCountries: props.countries.reduce((accumulator, country) => {
        accumulator[country.id] = country.name;
        return accumulator;
      }, {}),

      routesModalOpen: ref(false),
    };
  },
};
</script>
