<template>
  <CrudShow
    :title="`Payout #${payout.id}`"
    sub-title="Payouts"
    :rows="['ID', 'Member ID', 'Country', 'Payout height', 'Payout costs', 'Status', 'Paid status', 'Disapprove reason','Payout type', 'Receiver name', 'Receiver account', 'Request date', 'Paid date', 'Update date']"
    :data="payout"
  >
    <template #after-back>
      <a
        :href="route('admin.payouts.history', payout.id)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        History
      </a>

      <DropdownButton
        v-if="payout.canDisapprove"
        :items="disapproveReasons.map(reason => ({
          name: reason.name,
          action: () => doDisapprove(reason.id),
        }))"
        classes="text-white bg-red-600 hover:bg-red-700"
      >
        Disapprove
      </DropdownButton>

      <DropdownButton
        v-if="payout.canMarkedAsFailed"
        :items="disapproveReasons.map(reason => ({
          name: reason.name,
          action: () => doMarkAsFailed(reason.id),
        }))"
        classes="text-white bg-red-600 hover:bg-red-700"
      >
        Payout failed
      </DropdownButton>
    </template>

    <template #member_id="{ item }">
      <a
        :href="route('admin.members.edit', item)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ item }}
      </a>
    </template>

    <template #country="{ data }">
      {{ data.member.country_name }}
    </template>

    <template #payout_height="{ data }">
      <span v-if="data.points_value">
        {{ data.value }} ({{ data.points_value }})
      </span>
      <span v-else>
        {{ data.value }}
      </span>
    </template>

    <template #payout_costs="{ data }">
      {{ data.handling_costs }}
    </template>

    <template #status="{ item }">
      <span
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item === 'APPROVED',
          'bg-red-100 text-red-800' : item === 'DISAPPROVED',
          'bg-yellow-100 text-yellow-800' : item === 'PENDING',
        }"
      >
        {{ item }}
      </span>
    </template>

    <template #paid_status="{ item }">
      <span
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item === 'DONE',
          'bg-yellow-100 text-yellow-800' : item === 'BUSY',
          'bg-blue-100 text-blue-800' : !item || item === 'OPEN',
        }"
      >
        {{ item ?? 'OPEN' }}
      </span>
    </template>

    <template
      #disapprove_reason="{ data }"
    >
      <span v-if="data.disapprove_reason">
        {{ data.disapprove_reason.name }}
      </span>
      <span v-else>
        -
      </span>
    </template>

    <template #payout_type="{ data }">
      <PayoutAccountData :data="data" />
    </template>

    <template #receiver_name="{ data }">
      {{ data.payout_receiver_name }}
    </template>

    <template #receiver_account="{ data }">
      {{ data.payout_receiver_account }}
    </template>
  </CrudShow>

  <CrudCard v-if="payout.giftcard">
    <template #header>
      <span class="font-medium">
        Giftcard details
      </span>
    </template>

    <template #content>
      <CrudShowContent
        :rows="['ID', 'Shop', 'Value', 'Code', 'Pin', 'Expiration date']"
        :data="payout.giftcard"
      >
        <template #pin="{ item }">
          <span v-if="item">
            {{ item }}
          </span>
          <span v-else>
            Not needed.
          </span>
        </template>

        <template #expiration_date="{ data }">
          <span v-if="data.expires_at">
            {{ data.expires_at }}
          </span>
          <span v-else>
            No expiration date.
          </span>
        </template>
      </CrudShowContent>
    </template>
  </CrudCard>

  <CrudCard v-if="payout.charity">
    <template #header>
      <span class="font-medium">
        Charity details
      </span>
    </template>

    <template #content>
      <CrudShowContent
        :rows="['ID', 'Name', 'Bank account name', 'IBAN']"
        :data="payout.charity"
      />
    </template>
  </CrudCard>

  <SiteHead
    :title="`Payout #${payout.id}`"
  />
</template>

<script>
import PayoutAccountData from '../../Components/PayoutAccountData.vue';

export default {
  components: {
    PayoutAccountData,
  },

  props: {
    payout: {
      type: Object,
      required: true,
    },

    disapproveReasons: {
      type: Array,
      required: true,
    },
  },

  methods: {
    doDisapprove(reasonId) {
      const payload = {
        action: 'disapproved',
        reason_id: reasonId,
      };
      this.$inertia.patch(this.route('admin.payouts.disapprove', [this.payout.id]), payload);
    },

    doMarkAsFailed(reasonId) {
      const payload = {
        action: 'disapproved',
        reason_id: reasonId,
      };
      this.$inertia.patch(this.route('admin.payouts.failed', [this.payout.id]), payload);
    },
  },
};
</script>
