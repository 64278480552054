<template>
  <CrudIndex
    :title="'Puzzle Winners'"
    :data="puzzle.winners"
    :columns="['Member ID', 'Price', 'Finished At']"
    :actions="true"
  >
    <template #member_id="{ row }">
      <a
        :href="route('admin.members.edit', row)"
        class="text-indigo-600 hover:text-indigo-900"
      >
        {{ row.id }}
      </a>
    </template>

    <template #price>
      {{ puzzle.main_reward }}
    </template>

    <template #finished_at>
      {{ puzzle.finished_at }}
    </template>
  </CrudIndex>

  <CrudIndex
    v-if="puzzle.type === 'open'"
    :title="'Open answers'"
    :data="puzzle.member_open_answers"
    :columns="['Member ID', 'Answer']"
    :actions="true"
    :menu-items="[
      {name: 'Mark as winner', icon: 'star', action: (item) => markWinner(item), enabled: !puzzle.main_reward_winner_member_id},
    ]"
  >
    <template #member_id="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
      >
        {{ row.member_id }}
      </a>
    </template>

    <template #answer="{ row }">
      {{ row.answer }}
    </template>
  </CrudIndex>
</template>

<script>
import axios from 'axios';
import {router} from '@inertiajs/vue3';

export default {
  props: {
    puzzle: {
      type: Object,
      required: true,
    },
  },

  methods: {
    markWinner(answer) {
      if (confirm(`Are you sure you want to mark member ${answer.member_id} as the winner?`)) {
        axios.patch(this.route('admin.api.puzzle-member-open-answers.mark-winner', { puzzleMemberOpenAnswer: answer.id })).then(() => {
          router.reload();
        });
      }
    },
  },
};
</script>
