<template>
  <CrudIndex
    title="Transactions"
    row-route-path="admin.transactions.show"
    :columns="['ID', 'Transaction datetime', 'Member ID', 'Advertiser', 'Promotion Type', 'Country', 'Commission', 'Cashback']"
    :actions="true"
    :data="transactions.data"
    :paginator="transactions.meta.pagination"
    :menu-items="[
      {name: 'Details', url: (item) => route('admin.transactions.show', item.id), enabled: true},
      {name: 'History', url: (item) => route('admin.transactions.history', item.id), enabled: true},
    ]"

    :filters="[
      {type: 'search', fields: [
        {label: 'Member ID', value: 'transactions.member_id', checked: true},
        {label: 'Click ID', value: 'click_id', checked: true},
      ]},
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Member is possible cheater',
        value: 'member.is_possible_cheater',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Member is fraudster',
        value: 'member.is_fraudster',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
    ]"
  >
    <template #member_id="{ item }">
      <a
        :href="route('admin.members.edit', item)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ item }}
      </a>
    </template>

    <template #country="{ row }">
      {{ row.country.code }}
    </template>

    <template #status="{ item, row }">
      <span
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :title="item.status === 'DISAPPROVED' ? item.disapproval_reason : ''"
        :class="{
          'bg-green-100 text-green-800' : item.status === 'APPROVED',
          'bg-red-100 text-red-800' : item.status === 'DISAPPROVED',
          'bg-yellow-100 text-yellow-800' : item.status === 'PENDING',
        }"
      >
        {{ item.status }}
      </span>

      <InertiaLink
        v-if="row.on_hold"
        :href="route('admin.transactions-on-hold.show', row.id)"
        class="text-indigo-600 hover:text-indigo-900"
        @click.stop
      >
        <span
          class="material-icons md-18 align-middle"
          :title="`On hold: ${row.on_hold_reason}`"
        >search</span>
      </InertiaLink>
    </template>

    <template #advertiser="{ row }">
      <a
        :href="route('admin.advertisers.edit', row.clickout.advertiser_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.clickout.advertiser_name }}
      </a>
    </template>

    <template #promotion_type="{ row }">
      <PromotionActionLink
        :action-type="row.clickout.promotion_type"
        :action-name="row.clickout.promotion_action_name"
        :action-url="row.clickout.promotion_action_url"
      />
    </template>

    <template #commission="{ row }">
      <TransactionCommission :row="row" />
    </template>

    <template #cashback="{ row }">
      <span v-if="!row.cashback">n/a</span>
      <div
        v-else
        class="flex items-center"
      >
        <span
          class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : row.cashback.status.status === 'APPROVED',
            'bg-red-100 text-red-800' : row.cashback.status.status === 'DISAPPROVED',
            'bg-yellow-100 text-yellow-800' : row.cashback.status.status === 'PENDING',
          }"
        >
          {{ row.cashback.cashback }}
        </span>
        <Popover
          v-if="row.cashback.status.status === 'DISAPPROVED'"
          class="h-5 w-5 ml-1"
        >
          <PopoverButton class="inline-block">
            <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
          </PopoverButton>
          <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
            <p class="w-full">
              {{ row.cashback.status.status_reason }}
            </p>
          </PopoverPanel>
        </Popover>
      </div>
    </template>
  </CrudIndex>
</template>

<script>
import {InformationCircleIcon} from '@heroicons/vue/solid';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import PromotionActionLink from '../../Components/PromotionActionLink.vue';
import TransactionCommission from '../../Components/TransactionCommission.vue';

export default {
  components: {
    InformationCircleIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    PromotionActionLink,
    TransactionCommission,
  },

  props: {
    transactions: {
      type: Object,
      required: true,
    },
  },
};
</script>
