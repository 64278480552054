<template>
  <MailingOverviewStatistics
    :mailings="mailings"
    :filterable-notification-types="filterableNotificationTypes"
    :show-revenue="showRevenue"
    :disable-advertiser-filter="disableAdvertiserFilter"
    :disable-notification-type-filter="disableNotificationTypeFilter"
    :enable-survey-statistics="enableSurveyStatistics"
    :enable-survey-filter="enableSurveyFilter"
    :enable-survey-wave-filter="enableSurveyWaveFilter"
    :show-duplicate-menu-item="showDuplicateMenuItem"
    :order="order"
  />
</template>

<script>
import MailingOverviewStatistics from '../../Components/Mailing/Overview/Statistics.vue';

export default {
  components: {MailingOverviewStatistics},

  props: {
    mailings: {
      type: Object,
      required: true,
    },

    filterableNotificationTypes: {
      type: Array,
      required: true,
    },

    showRevenue: {
      type: Boolean,
      required: false,
      default: false,
    },

    showDuplicateMenuItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableAdvertiserFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableNotificationTypeFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyStatistics: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyWaveFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
