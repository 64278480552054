<template>
  <CrudEdit
    :title="`Voucher code ${voucherCode.id}`"
    sub-title="Voucher codes"
    :index-route="route('admin.voucher-codes.show', [advertiserId])"
    :update-route="route('admin.voucher-codes.update', [advertiserId, voucherCode.id])"
  >
    <template #form>
      <InputField
        :model-value="voucherCode.code"
        label="Code"
        name="code"
        :disabled="true"
      />
      <InputField
        :model-value="voucherCode.valid_from"
        label="Valid from"
        name="valid_from"
        type="datetime-local"
        :disabled="true"
      />
      <InputField
        v-if="voucherCode.valid_to"
        :model-value="voucherCode.valid_to"
        label="Valid to"
        name="valid_to"
        type="datetime-local"
        :disabled="true"
      />
      <InputField
        v-else
        value="n/a"
        label="Valid to"
        name="valid_to"
        :disabled="true"
      />

      <TextareaField
        :model-value="voucherCode.description"
        label="Description"
        name="description"
        rows="6"
        :disabled="true"
      />
      <TextareaField
        v-model="$store.state.form.manual_description"
        label="Manual description"
        name="manual_description"
        rows="6"
        :required="false"
      />

      <TextareaField
        :model-value="voucherCode.conditions"
        label="Conditions"
        name="conditions"
        rows="6"
        :disabled="true"
      />
      <TextareaField
        v-model="$store.state.form.manual_conditions"
        label="Manual conditions"
        name="manual_conditions"
        rows="6"
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.may_show"
        label="May show"
        name="may_show"
        :required="false"
      />
    </template>
  </CrudEdit>
</template>

<script setup>
import {defineProps} from 'vue';
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';

const props = defineProps({
  advertiserId: {
    type: Number,
    required: true,
  },

  voucherCode: {
    type: Object,
    required: true,
  },
});

const form = useForm({
  manual_description: props.voucherCode.manual_description,
  manual_conditions: props.voucherCode.manual_conditions,
  may_show: props.voucherCode.may_show,
});
useStore().commit('form/setForm', form);

</script>
