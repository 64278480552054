<template>
  <CrudIndex
    title="Care tips"
    :columns="['ID', 'Member', 'Tips', 'Date', 'Text', 'Status']"
    :actions="true"
    :menu-items="[
      {name: 'Approve', icon: 'thumb_up', url: (item) => route('admin.care-tips.approve', item.id), enabled: (item) => item.status !== 'approved'},
      {name: 'Disapprove', icon: 'thumb_down', url: (item) => route('admin.care-tips.disapprove', item.id), enabled: (item) => item.status !== 'disapproved'},
    ]"
    :data="tips.data"
    :paginator="tips.meta.pagination"
    :filters="filters"
  >
    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #date="{ row }">
      {{ row.created_at }}
    </template>

    <template #status="{ item }">
      <span
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item.toLowerCase() === 'approved',
          'bg-red-100 text-red-800' : item.toLowerCase() === 'disapproved',
          'bg-yellow-100 text-yellow-800' : item.toLowerCase() === 'in_review',
        }"
      >
        {{ item }}
      </span>
    </template>
  </CrudIndex>
</template>

<script>
import {usePage} from '@inertiajs/vue3';

export default {
  props: {
    tips: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const filters = [
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Status',
        value: 'status',
        multiple: true,
        items: [
          {name: 'In review', value: 'in_review'},
          {name: 'Approved', value: 'approved'},
          {name: 'Disapproved', value: 'disapproved'},
        ],
      },
      {type: 'search', fields: [{label: 'Member ID', value: 'member_id'}, {label: 'Text', value: 'text'}]},
      {type: 'date-range', timezone: usePage().props.auth.user.timezone, fields: [{label: 'Date', value: 'created_at'}]},
    ];

    return {
      filters: filters,
    };
  },
};
</script>
