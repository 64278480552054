<template>
  <CrudCreate
    title="Currency - Create"
    sub-title="Currencies"
    :index-route="route('admin.currencies.index')"
    :store-route="route('admin.currencies.store')"
  >
    <template #form>
      <CurrencyForm />
    </template>
  </CrudCreate>
</template>

<script>
import CurrencyForm from './Form.vue';

export default {
  components: {CurrencyForm},
};
</script>
