<template>
  <CrudEdit
    :title="language.name"
    sub-title="Languages"
    :index-route="route('admin.languages.index')"
    :update-route="route('admin.languages.update', language.id)"
  >
    <template #form>
      <LanguageForm :language="language" />
    </template>
  </CrudEdit>
</template>

<script>
import LanguageForm from './Form.vue';

export default {
  components: {LanguageForm},

  props: {
    language: {
      type: Object,
      required: true,
    },
  },
};
</script>
