<template>
  <CrudEdit
    title="Program Term Category"
    sub-title="Program Term Category"
    :index-route="route('admin.term-categories.index')"
    :update-route="route('admin.term-categories.update', category.id)"
  >
    <template #form>
      <CategoryForm />
    </template>
  </CrudEdit>
</template>

<script>

import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import CategoryForm from './Form.vue';

export default {
  components: {CategoryForm},

  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.category.name,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
