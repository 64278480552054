<template>
  <CrudIndex
    title="Mailing eCPC statistics"
    :columns="['Day', 'Clicks', 'Revenue', 'eCPC', 'Amount']"
    :data="stats"
  />
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
};
</script>
