<template>
  <div class="col-span-2">
    <MultiSelectField
      :model-value="item.survey_wave_id"
      name="survey_wave_id"
      placeholder="Select wave"
      mode="single"
      :required="true"
      :inline="true"
      :searchable="true"
      :can-deselect="false"
      :can-clear="false"
      :resolve-on-load="true"
      :min-chars="2"
      :delay="150"
      :loading="loading"
      :options="async (query) => await fetchWaves(query)"
      @change="value => item.survey_wave_id = parseInt(value)"
    />
  </div>
  <div>
    <div class="flex mt-2">
      <label
        v-for="(label, key) of mailingFilterItemOperators"
        :key="`operator-${key}`"
        class="mr-2 flex items-center"
      >
        <input
          v-model="item.operator"
          :value="key"
          type="radio"
          class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
        >
        <span class="ml-2">{{ label }}</span>
      </label>
    </div>
  </div>
  <div>
    <label
      v-for="(label, key) of surveyStatuses"
      :key="`status-${key}`"
      class="mr-2 flex items-center"
    >
      <input
        v-model="item.survey_statuses"
        :value="key"
        type="checkbox"
        class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
      <span class="ml-2">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import Base from '../Base';

export default {
  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const loading = ref(false);
    const item = ref(props.modelValue);
    item.value.survey_wave_id = props.modelValue?.survey_wave_id || null;
    item.value.operator = props.modelValue?.operator || Object.keys(props.mailingFilterItemOperators)[0];
    item.value.survey_statuses = props.modelValue?.survey_statuses || [];

    return {loading, item};
  },

  methods: {
    fetchWaves(query) {
      this.loading = true;

      let fetchInitial = query === null;
      if (fetchInitial) {
        return axios.get(this.route('admin.api.survey-waves.find', {id: this.item.survey_wave_id}))
          .then(({data}) => {
            this.loading = false;
            return data;
          });
      }

      return axios.get(this.route('admin.api.survey-waves.search.name', {name: query}))
        .then(({data}) => {
          this.loading = false;
          return data;
        });
    },
  },
};
</script>
