<template>
  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
  />

  <TextareaField
    v-model="$store.state.form.text"
    label="Text"
    name="text"
    rows="6"
  />

  <InputField
    v-model="$store.state.form.url"
    label="Link"
    name="url"
    :required="false"
  >
    <template #extra>
      <DefaultButton @click.prevent="routesModalOpen = true">
        <span class="material-icons md-18">fork_right</span>
        Available routes
      </DefaultButton>
    </template>
  </InputField>

  <InputField
    v-model="$store.state.form.background_color"
    label="Background color"
    name="background_color"
    type="color"
  >
    <template #extra>
      <DefaultButton @click.prevent="$store.state.form.background_color = tenantMainColor">
        <span class="material-icons md-18">refresh</span>
        Reset
      </DefaultButton>
    </template>
  </InputField>

  <MultiSelectField
    v-model="$store.state.form.authentication_status"
    label="Show to members"
    name="authentication_status"
    placeholder="Select type"
    help="Leave empty to disable."
    mode="single"
    :options="authenticationStatus"
    :required="false"
  />

  <MultiSelectField
    v-model="$store.state.form.subscription_type"
    label="Subscription type"
    name="subscription_type"
    placeholder="Select type"
    help="Leave empty to disable."
    mode="single"
    :options="availableSubscriptionTypes"
    :required="false"
  />

  <InputField
    v-model="$store.state.form.start_at"
    type="datetime-local"
    label="Start date"
    name="start_at"
    help="Start date needed to be shown on platform."
    step="60"
    :required="false"
  />

  <InputField
    v-model="$store.state.form.end_at"
    type="datetime-local"
    label="End date"
    name="end_at"
    help="No end date needed to be shown on platform."
    step="60"
    :required="false"
  />

  <CheckboxField
    v-model="$store.state.form.is_enabled"
    label="Enabled"
    name="is_enabled"
    :required="false"
  />

  <SelectField
    v-model="$store.state.form.country_id"
    label="Country"
    name="country_id"
    placeholder="Select a Country"
    :items="countries"
    :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
  />

  <RoutesModal v-model:open="routesModalOpen" />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {ref} from 'vue';
import {useStore} from 'vuex';
import RoutesModal from '../../Components/RoutesModal.vue';

export default {
  components: {
    RoutesModal,
  },

  props: {
    notificationBar: {
      type: Object,
      required: false,
      default: null,
    },

    tenantMainColor: {
      type: String,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },

    authenticationStatus: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.notificationBar?.name ?? null,
      text: props.notificationBar ? props.notificationBar.text : '',
      url: props.notificationBar ? props.notificationBar.url : '',
      background_color: props.notificationBar ? props.notificationBar.background_color : props.tenantMainColor,
      authentication_status: props.notificationBar ? props.notificationBar.authentication_status : null,
      subscription_type: props.notificationBar ? props.notificationBar.subscription_type : null,
      start_at: props.notificationBar ? props.notificationBar.start_at : null,
      end_at: props.notificationBar ? props.notificationBar.end_at : null,
      is_enabled: props.notificationBar ? props.notificationBar.is_enabled : false,
      country_id: props.theme ? props.theme.country_id : null,
    });

    useStore().commit('form/setForm', form);

    return {
      availableCountries: props.countries.reduce((accumulator, country) => {
        accumulator[country.id] = country.name;
        return accumulator;
      }, {}),

      availableSubscriptionTypes: props.subscriptionTypes.reduce((accumulator, subscriptionType) => {
        accumulator[subscriptionType] = subscriptionType;
        return accumulator;
      }, {}),

      routesModalOpen: ref(false),
    };
  },

  mounted() {
    if (!this.theme && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }
  },
};
</script>
