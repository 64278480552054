<template>
  <CrudIndex
    title="Click actions"
    :columns="['ID', 'Advertiser', 'Click action', 'Unique clicks', 'Unique rewarded', 'Status', 'Added at', 'Start at', 'End at']"
    create-route-path="admin.click-actions.select-webshop"
    edit-route-path="admin.click-actions.edit"
    delete-route-path="admin.click-actions.destroy"
    :actions="true"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', color: 'blue-400', action: (item) => move(item.id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', color: 'blue-400', action: (item) => move(item.id, 'down'), enabled: true},
    ]"
    :data="actions.data"
    :paginator="actions.meta.pagination"
    :filters="[
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Is active',
        value: 'is_active',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
    ]"
  >
    <template #advertiser="{ item }">
      <a
        :href="route('admin.advertisers.edit', item.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ item.name }}
      </a>
    </template>

    <template #click_action="{ row }">
      {{ row.name }}
    </template>

    <template #unique_rewarded="{ row }">
      {{ row.unique_cashbacks }}
    </template>

    <template #status="{ row }">
      <DefaultButton
        classes="px-2 py-0 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 border-none ml-1"
        :class="{
          'bg-green-100 text-green-800' : row.is_active,
          'bg-red-100 text-red-800' : !row.is_active,
        }"
        @click.stop="updateActionActive(row.id, row.is_active)"
      >
        <span v-if="row.is_active">
          Active
        </span>
        <span v-else>
          Inactive
        </span>
      </DefaultButton>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    actions: {
      type: Object,
      required: true,
    },
  },

  methods: {
    move(clickActionId, direction) {
      this.$inertia.post(
        this.route('admin.click-actions.move', [clickActionId]),
        {move: direction},
      );
    },

    updateActionActive(actionId, isCurrentlyActive) {
      const confirmationMessage = `Are you sure you want to ${isCurrentlyActive ? 'deactivate' : 'activate'} this action?`;

      if (confirm(confirmationMessage)) {
        this.$inertia.patch(this.route('admin.click-actions.update-status', [actionId]), {is_active: !isCurrentlyActive});
      }
    },
  },
};
</script>
