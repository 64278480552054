<template>
  <CrudShow
    :title="`Transaction #${transaction.id}`"
    sub-title="Transactions"
    :back-route-path="route('admin.transactions.index')"
    :rows="[]"
    :data="transaction"
  >
    <template #cards>
      <div
        v-if="!transaction.member || !transaction.clickout"
        class="rounded-md bg-red-200 border border-red-400 mb-6 p-4"
      >
        <p class="text-sm text-red-800">
          <span class="font-medium">Mismatch transaction: no clickout or member.</span>
        </p>
      </div>

      <div
        v-if="transaction.member && transaction.member.is_deleted"
        class="rounded-md bg-red-200 border border-red-400 mb-6 p-4"
      >
        <p class="text-sm text-red-800">
          <span class="font-medium">Member is deleted.</span>
        </p>
      </div>

      <Transaction :transaction="transaction" />

      <Member
        v-if="transaction.member"
        :member="transaction.member"
      />

      <Clickout
        v-if="transaction.clickout"
        :clickout="transaction.clickout"
      />
    </template>
  </CrudShow>
</template>

<script>
import Transaction from '../../Components/Transaction.vue';
import Member from '../../Components/Member.vue';
import Clickout from '../../Components/Clickout.vue';

export default {
  components: {Transaction, Member, Clickout},

  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
};
</script>
