<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <CrudIndex
    v-else
    title="Profile questions"
    create-route-path="admin.profile-questions.create"
    edit-route-path="admin.profile-questions.edit"
    delete-route-path="admin.profile-questions.destroy"
    row-route-path="admin.profile-answers.index"
    :columns="['id', 'question', 'has multiple answers', 'has priority', 'retention']"
    :actions="true"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', color: 'blue-400', action: (item) => move(item.id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', color: 'blue-400', action: (item) => move(item.id, 'down'), enabled: true},
    ]"
    :data="questions.data"
    :paginator="questions.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Question', value: 'question'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    questions: {
      type: Object,
      required: true,
    },
  },

  methods: {
    move(questionId, direction) {
      this.$inertia.post(
        this.route('admin.profile-questions.move', [questionId]),
        {move: direction},
      );
    },
  },
};
</script>
