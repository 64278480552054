<template>
  <CrudEdit
    title="FAQ Item"
    sub-title="FAQ Category - FAQ Item"
    :index-route="route('admin.faq-categories.show', [category.id])"
    :update-route="route('admin.faq-categories.item.update', [category.id, item.id])"
  >
    <template #form>
      <ErrorList :fields="['question', 'answer']" />

      <FaqItemForm
        :item="item"
        :countries="countries"
        :page-types="pageTypes"
      />
    </template>
  </CrudEdit>
</template>

<script>
import FaqItemForm from './Form.vue';

export default {
  components: {FaqItemForm},

  props: {
    category: {
      type: Object,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },

    pageTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
