<template>
  <CrudIndex
    title="Cashbacks review"
    row-route-path="admin.cashbacks.review"
    :columns="['ID', 'Advertiser', 'Member', 'Cashback', 'Transaction', 'Transaction date', 'Reason']"
    :actions="true"
    :menu-items="[
      {name: 'Approve', action: (item) => approveCashback(item.id), enabled: true},
      {name: 'Review', url: (item) => route('admin.cashbacks.review', item.id), enabled: true},
    ]"
    :data="cashbacks.data"
    :paginator="cashbacks.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Member ID', value: 'cashbacks.member_id'}]},
      {
        type: 'relation',
        label: 'Advertiser',
        value: 'clickout.advertiser_id',
        multiple: true,
        api: route('admin.api.cashbacks.advertiser.filter'),
      },
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'cashbacks.created_at'}],
      },
    ]"
    :order-columns="{
      'ID': 'id',
      'Advertiser': 'advertiser_id',
      'Member': 'member_id',
      'Cashback': 'value',
      'Transaction': 'transactions.commission_value',
      'Transaction date': 'transactions.transaction_timestamp',
    }"
    :order="order"
    :can-select-rows="true"
  >
    <template #before-filter>
      <p class="text-gray-500 mb-2">
        When you manually ‘disapprove’ a cashback then the cashback will be disapproved and stay disapproved, regardless of the status of the linked transaction. When you manually ‘approve' a cashback then the cashback will return to the automatic flow and will be approved/disapproved by the system based on the linked transaction status and all other criteria (like cookietime out).
      </p>
    </template>

    <template #selected-actions="{ selected, disabled, selectedIds }">
      <DefaultButton
        :classes="(disabled ? 'bg-gray-400 text-gray-800 cursor-not-allowed disabled' : 'text-white bg-indigo-600 hover:bg-indigo-700')"
        :disabled="disabled"
        @click="disabled ? null : approveSelected(selectedIds)"
      >
        Approve ({{ selected }})
      </DefaultButton>

      <DropdownButton
        :items="disapproveReasons.map(reason => ({
          name: reason.name,
          action: () => disapproveSelected(selectedIds, reason.id),
        }))"
        :classes="(disabled ? 'bg-gray-400 text-gray-800 cursor-not-allowed disabled' : 'text-white bg-red-600 hover:bg-red-700')"
        :disabled="disabled"
      >
        Disapprove ({{ selected }})
      </DropdownButton>
    </template>

    <template #advertiser="{ item }">
      <a
        :href="route('admin.advertisers.edit', item.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ item.name }}
      </a>
    </template>

    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #cashback="{ row }">
      <span
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :title="row.status.status === 'DISAPPROVED' ? row.status.status_reason : ''"
        :class="{
          'bg-green-100 text-green-800' : row.status.status === 'APPROVED',
          'bg-red-100 text-red-800' : row.status.status === 'DISAPPROVED',
          'bg-yellow-100 text-yellow-800' : row.status.status === 'PENDING',
        }"
      >
        {{ row.cashback }}
      </span>
    </template>

    <template #transaction="{ row }">
      <span
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :title="row.transaction.status.status === 'DISAPPROVED' ? row.transaction.status.disapproval_reason : ''"
        :class="{
          'bg-green-100 text-green-800' : row.transaction.status.status === 'APPROVED',
          'bg-red-100 text-red-800' : row.transaction.status.status === 'DISAPPROVED',
          'bg-yellow-100 text-yellow-800' : row.transaction.status.status === 'PENDING',
        }"
      >
        {{ row.transaction.commission }}
      </span>
    </template>

    <template #transaction_date="{ row }">
      {{ row.transaction.transaction_datetime }}
    </template>

    <template #reason="{ row }">
      <ul>
        <li
          v-for="reason in row.validation_failures"
          :key="reason.id"
        >
          {{ reason.reason }}
        </li>
      </ul>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    cashbacks: {
      type: Object,
      required: true,
    },

    disapproveReasons: {
      type: Array,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  methods: {
    approveCashback(cashbackId) {
      this.$inertia.patch(this.route('admin.cashbacks.review-update', {cashback: cashbackId}), {
        action: 'approve',
      }, {
        preserveState: true,
        preserveScroll: true,
      });
    },

    approveSelected(cashbackIds) {
      this.$inertia.post(this.route('admin.cashbacks.approve-selected'), {
        cashback_ids: cashbackIds,
      });
    },

    disapproveSelected(cashbackIds, reasonId) {
      this.$inertia.post(this.route('admin.cashbacks.disapprove-selected'), {
        cashback_ids: cashbackIds,
        reason_id: reasonId,
      });
    },
  },
};
</script>
