<template>
  <div>
    <CrudIndex
      :title="survey.name"
      sub-title="Surveys"
      create-route-path="admin.survey-waves.create"
      edit-route-path="admin.survey-waves.edit"
      :links-active="true"
      :index-route="route('admin.surveys.index')"
      row-route-path="admin.survey-waves.show"
      :route-parameters="[survey]"
      :columns="['id', 'name', 'has overrule mailing']"
      :actions="true"
      :data="surveyWaves.data"
      :paginator="surveyWaves.meta.pagination"
      :filters="[
        {type: 'search', fields: [{label: 'Name', value: 'name'}]},
      ]"
      :menu-items="[
        {name: 'Create mailing', icon: 'calendar_month', url: (item) => route('admin.mailings.create', {'survey': true, 'survey_wave': item.id}), enabled: true},
        {name: 'Set inactive link', icon: 'link_off', action: (item) => openOverruleMailingModal(item.id), enabled: true},
      ]"
    />

    <OverruleMailingModal
      v-if="overruleWaveId"
      v-model:wave-id="overruleWaveId"
      :survey-id="survey.id"
    />
  </div>
</template>

<script>
import OverruleMailingModal from './OverruleMailingModal.vue';

export default {
  components: {
    OverruleMailingModal,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },

    surveyWaves: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      overruleWaveId: null,
    };
  },

  methods: {
    openOverruleMailingModal(waveId) {
      this.overruleWaveId = waveId;
    },
  },
};
</script>
