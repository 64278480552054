<template>
  <CrudCard>
    <template #content>
      <SelectField
        v-model="$store.state.form.country_id"
        label="Country"
        name="country_id"
        placeholder="Select a Country"
        :items="countries"
        :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
      />

      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />
    </template>
  </CrudCard>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    countries: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      country_id: null,
      name: null,
    });

    useStore().commit('form/setForm', form);
  },

  mounted() {
    if (parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }
  },
};
</script>
