<template>
  <CrudIndex
    title="Webshop battle logs"
    :columns="['ID', 'Winner', 'Network', 'Reason', 'Created at']"
    :data="logs.data"
    :actions="false"
    :paginator="logs.meta.pagination"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.webshops.edit', webshopId)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Back to webshop
      </InertiaLink>

      <InertiaLink
        :href="route('admin.webshop-trigger-battle', {webshop: webshopId})"
        class="inline-flex ml-3 items-center px-4 py-2 border text-white text-sm font-medium rounded-md bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-indigo-600"
      >
        Battle Now
      </InertiaLink>
    </template>

    <template #winner="{ row }">
      <InertiaLink
        :href="route('admin.programs.edit', row.winner_program_id)"
        class="text-indigo-600 hover:text-indigo-900"
      >
        {{ row.winner_program_name }}
      </InertiaLink>
    </template>
  </CrudIndex>
</template>

<script>

export default {
  props: {
    webshopId: {
      type: Number,
      required: true,
    },

    logs: {
      type: Object,
      required: true,
    },
  },
};
</script>
