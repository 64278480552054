<template>
  <CrudEdit
    :title="`Review #${cashback.id}`"
    sub-title="Cashbacks"
    :index-route="route('admin.cashbacks.index')"
    :update-route="route('admin.cashbacks.review-update', cashback.id)"
    action="Approve"
  >
    <template #after-cancel>
      <DropdownButton
        :items="disapproveReasons.map(reason => ({
          name: reason.name,
          action: () => doDisapprove(reason.id),
        }))"
        classes="text-white bg-red-600 hover:bg-red-700"
      >
        Disapprove
      </DropdownButton>
    </template>

    <template #cards>
      <CrudCard>
        <template #header>
          <span class="font-medium">
            Cashback
          </span>
        </template>

        <template #content>
          <CrudShowContent
            :rows="['ID', 'Member', 'Advertiser', 'Clickout', 'Transaction', 'Cashback', 'Status', 'Date', 'Last update']"
            :data="cashback"
          >
            <template #member="{ data }">
              <a
                :href="route('admin.members.edit', data.member_id)"
                class="text-indigo-600 hover:text-indigo-900"
                target="_blank"
                @click.stop
              >
                #{{ data.member_id }}
              </a>
            </template>

            <template #advertiser="{ data }">
              <a
                :href="route('admin.advertisers.edit', data.advertiser.id)"
                class="text-indigo-600 hover:text-indigo-900"
                target="_blank"
                @click.stop
              >
                {{ data.advertiser.name }}
              </a>
            </template>

            <template #clickout>
              <a
                :href="route('admin.members.clickouts.show', [cashback.member_id, clickout.id])"
                class="text-indigo-600 hover:text-indigo-900"
                target="_blank"
                @click.stop
              >
                Clickout #{{ clickout.id }}
              </a>
            </template>

            <template #transaction="{ item }">
              <a
                :href="route('admin.transactions.show', item)"
                class="text-indigo-600 hover:text-indigo-900"
                target="_blank"
                @click.stop
              >
                Transaction #{{ item }}
              </a>
            </template>

            <template #status="{ item }">
              <p class="flex items-center text-sm">
                <span
                  class="mr-2 px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
                  :class="{
                    'bg-green-100 text-green-800' : item.status === 'APPROVED',
                    'bg-red-100 text-red-800' : item.status === 'DISAPPROVED',
                    'bg-yellow-100 text-yellow-800' : item.status === 'PENDING',
                  }"
                >
                  {{ item.status }}
                </span>
                {{ item.status_reason }}
              </p>
            </template>
          </CrudShowContent>
        </template>
      </CrudCard>

      <CrudCard padding="pb-6">
        <template #header>
          <span class="font-medium">
            Validation failures
          </span>
        </template>

        <template #content>
          <table class="min-w-full table-fixed">
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="failure in cashback.validation_failures"
                :key="failure.id"
                class="even:bg-gray-50 hover:bg-gray-100 focus-within:bg-gray-100"
              >
                <td class="w-1/3 p-4 text-sm font-medium text-gray-900">
                  {{ failure.name }}
                </td>
                <td class="p-4 text-sm font-medium text-gray-900">
                  {{ failure.reason }}
                </td>
              </tr>

              <tr v-if="cashback.validation_failures.length === 0">
                <td
                  class="border-t px-6 py-4 text-center"
                  colspan="2"
                >
                  No results found.
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </CrudCard>

      <Clickout :clickout="clickout" />

      <Member :member="cashback.member" />
    </template>
  </CrudEdit>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import Clickout from '../../Components/Clickout.vue';
import Member from '../../Components/Member.vue';

export default {
  components: {Clickout, Member},

  props: {
    cashback: {
      type: Object,
      required: true,
    },

    clickout: {
      type: Object,
      required: true,
    },

    disapproveReasons: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      action: 'approve',
    });

    useStore().commit('form/setForm', form);
  },

  methods: {
    doDisapprove(reasonId) {
      const payload = {
        action: 'disapprove',
        reason_id: reasonId,
      };
      this.$inertia.patch(this.route('admin.cashbacks.review-update', [this.cashback.id]), payload);
    },
  },
};
</script>
