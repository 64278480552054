<template>
  <CrudIndex
    :title="`Notes: #${member.id}`"
    :route-parameters="[member.id]"
    create-route-path="admin.members.notes.create"
    :columns="['ID', 'Note', 'Created by', 'Updated by']"
    :data="notes.data"
    :paginator="notes.meta.pagination"
    :filters="[{type: 'search', fields: [{label: 'Note', value: 'note'}]}]"
    :actions="true"
    :menu-items="[
      {name: 'Edit', url: (item) => route('admin.members.notes.edit', [member.id, item.id]), enabled: true},
      {name: 'Delete', action: (item) => destroy(member.id, item.id), enabled: true},
    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.members.edit', member.id)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Member
      </InertiaLink>
    </template>

    <template #note="{ item }">
      <span class="whitespace-pre">{{ item }}</span>
    </template>

    <template #created_by="{ item, row }">
      <span v-if="item">{{ item.name }}</span>
      <span v-else>System</span>
      ({{ row.created_at }})
    </template>

    <template #updated_by="{ item, row }">
      <span v-if="item">
        {{ item.name }} ({{ row.updated_at }})
        <InertiaLink :href="route('admin.members.notes.history', [row.member_id, row.id])">
          <span
            class="material-icons md-24 text-indigo-600 hover:text-indigo-900"
            title="Show history"
          >
            history
          </span>
        </InertiaLink>
      </span>
      <span v-else>-</span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },

    notes: {
      type: Object,
      required: true,
    },
  },

  methods: {
    destroy(memberId, noteId) {
      if (confirm('Are you sure you want to delete this note?')) {
        this.$inertia.delete(this.route('admin.members.notes.destroy', [memberId, noteId]));
      }
    },
  },
};
</script>
