<template>
  <CrudEdit
    title="Profile questions - Edit"
    sub-title="Profile questions"
    :index-route="route('admin.profile-questions.index')"
    :update-route="route('admin.profile-questions.update', question.id)"
  >
    <template #form>
      <QuestionForm
        :selected-country-id="selectedCountryId"
        :question="question"
      />
    </template>
  </CrudEdit>
</template>

<script>
import QuestionForm from './Form.vue';

export default {
  components: {
    QuestionForm,
  },

  props: {
    selectedCountryId: {
      type: Number,
      required: true,
    },

    question: {
      type: Object,
      required: true,
    },
  },
};
</script>
