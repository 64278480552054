<template>
  <div class="col-span-2">
    <MultiSelectField
      :model-value="item.mailing_filter_id"
      name="mailing_filter_id"
      placeholder="Select mailing filter file"
      mode="single"
      :required="true"
      :inline="true"
      :searchable="true"
      :can-deselect="false"
      :can-clear="false"
      :resolve-on-load="item.mailing_filter_id"
      :min-chars="2"
      :delay="150"
      :loading="loading"
      :options="async (query) => await fetchMailingFilter(query)"
      @change="value => item.mailing_filter_id = parseInt(value)"
    />
  </div>
  <div class="col-span-2 flex">
    <label
      v-for="(label, key) of mailingFilterItemOperators"
      :key="`operator-${key}`"
      class="mr-2 flex items-center"
    >
      <input
        v-model="item.operator"
        :value="key"
        type="radio"
        class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
      <span class="ml-2">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import Base from '../Base';

export default {
  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const loading = ref(false);
    const item = ref(props.modelValue);
    item.value.operator = props.modelValue?.operator || Object.keys(props.mailingFilterItemOperators)[0];
    item.value.mailing_filter_id = props.modelValue?.mailing_filter_id || null;

    return {loading, item};
  },

  methods: {
    fetchMailingFilter(query) {
      this.loading = true;

      let fetchInitial = query === null;
      if (fetchInitial) {
        return axios.get(this.route('admin.api.mailing-filters.find', {id: this.item.mailing_filter_id}))
          .then(({data}) => {
            this.loading = false;
            return data;
          });
      }

      return axios.get(this.route('admin.api.mailing-filters.search.name', {name: query}))
        .then(({data}) => {
          this.loading = false;
          return data;
        });
    },
  },
};
</script>
