<template>
  <CrudCreate
    title="Language - Create"
    sub-title="Languages"
    :index-route="route('admin.languages.index')"
    :store-route="route('admin.languages.store')"
  >
    <template #form>
      <LanguageForm />
    </template>
  </CrudCreate>
</template>

<script>
import LanguageForm from './Form.vue';

export default {
  components: {LanguageForm},
};
</script>
