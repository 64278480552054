<template>
  <CrudCreate
    title="Webshop Theme - Create"
    sub-title="Webshop Theme"
    :index-route="route('admin.webshop-themes.index')"
    :store-route="route('admin.webshop-themes.store')"
  >
    <template #cards>
      <ThemeForm
        :countries="countries"
        :minimum-media-size="minimumMediaSize"
        :sort-options="sortOptions"
        :block-types="blockTypes"
        :page-types="pageTypes"
      />
    </template>
  </CrudCreate>
</template>

<script>
import ThemeForm from './Form.vue';

export default {
  components: {ThemeForm},

  props: {
    countries: {
      type: Object,
      required: true,
    },

    minimumMediaSize: {
      type: Array,
      required: true,
    },

    sortOptions: {
      type: Array,
      required: true,
    },

    blockTypes: {
      type: Array,
      required: true,
    },

    pageTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
