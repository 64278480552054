<template>
  <CrudIndex
    title="Transaction stats"
    :columns="['Status', 'Commission height', 'Transactions']"
    :actions="true"
    :data="statsPerStatus"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}],
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Promotion type',
        value: 'model_type',
        multiple: true,
        items: filterablePromotionTypes,
      },
    ]"
  />

  <CrudIndex
    title="Commission per advertiser"
    :columns="['Advertiser', 'Approved + pending', 'Approved', 'Pending', 'Disapproved', 'Transactions']"
    :data="statsPerAdvertiser.data"
    :paginator="statsPerAdvertiser.meta.pagination"
    :order-columns="{
      'Advertiser': 'advertiser_id',
      'Approved + pending': 'approved_pending',
      'Approved': 'approved',
      'Pending': 'pending',
      'Disapproved': 'disapproved',
      'Transactions': 'transactions',
    }"
    :order="order"
  />

  <SiteHead
    title="Transaction stats"
  />
</template>

<script>
export default {
  props: {
    promotionTypes: {
      type: Object,
      required: true,
    },

    statsPerStatus: {
      type: Object,
      required: true,
    },

    statsPerAdvertiser: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let filterablePromotionTypes = [];
    Object.entries(props.promotionTypes).forEach(entry => {
      const [key, value] = entry;
      filterablePromotionTypes.push({name: value, value: key});
    });

    return {
      filterablePromotionTypes: filterablePromotionTypes,
    };
  },
};
</script>
