<template>
  <CrudIndex
    :title="category.name"
    create-route-path="admin.term-categories.term-item.create"
    edit-route-path="admin.term-categories.term-item.edit"
    row-route-path="admin.term-categories.term-item.edit"
    delete-route-path="admin.term-categories.term-item.destroy"
    :route-parameters="[category.id]"
    :columns="['id', 'description', 'program specific', 'attached program']"
    :actions="true"
    :data="items.data"
    :paginator="items.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Description', value: 'description'}]},
      {type: 'relation', label: 'Program', value: 'program', api: route('admin.api.program-term-categories.attached-programs', [category.id])},
    ]"
  >
    <template #program_specific="{ row }">
      <span
        v-if="row.program_id"
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        Yes
      </span>
      <span
        v-else
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        No
      </span>
    </template>

    <template #attached_program="{ row }">
      <InertiaLink
        v-if="row.program_id"
        :href="route('admin.programs.edit', row.program_id)"
        class="text-indigo-600 hover:text-indigo-900"
      >
        {{ row.program.name }}
      </InertiaLink>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },

    items: {
      type: Object,
      required: true,
    },
  },
};
</script>
