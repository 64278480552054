<template>
  <MultiSelectField
    v-model="$store.state.form.country_ids"
    label="Active countries"
    name="country_ids"
    placeholder="Select countries"
    mode="tags"
    :options="availableCountries"
  />

  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
    help="In English"
  />

  <TextareaField
    v-model="$store.state.form.description"
    label="Description"
    name="description"
    help="In English"
    rows="6"
    :required="false"
  />

  <CheckboxField
    v-model="$store.state.form.is_enabled"
    label="Enabled"
    name="is_enabled"
    :required="false"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    category: {
      type: Object,
      required: false,
      default: null,
    },

    countries: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      country_ids: props.category ? props.category.country_ids : null,
      name: props.category ? props.category.name : null,
      description: props.category ? props.category.description : null,
      is_enabled: props.category ? props.category.is_enabled : false,
    });

    useStore().commit('form/setForm', form);

    return {
      availableCountries: props.countries.reduce((accumulator, country) => {
        accumulator[country.id] = country.name;
        return accumulator;
      }, {}),
    };
  },
};
</script>
