<template>
  <CrudEdit
    :title="`On hold transaction #${transaction.id}`"
    sub-title="On hold transactions"
    :index-route="route('admin.transactions-on-hold.index')"
    :update-route="route('admin.transactions-on-hold.process', transaction.id)"
    action="Process again"
  >
    <template #cards>
      <Transaction :transaction="transaction" />

      <Member :member="transaction.member" />

      <Clickout :clickout="transaction.clickout" />
    </template>
  </CrudEdit>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import Transaction from '../../Components/Transaction.vue';
import Member from '../../Components/Member.vue';
import Clickout from '../../Components/Clickout.vue';

export default {
  components: {Transaction, Member, Clickout},

  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm();

    useStore().commit('form/setForm', form);
  },
};
</script>
