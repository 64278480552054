<template>
  <CrudEdit
    :title="game.name"
    sub-title="Games"
    :index-route="route('admin.games.index')"
    :update-route="route('admin.games.update', game.id)"
  >
    <template #cards>
      <GameForm
        :countries="countries"
        :registered-games="registeredGames"
        :game="game"
      />
    </template>
  </CrudEdit>
</template>

<script>
import GameForm from './Form.vue';

export default {
  components: {GameForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },

    registeredGames: {
      type: Array,
      required: true,
    },

    game: {
      type: Object,
      required: true,
    },
  },
};
</script>
