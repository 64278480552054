<template>
  <div class="mb-2 !border-0 !p-0">
    <div
      v-if="showOperatorIndicator"
      class="mb-2 text-center text-xs uppercase"
    >
      AND
    </div>

    <div class="rounded-md bg-gray-50 border border-gray-200">
      <div class="px-4 py-2 grid grid-cols-6 gap-2">
        <div class="py-2">
          {{ mailingFilterItems[item.id] }}
        </div>

        <Component
          :is="getComponentNameForItem(item)"
          v-model="item"
          :genders="genders"
          :provinces="provinces"
          :survey-statuses="surveyStatuses"
          :mailing-filter-items="mailingFilterItems"
          :mailing-filter-item-explanations="mailingFilterItemExplanations"
          :mailing-filter-item-file-fields="mailingFilterItemFileFields"
          :mailing-filter-item-operators="mailingFilterItemOperators"
        />

        <div class="text-right">
          <button
            type="button"
            class="p-2"
            @click="remove"
          >
            <i class="far fa-times" />
          </button>
        </div>

        <div
          v-if="itemHasExplanation(item)"
          class="col-span-6 block text-sm text-gray-400"
        >
          {{ mailingFilterItemExplanations[item.id] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import camelCase from 'lodash/camelCase';
import AgeItem from './Items/Age.vue';
import MailingFilterItem from './Items/MailingFilter.vue';
import MailingFilterFileItem from './Items/MailingFilterFile.vue';
import GenderItem from './Items/Gender.vue';
import ProfileQuestionItem from './Items/ProfileQuestion.vue';
import ProvinceItem from './Items/ProvinceItem.vue';
import SurveyWaveItem from './Items/SurveyWave.vue';
import SurveyWaveStatusItem from './Items/SurveyWaveStatus.vue';
import Base from './Base';

export default {
  components: {
    AgeItem,
    MailingFilterItem,
    MailingFilterFileItem,
    GenderItem,
    ProfileQuestionItem,
    ProvinceItem,
    SurveyWaveItem,
    SurveyWaveStatusItem,
  },

  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },

    index: {
      required: true,
      type: Number,
    },
  },

  emits: ['update:modelValue', 'delete'],

  setup(props) {
    const item = ref(props.modelValue.item);

    return {item};
  },

  computed: {
    showOperatorIndicator () {
      return this.depth > 0 && this.order > 0;
    },
  },

  methods: {
    getComponentNameForItem(item) {
      return camelCase(item.id) + 'Item';
    },

    itemHasExplanation(item) {
      return this.mailingFilterItemExplanations
        && Object.keys(this.mailingFilterItemExplanations).includes(item.id);
    },

    remove() {
      this.$emit('delete', this.index);
    },
  },
};
</script>
