<template>
  <CrudIndex
    :title="`Permissions for ${role.name} role`"
    sub-title="Roles"
    :links-active="true"
    action="Update permissions"
    :index-route="route('admin.roles.index')"
    :columns="['name', 'has permission']"
    :actions="false"
    :data="allPermissions"
  >
    <template #before-filter>
      <ErrorList />
    </template>

    <template #before-create>
      <DefaultLink :href="route('admin.roles.index')">
        Cancel
      </DefaultLink>
      <DefaultButton
        v-if="!hasChanges()"
        classes="bg-gray-200 text-gray-400 cursor-not-allowed disabled"
        :disabled="true"
      >
        Update
      </DefaultButton>
      <DefaultButton
        v-else
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        :disabled="$store.state.form.processing"
        @click.prevent="$store.state.form.post(route('admin.permissions.update', [role.id]))"
      >
        Update
      </DefaultButton>
    </template>

    <template #has_permission="{ row }">
      <CheckboxField
        v-model="$store.state.form.permissions[row.id]"
        :name="`permissions[${row.id}]`"
        :required="false"
      />
    </template>
  </CrudIndex>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    role: {
      type: Object,
      required: true,
    },

    allPermissions: {
      type: Array,
      required: true,
    },

    rolePermissionIds: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const permissions = {};
    for (const permission of props.allPermissions) {
      const permissionId = permission.id;
      permissions[permissionId] = props.rolePermissionIds.includes(permissionId);
    }

    const form = useForm({
      permissions: permissions,
    });

    useStore().commit('form/setForm', form);

    return {
      initialPermissions: JSON.parse(JSON.stringify(permissions)), // clone object, do not keep reference
    };
  },

  methods: {
    hasChanges() {
      return JSON.stringify(this.initialPermissions) !== JSON.stringify(this.$store.state.form.permissions);
    },
  },
};
</script>
