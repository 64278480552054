<template>
  <ModalForm
    :id="`mailing_check_form_${mailingId}`"
    v-model:open="modalOpen"
    :submit-route="route('admin.mailings.submit-check', mailingId)"
  >
    <template #fields>
      <h1
        class="text-2xl font-bold leading-7 text-gray-900 sm:truncate capitalize"
      >
        {{ mailingStatus.replaceAll('_', ' ') }}
      </h1>

      <SelectField
        v-model="approval"
        label="Approval"
        name="approval"
        placeholder="Select"
        :items="[
          {id: 'approved', name: 'Approved'},
          {id: 'disapproved', name: 'Disapproved'},
        ]"
        :required="true"
      />

      <TextareaField
        v-if="approval === 'disapproved'"
        label="Disapproval reason"
        name="disapproval_reason"
        rows="6"
        :required="true"
      />
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from '../ModalForm.vue';

export default {
  components: {ModalForm},

  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    mailingStatus: {
      type: String,
      required: true,
    },
  },

  emits: ['update:mailingId'],

  data() {
    return {
      approval: '',
      modalOpen: this.mailingId > 0,
    };
  },

  watch: {
    modalOpen: function (newValue) {
      if (newValue === false) {
        this.$emit('update:mailingId', null);
      }
    },
  },
};
</script>
