<template>
  <div>
    <CrudIndex
      title="Mailing planning"
      row-route-path="admin.mailings.edit"
      :columns="columns"
      :actions="true"
      :menu-items="menuItems"
      :data="mailings.data"
      :paginator="mailings.meta.pagination"
      :filters="disableFilters ? [] : filters"
    >
      <template #before-create>
        <DefaultButton
          v-if="!disableRefresher"
          classes="text-white bg-indigo-600 hover:bg-indigo-700"
          @click="refresh"
        >
          Refreshing (<span class="font-bold">{{ refreshSeconds }}</span>)
        </DefaultButton>
      </template>

      <template #name="{ row }">
        <template v-if="!row.auto_remail_mailing_id">
          {{ row.name }}
        </template>
        <template v-else>
          <div class="bg-blue-600 text-white">
            {{ row.name }}
          </div>
        </template>
      </template>

      <template #planner="{ row }">
        {{ row.planner_name }}
      </template>

      <template #controller="{ row }">
        {{ row.controller_name }}
      </template>

      <template #scheduled_at="{ item, row }">
        <div class="flex">
          <MailingScheduledAt
            :mailing-id="row.id"
            :scheduled-at="item"
            :can-be-changed="!nonEditableScheduledAtStatuses.includes(row.status)"
          />
        </div>
      </template>

      <template #status="{ item, row }">
        <div class="flex">
          <MailingStatus
            :mailing-id="row.id"
            :status="item"
            :hold-reason="row.hold_reason"
            :is-stopped="row.is_stopped"
            :overrule-mailing-id="row.overrule_mailing_id"
            :allowed-statuses="allowedStatuses"
            :tracking-url="row.tracking_url"
            :pre-header-text="row.pre_header_text"
            :subject="row.subject"
            :mailing-filter-name="row.mailing_filter_name"
            :percentiles="row.percentiles"
          />

          <StopMailing
            v-if="item === 'busy' && !row.is_stopped"
            :mailing-id="row.id"
          />

          <span
            v-if="!row.last_test_status"
            class="material-icons md-18 text-red-500"
            title="Mailing test is still busy"
          >
            hourglass_top
          </span>
        </div>
      </template>

      <template #recipients="{ row }">
        <div v-if="recipientsLoading[row.id]">
          <div class="text-gray-600">
            <span class="material-icons md-18 mr-2 animate-spin">autorenew</span>
          </div>
        </div>
        <div
          v-else
          class="flex items-center"
        >
          {{ recipients[row.id] }}

          <Popover
            v-if="row.is_limited"
            class="h-5 w-5 ml-1"
          >
            <PopoverButton class="inline-block">
              <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
            </PopoverButton>
            <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
              <p class="w-full">
                The recipients for limited mailings are an estimate. The number will not be more than this, but can be less. The exact number will be visible in the stats when the mailing is sent.
              </p>
            </PopoverPanel>
          </Popover>
        </div>
      </template>

      <template #segmentation="{ row }">
        {{ row.segmentation_name }}
      </template>
    </CrudIndex>

    <EmailPreviewModal
      v-if="emailPreviewMailingId"
      v-model:mailing-id="emailPreviewMailingId"
      v-model:email-preview-url="emailPreviewUrl"
    />

    <AdvertiserCheckModal
      v-if="advertiserCheckMailingId"
      v-model:mailing-id="advertiserCheckMailingId"
      v-model:submit-url="advertiserCheckUrl"
    />

    <MailingCheckModal
      v-if="mailingCheckMailingId"
      v-model:mailing-id="mailingCheckMailingId"
      v-model:mailing-status="mailingCheckMailingStatus"
    />

    <OverruleMailingModal
      v-if="overruleMailingId"
      v-model:mailing-id="overruleMailingId"
      v-model:current-overrule-mailing-id="currentOverruleMailingId"
    />
  </div>
</template>

<script>
import {InformationCircleIcon} from '@heroicons/vue/solid';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import axios from 'axios';
import EmailPreviewModal from '../EmailPreviewModal.vue';
import AdvertiserCheckModal from '../AdvertiserCheckModal.vue';
import MailingCheckModal from '../MailingCheckModal.vue';
import OverruleMailingModal from '../OverruleMailingModal.vue';
import MailingStatus from '../MailingStatus.vue';
import StopMailing from '../StopMailing.vue';
import MailingScheduledAt from '../MailingScheduledAt.vue';

export default {
  components: {
    MailingStatus,
    StopMailing,
    MailingScheduledAt,
    MailingCheckModal,
    EmailPreviewModal,
    AdvertiserCheckModal,
    OverruleMailingModal,
    InformationCircleIcon,
    Popover, PopoverButton, PopoverPanel,
  },

  props: {
    mailings: {
      type: Object,
      required: true,
    },

    allowedStatuses: {
      type: Array,
      required: true,
    },

    nonEditableScheduledAtStatuses: {
      type: Array,
      required: true,
    },

    columns: {
      type: Array,
      required: false,
      default: () => ['Name', 'Planner', 'Controller', 'Scheduled at', 'Status', 'Recipients', 'ID'],
    },

    disableRefresher: {
      type: Boolean,
      required: false,
      default: false,
    },

    filterableNotificationTypes: {
      type: Array,
      required: false,
      default: () => [],
    },

    disableFilters: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableAdvertiserFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableNotificationTypeFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyWaveFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    showDuplicateMenuItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableInactiveLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    canAdvertiserCheck: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    const defaultRefreshSeconds = 30;
    const menuItems = [
      {
        name: 'Check',
        icon: 'flaky',
        action: (item) => this.openMailingCheckModal(item),
        enabled: (item) => ['advertiser_check', 'first_internal_check', 'final_internal_check'].includes(item.status),
      },
      {name: 'Spamscore', icon: 'scoreboard', url: (item) => this.route('admin.mailings.tests.index', item.id), enabled: true},
      {name: 'Notes', icon: 'notes', url: (item) => this.route('admin.mailings.notes.index', item.id), enabled: true},
      {name: 'Preview', icon: 'preview', url: (item) => item.preview_url, target: '_blank', enabled: true},
      {name: 'Email preview', icon: 'forward_to_inbox', action: (item) => this.openEmailPreviewModal(item.id, item.email_preview_url), enabled: true},
    ];

    if (this.canAdvertiserCheck) {
      menuItems.push({name: 'Advertiser check', icon: 'how_to_reg', action: (item) => this.openAdvertiserCheckModal(item.id, item.advertiser_check_url), enabled: true});
    }

    menuItems.push({name: 'Delete from overview', icon: 'delete', action: (item) => this.deleteFromOverview(item.id), enabled: (item) => item.status === 'hold'});

    if (this.showDuplicateMenuItem) {
      menuItems.push(
          {name: 'Duplicate', icon: 'content_copy', url: (item) => this.route('admin.mailings.duplicate', item.id), enabled: (item) => item.can_duplicate},
      );
      menuItems.push(
          {name: 'Duplicate multiple', icon: 'dynamic_feed', url: (item) => this.route('admin.mailings.duplicate-multiple', {mailing: item.id}), enabled: (item) => item.can_duplicate},
      );
    }

    if (this.enableInactiveLink) {
      menuItems.push(
        {name: 'Set overrule mailing', icon: 'link_off', action: (item) => this.openOverruleMailingModal(item.id, item.overrule_mailing_id), enabled: true},
      );
    }

    menuItems.push(
      {name: 'Activate', icon: 'play_circle', action: (item) => this.toggleActiveStatus(item.id, true), enabled: (item) => !item.is_active},
      {name: 'Deactivate', icon: 'pause_circle', action: (item) => this.toggleActiveStatus(item.id, false), enabled: (item) => item.is_active},
    );

    menuItems.push(
        {name: 'Edit', icon: 'edit', url: (item) => this.route('admin.mailings.edit', item.id), enabled: (item) => 'canEdit' in item ? item.canEdit : true},
    );
    menuItems.push(
        {name: 'Delete', icon: 'delete_forever', action: (item) => this.destroy(item.id), enabled: (item) => 'canDelete' in item ? item.canDelete : true},
    );

    const filters = [
      {
        type: 'date-range',
        label: 'Date',
        timezone: this.$page.props.auth.user.timezone,
        fields: [
          {label: 'Scheduled at', value: 'scheduled_at'},
          {label: 'Sent at', value: 'sent_at'},
        ],
      },
    ];

    if (!this.disableAdvertiserFilter) {
      filters.push({
        type: 'relation',
        label: 'Advertiser',
        value: 'advertiser_id',
        multiple: true,
        api: this.route('admin.api.mailings.advertiser.filter'),
      });
    }

    if (!this.disableNotificationTypeFilter) {
      filters.push({
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Notification types',
        value: 'notification_type',
        multiple: true,
        items: this.filterableNotificationTypes,
      });
    }

    if (this.enableSurveyFilter) {
      filters.push({
        type: 'relation',
        label: 'Survey',
        value: 'survey_id',
        multiple: true,
        api: this.route('admin.api.surveys.filter'),
      });
    }

    if (this.enableSurveyWaveFilter) {
      filters.push({
        type: 'relation',
        label: 'Survey Wave',
        value: 'survey_wave_id',
        multiple: true,
        api: this.route('admin.api.survey-waves.filter'),
      });
    }

    const recipientsLoading = {};
    const recipients = {};
    for (const mailingDay of Object.values(this.mailings.data ?? {})) {
      for (const mailing of mailingDay) {
        recipientsLoading[mailing.id] = true;
        recipients[mailing.id] = 'n/a';
      }
    }

    return {
      filters,
      menuItems,
      defaultRefreshSeconds: defaultRefreshSeconds,
      refreshSeconds: defaultRefreshSeconds,
      refreshTimer: null,
      mailingCheckMailingId: null,
      mailingCheckMailingStatus: '',
      emailPreviewMailingId: null,
      emailPreviewUrl: null,
      advertiserCheckMailingId: null,
      advertiserCheckUrl: null,
      overruleMailingId: null,
      currentOverruleMailingId: null,
      recipientsLoading: recipientsLoading,
      recipients: recipients,
    };
  },

  mounted() {
    this.fetchRecipients();

    if (!this.disableRefresher) {
      this.refreshTimer = setInterval(this.updateRefreshTimer, 1000);
    }
  },

  beforeUnmount() {
    clearInterval(this.refreshTimer);
  },

  methods: {
    fetchRecipients() {
      for (const mailingId in this.recipientsLoading) {
        axios.get(this.route('admin.api.mailings.recipients', {id: mailingId}))
          .then(({data}) => {
            this.recipientsLoading[mailingId] = false;
            this.recipients[mailingId] = data.recipients;
          });
      }
    },

    updateRefreshTimer() {
      if (this.emailPreviewMailingId || this.mailingCheckMailingId || this.overruleMailingId || this.advertiserCheckMailingId) {
        // prevent refreshing if a modal is open
        return;
      }

      this.refreshSeconds--;

      if (this.refreshSeconds === 0) {
        this.refresh();
      }
    },

    refresh() {
      this.refreshSeconds = this.defaultRefreshSeconds;
      this.$inertia.reload({
        preserveScroll: true,
        preserveState: true,
      });

      this.fetchRecipients();
    },

    openMailingCheckModal(mailing) {
      this.mailingCheckMailingId = mailing.id;
      this.mailingCheckMailingStatus = mailing.status;
    },

    openEmailPreviewModal(mailingId, emailPreviewUrl) {
      this.emailPreviewMailingId = mailingId;
      this.emailPreviewUrl = emailPreviewUrl;
    },

    openAdvertiserCheckModal(mailingId, advertiserCheckUrl) {
      this.advertiserCheckMailingId = mailingId;
      this.advertiserCheckUrl = advertiserCheckUrl;
    },

    openOverruleMailingModal(mailingId, overruleMailingId) {
      this.overruleMailingId = mailingId;
      this.currentOverruleMailingId = overruleMailingId;
    },

    deleteFromOverview(mailingId) {
      if (confirm('Are you sure you want to delete this mailing from the overview?')) {
        this.$inertia.delete(this.route('admin.mailing-planning.delete-from-overview', mailingId));
      }
    },

    toggleActiveStatus(mailingId, activate) {
      const confirmMessage = activate
        ? 'Are you sure you want to activate this mailing?'
        : 'Are you sure you want to deactivate this mailing?\n\nWhen a mailing is deactivated the member will be redirected to the homepage instead of a clickout url.';

      if (confirm(confirmMessage)) {
        this.$inertia.post(this.route('admin.mailing-planning.toggle-active-status', {mailing: mailingId, activate}));
      }
    },

    destroy(mailingId) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$inertia.delete(this.route('admin.mailings.destroy', mailingId));
      }
    },
  },
};
</script>
