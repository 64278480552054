<template>
  <CrudIndex
    title="Games stats"
    :columns="['Game', 'Total played', 'Unique players']"
    :actions="true"
    :data="totalStats"
    :filters="[
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Game',
        value: 'game',
        multiple: true,
        items: filterableGames,
      },
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'date'}],
      },
    ]"
  />

  <CrudIndex
    title="Last 50 days"
    :columns="['Date', 'Total played', 'Unique players']"
    :data="dayStats"
  />

  <SiteHead
    title="Games stats"
  />
</template>

<script>
export default {
  props: {
    games: {
      type: Object,
      required: true,
    },

    totalStats: {
      type: Object,
      required: true,
    },

    dayStats: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let filterableGames = [];
    Object.entries(props.games).forEach(entry => {
      const [key, value] = entry;
      filterableGames.push({name: value, value: key});
    });

    return {
      filterableGames: filterableGames,
    };
  },
};
</script>
