<template>
  <CrudIndex
    title="Roles"
    create-route-path="admin.roles.create"
    edit-route-path="admin.roles.edit"
    row-route-path="admin.permissions.index"
    :columns="['id', 'name']"
    :actions="true"
    :data="roles.data"
    :paginator="roles.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    roles: {
      type: Object,
      required: true,
    },
  },
};
</script>
