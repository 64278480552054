<template>
  <CrudIndex
    title="Languages"
    create-route-path="admin.languages.create"
    edit-route-path="admin.languages.edit"
    row-route-path="admin.languages.edit"
    delete-route-path="admin.languages.destroy"
    :columns="['ID', 'Is Default', 'Name', 'ISO']"
    :actions="true"
    :data="languages.data"
    :paginator="languages.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}, {label: 'ISO', value: 'iso'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    languages: {
      type: Object,
      required: true,
    },
  },
};
</script>
