<template>
  <CrudIndex
    title="Members"
    row-route-path="admin.members.edit"
    :columns="['ID', 'Country', 'Name', 'Username', 'Email', 'Registration Date']"
    :actions="true"
    :menu-items="[
      {name: 'view', url: (item) => route('admin.members.edit', item.id), enabled: true},
    ]"
    :data="members.data"
    :paginator="members.meta.pagination"
    :filters="[
      {type: 'search', fields: [
        {label: 'ID', value: 'id', checked: false},
        {label: 'Username', value: 'username', checked: false},
        {label: 'First name', value: 'first_name', checked: false},
        {label: 'Last name', value: 'last_name', checked: false},
        {label: 'Email', value: 'email'},
        {label: 'External ID', value: 'external_id', checked: false},
        {label: 'Bank', value: 'bank', checked: false},
        {label: 'Click ID', value: 'click_id', checked: false},
        {label: 'Share ID', value: 'share_id', checked: false},
      ], defaultOperator: 'equals'},
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Created At', value: 'created_at'}],
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Possible cheater',
        value: 'is_possible_cheater',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Fraudster',
        value: 'is_fraudster',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
    ]"
  >
    <template #before-create>
      <DefaultButton
        :href="route('admin.deleted-members.index')"
        classes="relative inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Deleted members
      </DefaultButton>
    </template>

    <template
      v-if="deletedMembersFound > 0"
      #after-filter
    >
      <div class="flex items-center justify-between bg-orange-300 border border-gray-200 rounded-lg shadow-sm px-6 py-4 mb-4">
        <p>
          <i class="fal fa-exclamation-circle" />
          There {{ deletedMembersFound === 1 ? 'is' : 'are' }} {{ deletedMembersFound }} deleted member{{ deletedMembersFound === 1 ? '' : 's' }} found for your search filter.
          <InertiaLink
            :href="route('admin.deleted-members.index', requestFilter)"
            class="text-indigo-600 hover:text-indigo-900"
          >
            Show the results.
          </InertiaLink>
        </p>
      </div>
    </template>

    <template #name="{ row }">
      {{ row.first_name }} {{ row.last_name }}
    </template>

    <template #country="{ row }">
      {{ row.country_name }}
    </template>

    <template #registration_date="{ row }">
      {{ row.created_at }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    members: {
      type: Object,
      required: true,
    },

    deletedMembersFound: {
      type: Number,
      required: true,
    },

    requestFilter: {
      type: Object,
      required: true,
    },
  },
};
</script>
