<template>
  <CrudIndex
    title="Network Guarantees"
    create-route-path="admin.network-revenue-statistics.network-guarantees.create"
    edit-route-path="admin.network-revenue-statistics.network-guarantees.edit"
    row-route-path="admin.network-revenue-statistics.network-guarantees.edit"
    delete-route-path="admin.network-revenue-statistics.network-guarantees.destroy"
    :columns="['Network', 'Date', 'Value']"
    :actions="true"
    :data="networkGuarantees.data"
    :paginator="networkGuarantees.meta.pagination"
    :filters="[
      {
        type: 'dropdown',
        operator: 'equals_array',
        label: 'Network',
        value: 'affiliwingsChannel.network_id',
        multiple: true,
        items: affiliwingsNetworks,
      },
    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.network-revenue-statistics.index', {filter: {year: 'custom:' + new Date().getFullYear() }})"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Network Revenue
      </InertiaLink>
    </template>

    <template #network="{ row }">
      {{ row.affiliwingsChannel.network_name }}
    </template>

    <template #value="{ row }">
      {{ row.formatted_value }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    networkGuarantees: {
      type: Object,
      required: true,
    },

    affiliwingsNetworks: {
      type: Object,
      required: true,
    },
  },
};
</script>
