<template>
  <CrudEdit
    :title="mailing.name"
    sub-title="Mailings"
    :index-route="isSurvey ? route('admin.survey-mailing-planning.index') : route('admin.mailing-planning.index')"
    :update-route="route('admin.mailings.update', mailing.id)"
  >
    <template #cards>
      <div
        v-if="!mailing.canEdit"
        class="rounded-md bg-red-200 border border-red-400 mb-6 p-4"
      >
        <p class="text-sm text-red-800">
          <span class="font-medium">Cannot edit this mailing. Status is {{ mailing.status }}.</span>
        </p>
      </div>

      <MailingForm
        :is-survey="isSurvey"
        :mailing="mailing"
        :country-currency="countryCurrency"
        :reward-types="rewardTypes"
        :default-click-reward-value="defaultClickRewardValue"
        :default-view-reward-value="defaultViewRewardValue"
        :notification-types="notificationTypes"
        :subscription-types="subscriptionTypes"
        :allowed-types-for-subscription-segmentation="allowedTypesForSubscriptionSegmentation"
        :templates="templates"
        :commission-model-display-options="commissionModelDisplayOptions"
        :planner="planner"
        :administrators="administrators"
        :genders="genders"
        :mailbanners="mailbanners"
        :types-without-click-reward="typesWithoutClickReward"
        :replaceable-tracking-url-variables="replaceableTrackingUrlVariables"
        :replaceable-member-attributes="replaceableMemberAttributes"
        :replaceable-survey-attributes="replaceableSurveyAttributes"
        :segmentation-attributes="segmentationAttributes"
        :advertisers="advertiserWithKeys()"
        :multi-advertiser-media-types="multiAdvertiserMediaTypes"
        :survey-statuses="surveyStatuses"
        :default-exclude-referred-by-members="[]"
        :auto-remail-delay-days="autoRemailDelayDays"
      />
    </template>
  </CrudEdit>
</template>

<script>
import MailingForm from './Form.vue';

export default {
  components: {
    MailingForm,
  },

  props: {
    isSurvey: {
      type: Boolean,
      required: true,
    },

    mailing: {
      type: Object,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    rewardTypes: {
      type: Array,
      required: true,
    },

    defaultClickRewardValue: {
      type: String,
      required: true,
    },

    defaultViewRewardValue: {
      type: String,
      required: true,
    },

    notificationTypes: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },

    allowedTypesForSubscriptionSegmentation: {
      type: Array,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },

    commissionModelDisplayOptions: {
      type: Array,
      required: true,
    },

    planner: {
      type: Object,
      required: true,
    },

    administrators: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    mailbanners: {
      type: Object,
      required: false,
      default: null,
    },

    typesWithoutClickReward: {
      type: Array,
      required: true,
    },

    replaceableTrackingUrlVariables: {
      type: Array,
      required: true,
    },

    replaceableMemberAttributes: {
      type: Array,
      required: true,
    },

    replaceableSurveyAttributes: {
      type: Array,
      required: true,
    },

    segmentationAttributes: {
      type: Array,
      required: true,
    },

    advertisers: {
      type: Array,
      required: true,
    },

    multiAdvertiserMediaTypes: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Array,
      required: true,
    },

    autoRemailDelayDays: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    if (!this.mailing.canEdit) {
      this.$store.state.form.processing = true; // disables the "save" button
    }
  },

  methods: {
    advertiserWithKeys() {
      let advertiserWithKeys = {};
      this.advertisers.forEach(advertiser => advertiserWithKeys[advertiser.id] = advertiser);

      return advertiserWithKeys;
    },
  },
};
</script>
