<template>
  <CrudEdit
    :title="news.title"
    sub-title="News"
    :index-route="route('admin.news.index')"
    :update-route="route('admin.news.update', news.id)"
  >
    <template #form>
      <NewsForm
        :countries="countries"
        :puzzles="puzzles"
        :news="news"
      />
    </template>
  </CrudEdit>
</template>

<script>
import NewsForm from './Form.vue';

export default {
  components: {NewsForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },

    puzzles: {
      type: Array,
      required: true,
    },

    news: {
      type: Object,
      required: true,
    },
  },
};
</script>
