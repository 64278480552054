<template>
  <SiteHead title="Find hashed members" />

  <form
    target="_blank"
    enctype="multipart/form-data"
    :action="route('admin.find-hashed-member.post')"
    method="post"
  >
    <input
      type="hidden"
      name="_token"
      :value="$page.props.csrfToken"
    >

    <Breadcrumbs
      title="Find hashed members"
    >
      <div class="md:flex md:items-center md:justify-end pt-2 pb-6">
        <DefaultButton
          classes="text-white bg-indigo-600 hover:bg-indigo-700"
          :disabled="$store.state.form.processing"
        >
          Convert
        </DefaultButton>
      </div>
    </Breadcrumbs>

    <slot name="cards">
      <CrudCard
        :index-route="indexRoute"
        :edit-button="action"
      >
        <template #content>
          <SelectField
            v-model="$store.state.form.type"
            label="Hash type"
            name="type"
            placeholder="Select a hash type"
            :items="[
              {id: 'MD5', name: 'MD5'},
              {id: 'SHA256', name: 'SHA256'},
              {id: 'SHA512', name: 'SHA512'},
            ]"
            :required="true"
          />

          <InputField
            type="file"
            accept=".csv"
            label="File"
            name="file"
            help="The file must be CSV. The first column contains the hashed email"
            class="!rounded-none !shadow-none"
            :required="true"
            @input="$store.state.form.file = $event.target.files[0]"
          />
        </template>
      </CrudCard>
    </slot>

    <div class="md:flex md:items-center md:justify-end pt-2 pb-6">
      <DefaultButton
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        :disabled="$store.state.form.processing"
      >
        Convert
      </DefaultButton>
    </div>
  </form>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import {Breadcrumbs} from '@rocketcode/backoffice';

export default {
  components: {
    Breadcrumbs,
  },

  setup() {
    const form = useForm({
      type: 'MD5',
      file: null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
