<template>
  <CrudCreate
    title="Webshop - Create"
    sub-title="Webshops"
    :index-route="route('admin.webshops.index')"
    :store-route="storeRoute()"
  >
    <template #cards>
      <WebshopForm
        :webshop-categories="webshopCategories"
        :webshop-themes="webshopThemes"
        :program="program"
        :program-advertiser-id="programAdvertiserId"
        :cashback-communication-types="cashbackCommunicationTypes"
        :network-ids="networkIds"
      />
    </template>
  </CrudCreate>
</template>

<script>
import WebshopForm from './Form.vue';

export default {
  components: {WebshopForm},

  props: {
    webshopCategories: {
      type: Object,
      required: true,
    },

    webshopThemes: {
      type: Object,
      required: true,
    },

    program: {
      type: Object,
      required: false,
      default: null,
    },

    programAdvertiserId: {
      type: Number,
      required: false,
      default: null,
    },

    cashbackCommunicationTypes: {
      type: Array,
      required: true,
    },

    networkIds: {
      type: Array,
      required: true,
    },
  },

  methods: {
    storeRoute() {
      let storeRoute = this.route('admin.webshops.store');
      if (this.program) {
        storeRoute += `?program=${this.program.id}`;
      }

      return storeRoute;
    },
  },
};
</script>
