<template>
  <CrudIndex
    :title="`Mailing tests: #${mailingId}`"
    :columns="['ID', 'Is done', 'Score']"
    :data="tests.data"
    :paginator="tests.meta.pagination"
    :actions="true"
    :menu-items="[
      {name: 'View results', url: (item) => item.results_url, target: '_blank', enabled: (item) => item.results_url !== null},
    ]"
  >
    <template #score="{ row }">
      {{ row.display_score }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    tests: {
      type: Object,
      required: true,
    },
  },
};
</script>
