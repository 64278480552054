<template>
  <CrudIndex
    title="Payouts - Disapproved"
    row-route-path="admin.payouts.show"
    :columns="['ID', 'Member', 'Payout type', 'Value', 'Handling costs', 'Status', 'Reason', 'Paid status', 'Request date', 'Update date']"
    :actions="true"
    :data="payouts.data"
    :paginator="payouts.meta.pagination"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}],
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Payout type',
        value: 'type',
        multiple: true,
        items: filterablePayoutTypes,
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Paid status',
        value: 'paid_status',
        multiple: true,
        items: filterablePaidStatuses,
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Reason',
        value: 'disapprove_reason_id',
        multiple: true,
        items: filterableDisapproveReasons,
      },
    ]"
    :menu-items="[
      {name: 'Details', url: (item) => route('admin.payouts.show', item.id), enabled: true},
    ]"
  >
    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #payout_type="{ row }">
      <PayoutAccountData :data="row" />
    </template>

    <template #reason="{ row }">
      {{ row?.disapprove_reason?.name ?? '-' }}
    </template>

    <template #status="{ item }">
      <span
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item === 'APPROVED',
          'bg-red-100 text-red-800' : item === 'DISAPPROVED',
          'bg-yellow-100 text-yellow-800' : item === 'PENDING',
        }"
      >
        {{ item }}
      </span>
    </template>

    <template #paid_status="{ item }">
      <span
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item === 'DONE',
          'bg-yellow-100 text-yellow-800' : item === 'BUSY',
          'bg-blue-100 text-blue-800' : !item || item === 'OPEN',
        }"
      >
        {{ item ?? 'OPEN' }}
      </span>
    </template>
  </CrudIndex>
</template>

<script>
import PayoutAccountData from '../../Components/PayoutAccountData.vue';

export default {
  components: {PayoutAccountData},

  props: {
    payouts: {
      type: Object,
      required: true,
    },

    payoutTypes: {
      type: Array,
      required: true,
    },

    paidStatuses: {
      type: Array,
      required: true,
    },

    disapproveReasons: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let filterablePayoutTypes = [];
    for (const payoutType of props.payoutTypes) {
      filterablePayoutTypes.push({name: payoutType, value: payoutType});
    }

    let filterablePaidStatuses = [];
    for (const paidStatus of props.paidStatuses) {
      filterablePaidStatuses.push({name: paidStatus, value: paidStatus});
    }

    let filterableDisapproveReasons = [];
    for (const disapproveReason of props.disapproveReasons) {
      filterableDisapproveReasons.push({name: disapproveReason.name, value: disapproveReason.id});
    }

    return {
      filterablePayoutTypes: filterablePayoutTypes,
      filterablePaidStatuses: filterablePaidStatuses,
      filterableDisapproveReasons: filterableDisapproveReasons,
    };
  },
};
</script>
