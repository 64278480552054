<template>
  <CrudCreate
    title="Notification Bar - Create"
    sub-title="Notification Bars"
    :index-route="route('admin.notification-bars.index')"
    :store-route="route('admin.notification-bars.store')"
  >
    <template #form>
      <NotificationBarForm
        :tenant-main-color="tenantMainColor"
        :countries="countries"
        :authentication-status="authenticationStatus"
        :subscription-types="subscriptionTypes"
      />
    </template>
  </CrudCreate>
</template>

<script>
import NotificationBarForm from './Form.vue';

export default {
  components: {NotificationBarForm},

  props: {
    tenantMainColor: {
      type: String,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },

    authenticationStatus: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
