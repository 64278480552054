<template>
  <CrudCreate
    title="Mailing filter file - Create"
    sub-title="Mailing filter files"
    :index-route="route('admin.mailing-filter-files.index')"
    :store-route="route('admin.mailing-filter-files.store')"
  >
    <template #form>
      <MailingFilterFileForm
        :selected-country-id="selectedCountryId"
        :file-max-size-in-kb="fileMaxSizeInKb"
        :file-max-rows="fileMaxRows"
      />
    </template>
  </CrudCreate>
</template>

<script>
import MailingFilterFileForm from './Form.vue';

export default {
  components: {
    MailingFilterFileForm,
  },

  props: {
    selectedCountryId: {
      type: Number,
      required: true,
    },

    fileMaxSizeInKb: {
      type: Number,
      required: true,
    },

    fileMaxRows: {
      type: Number,
      required: true,
    },
  },
};
</script>
