<template>
  <div
    v-if="otherErrors.length > 0"
    class="bg-red-100 text-red-800 rounded p-4 mb-4"
  >
    <div
      v-for="(error, key) in otherErrors"
      :key="key"
    >
      {{ error }}
    </div>
  </div>

  <CrudToggleCard :default-open="true">
    <template #header>
      <span class="font-medium">
        General
      </span>
    </template>

    <template #content>
      <InputField
        :model-value="program.name"
        label="Name"
        name="name"
        :disabled="true"
      />
      <InputField
        :model-value="program.country"
        label="Country"
        name="country"
        :disabled="true"
      />

      <InputField
        :model-value="program.tracking_url"
        label="Tracking URL"
        name="tracking_url"
        :disabled="true"
      >
        <template #extra>
          <DefaultButton @click.prevent="copyTrackingUrl">
            <i class="far fa-copy text-lg" />
          </DefaultButton>

          <DefaultButton
            :internal-link="false"
            :href="program.tracking_url"
            target="_blank"
          >
            <i class="far fa-external-link text-lg" />
          </DefaultButton>
        </template>
      </InputField>

      <InputField
        v-model="$store.state.form.custom_tracking_url"
        label="Custom tracking URL"
        name="custom_tracking_url"
        :required="false"
      >
        <template #extra>
          <DefaultButton @click.prevent="copyCustomTrackingUrl">
            <i class="far fa-copy text-lg" />
          </DefaultButton>

          <DefaultButton
            :internal-link="false"
            :href="$store.state.form.custom_tracking_url"
            target="_blank"
          >
            <i class="far fa-external-link text-lg" />
          </DefaultButton>
        </template>
      </InputField>

      <SelectField
        v-model="$store.state.form.commission_model_display_option"
        label="Commission model display option"
        name="commission_model_display_option"
        :items="commissionModelDisplayOptions.map((option) => ({id: option, name: option}))"
        placeholder="Select an option"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Details
      </span>
    </template>

    <template #content>
      <RadioField
        v-model="$store.state.form.attribution_model"
        label="Attribution model"
        name="attribution_model"
        :items="[
          {value: 'enabled', name: 'Yes (Attribution model is applicable)'},
          {value: 'disabled', name: 'No (Attribution model is not applicable)'},
          {value: 'keep', name: `Keep status linked based on info from affiliate network (Affiliate Network status: ${$store.state.form.has_attribution_model})`},
        ]"
      />

      <MultiSelectField
        v-model="advertiserIds"
        label="Attached advertiser(s)"
        name="advertiser_ids"
        placeholder="-- No advertisers --"
        mode="tags"
        :can-clear="false"
        :disabled="true"
        help="Cannot attach or detach advertisers here"
        :options="advertisers"
      />

      <InputField
        v-model="$store.state.form.skip_transaction_mismatch_commissions"
        label="Don't do ‘transaction mismatch’ check."
        name="skip_transaction_mismatch_commissions"
        help="Hide mismatches when the commission value equals the filled in value(s). Use ; as separation to fill in multiple values. Example 0.50 = 50ct. Use dot as decimal separator."
        :required="false"
      />

      <SelectField
        v-model="$store.state.form.currency_id"
        :items="currencies"
        :required="false"
        label="Currency"
        name="currency_id"
        help="The currency the commission is paid in"
      />

      <InputField
        :model-value="program.network_program_id"
        label="Affiliate network program ID"
        name="network_program_id"
        :disabled="true"
        :help="program.program_id"
      />

      <div
        class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0"
      >
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Affiliate network program status
          </div>
        </div>

        <div
          class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2"
        >
          {{ program.status }}
        </div>
        <div
          class="col-span-2"
        >
          <div
            class="float-left"
          >
            <DefaultButton
              type="button"
              classes="text-white bg-indigo-600 hover:bg-indigo-700 px-2.5 py-1.5"
              @click="openStatusHistoryModal"
            >
              <span
                class="material-icons md-24"
                title="Show history"
              >history</span>
            </DefaultButton>
          </div>
        </div>
      </div>

      <HistoryModal
        :id="program.id"
        v-model:open="statusHistoryOpen"
        :history-route="'admin.programs.status.history.get'"
      />

      <InputField
        :model-value="program.network_name"
        label="Affiliate network name"
        name="network_name"
        :disabled="true"
        :help="program.network_id"
      />
      <InputField
        :model-value="program.account_name"
        label="Affiliate account name"
        name="account_name"
        :disabled="true"
        :help="program.account_id"
      />
      <InputField
        :model-value="program.channel_name"
        label="Affiliate channel name"
        name="channel_name"
        :disabled="true"
        :help="program.channel_id"
      />
      <InputField
        :model-value="program.last_seen_timestamp"
        label="Last imported at"
        name="last_seen_timestamp"
        :disabled="true"
      />
    </template>
  </CrudToggleCard>
</template>

<script>
import {ref} from 'vue';
import HistoryModal from '../../Components/HistoryModal.vue';

export default {
  components: {
    HistoryModal,
  },

  props: {
    program: {
      type: Object,
      required: false,
      default: null,
    },

    currencies: {
      type: Array,
      required: true,
    },

    commissionModelDisplayOptions: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let advertiserIds = [];
    let advertisers = {};

    for (const advertiser of props.program.advertisers) {
      advertiserIds.push(advertiser.id);
      advertisers[advertiser.id] = advertiser.name;
    }

    return {
      advertiserIds: advertiserIds,
      advertisers: advertisers,
      status: ref(props.program.status),
      statusHistoryOpen: ref(false),
    };
  },

  computed: {
    otherErrors() {
      const errors = [];

      for (const [key, error] of Object.entries(this.$store.state.form.errors)) {
        if (key.startsWith('commission_models')) {
          errors.push(error);
        }
      }

      return errors;
    },
  },

  methods: {
    copyTrackingUrl() {
      navigator.clipboard.writeText(this.program.tracking_url);
    },

    copyCustomTrackingUrl() {
      navigator.clipboard.writeText(this.$store.state.form.custom_tracking_url);
    },

    openStatusHistoryModal() {
      this.statusHistoryOpen = true;
    },
  },
};
</script>
