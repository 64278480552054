<template>
  <CrudCard>
    <template #content>
      <div>
        <InputField
          v-model="$store.state.form.name"
          label="Name"
          name="name"
        />

        <CheckboxField
          v-model="$store.state.form.is_default"
          label="Is default"
          name="is_default"
          :required="false"
        />
      </div>
    </template>
  </CrudCard>
</template>

<script>

import {useForm} from '@inertiajs/vue3';
import {unref} from 'vue';
import {useStore} from 'vuex';

export default {
  props: {
    advertiserType: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    let name = null;
    let is_default = false;

    if (props.advertiserType) {
      name = unref(props.advertiserType.name);
      is_default = unref(props.advertiserType.is_default);
    }

    const form = useForm({
      name,
      is_default,
    });

    useStore().commit('form/setForm', form);
  },
};

</script>
