<template>
  <CrudIndex
    title="Free action categories"
    create-route-path="admin.free-action-categories.create"
    edit-route-path="admin.free-action-categories.edit"
    row-route-path="admin.free-action-categories.edit"
    delete-route-path="admin.free-action-categories.destroy"
    :columns="['id', 'name']"
    :actions="true"
    :data="categories"
    :filters="[{type: 'search', fields: [{label: 'Name', value: 'name'}]}]"
  />
</template>

<script>
export default {
  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
};
</script>
