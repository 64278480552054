<template>
  <CrudIndex
    :title="`Network revenue statistics (${totalRevenue})`"
    :columns="[
      'Status',
      ...objectColumns,
    ]"
    :actions="false"
    :data="networkRevenueStatistics"
    :filters="[
      {
        type: 'dropdown',
        operator: 'custom',
        label: 'Year',
        value: 'year',
        items: years.map((type) => ({name: type, value: type})),
        multiple: false,
      },
    ]"
    :border-between="true"
    :center-headers="false"
  >
    <template #after-create>
      <InertiaLink
        :href="route('admin.network-revenue-statistics.index.simple')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Show Simple
      </InertiaLink>

      <InertiaLink
        :href="route('admin.network-revenue-statistics.network-guarantees.index')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Network Guarantees
      </InertiaLink>

      <InertiaLink
        :href="route('admin.network-revenue-statistics.manual-networks.create')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Create Manual Network
      </InertiaLink>
    </template>

    <template
      v-for="column in objectColumns"
      :key="column"
      #[column.toLowerCase()]="{ item }"
    >
      <p
        v-if="!item.is_manual"
        :class="{ 'font-extrabold': item.is_complete }"
      >
        {{ item.value }}
      </p>

      <InlineEditableField
        v-if="item.is_manual"
        :item="item"
        :form="$store.state.form"
        @toggle-modal="toggleModal(item.id)"
        @save="submit(item.id)"
      >
        <template #content>
          {{ $store.state.form.stats.find(stat => stat.id === item.id).value }}
        </template>

        <template #form>
          <CurrencyField
            v-model="$store.state.form.stats.find(stat => stat.id === item.id).value"
            name="value"
            title="Value"
            @keydown.enter="submit(item.id)"
          />
        </template>
      </InlineEditableField>
    </template>
  </CrudIndex>

  <CrudIndex
    v-if="manualNetworks.length > 0"
    title="Network logs"
    :data="manualNetworks"
    :columns="['ID', 'Name']"
    :actions="true"
    row-route-path="admin.network-revenue-statistics.manual-networks.show"
  />

  <SiteHead :title="`Network revenue statistics (${totalRevenue})`" />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import axios from 'axios';
import InlineEditableField from '../../Components/InlineEditableField.vue';

export default {
  components: {InlineEditableField},
  props: {
    totalRevenue: {
      type: String,
      required: true,
    },

    networkRevenueStatistics: {
      type: Object,
      required: true,
    },

    years: {
      type: Array,
      required: true,
    },

    selectedYear: {
      type: Number,
      required: true,
    },

    manualNetworks: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const formProperties = [];

    for (const network in props.networkRevenueStatistics) {
      Object.values(props.networkRevenueStatistics[network]).forEach(stat =>
        Object.values(stat).forEach(monthStat => {
          if (monthStat.is_manual) {
            const id = crypto.randomUUID();

            formProperties.push({
              network: network,
              month_index: monthStat.month_index,
              value: monthStat.value,
              status: stat.status.toLowerCase(),
              id: id,
            });

            monthStat.id = id;
          }
        }),
      );
    }

    const form = useForm({
      stats: formProperties,
      activeModal: null,
    });

    useStore().commit('form/setForm', form);
  },

  data() {
    return {
      objectColumns: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'],
    };
  },

  methods: {
    submit(id) {
      this.$store.state.form.activeModal = null;

      axios.patch(route('admin.api.network-revenue-statistics.manual-networks.stats.update'), {
        year: this.selectedYear,
        stats: this.$store.state.form.stats.filter(stat => stat.id === id),
      });
    },

    toggleModal(id) {
      if (this.$store.state.form.activeModal === id) {
        this.$store.state.form.activeModal = null;

        return;
      }

      this.$store.state.form.activeModal = id;
    },
  },
};
</script>
