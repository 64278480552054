<template>
  <CrudEdit
    :title="`Member notifications #${member.id}`"
    sub-title="Notifications"
    :index-route="route('admin.members.edit', member.id)"
    :update-route="route('admin.members.notifications.update', member.id)"
  >
    <template #form>
      <InputField
        v-model="$store.state.form.vacation_start"
        label="Vacation start"
        name="vacation_start"
        type="date"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.vacation_end"
        label="Vacation end"
        name="vacation_end"
        type="date"
        :required="false"
      />

      <template
        v-for="(types, group) in groupedTypes"
        :key="`grouped-type-${group}`"
      >
        <SelectField
          :label="group"
          :name="`grouped_type_${group}`"
          :items="types"
          :model-value="selectedGroupValue[group]"
          @change="(event) => setGroupValue(group, event.target.value)"
        />
      </template>

      <template
        v-for="notificationType in singleTypes"
        :key="`notification-type-${notificationType}`"
      >
        <CheckboxField
          v-model="$store.state.form.notifications[notificationType]"
          :label="`Notification '${notificationType}'`"
          :name="`notification_type[${notificationType}]`"
          :required="false"
        />
      </template>
    </template>
  </CrudEdit>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    member: {
      type: Object,
      required: true,
    },

    availableTypes: {
      type: Array,
      required: true,
    },

    groupedTypes: {
      type: Object,
      required: true,
    },

    singleTypes: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const formData = {
      vacation_start: props.member.vacation_start,
      vacation_end: props.member.vacation_end,
      notifications: {},
    };

    for (const notificationType of props.availableTypes) {
      formData.notifications[notificationType] = props.member.notificationTypes.includes(notificationType);
    }

    const form = useForm(formData);
    useStore().commit('form/setForm', form);

    const selectedGroupValue = {};
    for (const [group, types] of Object.entries(props.groupedTypes)) {
      selectedGroupValue[group] = -1; // "none"

      for (const type of types) {
        if (formData.notifications[type.id]) {
          selectedGroupValue[group] = type.id;
          break;
        }
      }
    }

    return {
      selectedGroupValue,
    };
  },

  methods: {
    setGroupValue(group, value) {
      const none = -1;

      // unset all group values first
      this.groupedTypes[group].forEach(type => {
        if (type.id != none) {
          this.$store.state.form.notifications[type.id] = false;
        }
      });
      this.selectedGroupValue[group] = none;

      if (value) {
        if (value != none) {
          this.$store.state.form.notifications[value] = true;
        }
        this.selectedGroupValue[group] = value;
      }
    },
  },
};
</script>
