<template>
  <CrudEdit
    :title="currency.name"
    sub-title="Currencies"
    :index-route="route('admin.currencies.index')"
    :update-route="route('admin.currencies.update', currency.id)"
  >
    <template #form>
      <CurrencyForm :currency="currency" />
    </template>
  </CrudEdit>
</template>

<script>
import CurrencyForm from './Form.vue';

export default {
  components: {CurrencyForm},

  props: {
    currency: {
      type: Object,
      required: true,
    },
  },
};
</script>
