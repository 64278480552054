<template>
  <CrudEdit
    title="Mailing filter - Edit"
    sub-title="Mailing filters"
    :index-route="route('admin.mailing-filters.index')"
    :update-route="route('admin.mailing-filters.update', mailingFilter.id)"
  >
    <template #cards>
      <MailingFilterForm
        :mailing-filter="mailingFilter"
        :selected-country-id="selectedCountryId"
        :genders="genders"
        :provinces="provinces"
        :survey-statuses="surveyStatuses"
        :mailing-filter-items="mailingFilterItems"
        :mailing-filter-item-explanations="mailingFilterItemExplanations"
        :mailing-filter-item-file-fields="mailingFilterItemFileFields"
        :mailing-filter-item-operators="mailingFilterItemOperators"
      />
    </template>
  </CrudEdit>
</template>

<script>
import MailingFilterForm from './Form.vue';

export default {
  components: {
    MailingFilterForm,
  },

  props: {
    mailingFilter: {
      type: Object,
      required: true,
    },

    selectedCountryId: {
      type: Number,
      required: true,
    },

    genders: {
      type: Object,
      required: true,
    },

    provinces: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Object,
      required: true,
    },

    mailingFilterItems: {
      type: Object,
      required: true,
    },

    mailingFilterItemExplanations: {
      type: Object,
      required: true,
    },

    mailingFilterItemFileFields: {
      type: Object,
      required: true,
    },

    mailingFilterItemOperators: {
      type: Object,
      required: true,
    },
  },
};
</script>
