<template>
  <div class="col-span-2">
    <MultiSelectField
      v-model="value.profile_question_id"
      :name="`required_questions.${index}.profile_question_id`"
      placeholder="Select a Profile Question"
      mode="single"
      :required="true"
      :inline="true"
      :searchable="true"
      :can-deselect="false"
      :can-clear="false"
      :resolve-on-load="value.profile_question_id"
      :min-chars="2"
      :delay="150"
      :loading="loadingProfileQuestions"
      :options="async (query) => await fetchProfileQuestions(query)"
      @select="(value, selectedOption) => updatedProfileQuestion(selectedOption)"
    />
  </div>
  <div class="col-span-1">
    <div v-if="value.profile_question_id && profileQuestion">
      <label
        v-for="answers of profileQuestion.answers"
        :key="`answers-${answers.id}`"
        class="mr-2 flex items-center"
      >
        <input
          v-model="value.profile_answer_ids"
          :value="answers.id"
          name="profile_answer_ids"
          type="checkbox"
          class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          @change="updated"
        >
        <span class="ml-2">{{ answers.answer }}</span>
      </label>

      <p
        v-if="$page.props.errors[`required_questions.${index}.profile_answer_ids`]"
        class="mt-1 text-sm text-red-600"
        v-text="$page.props.errors[`required_questions.${index}.profile_answer_ids`]"
      />
    </div>
  </div>
  <div class="col-span-1 text-right">
    <DefaultButton
      class="!ml-0"
      @click.prevent="remove"
    >
      <span
        class="material-icons md-18"
        title="Delete"
      >delete</span>
    </DefaultButton>
  </div>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },

    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['delete', 'update:modelValue'],

  setup(props) {
    const value = ref(props.modelValue);
    const loadingProfileQuestions = ref(false);
    const profileQuestion = ref(null);

    return {value, loadingProfileQuestions, profileQuestion};
  },

  methods: {
    remove() {
      this.$emit('delete', this.index);
    },

    updatedProfileQuestion(selectedOption) {
      this.profileQuestion = selectedOption;

      this.updated();
    },

    updated() {
      this.$emit('update:modelValue', this.value);
    },

    fetchProfileQuestions(query) {
      this.loading = true;

      let fetchInitial = query === null;
      if (fetchInitial) {
        return axios.get(this.route('admin.api.profile-questions.find', {id: this.value.profile_question_id}))
            .then(({data}) => {
              this.loading = false;
              this.profileQuestion = data[0];

              return data.map(v => {
                v.value = v.id;
                v.label = v.question;
                return v;
              });
            });
      }

      return axios.get(this.route('admin.api.profile-questions.search.question', {question: query}))
          .then(({data}) => {
            this.value.profile_answer_ids = [];
            this.updated();
            this.loading = false;

            return data.map(v => {
              v.value = v.id;
              v.label = v.question;
              return v;
            });
          });
    },
  },
};
</script>
