<template>
  <CrudCreate
    title="Click action - Create"
    sub-title="Click actions"
    :index-route="route('admin.click-actions.index')"
    :store-route="route('admin.click-actions.choose-webshop', {webshop: $store.state.form.webshop_id})"
  >
    <template #form>
      <MultiSelectField
        v-model="$store.state.form.webshop_id"
        label="Webshop"
        name="webshop_id"
        placeholder="Select a Webshop"
        mode="single"
        :min-chars="2"
        :delay="150"
        :loading="loading"
        :options="async (query) => await fetchWebshops(query)"
      />
    </template>
  </CrudCreate>
</template>

<script>
import axios from 'axios';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  setup() {
    const form = useForm({
      webshop_id: null,
    });

    useStore().commit('form/setForm', form);
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async fetchWebshops(query) {
      this.loading = true;

      return await axios.get(this.route('admin.api.webshop.search.name', {name: query}))
        .then(({data}) => {
          this.loading = false;
          return data;
        });
    },
  },
};
</script>
