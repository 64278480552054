<template>
  <CrudCreate
    title="Advertiser Type - Create"
    sub-title="Advertiser Types"
    :index-route="route('admin.advertiser-types.index')"
    :store-route="route('admin.advertiser-types.store')"
  >
    <template #cards>
      <AdvertiserTypeForm />
    </template>
  </CrudCreate>
</template>

<script>
import AdvertiserTypeForm from './Form.vue';

export default {
  components: {
    AdvertiserTypeForm,
  },
};
</script>
