<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        General
      </span>
    </template>

    <template #content>
      <div>
        <CheckboxField
          v-model="$store.state.form.is_active"
          label="Is active"
          name="is_active"
          :required="false"
          @change="isActiveChanged()"
        >
          <template #extra>
            <i
              v-if="activeLogs.length > 0"
              class="fas fa-info-circle cursor-pointer text-indigo-600 hover:text-indigo-800"
              title="Show logs"
              @click="showLogs = true"
            />
          </template>
        </CheckboxField>

        <div
          v-if="$store.state.form.is_active_reason"
          class="flex"
        >
          <p
            class="text-sm hover:cursor-pointer flex"
            title="Edit"
            @click="isActiveReasonModalIsOpen = true"
          >
            Change reason: {{ $store.state.form.is_active_reason }}
          </p>
        </div>
      </div>

      <CheckboxField
        v-model="$store.state.form.cashback_once"
        label="Cashback once"
        name="cashback_once"
        :required="false"
      />

      <MultiSelectField
        v-if="!webshop"
        v-model="$store.state.form.advertiser_id"
        label="Advertiser"
        name="advertiser_id"
        placeholder="Select an advertiser"
        mode="single"
        :searchable="true"
        :can-deselect="false"
        :can-clear="false"
        :resolve-on-load="$store.state.form.advertiser_id > 0"
        :min-chars="2"
        :delay="150"
        :loading="loadingAdvertisers"
        :options="async (query) => await fetchAdvertisers(query)"
        @select="advertiserChanged"
      >
        <template
          v-if="$store.state.form.advertiser_id"
          #extra
        >
          <DefaultButton
            :internal-link="false"
            :href="route('admin.advertisers.edit', $store.state.form.advertiser_id)"
            target="_blank"
          >
            <span class="material-icons md-18">open_in_new</span>
          </DefaultButton>
        </template>
      </MultiSelectField>
      <InputField
        v-else
        v-model="advertiserName"
        label="Advertiser"
        name="advertiser_id"
        :disabled="true"
      >
        <template #extra>
          <DefaultButton
            :internal-link="false"
            :href="route('admin.advertisers.edit', $store.state.form.advertiser_id)"
            target="_blank"
          >
            <span class="material-icons md-18">open_in_new</span>
          </DefaultButton>
        </template>
      </InputField>

      <InputField
        v-if="$store.state.form.advertiser_id"
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />

      <InputField
        v-if="$store.state.form.advertiser_id"
        v-model="$store.state.form.slug"
        label="Slug"
        name="slug"
      />

      <div>
        <div v-if="loadingAdvertiserSettings">
          <div class="text-2xl text-gray-600 mb-2">
            <span class="material-icons md-18 mr-2 animate-spin">autorenew</span>
          </div>
        </div>
        <div v-else>
          <InputField
            v-if="useDefaultCashbackCommunication"
            v-model="defaultCashbackCommunication"
            :disabled="true"
            label="Cashback communication"
            name="default_cashback_communication"
            help="Default cashback communication"
          >
            <template #extra>
              <DefaultButton @click.prevent="useDefaultCashbackCommunication = false">
                <span
                  class="material-icons md-18"
                  title="Don't use default"
                >
                  edit
                </span>
              </DefaultButton>
            </template>
          </InputField>

          <SelectField
            v-else
            v-model="$store.state.form.cashback_communication"
            :items="selectableCashbackCommunicationTypes"
            label="Cashback communication"
            name="cashback_communication"
            placeholder="Select option"
          >
            <template #extra>
              <DefaultButton @click.prevent="doUseDefaultCashbackCommunication">
                <span
                  class="material-icons md-18"
                  title="Use default"
                >edit_off</span>
              </DefaultButton>
            </template>
          </SelectField>
        </div>
      </div>

      <MultiSelectField
        v-if="$store.state.form.advertiser_id"
        ref="programInput"
        v-model="programIds"
        label="Program"
        name="program_id"
        placeholder="Select a program"
        mode="tags"
        :required="false"
        :searchable="true"
        :can-deselect="true"
        :loading="loadingAdvertiserPrograms"
        :options="advertiserPrograms"
      />

      <div>
        <div v-if="loadingAdvertiserSettings">
          <div class="text-2xl text-gray-600 mb-2">
            <span class="material-icons md-18 mr-2 animate-spin">autorenew</span>
          </div>
        </div>
        <div v-else>
          <InputField
            v-if="useDefaultPreferredNetworkWhenTie"
            v-model="defaultPreferredNetworkWhenTie"
            :disabled="true"
            label="Default preferred network (in case of tie)"
            name="preferred_network_id_tie"
            help="Only in case of tie (i.e. same commission)"
          >
            <template #extra>
              <DefaultButton @click.prevent="useDefaultPreferredNetworkWhenTie = false">
                <span
                  class="material-icons md-18"
                  title="Don't use default"
                >edit</span>
              </DefaultButton>
              <DefaultButton
                title="Show possible values"
                @click.prevent="showNetworkIds = true"
              >
                <span class="material-icons md-18">list</span>
              </DefaultButton>
            </template>
          </InputField>

          <InputField
            v-else
            v-model="$store.state.form.preferred_network_id_tie"
            label="Preferred network (in case of tie)"
            name="preferred_network_id_tie"
            help="Only in case of tie (i.e. same commission)"
          >
            <template #extra>
              <DefaultButton @click.prevent="doUseDefaultPreferredNetworkWhenTie">
                <span
                  class="material-icons md-18"
                  title="Use default"
                >edit_off</span>
              </DefaultButton>
              <DefaultButton
                title="Show possible values"
                @click.prevent="showNetworkIds = true"
              >
                <span class="material-icons md-18">list</span>
              </DefaultButton>
            </template>
          </InputField>
        </div>
      </div>

      <InputField
        v-model="$store.state.form.preferred_network_id"
        label="Preferred network (without battling)"
        name="preferred_network_id"
        help="Uses attached active program of this network without battling (i.e. comparing commissions)"
        :required="false"
      >
        <template #extra>
          <DefaultButton
            title="Show possible values"
            @click.prevent="showNetworkIds = true"
          >
            <span class="material-icons md-18">list</span>
          </DefaultButton>
        </template>
      </InputField>
    </template>
  </CrudToggleCard>

  <CrudToggleCard :default-open="!webshop">
    <template #header>
      <span class="font-medium">
        Promotions
      </span>
    </template>

    <template #content>
      <MultiSelectField
        v-model="$store.state.form.webshop_category_ids"
        label="Webshop Categories"
        name="webshop_category_ids"
        placeholder="Select a category"
        mode="tags"
        :searchable="true"
        :can-deselect="true"
        :options="webshopCategories"
        :required="false"
      />

      <MultiSelectField
        v-model="$store.state.form.top_webshop_category_ids"
        label="TopShop categories"
        name="top_webshop_category_ids"
        placeholder="Select a category"
        mode="tags"
        :searchable="true"
        :can-deselect="true"
        :options="topWebshopCategories"
        :required="false"
      />

      <MultiSelectField
        v-model="$store.state.form.webshop_theme_ids"
        label="Webshop Themes"
        name="webshop_theme_ids"
        placeholder="Select a theme"
        mode="tags"
        :searchable="true"
        :can-deselect="true"
        :options="webshopThemes"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Segmentation
      </span>
    </template>

    <template #content>
      <InputField
        v-model="$store.state.form.minimum_age"
        type="number"
        label="Minimum Age"
        name="minimum_lage"
        min="0"
        max="255"
        help="Leave empty to disable segmentation, inclusive number"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.maximum_age"
        type="number"
        label="Maximum Age"
        name="maximum_age"
        min="0"
        max="255"
        help="Leave empty to disable segmentation, inclusive number"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Search tags
      </span>
    </template>

    <template #content>
      <MultiSelectField
        v-model="$store.state.form.misspellings"
        label="Misspellings"
        name="misspellings"
        placeholder="Enter misspelling"
        mode="tags"
        :create-tag="true"
        :options="$store.state.form.misspellings.map((misspelling) => ({label: misspelling, value: misspelling}))"
        :required="false"
      />

      <MultiSelectField
        v-model="$store.state.form.webshops_without_cashback"
        label="Webshops without cashback"
        name="webshops_without_cashback"
        placeholder="Enter webshops without cashback"
        mode="tags"
        :create-tag="true"
        :options="$store.state.form.webshops_without_cashback.map((webshop) => ({label: webshop, value: webshop}))"
        :required="false"
      />

      <MultiSelectField
        v-model="$store.state.form.similar_webshops"
        label="Similar webshops"
        name="similar_webshops"
        placeholder="Select webshop"
        mode="tags"
        :can-clear="false"
        :resolve-on-load="$store.state.form.similar_webshops.length > 0"
        :min-chars="2"
        :delay="150"
        :loading="isLoadingSimilarWebshops"
        :options="async (query) => await fetchSimilarWebshops(query)"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Meta data
      </span>
    </template>

    <template #content>
      <InputField
        v-model="$store.state.form.meta_title"
        label="Meta title"
        name="meta_title"
        help="Leave empty to use the default translation"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.meta_description"
        label="Meta description"
        name="meta_description"
        help="Leave empty to use the default translation"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <PromotionActionProgramForm
    v-for="programId in programIds"
    :key="`webshop-programs-form-${programId}`"
    :program-id="parseInt(programId)"
    :promotion-action-id="webshop ? webshop.id : null"
    :promotion-action-type="webshop ? webshop.promotion_action_type : null"
  />

  <Modal
    v-model:open="showNetworkIds"
    :items="networkIds"
  />

  <Modal
    v-model:open="showLogs"
    :items="activeLogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))"
    :no-white-space-wrap="false"
  />

  <ModalForm
    id="active_reason"
    :open="isActiveReasonModalIsOpen"
    :submit-handler="(submitData) => submitData.success()"
    :show-close="false"
    :can-close="$store.state.form.is_active_reason !== null"
    @update:open="isActiveReasonModalIsOpen = false"
  >
    <template #fields>
      <TextareaField
        v-model="$store.state.form.is_active_reason"
        name="is_active_reason"
        label="Reason"
      />
    </template>
  </ModalForm>
</template>

<script>
import {ref} from 'vue';
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';
import axios from 'axios';
import PromotionActionProgramForm from '../../Components/PromotionActionProgramForm.vue';
import Modal from '../../Components/Modal.vue';
import ModalForm from '../../Components/ModalForm.vue';

export default {
  components: {ModalForm, PromotionActionProgramForm, Modal},

  props: {
    webshop: {
      type: Object,
      required: false,
      default: null,
    },

    webshopCategories: {
      type: Object,
      required: true,
    },

    webshopThemes: {
      type: Object,
      required: true,
    },

    programs: {
      type: Object,
      required: false,
      default: null,
    },

    program: {
      type: Object,
      required: false,
      default: null,
    },

    programAdvertiserId: {
      type: Number,
      required: false,
      default: null,
    },

    cashbackCommunicationTypes: {
      type: Array,
      required: true,
    },

    networkIds: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let advertiser_id = null;
    let advertiserName = null;
    let webshop_category_ids = [];
    let top_webshop_category_ids = [];
    let webshop_theme_ids = [];
    let commission_model_ids = {};

    if (props.programAdvertiserId) {
      advertiser_id = ref(props.programAdvertiserId);
    }

    if (props.webshop) {
      advertiser_id = ref(props.webshop.advertiser_id);
      advertiserName = ref(props.webshop.advertiser.name);
      webshop_category_ids = props.webshop.categories.map(category => category.id);
      top_webshop_category_ids = props.webshop.top_categories.map(category => category.id);
      webshop_theme_ids = props.webshop.themes.map(theme => theme.id);

      commission_model_ids = props.webshop.webshop_commission_models.reduce((accumulator, commissionModel) => {
        if (!Object.prototype.hasOwnProperty.call(accumulator, commissionModel.program_id)) {
          accumulator[commissionModel.program_id] = [];
        }

        accumulator[commissionModel.program_id].push(commissionModel.commission_model_id);

        return accumulator;
      }, {});
    }

    const form = useForm({
      advertiser_id,
      name: props.webshop ? props.webshop.name : null,
      slug: props.webshop ? props.webshop.slug : null,
      minimum_age: props.webshop ? props.webshop.minimum_age : null,
      maximum_age: props.webshop ? props.webshop.maximum_age : null,
      webshop_category_ids,
      top_webshop_category_ids,
      webshop_theme_ids,
      programs: props.programs ? props.programs : {},
      commission_model_ids,
      cashback_communication: props.webshop ? props.webshop.cashback_communication : null,
      is_active: props.webshop ? props.webshop.is_active : true,
      cashback_once: props.webshop ? props.webshop.cashback_once : false,
      preferred_network_id_tie: props.webshop ? props.webshop.preferred_network_id_tie : null,
      preferred_network_id: props.webshop ? props.webshop.preferred_network_id : null,
      misspellings: props.webshop ? props.webshop.misspellings : [],
      webshops_without_cashback: props.webshop ? props.webshop.webshops_without_cashback : [],
      similar_webshops: props.webshop ? props.webshop.similar_webshops : [],
      is_active_reason: null,
      meta_title: props.webshop ? props.webshop.meta_title : null,
      meta_description: props.webshop ? props.webshop.meta_description : null,
    });

    useStore().commit('form/setForm', form);

    return {
      advertiserName,
      defaultCashbackCommunication: ref(null),
      defaultPreferredNetworkWhenTie: ref(null),
      isLoadingSimilarWebshops: false,
    };
  },

  data(props) {
    const initialProgramIds = this.programs ? Object.keys(this.programs) : [];
    if (this.program) {
      initialProgramIds.push(this.program.id);
    }

    let topWebshopCategories = {};
    if (this.$store.state.form.webshop_category_ids) {
      topWebshopCategories = Object.keys(this.webshopCategories)
        .filter((categoryId) => {
          return Object.values(this.$store.state.form.webshop_category_ids).includes(parseInt(categoryId));
        })
        .reduce((obj, key) => {
          obj[key] = this.webshopCategories[key];
          return obj;
        }, {});
    }

    let selectableCashbackCommunicationTypes = [];
    for (const type of this.cashbackCommunicationTypes) {
      selectableCashbackCommunicationTypes.push({id: type, name: type});
    }

    return {
      topWebshopCategories,

      loadingAdvertisers: false,
      loadingAdvertiserSettings: false,
      loadingAdvertiserPrograms: false,
      advertiserPrograms: [],
      programIds: initialProgramIds,

      useDefaultCashbackCommunication: ref(this.$store.state.form.cashback_communication === null),
      selectableCashbackCommunicationTypes: ref(selectableCashbackCommunicationTypes),
      useDefaultPreferredNetworkWhenTie: ref(this.$store.state.form.preferred_network_id_tie === null),
      showNetworkIds: ref(false),
      originalIsActive: this.$store.state.form.is_active,
      isActiveReasonModalIsOpen: ref(false),
      showLogs: ref(false),
      activeLogs: ref(props.webshop?.active_logs ?? []),
    };
  },

  watch: {
    '$store.state.form.name': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },

    '$store.state.form.slug': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },

    '$store.state.form.webshop_category_ids': function (newValue) {
      this.$store.state.form.top_webshop_category_ids =
        this.$store.state.form.top_webshop_category_ids.filter(function (categoryId) {
          return newValue.includes(categoryId.toString());
        });

      this.topWebshopCategories = Object.keys(this.webshopCategories)
        .filter((categoryId) => {
          return newValue.includes(categoryId.toString());
        })
        .reduce((obj, key) => {
          obj[key] = this.webshopCategories[key];
          return obj;
        }, {});
    },
  },

  mounted() {
    if (this.$store.state.form.advertiser_id) {
      if (this.program) {
        let advertiserName = this.program.advertisers.find(advertiser => advertiser.id === this.$store.state.form.advertiser_id).name;
        this.$store.state.form.name = advertiserName;
        this.$store.state.form.slug = this.sluggify(advertiserName);
      }

      this.loadAdvertiserSettingsAndPrograms(this.$store.state.form.advertiser_id);
    }
  },

  methods: {
    advertiserChanged(advertiserId, selectedOption) {
      advertiserId = parseInt(advertiserId);
      if (isNaN(advertiserId)) {
        return;
      }
      let advertiserName = selectedOption.label;

      this.$store.state.form.name = advertiserName;
      this.$store.state.form.slug = this.sluggify(advertiserName);

      this.$nextTick(() => {
        this.programIds = [];
        this.loadAdvertiserSettingsAndPrograms(advertiserId);

        this.$refs.programInput.clear();
      });
    },

    async fetchAdvertisers(query) {
      this.loadingAdvertisers = true;

      let fetchInitialSelectedAdvertiser = query === null;
      if (fetchInitialSelectedAdvertiser) {
        return await axios.get(this.route('admin.api.advertisers.find', {
          id: this.$store.state.form.advertiser_id,
          without_webshops: true,
        }))
          .then(({data}) => {
            this.loadingAdvertisers = false;
            return data;
          });
      }

      return await axios.get(this.route('admin.api.advertisers.search.name', {
        name: query,
        without_webshops: true,
      }))
        .then(({data}) => {
          this.loadingAdvertisers = false;
          return data;
        });
    },

    loadAdvertiserSettingsAndPrograms(advertiserId) {
      this.loadingAdvertiserSettings = true;
      this.loadingAdvertiserPrograms = true;

      this.advertiserPrograms = [];
      this.defaultCashbackCommunication = null;
      this.defaultPreferredNetworkWhenTie = null;

      axios.get(this.route('admin.api.advertisers.show', [advertiserId]))
        .then(({data}) => {
          this.defaultCashbackCommunication = data.cashback_communication;
          this.defaultPreferredNetworkWhenTie = data.preferred_network_id_when_tie;

          this.loadingAdvertiserSettings = false;
        });

      return axios.get(this.route('admin.api.programs.find.advertiser', [advertiserId]))
        .then(({data}) => {
          this.advertiserPrograms = data;

          this.loadingAdvertiserPrograms = false;
        });
    },

    doUseDefaultCashbackCommunication() {
      this.useDefaultCashbackCommunication = true;
      this.$store.state.form.cashback_communication = null;
    },

    doUseDefaultPreferredNetworkWhenTie() {
      this.useDefaultPreferredNetworkWhenTie = true;
      this.$store.state.form.preferred_network_id_tie = null;
    },

    async fetchSimilarWebshops(query) {
      this.isLoadingSimilarWebshops = true;

      if (query === null && this.$store.state.form.similar_webshops.length > 0) {
        return await axios.get(this.route('admin.api.webshop.find', {id: this.$store.state.form.similar_webshops}))
          .then(({data}) => {
            this.isLoadingSimilarWebshops = false;
            return data;
          });
      }
      return await axios.get(this.route('admin.api.webshop.search.name', {name: query}))
        .then(({data}) => {
          this.isLoadingSimilarWebshops = false;
          return data;
        });
    },

    isActiveChanged() {
      if (this.$store.state.form.is_active === this.originalIsActive) {
        this.$store.state.form.is_active_reason = null;

        return;
      }

      this.isActiveReasonModalIsOpen = true;
    },
  },
};
</script>
