<template>
  <CrudCreate
    title="Tag Category - Create"
    sub-title="Tag Category"
    :index-route="route('admin.tag-categories.index')"
    :store-route="route('admin.tag-categories.store')"
  >
    <template #form>
      <CategoryForm />
    </template>
  </CrudCreate>
</template>

<script>
import CategoryForm from './Form.vue';

export default {
  components: {CategoryForm},
};
</script>
