<template>
  <InputField
    v-model="$store.state.form.description"
    label="Description"
    name="description"
    help="In English"
  />
  <CheckboxField
    v-model="$store.state.form.informational"
    label="Informational"
    name="informational"
    :required="false"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    category: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      description: props.category ? props.category.description : '',
      informational: props.category ? props.category.informational : false,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
