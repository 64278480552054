<template>
  <CrudIndex
    title="Program Term Categories"
    create-route-path="admin.term-categories.create"
    edit-route-path="admin.term-categories.edit"
    delete-route-path="admin.term-categories.destroy"
    row-route-path="admin.term-categories.show"
    :columns="['id', 'name']"
    :actions="true"
    :data="categories.data"
    :paginator="categories.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
};
</script>
