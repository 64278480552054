<template>
  <CrudIndex
    title="Bookkeeping"
    :columns="['Country', 'Year', 'Month', 'Member activity weeks', 'Date']"
    :actions="true"
    :menu-items="[
      {name: 'PDF', action: (item) => downloadPdf(item.id), enabled: true},
    ]"
    :data="bookkeepings.data"
    :paginator="bookkeepings.meta.pagination"
  >
    <template #country="{ row }">
      {{ row.country.code }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    bookkeepings: {
      type: Object,
      required: true,
    },
  },

  methods: {
    downloadPdf(bookkeeingId) {
      window.location.href = this.route('admin.bookkeeping.downloadPdf', bookkeeingId);
    },
  },
};
</script>
