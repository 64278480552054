<template>
  <CrudIndex
    title="Gross margin per user group"
    :columns="columns"
    :data="statistics"
    :actions="false"
    :filters="[
      {
        type: 'relation',
        label: 'Referred By',
        value: 'referred_by',
        multiple: true,
        api: route('admin.api.referrals.referrers.filter'),
      },
      {
        type: 'dropdown',
        operator: 'custom',
        label: 'Year',
        value: 'year',
        items: years.map((type) => ({name: type, value: type})),
        multiple: false,
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Subscription type',
        value: 'subscription_type',
        items: subscriptionTypes.map((type) => ({name: type, value: type})),
        multiple: true,
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Gender',
        value: 'gender',
        items: genders.map((type) => ({name: type, value: type})),
        multiple: true,
      },
      {
        type: 'date-range',
        label: 'Birth date',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Birth date', value: 'birth_date'}]
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Possible cheater',
        value: 'is_possible_cheater',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Fraudster',
        value: 'is_fraudster',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
    ]"
  >
    <template #after-filter>
      <div class="mb-4">
        Members in filter: {{ totalMembers }}
      </div>
    </template>

    <template #tr>
      <tr class="even:bg-gray-50 hover:bg-gray-100 focus-within:bg-gray-100">
        <td class="text-sm font-bold text-gray-900 align-top px-4 md:px-6 py-4 narrow-table:p-1 w-px whitespace-nowrap">
          Users
        </td>
        <template
          v-for="(total, i) in totals"
          :key="`total-${i}`"
        >
          <td class="text-sm font-medium text-gray-900 align-top px-4 md:px-6 py-4 narrow-table:p-1">
            {{ total }}
          </td>
        </template>
      </tr>

      <template
        v-for="(row, key) in statistics"
        :key="key"
      >
        <tr class="even:bg-gray-50 hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="text-sm font-bold text-gray-900 align-top px-4 md:px-6 py-4 narrow-table:p-1 w-px whitespace-nowrap">
            {{ currencySymbol }} {{ key }}
          </td>
          <template
            v-for="(column, i) in row"
            :key="`${key}-${i}`"
          >
            <td class="text-sm font-medium text-gray-900 align-top px-4 md:px-6 py-4 narrow-table:p-1">
              {{ column.percentage }}
            </td>
          </template>
        </tr>
      </template>

      <tr class="even:bg-gray-50 hover:bg-gray-100 focus-within:bg-gray-100">
        <td class="text-sm font-bold text-gray-900 align-top px-4 md:px-6 py-4 narrow-table:p-1 w-px whitespace-nowrap">
          Average
        </td>
        <template
          v-for="(average, i) in averages"
          :key="`average-${i}`"
        >
          <td class="text-sm font-medium text-gray-900 align-top px-4 md:px-6 py-4 narrow-table:p-1">
            {{ average }}
          </td>
        </template>
      </tr>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },

    currencySymbol: {
      type: String,
      required: true,
    },

    totals: {
      type: Object,
      required: true,
    },

    averages: {
      type: Object,
      required: true,
    },

    statistics: {
      type: Object,
      required: true,
    },

    totalMembers: {
      type: Number,
      required: true,
    },

    years: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },
  },
};
</script>
