<template>
  <CrudCreate
    title="Giftcard - Create shop"
    sub-title="Giftcards"
    :index-route="route('admin.giftcard-shops.index')"
    :store-route="route('admin.giftcard-shops.store')"
  >
    <template #form>
      <GiftcardForm :countries="countries" />
    </template>
  </CrudCreate>
</template>

<script>
import GiftcardForm from './Form.vue';

export default {
  components: {GiftcardForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },
  },
};
</script>
