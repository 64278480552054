<template>
  <CrudCreate
    title="Mailing - Create"
    sub-title="Mailings"
    :index-route="isSurvey ? route('admin.survey-mailing-planning.index') : route('admin.mailing-planning.index')"
    :store-route="route('admin.mailings.store')"
  >
    <template #cards>
      <MailingForm
        :is-survey="isSurvey"
        :country-currency="countryCurrency"
        :default-scheduled-at="defaultScheduledAt"
        :reward-types="rewardTypes"
        :default-click-reward-value="defaultClickRewardValue"
        :default-view-reward-value="defaultViewRewardValue"
        :notification-types="notificationTypes"
        :subscription-types="subscriptionTypes"
        :allowed-types-for-subscription-segmentation="allowedTypesForSubscriptionSegmentation"
        :templates="templates"
        :commission-model-display-options="commissionModelDisplayOptions"
        :planner="planner"
        :administrators="administrators"
        :genders="genders"
        :prefill-advertiser-id="prefillAdvertiserId"
        :prefill-program-id="prefillProgramId"
        :prefill-survey-id="prefillSurveyId"
        :prefill-survey-wave-id="prefillSurveyWaveId"
        :prefill-notification-type="prefillNotificationType"
        :prefill-commission-model-display="prefillCommissionModelDisplay"
        :prefill-final-control="prefillFinalControl"
        :prefill-final-controller-id="prefillFinalControllerId"
        :mailbanners="mailbanners"
        :types-without-click-reward="typesWithoutClickReward"
        :replaceable-tracking-url-variables="replaceableTrackingUrlVariables"
        :replaceable-member-attributes="replaceableMemberAttributes"
        :replaceable-survey-attributes="replaceableSurveyAttributes"
        :segmentation-attributes="segmentationAttributes"
        :multi-advertiser-media-types="multiAdvertiserMediaTypes"
        :survey-statuses="surveyStatuses"
        :default-exclude-referred-by-members="defaultExcludeReferredByMembers"
        :auto-remail-delay-days="autoRemailDelayDays"
      />
    </template>
  </CrudCreate>
</template>

<script>
import MailingForm from './Form.vue';

export default {
  components: {
    MailingForm,
  },

  props: {
    isSurvey: {
      type: Boolean,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    defaultScheduledAt: {
      type: String,
      required: true,
    },

    rewardTypes: {
      type: Array,
      required: true,
    },

    defaultClickRewardValue: {
      type: String,
      required: true,
    },

    defaultViewRewardValue: {
      type: String,
      required: true,
    },

    notificationTypes: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },

    allowedTypesForSubscriptionSegmentation: {
      type: Array,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },

    commissionModelDisplayOptions: {
      type: Array,
      required: true,
    },

    planner: {
      type: Object,
      required: true,
    },

    administrators: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    prefillAdvertiserId: {
      type: Number,
      required: false,
      default: null,
    },

    prefillProgramId: {
      type: String,
      required: false,
      default: null,
    },

    prefillSurveyId: {
      type: Number,
      required: false,
      default: null,
    },

    prefillSurveyWaveId: {
      type: Number,
      required: false,
      default: null,
    },

    prefillNotificationType: {
      type: String,
      required: false,
      default: null,
    },

    prefillCommissionModelDisplay: {
      type: String,
      required: false,
      default: null,
    },

    prefillFinalControl: {
      type: Boolean,
      required: false,
      default: null,
    },

    prefillFinalControllerId: {
      type: Boolean,
      required: false,
      default: null,
    },

    mailbanners: {
      type: Object,
      required: false,
      default: null,
    },

    typesWithoutClickReward: {
      type: Array,
      required: true,
    },

    replaceableTrackingUrlVariables: {
      type: Array,
      required: true,
    },

    replaceableMemberAttributes: {
      type: Array,
      required: true,
    },

    replaceableSurveyAttributes: {
      type: Array,
      required: true,
    },

    segmentationAttributes: {
      type: Array,
      required: true,
    },

    multiAdvertiserMediaTypes: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Array,
      required: true,
    },

    defaultExcludeReferredByMembers: {
      type: Array,
      required: true,
    },

    autoRemailDelayDays: {
      type: Number,
      required: true,
    },
  },
};
</script>
