<template>
  <CrudCreate
    title="Add IBAN to blacklist"
    sub-title="IBAN blacklist"
    :index-route="route('admin.iban-blacklist.index')"
    :store-route="route('admin.iban-blacklist.store')"
  >
    <template #form>
      <IbanBlacklistForm />
    </template>
  </CrudCreate>
</template>

<script>
import IbanBlacklistForm from './Form.vue';

export default {
  components: {IbanBlacklistForm},
};
</script>
