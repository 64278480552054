<template>
  <CrudCreate
    title="Reward"
    sub-title="Unrewarded Mailings"
    :index-route="route('admin.unrewarded-mailings.index')"
    :store-route="route('admin.unrewarded-mailings.apply-rewards', [mailing.id])"
    action="Reward"
  >
    <template #cards>
      <div class="bg-orange-50 border-l-8 border-orange-700 mb-2">
        <div class="flex items-center">
          <div class="p-2">
            <div class="flex flex-col">
              <p class="px-4 py-2 text-orange-700">
                This action can only be done once per mailing. All participants not in the list will receive a reward of 0.
              </p>


              <div
                v-if="mailings"
                class="px-4 py-2 text-orange-700 font-bold"
              >
                This is a batch update for mailings:
                <ul>
                  <li
                    v-for="batchMailing in mailings"
                    :key="batchMailing.id"
                  >
                    #{{ batchMailing.id }} {{ batchMailing.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CrudCard>
        <template #content>
          <CurrencyAndPointsField
            v-if="survey"
            :model-value="survey.participation_reward_value"
            :options="participationRewardOptions"
            :points-conversion-ratio="pointsConversionRatio"
            label="Participation reward"
            name="participation_reward_value"
            help="As set in the survey"
            :disabled="true"
          />

          <CurrencyAndPointsField
            v-model="$store.state.form.reward"
            :options="participationRewardOptions"
            :points-conversion-ratio="pointsConversionRatio"
            label="Reward"
            name="reward"
            :required="true"
            help="Manual reward value"
          />

          <InputField
            label="File"
            type="file"
            accept=".csv"
            name="file"
            :required="true"
            help="Only .csv. Must contain 'clickout_ids'"
            @input="$store.state.form.file = $event.target.files[0]"
          />

          <CheckboxField
            v-model="$store.state.form.reward_all"
            label="Reward double clickout id's"
            name="reward_all"
            help="Member get for each clickout id the reward value"
            :required="false"
          />
        </template>
      </CrudCard>
    </template>
  </CrudCreate>
</template>

<script>
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';

export default {
  components: {CurrencyAndPointsField},

  props: {
    mailing: {
      type: Array,
      required: true,
    },

    mailings: {
      type: Array,
      required: false,
      default: () => null,
    },

    survey: {
      type: Array,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },

  setup (props) {
    const form = useForm({
      reward: props.survey?.participation_reward_value ?? null,
      file: null,
      reward_all: false,
      mailing_ids: props.mailings ? props.mailings.map(mailing => mailing.id) : null,
    });

    useStore().commit('form/setForm', form);
  },

  data() {
    const participationRewardOptions = {
      currency: this.countryCurrency.iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    return {participationRewardOptions};
  },
};
</script>
