<template>
  <CrudToggleCard :default-open="true">
    <template #header>
      <span class="font-medium">
        General
      </span>
    </template>
    <template #content>
      <SelectField
        v-model="$store.state.form.country_id"
        label="Country"
        name="country_id"
        placeholder="Select a Country"
        :items="countries"
        :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
      />

      <SelectField
        v-model="$store.state.form.currency_id"
        label="Currency"
        name="currency_id"
        placeholder="Select a currency"
        :items="currencies"
      />

      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />

      <InputField
        v-model="$store.state.form.tracking_url"
        label="Tracking URL"
        name="tracking_url"
        :help="`Must contain '..=${subIdPlaceholder}'`"
      >
        <template
          v-if="$store.state.form.tracking_url"
          #extra
        >
          <DefaultButton @click.prevent="copy($store.state.form.tracking_url)">
            <i class="far fa-copy text-lg" />
          </DefaultButton>

          <DefaultButton
            :internal-link="false"
            :href="$store.state.form.tracking_url"
            target="_blank"
          >
            <i class="far fa-external-link text-lg" />
          </DefaultButton>
        </template>
      </InputField>
    </template>
  </CrudToggleCard>

  <CrudToggleCard :default-open="true">
    <template #header>
      <span class="font-medium">
        Commission Models
      </span>
    </template>
    <template #content>
      <ManualCommissionModelsForm />
    </template>
  </CrudToggleCard>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import ManualCommissionModelsForm from './ManualCommissionModelsForm.vue';

export default {
  components: {
    ManualCommissionModelsForm,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },

    currencies: {
      type: Array,
      required: true,
    },

    subIdPlaceholder: {
      type: String,
      required: true,
    },

    manualProgram: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      country_id: props.manualProgram?.country_id ?? null,
      currency_id: props.manualProgram?.currency_id ?? null,
      currency_iso: props.manualProgram?.currency_iso ?? null,
      name: props.manualProgram?.name ?? null,
      tracking_url: props.manualProgram?.tracking_url ?? null,
      commission_models: props.manualProgram?.commission_models ?? [],
    });

    useStore().commit('form/setForm', form);
  },

  watch: {
    '$store.state.form.country_id': function(newCountryId) {
      newCountryId = parseInt(newCountryId);
      const currency = this.countries.find((country) => country.id === newCountryId).currency;

      this.$store.state.form.currency_id = currency.id;
    },

    '$store.state.form.currency_id': function(newCurrencyId) {
      newCurrencyId = parseInt(newCurrencyId);
      const currency = this.currencies.find((currency) => currency.id === newCurrencyId);
      this.$store.state.form.currency_iso = currency.iso;
    },
  },

  mounted() {
    if (!this.manualProgram && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }
  },

  methods: {
    copy(value) {
      navigator.clipboard.writeText(value);
    },
  },
};
</script>
