<template>
  <div
    v-if="showOperatorIndicator"
    class="mb-4 text-center text-xs uppercase"
  >
    {{ value.operator }}
  </div>

  <CrudCard :default-open="true">
    <template #header>
      <div class="flex">
        <div class="grow font-medium">
          Filter group

          <div
            v-if="showAddAnotherGroupMessage"
            class="block text-xs text-gray-400"
          >
            Add at least another group on the same level. Since the groups are "or" it has no effect to have one group.
          </div>
        </div>
        <DefaultButton
          v-if="canCalculateCount"
          type="button"
          class="p-2"
          @click="calculateMemberCount"
        >
          <i
            v-if="loadingMemberCount"
            class="far fa-spin fa-spinner"
          />
          <i
            v-else
            class="far fa-calculator"
          />
          <span
            v-if="memberCount !== null"
            class="ml-2"
          >
            {{ memberCount }} members
          </span>
        </DefaultButton>
        <DropdownButton
          :items="addItemList"
          :style="`z-index: ${100 - depth} !important;`"
        >
          Add item
        </DropdownButton>
        <button
          v-if="canBeDeleted"
          type="button"
          class="p-2"
          @click="remove"
        >
          <i class="far fa-times" />
        </button>
      </div>
    </template>
    <template #content>
      <template
        v-for="(item, i) in value.children"
        :key="`group-${item.key}-children-rules`"
      >
        <Item
          v-if="item.type === 'item'"
          v-model="value.children[i]"
          :index="i"
          :order="getOrderForChild(item)"
          :depth="depth + 1"
          :genders="genders"
          :provinces="provinces"
          :survey-statuses="surveyStatuses"
          :mailing-filter-items="mailingFilterItems"
          :mailing-filter-item-explanations="mailingFilterItemExplanations"
          :mailing-filter-item-file-fields="mailingFilterItemFileFields"
          :mailing-filter-item-operators="mailingFilterItemOperators"
          @delete="deleteChild"
        />
      </template>
    </template>
  </CrudCard>

  <div
    v-for="(item, i) in value.children"
    :key="`group-${item.key}-children-groups`"
  >
    <div
      v-if="item.type === 'group'"
      class="ml-20"
    >
      <Group
        v-model="value.children[i]"
        :index="i"
        :order="getOrderForChild(item)"
        :has-other-groups="hasMultipleGroups()"
        :depth="depth + 1"
        :genders="genders"
        :provinces="provinces"
        :survey-statuses="surveyStatuses"
        :mailing-filter-items="mailingFilterItems"
        :mailing-filter-item-explanations="mailingFilterItemExplanations"
        :mailing-filter-item-file-fields="mailingFilterItemFileFields"
        :mailing-filter-item-operators="mailingFilterItemOperators"
        :mailing-filter-item-usage="mailingFilterItemUsage"
        @delete="deleteChild"
      />
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import Item from './Item.vue';
import Base from './Base';

export default {
  name: 'Group',

  components: { Item },

  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },

    hasOtherGroups: {
      required: false,
      type: Number,
      default: 0,
    },

    mailingFilterItemUsage: {
      required: true,
      type: Object,
    },
  },

  emits: ['update:modelValue', 'delete'],

  setup(props) {
    props.modelValue.children.map((child) => {
      child.key = Math.random().toString(36).substr(2, 5);

      return child;
    });

    const value = ref(props.modelValue);
    if (!value.value.name) {
      value.value.name = 'Group name';
    }

    return {value};
  },

  data() {
    return {
      newItemId: Object.keys(this.mailingFilterItems)[0],
      loadingMemberCount: false,
      memberCount: null,
    };
  },

  computed: {
    showAddAnotherGroupMessage () {
      return this.depth > 0 && !this.hasOtherGroups;
    },

    showOperatorIndicator () {
      return this.depth > 0 && this.order > 0;
    },

    canBeDeleted () {
      return this.depth > 0;
    },

    canCalculateCount () {
      return this.depth > 0;
    },

    addItemList() {
      const items = [];

      for (let key of Object.keys(this.mailingFilterItems)) {
        if (
          key === 'mailing_filter_file'
          && this.mailingFilterItemUsage.mailing_filter_file
          && this.mailingFilterItemUsage.mailing_filter_file >= 2
        ) {
          continue;
        }

        const label = this.mailingFilterItems[key];
        items.push({name: label, action: () => this.addItem({id: key})});
      }

      if (this.depth < 2) {
        items.push({name: 'Group', action: () => this.addGroup()});
      }

      return items;
    },
  },

  methods: {
    getOrderForChild(child) {
      return this.value.children.filter(v => v.type === child.type)
        .findIndex(v => v.key === child.key);
    },

    hasMultipleGroups() {
      return this.value.children.filter(v => v.type === 'group').length > 1;
    },

    getGroupLevelForGroup(group) {
      return this.value.children.filter(v => v.type === 'group')
        .findIndex(v => v.key === group.key);
    },

    addGroup() {
      const key = Math.random().toString(36).substr(2, 5);
      this.value.children.push({key, type: 'group', operator: 'or', children: []});
    },

    addItem(item) {
      const key = Math.random().toString(36).substr(2, 5);
      this.value.children.push({key, type: 'item', item: cloneDeep(item)});
    },

    deleteChild(index) {
      this.value.children.splice(index, 1);
    },

    calculateMemberCount() {
      this.loadingMemberCount = true;

      axios.post(this.route('admin.api.mailing.filter.calculate-filter'), {
        mailing_filter_id: this.$store.state.form.id,
        query: this.value,
      })
        .then((response) => {
          this.memberCount = response.data.count;
          this.loadingMemberCount = false;
        });
    },

    remove() {
      this.$emit('delete', this.index);
    },
  },
};
</script>
