<template>
  <CrudCreate
    title="Create"
    :sub-title="`Notes: #${member.id}`"
    :index-route="route('admin.members.edit', member.id)"
    :store-route="route('admin.members.notes.store', member.id)"
  >
    <template #form>
      <TextareaField
        v-model="$store.state.form.note"
        label="Note"
        name="note"
      />
    </template>
  </CrudCreate>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      note: null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
