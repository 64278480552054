<template>
  <CrudCreate
    title="Click action - Create"
    sub-title="Click actions"
    :index-route="route('admin.click-actions.index')"
    :store-route="route('admin.click-actions.store')"
  >
    <template #cards>
      <ClickActionForm
        :webshop="webshop"
        :default-reward-value="defaultRewardValue"
        :max-reward-value="maxRewardValue"
        :points-conversion-ratio="pointsConversionRatio"
      />
    </template>
  </CrudCreate>
</template>

<script>
import ClickActionForm from './Form.vue';

export default {
  components: {
    ClickActionForm,
  },

  props: {
    webshop: {
      type: Object,
      required: true,
    },

    defaultRewardValue: {
      type: String,
      required: true,
    },

    maxRewardValue: {
      type: String,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },
};
</script>
