<template>
  <div>
    <MultiSelectField
      v-if="!isSurvey && !$store.state.mailingData.advertiserId"
      v-model="$store.state.form.advertiser_id"
      label="Advertiser"
      name="advertiser_id"
      placeholder="Select an advertiser"
      mode="single"
      :searchable="true"
      :can-deselect="false"
      :can-clear="false"
      :resolve-on-load="$store.state.form.advertiser_id > 0 && !$store.state.mailingData.advertiserId"
      :min-chars="2"
      :delay="150"
      :loading="loadingAdvertisers"
      :options="async (query) => await fetchAdvertisers(query)"
      @select="advertiserChanged"
    >
      <template
        v-if="$store.state.form.advertiser_id"
        #extra
      >
        <DefaultButton
          :internal-link="false"
          :href="route('admin.advertisers.edit', $store.state.form.advertiser_id)"
          target="_blank"
        >
          <span class="material-icons md-18">open_in_new</span>
        </DefaultButton>
      </template>
    </MultiSelectField>
    <InputField
      v-else-if="!isSurvey"
      v-model="$store.state.mailingData.advertiserName"
      label="Advertiser"
      name="advertiser_id"
      :disabled="true"
    >
      <template #extra>
        <DefaultButton
          :internal-link="false"
          :href="route('admin.advertisers.edit', $store.state.mailingData.advertiserId)"
          target="_blank"
        >
          <span class="material-icons md-18">open_in_new</span>
        </DefaultButton>
      </template>
    </InputField>

    <MultiSelectField
      v-if="$store.state.form.advertiser_id && !isSurvey"
      ref="programInput"
      v-model="$store.state.form.program_id"
      label="Program"
      name="program_id"
      placeholder="Select a program"
      mode="single"
      :searchable="false"
      :can-deselect="true"
      :can-clear="true"
      :loading="loadingAdvertiserPrograms"
      :options="advertiserPrograms"
      @select="programChanged"
    />
    <InputField
      v-else-if="!isSurvey"
      v-model="$store.state.mailingData.programName"
      label="Program"
      name="program_id"
      :disabled="true"
    />

    <InputField
      v-model="$store.state.form.name"
      label="Name"
      name="name"
    />

    <InputField
      v-model="$store.state.form.scheduled_at"
      label="Scheduled at"
      name="scheduled_at"
      type="datetime-local"
      :disabled="$store.state.mailingData.isSent"
    />

    <SelectField
      v-if="!isSurvey"
      v-model="$store.state.form.notification_type"
      label="Notification type"
      name="notification_type"
      placeholder="Select a type"
      :items="notificationTypes"
      :disabled="$store.state.mailingData.isSent"
      @change="resetAllowAsLimited()"
    />

    <div
      v-if="needsReward && !isSurvey"
      class="gap-1 py-6"
    >
      <SelectField
        v-model="$store.state.form.reward_type"
        label="Click or view reward"
        name="click_or_view_reward"
        :items="rewardTypes"
        :disabled="$store.state.mailingData.isSent"
      />

      <div
        v-if="$store.state.form.reward_type === 'click'"
        class="gap-1 mt-6"
      >
        <CurrencyField
          v-if="useDefaultClickRewardValue"
          v-model="defaultClickReward"
          :options="rewardCurrency"
          :disabled="true"
          label="Click reward"
          name="reward_value"
          help="Default click reward"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultClickRewardValue = false">
              <span
                class="material-icons md-18"
                title="Don't use default config"
              >edit</span>
            </DefaultButton>
          </template>
        </CurrencyField>
        <CurrencyField
          v-else
          v-model="$store.state.form.reward_value"
          :options="rewardCurrency"
          label="Click reward"
          name="reward_value"
          :disabled="$store.state.mailingData.isSent"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultClickReward">
              <span
                class="material-icons md-18"
                title="Use default config"
              >edit_off</span>
            </DefaultButton>
          </template>
        </CurrencyField>
      </div>

      <div
        v-if="$store.state.form.reward_type === 'view'"
        class="gap-1 mt-6"
      >
        <CurrencyField
          v-if="useDefaultViewRewardValue"
          v-model="defaultViewReward"
          :options="rewardCurrency"
          :disabled="true"
          label="View reward"
          name="view_reward_value"
          help="Default view reward"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultViewRewardValue = false">
              <span
                class="material-icons md-18"
                title="Don't use default config"
              >edit</span>
            </DefaultButton>
          </template>
        </CurrencyField>
        <CurrencyField
          v-else
          v-model="$store.state.form.reward_value"
          :options="rewardCurrency"
          label="View reward"
          name="reward_value"
          :disabled="$store.state.mailingData.isSent"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultViewReward">
              <span
                class="material-icons md-18"
                title="Use default config"
              >edit_off</span>
            </DefaultButton>
          </template>
        </CurrencyField>
      </div>
    </div>

    <CheckboxField
      v-model="$store.state.form.is_manual_reward"
      label="Manual reward"
      name="is_manual_reward"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />

    <CheckboxField
      v-model="$store.state.form.must_auto_remail"
      label="Auto remail non-clickers"
      name="must_auto_remail"
      :help="`Re-send this mailing to members who have not clicked yet. A new mailing will be automatically set up after the current one is sent more than ${$store.state.mailingData.autoRemailDelayDays} days ago.`"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />

    <InputField
      v-if="!isSurvey"
      v-model="$store.state.form.expected_ecpc"
      label="Expected eCPC"
      name="expected_ecpc"
      type="number"
      help="in cents"
      min="1"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />

    <CheckboxField
      v-if="$store.state.form.notification_type === 'commercial_unlimited'"
      v-model="$store.state.form.allow_as_limited"
      label="Allow as limited mailing"
      name="allow_as_limited"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />

    <CheckboxField
      v-if="!isSurvey"
      v-model="$store.state.form.route_outside_platform"
      label="Route outside platform"
      name="route_outside_platform"
      help="Uses tracking-url directly, no clickout is measured. Mainly useful for Adthletic."
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />

    <CheckboxField
      v-if="!isSurvey"
      v-model="$store.state.form.hide_on_platform"
      label="Do not show online"
      name="hide_on_platform"
      help="Mailing is not visible on the platform"
      :required="false"
    />
  </div>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';

export default {
  props: {
    isDuplicate: {
      type: Boolean,
      required: true,
    },

    isSurvey: {
      type: Boolean,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    notificationTypes: {
      type: Array,
      required: true,
    },

    rewardTypes: {
      type: Array,
      required: true,
    },

    defaultClickRewardValue: {
      type: String,
      required: true,
    },

    defaultViewRewardValue: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    return {
      defaultClickReward: props.defaultClickRewardValue,
      defaultViewReward: props.defaultViewRewardValue,
    };
  },

  data() {
    const rewardCurrency = {
      currency: this.countryCurrency.iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    return {
      loadingAdvertisers: false,
      loadingAdvertiserPrograms: false,
      advertiserPrograms: [],
      needsReward: true,
      rewardCurrency: rewardCurrency,
      useDefaultClickRewardValue: ref(this.$store.state.form.reward_value === null),
      useDefaultViewRewardValue: ref(this.$store.state.form.reward_value === null),
    };
  },

  watch: {
    '$store.state.form.notification_type': function () {
      this.showOrHideReward();
    },
  },

  mounted() {
    this.showOrHideReward();

    if (this.$store.state.form.advertiser_id && this.$store.state.form.program_id) {
      this.loadAdvertiserSettingsAndPrograms(this.$store.state.form.advertiser_id);
    }

    if (this.isDuplicate) {
      this.fetchAdvertisers(null);
    }
  },

  methods: {
    showOrHideReward() {
      const typesWithoutClickReward = this.$store.state.mailingData.typesWithoutClickReward;

      if (typesWithoutClickReward.includes(this.$store.state.form.notification_type)) {
        this.needsReward = false;
        this.$store.state.form.reward_type = null;
        this.$store.state.form.reward_value = null;
        this.$store.state.form.reward_currency_id = null;
      } else {
        this.needsReward = true;

        this.$store.state.form.reward_type = this.$store.state.form.reward_type ?? this.$store.state.mailingData.originalRewardType;
        this.$store.state.form.reward_value = this.$store.state.form.reward_value ?? this.$store.state.mailingData.originalRewardValue;
        this.$store.state.form.reward_currency_id = this.$store.state.form.reward_currency_id ?? this.$store.state.mailingData.originalRewardCurrency;

        if (!this.$store.state.form.reward_value) {
          if (this.$store.state.form.reward_type === 'click') {
            this.useDefaultClickRewardValue = true;
          } else if (this.$store.state.form.reward_type === 'view') {
            this.useDefaultViewRewardValue = true;
          }
        }
      }
    },

    advertiserChanged(advertiserId, selectedOption) {
      advertiserId = parseInt(advertiserId);
      if (isNaN(advertiserId)) {
        return;
      }

      if (advertiserId !== this.$store.state.form.advertiser_id) {
        this.$store.state.form.name = selectedOption.label;
        this.$store.state.mailingData.advertiserName = selectedOption.label;
      }

      this.$nextTick(() => {
        if (!this.isDuplicate) {
          this.$store.state.form.program_id = 0;
          this.$refs.programInput.clear();
        }

        this.loadAdvertiserSettingsAndPrograms(advertiserId);
      });
    },

    async fetchAdvertisers(query) {
      this.loadingAdvertisers = true;

      let fetchInitialSelectedAdvertiser = query === null;
      if (fetchInitialSelectedAdvertiser) {
        const fetchedData = await axios.get(this.route('admin.api.advertisers.find', {id: this.$store.state.form.advertiser_id}))
          .then(({data}) => {
            this.loadingAdvertisers = false;
            return data;
          });

        this.advertiserChanged(this.$store.state.form.advertiser_id, {
          label: fetchedData[this.$store.state.form.advertiser_id] ?? 'n/a',
        });

        return fetchedData;
      }

      return await axios.get(this.route('admin.api.advertisers.search.name', {name: query}))
        .then(({data}) => {
          this.loadingAdvertisers = false;
          return data;
        });
    },

    loadAdvertiserSettingsAndPrograms(advertiserId) {
      this.loadingAdvertiserPrograms = true;
      this.advertiserPrograms = [];

      this.$store.state.mailingData.advertiserLogoId = null;

      axios.get(this.route('admin.api.advertisers.show', {
        advertiser: advertiserId,
      }))
        .then(({data}) => {
          this.$store.state.mailingData.advertiserLogoId = data.logo_media_id;
        });

      return axios.get(this.route('admin.api.programs.find.advertiser', [advertiserId]))
        .then(({data}) => {
          this.advertiserPrograms = data;

          this.loadingAdvertiserPrograms = false;
        });
    },

    programChanged(programId) {
      this.$store.state.mailingData.programCommissionModels = [];

      if (this.isDuplicate) {
        this.$store.state.form.tracking_url = '';
      }

      axios
        .get(this.route('admin.api.programs.details', {program: programId, include: 'commission_models.tags.informational,commission_models.tags.category_description'}))
        .then(({data}) => {
          this.$store.state.mailingData.programCommissionModels = data.commission_models;
        });
    },

    useDefaultClickReward() {
      this.useDefaultClickRewardValue = true;
      this.$store.state.form.reward_value = null;
    },

    useDefaultViewReward() {
      this.useDefaultViewRewardValue = true;
      this.$store.state.form.reward_value = null;
    },

    resetAllowAsLimited() {
      if (this.$store.state.form.notification_type !== 'commercial_unlimited') {
        this.$store.state.form.allow_as_limited = false;
      }
    },
  },
};
</script>
