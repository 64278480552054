<template>
  <CrudEdit
    :title="mailbanner.name"
    sub-title="Mailbanner"
    :index-route="route('admin.mailbanners.index')"
    :update-route="route('admin.mailbanners.update', mailbanner.id)"
  >
    <template #cards>
      <MailbannerForm
        :mailbanner="mailbanner"
        :programs="programsWithKeys()"
      />
    </template>
  </CrudEdit>
</template>

<script>
import MailbannerForm from './Form.vue';

export default {
  components: {
    MailbannerForm,
  },

  props: {
    mailbanner: {
      type: Object,
      required: true,
    },

    programs: {
      type: Array,
      required: true,
    },
  },

  methods: {
    programsWithKeys() {
      let programsWithKeys = {};
      this.programs.forEach(program => programsWithKeys[program.id] = program);

      return programsWithKeys;
    },
  },

};
</script>
