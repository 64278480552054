<template>
  <CrudShow
    :title="`Deleted member #${deletedMember.member_id}`"
    sub-title="Deleted members"
    :back-route-path="route('admin.deleted-members.index')"
    :index-route="route('admin.deleted-members.index')"
    :rows="[
      'ID',
      'Member ID',
      'Country',
      'Language',
      'Currency',
      'Email',
      'Current balance',
      'Username',
      'First name',
      'Last name',
      'Birth date',
      'Is possible cheater',
      'Is fraudster',
      'Subscription type',
      'Subscription expires at',
      'First payment succeeded',
      'Registration IP',
      'Registration user agent',
      'Referred by',
      'Email verified at',
      'Created at',
      'Deleted at',
      'Deleted by',
    ]"
    :data="deletedMember"
  >
    <template #before-back>
      <InertiaLink
        :href="route('admin.deleted-members.edit', deletedMember.id)"
        class="inline-flex mr-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Edit
      </InertiaLink>
    </template>
  </CrudShow>
</template>

<script>
export default {
  props: {
    deletedMember: {
      type: Object,
      required: true,
    },
  },
};
</script>
