<template>
  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
    :required="true"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    surveyWave: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.surveyWave?.name ?? '',
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
