<template>
  <CrudEdit
    :title="`Manual program ${manualProgram.name}`"
    sub-title="Manual programs"
    :index-route="route('admin.manual-programs.index')"
    :update-route="route('admin.manual-programs.update', manualProgram.id)"
  >
    <template #cards>
      <ManualProgramForm
        :countries="countries"
        :currencies="currencies"
        :sub-id-placeholder="subIdPlaceholder"
        :manual-program="manualProgram"
      />
    </template>
  </CrudEdit>
</template>

<script>
import ManualProgramForm from './Form.vue';

export default {
  components: {ManualProgramForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },

    currencies: {
      type: Array,
      required: true,
    },

    subIdPlaceholder: {
      type: String,
      required: true,
    },

    manualProgram: {
      type: Object,
      required: true,
    },
  },
};
</script>
