<template>
  <CrudEdit
    title="Network Guarantee - Edit"
    sub-title="Network Guarantees"
    :index-route="route('admin.network-revenue-statistics.network-guarantees.index')"
    :update-route="route('admin.network-revenue-statistics.network-guarantees.update', networkGuarantee.id)"
  >
    <template #form>
      <NetworkGuaranteeForm
        :affiliwings-networks="affiliwingsNetworks"
        :currency="currency"
        :network-guarantee="networkGuarantee"
      />
    </template>
  </CrudEdit>
</template>

<script>
import NetworkGuaranteeForm from './Form.vue';

export default {
  components: {
    NetworkGuaranteeForm,
  },

  props: {
    affiliwingsNetworks: {
      type: Object,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    networkGuarantee: {
      type: Object,
      required: true,
    },
  },
};
</script>
