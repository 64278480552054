<template>
  <CrudEdit
    :title="`Deleted Member #${deletedMember.member_id}`"
    sub-title="Deleted Members"
    :index-route="route('admin.deleted-members.index')"
    :update-route="route('admin.deleted-members.update', deletedMember.id)"
  >
    <template #form>
      <CheckboxField
        v-model="$store.state.form.is_possible_cheater"
        label="Possible cheater"
        name="is_possible_cheater"
        :required="false"
      />
      <CheckboxField
        v-model="$store.state.form.is_fraudster"
        label="Fraudster"
        name="is_fraudster"
        :required="false"
      />
    </template>
  </CrudEdit>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    deletedMember: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      is_possible_cheater: props.deletedMember.is_possible_cheater,
      is_fraudster: props.deletedMember.is_fraudster,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
