<template>
  <CrudIndex
    title="Unrewarded Mailings"
    :columns="columns"
    :actions="true"
    :data="mailings.data"
    :paginator="mailings.meta.pagination"
    :menu-items="menuItems"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
      {
        type: 'relation',
        label: 'Survey',
        value: 'survey_id',
        multiple: true,
        api: route('admin.api.surveys.filter'),
      },
      {
        type: 'relation',
        label: 'Survey Wave',
        value: 'survey_wave_id',
        multiple: true,
        api: route('admin.api.survey-waves.filter'),
      },
    ]"
    :can-select-rows="true"
  >
    <template #selected-actions="{ selected, disabled, selectedIds }">
      <DefaultButton
        :classes="(disabled ? 'bg-gray-400 text-gray-800 cursor-not-allowed disabled' : 'text-white bg-indigo-600 hover:bg-indigo-700')"
        :disabled="disabled"
        @click="disabled ? null : rewardBatch(selectedIds)"
      >
        Reward batch ({{ selected }})
      </DefaultButton>
    </template>

    <template #segmentation="{ row }">
      {{ row.segmentation_name }}
    </template>

    <template #planner="{ row }">
      {{ row.planner_name }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    mailings: {
      type: Object,
      required: true,
    },

    showReward: {
      type: Boolean,
      required: false,
      default: true,
    },

    columns: {
      type: Array,
      required: false,
      default: () => ['id', 'name', 'sent_at'],
    },
  },

  data () {
    const menuItems = [];
    if (this.showReward) {
      menuItems.push({
        name: 'Reward',
        icon: 'gavel',
        url: (item) => this.route('admin.unrewarded-mailings.reward', item.id),
        enabled: (item) => item.canReward,
      });
    }

    return {menuItems};
  },

  methods: {
    rewardBatch(mailingIds) {
      this.$inertia.get(this.route('admin.unrewarded-mailings.reward-batch', {mailing_ids: mailingIds}), {}, {
        preserveState: true,
        preserveScroll: true,
      });
    },
  },
};
</script>
