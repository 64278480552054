<template>
  <CrudCreate
    title="Charity - Create"
    sub-title="Charities"
    :index-route="route('admin.charities.index')"
    :store-route="route('admin.charities.store')"
  >
    <template #form>
      <CharityForm :countries="countries" />
    </template>
  </CrudCreate>
</template>

<script>
import CharityForm from './Form.vue';

export default {
  components: {CharityForm},

  props: {
    countries: {
      type: Object,
      required: true,
    },
  },
};
</script>
