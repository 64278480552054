<template>
  <CrudEdit
    title="Webshop Themes"
    sub-title="Webshop Themes"
    :index-route="route('admin.webshop-themes.index')"
    :update-route="route('admin.webshop-themes.update', theme.id)"
  >
    <template #cards>
      <ThemeForm
        :theme="theme"
        :countries="countries"
        :minimum-media-size="minimumMediaSize"
        :sort-options="sortOptions"
        :block-types="blockTypes"
        :page-types="pageTypes"
      />
    </template>
  </CrudEdit>
</template>

<script>
import ThemeForm from './Form.vue';

export default {
  components: {ThemeForm},

  props: {
    theme: {
      type: Object,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },

    minimumMediaSize: {
      type: Array,
      required: true,
    },

    sortOptions: {
      type: Array,
      required: true,
    },

    blockTypes: {
      type: Array,
      required: true,
    },

    pageTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
