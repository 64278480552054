<template>
  <CrudEdit
    title="Webshop Category"
    sub-title="Webshop Category"
    :index-route="route('admin.webshop-categories.index')"
    :update-route="route('admin.webshop-categories.update', category.id)"
  >
    <template #cards>
      <CategoryForm
        :category="category"
        :countries="countries"
        :minimum-media-size="minimumMediaSize"
      />
    </template>
  </CrudEdit>
</template>

<script>
import CategoryForm from './Form.vue';

export default {
  components: {CategoryForm},

  props: {
    category: {
      type: Object,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },

    minimumMediaSize: {
      type: Array,
      required: true,
    },
  },
};
</script>
