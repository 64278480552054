<template>
  <CrudEdit
    title="Surveys - Edit"
    sub-title="Surveys"
    :index-route="route('admin.surveys.index')"
    :update-route="route('admin.surveys.update', survey.id)"
  >
    <template #form>
      <SurveyForm
        :types="types"
        :survey="survey"
        :country-currency="countryCurrency"
        :lead-price-currency="leadPriceCurrency"
        :points-conversion-ratio="pointsConversionRatio"
        :selected-country-id="selectedCountryId"
      />
    </template>
  </CrudEdit>
</template>

<script>
import SurveyForm from './Form.vue';

export default {
  components: {
    SurveyForm,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },

    types: {
      type: Array,
      required: true,
    },

    selectedCountryId: {
      type: Number,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    leadPriceCurrency: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },
};
</script>
