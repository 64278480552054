<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <CrudIndex
    v-else
    title="Games"
    create-route-path="admin.games.create"
    edit-route-path="admin.games.edit"
    row-route-path="admin.games.edit"
    delete-route-path="admin.games.destroy"
    :columns="['ID', 'Country', 'Active', 'Name']"
    :actions="true"
    :data="games.data"
    :paginator="games.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
    ]"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', color: 'blue-400', action: (item) => move(item.id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', color: 'blue-400', action: (item) => move(item.id, 'down'), enabled: true},
    ]"
  >
    <template #country="{ row }">
      {{ row.country.code }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    games: {
      type: Object,
      required: true,
    },
  },

  methods: {
    move(gameId, direction) {
      this.$inertia.post(
        this.route('admin.games.move', [gameId]),
        {move: direction},
      );
    },
  },
};
</script>
