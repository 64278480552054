<template>
  <CrudCreate
    title="Advertiser - Create"
    sub-title="Advertisers"
    :index-route="route('admin.advertisers.index')"
    :store-route="route('admin.advertisers.store')"
  >
    <template #after-action>
      <DefaultButton
        v-if="program"
        type="submit"
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        @click="doCreateWebshop"
      >
        Create & Add Webshop
      </DefaultButton>

      <DefaultButton
        v-if="program"
        type="submit"
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        @click="doCreateFreeAction"
      >
        Create & Add Free action
      </DefaultButton>
    </template>

    <template #cards>
      <AdvertiserForm
        :countries="countries"
        :program="program"
        :default-advertiser-type="defaultAdvertiserType"
        :default-cashback-communication="defaultCashbackCommunication"
        :advertiser-types="advertiserTypes"
        :settings="settings"
        :setting-categories="settingCategories"
        :required-settings="requiredSettings"
        :setting-labels="settingLabels"
        :setting-descriptions="settingDescriptions"
        :cashback-communication-types="cashbackCommunicationTypes"
        :button-types="buttonTypes"
        :media-types="mediaTypes"
      />
    </template>
  </CrudCreate>
</template>

<script>
import AdvertiserForm from './Form.vue';

export default {
  components: {
    AdvertiserForm,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },

    program: {
      type: Object,
      required: false,
      default: null,
    },

    defaultAdvertiserType: {
      type: Number,
      required: false,
      default: null,
    },

    defaultCashbackCommunication: {
      type: String,
      required: true,
    },

    advertiserTypes: {
      type: Array,
      required: true,
    },

    settings: {
      type: Object,
      required: true,
    },

    settingCategories: {
      type: Object,
      required: true,
    },

    requiredSettings: {
      type: Array,
      required: true,
    },

    settingLabels: {
      type: Object,
      required: true,
    },

    settingDescriptions: {
      type: Object,
      required: true,
    },

    cashbackCommunicationTypes: {
      type: Array,
      required: true,
    },

    buttonTypes: {
      type: Array,
      required: true,
    },

    mediaTypes: {
      type: Array,
      required: true,
    },
  },

  methods: {
    doCreateWebshop() {
      this.$store.commit('form/setForm', {
        do_create_webshop: true,
        do_create_free_action: false,
      });
    },

    doCreateFreeAction() {
      this.$store.commit('form/setForm', {
        do_create_webshop: false,
        do_create_free_action: true,
      });
    },
  },
};
</script>
