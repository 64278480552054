<template>
  <CrudIndex
    :title="'FAQ Category: ' + category.name"
    sub-title="Faq Category"
    create-route-path="admin.faq-categories.item.create"
    edit-route-path="admin.faq-categories.item.edit"
    delete-route-path="admin.faq-categories.item.destroy"
    row-route-path="admin.faq-categories.item.edit"
    :action="category.name"
    :links-active="true"
    :index-route="route('admin.faq-categories.index')"
    :route-parameters="[category.id]"
    :columns="['ID', 'Question', 'Countries', 'Enabled']"
    :actions="true"
    :data="items.data"
    :paginator="items.meta.pagination"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', action: (item) => move(item.id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', action: (item) => move(item.id, 'down'), enabled: true},
    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.faq-item-page')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Page types
      </InertiaLink>
    </template>

    <template #countries="{ row }">
      <span
        v-for="(country, i) in row.countries"
        :key="`country-${row.id}-${country.id}`"
      >
        <InertiaLink
          :href="route('admin.countries.edit', country.id)"
          class="text-indigo-600 hover:text-indigo-900"
          @click.stop
        >
          {{ country.name }}
        </InertiaLink>

        <span v-if="i < row.countries.length - 1">
          -
        </span>
      </span>
    </template>

    <template #enabled="{ row }">
      <span
        v-if="row.is_enabled"
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        Yes
      </span>

      <span
        v-else
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        No
      </span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },

    items: {
      type: Object,
      required: true,
    },
  },

  methods: {
    move(faqItemId, direction) {
      this.$inertia.post(
        this.route('admin.faq-categories.item.move', [this.category.id, faqItemId]),
        { move: direction },
      );
    },
  },
};
</script>
