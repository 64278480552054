<template>
  <CrudCard>
    <template #header>
      <div class="font-medium">
        General
      </div>
    </template>
    <template #content>
      <MultiSelectField
        v-model="$store.state.form.advertiser_id"
        label="Advertiser"
        name="advertiser_id"
        placeholder="Select an advertiser"
        mode="single"
        :searchable="true"
        :can-deselect="false"
        :can-clear="false"
        :resolve-on-load="$store.state.form.advertiser_id > 0"
        :min-chars="2"
        :delay="150"
        :loading="loadingAdvertisers"
        :options="async (query) => await fetchAdvertisers(query)"
        @select="advertiserChanged"
      >
        <template
          v-if="$store.state.form.advertiser_id"
          #extra
        >
          <DefaultButton
            :internal-link="false"
            :href="route('admin.advertisers.edit', $store.state.form.advertiser_id)"
            target="_blank"
          >
            <span class="material-icons md-18">open_in_new</span>
          </DefaultButton>
        </template>
      </MultiSelectField>

      <MultiSelectField
        v-if="$store.state.form.advertiser_id"
        ref="programInput"
        v-model="programIds"
        label="Program"
        name="program_id"
        placeholder="Select a program"
        mode="tags"
        :searchable="true"
        :can-deselect="true"
        :loading="loadingAdvertiserPrograms"
        :options="advertiserPrograms"
      />

      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />

      <MediaField
        v-model="$store.state.form.media_id"
        label="Mailbanner"
        name="media_id"
        help="Minimum size: 728x90px"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :media-key="`advertiser-mailbanner-${$store.state.form.advertiser_id}`"
        :crop-config="{
          enabled: true,
          minHeight: 90,
          minWidth: 728,
          aspectRatio: 728 / 90,
        }"
      />

      <CheckboxField
        v-model="$store.state.form.is_active"
        label="Is active"
        name="is_active"
        :required="false"
      />
    </template>
  </CrudCard>

  <PromotionActionProgramForm
    v-for="programId in programIds"
    :key="`mailbanner-program-form-${programId}`"
    :program-id="parseInt(programId)"
    :promotion-action-id="mailbanner ? mailbanner.id : null"
    :promotion-action-type="mailbanner ? mailbanner.promotion_action_type : null"
    :needs-commission-models="false"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import axios from 'axios';
import {ref} from 'vue';
import PromotionActionProgramForm from '../../Components/PromotionActionProgramForm.vue';

export default {

  components: {
    PromotionActionProgramForm,
  },

  props: {
    mailbanner: {
      type: Object,
      required: false,
      default: null,
    },

    advertiserId: {
      type: Number,
      required: false,
      default: null,
    },

    programs: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      advertiser_id: props.mailbanner?.advertiser_id ?? props.advertiserId,
      name: props.mailbanner?.name ?? null,
      media_id: props.mailbanner?.media_id ?? null,
      programs: props.programs ? props.programs : {},
      is_active: props.mailbanner?.is_active ?? true,
    });

    useStore().commit('form/setForm', form);

    return {
      defaultPreferredNetworkWhenTie: ref(null),
    };
  },

  data() {
    const initialProgramIds = this.programs ? Object.keys(this.programs) : [];
    if (this.program) {
      initialProgramIds.push(this.program.id);
    }

    return {
      loadingAdvertisers: false,
      loadingAdvertiserSettings: false,
      loadingAdvertiserPrograms: false,
      advertiserPrograms: [],
      programIds: initialProgramIds,

      useDefaultPreferredNetworkWhenTie: ref(this.$store.state.form.preferred_network_id_tie === null),
      showNetworkIds: ref(false),
    };
  },

  mounted() {
    if (this.$store.state.form.advertiser_id) {
      this.loadAdvertiserSettingsAndPrograms(this.$store.state.form.advertiser_id);
    }
  },

  methods: {
    advertiserChanged(advertiserId) {
      this.$nextTick(() => {
        this.programIds = [];
        this.loadAdvertiserSettingsAndPrograms(advertiserId);

        this.$refs.programInput.clear();
      });
    },

    async fetchAdvertisers(query) {
      this.loadingAdvertisers = true;

      let fetchInitialSelectedAdvertiser = query === null;
      if (fetchInitialSelectedAdvertiser) {
        const fetchedData = await axios.get(this.route('admin.api.advertisers.find', {id: this.$store.state.form.advertiser_id}))
          .then(({data}) => {
            this.loadingAdvertisers = false;
            return data;
          });
        return fetchedData;
      }

      return await axios.get(this.route('admin.api.advertisers.search.name', {name: query}))
        .then(({data}) => {
          this.loadingAdvertisers = false;
          return data;
        });
    },

    loadAdvertiserSettingsAndPrograms(advertiserId) {
      this.loadingAdvertiserSettings = true;
      this.loadingAdvertiserPrograms = true;

      this.advertiserPrograms = [];
      this.defaultPreferredNetworkWhenTie = null;

      axios.get(this.route('admin.api.advertisers.show', [advertiserId]))
        .then(({data}) => {
          this.defaultPreferredNetworkWhenTie = data.preferred_network_id_when_tie;

          this.loadingAdvertiserSettings = false;
        });

      return axios.get(this.route('admin.api.programs.find.advertiser', [advertiserId]))
        .then(({data}) => {
          this.advertiserPrograms = data;

          this.loadingAdvertiserPrograms = false;
        });
    },
  },
};
</script>
