<template>
  <CrudCreate
    title="Mailing - Duplicate"
    sub-title="Mailings"
    :index-route="isSurvey ? route('admin.survey-mailing-planning.index') : route('admin.mailing-planning.index')"
    :store-route="route('admin.mailings.store')"
    action="Duplicate"
  >
    <template #cards>
      <MailingForm
        :is-duplicate="true"
        :is-survey="isSurvey"
        :mailing="mailing"
        :country-currency="countryCurrency"
        :default-scheduled-at="defaultScheduledAt"
        :reward-types="rewardTypes"
        :default-click-reward-value="defaultClickRewardValue"
        :default-view-reward-value="defaultViewRewardValue"
        :notification-types="notificationTypes"
        :subscription-types="subscriptionTypes"
        :allowed-types-for-subscription-segmentation="allowedTypesForSubscriptionSegmentation"
        :templates="templates"
        :commission-model-display-options="commissionModelDisplayOptions"
        :planner="planner"
        :administrators="administrators"
        :genders="genders"
        :mailbanners="mailbanners"
        :types-without-click-reward="typesWithoutClickReward"
        :replaceable-tracking-url-variables="replaceableTrackingUrlVariables"
        :replaceable-member-attributes="replaceableMemberAttributes"
        :replaceable-survey-attributes="replaceableSurveyAttributes"
        :segmentation-attributes="segmentationAttributes"
        :advertisers="advertiserWithKeys()"
        :multi-advertiser-media-types="multiAdvertiserMediaTypes"
        :survey-statuses="surveyStatuses"
        :auto-remail-delay-days="autoRemailDelayDays"
      />
    </template>
  </CrudCreate>
</template>

<script>
import MailingForm from './Form.vue';

export default {
  components: {
    MailingForm,
  },

  props: {
    isSurvey: {
      type: Boolean,
      required: true,
    },

    mailing: {
      type: Object,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    defaultScheduledAt: {
      type: String,
      required: true,
    },

    rewardTypes: {
      type: Array,
      required: true,
    },

    defaultClickRewardValue: {
      type: String,
      required: true,
    },

    defaultViewRewardValue: {
      type: String,
      required: true,
    },

    notificationTypes: {
      type: Array,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },

    commissionModelDisplayOptions: {
      type: Array,
      required: true,
    },

    planner: {
      type: Object,
      required: true,
    },

    administrators: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    mailbanners: {
      type: Object,
      required: false,
      default: null,
    },

    typesWithoutClickReward: {
      type: Array,
      required: true,
    },

    allowedTypesForSubscriptionSegmentation: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },

    replaceableTrackingUrlVariables: {
      type: Array,
      required: true,
    },

    replaceableMemberAttributes: {
      type: Array,
      required: true,
    },

    replaceableSurveyAttributes: {
      type: Array,
      required: true,
    },

    segmentationAttributes: {
      type: Array,
      required: true,
    },

    advertisers: {
      type: Array,
      required: true,
    },

    multiAdvertiserMediaTypes: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Array,
      required: true,
    },

    autoRemailDelayDays: {
      type: Number,
      required: true,
    },
  },

  methods: {
    advertiserWithKeys() {
      let advertiserWithKeys = {};
      this.advertisers.forEach(advertiser => advertiserWithKeys[advertiser.id] = advertiser);

      return advertiserWithKeys;
    },
  },
};
</script>
