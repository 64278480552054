<template>
  <CrudCreate
    title="Bookkeeping - Export"
    sub-title="Bookkeeping"
    :store-route="route('admin.survey-bookkeeping.prepare-export')"
  >
    <template #form>
      <InputField
        v-model="$store.state.form.from"
        label="From"
        name="from"
        type="date"
        :required="true"
      />

      <InputField
        v-model="$store.state.form.to"
        label="To"
        name="to"
        type="date"
        :required="true"
      />
    </template>
  </CrudCreate>
</template>

<script>
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';

export default {
  props: {
    from: {
      required: true,
      type: String,
    },

    to: {
      required: true,
      type: String,
    },
  },

  setup (props) {
    const form = useForm({
      from: props.from ?? null,
      to: props.to ?? null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
