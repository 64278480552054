<template>
  <CrudEdit
    :title="reason.name"
    sub-title="Cashback disapprove reasons"
    :index-route="route('admin.advertiser-types.index')"
    :update-route="route('admin.cashback-disapprove-reasons.update', reason.id)"
  >
    <template #cards>
      <ReasonForm
        :reason="reason"
      />
    </template>
  </CrudEdit>
</template>

<script>
import ReasonForm from './Form.vue';

export default {
  components: {
    ReasonForm,
  },

  props: {
    reason: {
      type: Object,
      required: true,
    },
  },
};
</script>
