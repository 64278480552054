<template>
  <ErrorList :fields="['query']" />

  <Component
    :is="item.type"
    v-for="(item, i) in value"
    :key="`main-${i}`"
    v-model="value[i]"
    :index="0"
    :depth="0"
    :genders="genders"
    :provinces="provinces"
    :survey-statuses="surveyStatuses"
    :mailing-filter-items="mailingFilterItems"
    :mailing-filter-item-explanations="mailingFilterItemExplanations"
    :mailing-filter-item-file-fields="mailingFilterItemFileFields"
    :mailing-filter-item-operators="mailingFilterItemOperators"
    :mailing-filter-item-usage="mailingFilterItemUsage"
  />
</template>

<script>
import {ref} from 'vue';
import Group from './Group.vue';
import Item from './Item.vue';
import Base from './Base';

export default {
  components: {Group, Item},

  mixins: [Base],

  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  setup (props) {
    const value = ref(props.modelValue);
    const mailingFilterItemUsage = ref({});

    return {value, mailingFilterItemUsage};
  },

  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.calculateMailingFilterItemUsage();
        this.$emit('update:modelValue', newValue);
      },
    },
  },

  mounted () {
    if (Object.keys(this.value).length === 0) {
      this.value = [{type: 'group', operator: 'and', children: []}];
    }
  },

  methods: {
    calculateMailingFilterItemUsage () {
      this.mailingFilterItemUsage = this.getUsageForChild(this.value[0]);
    },

    getUsageForChild (child) {
      let usage = {};

      if (child.type === 'group') {
        for (const value of child.children) {
          const childUsage = this.getUsageForChild(value);

          for (const key in childUsage) {
            if (!Object.keys(usage).includes(key)) {
              usage[key] = 0;
            }

            usage[key] += childUsage[key];
          }
        }
      } else if (child.type === 'item') {
        if (!Object.keys(usage).includes(child.item.id)) {
          usage[child.item.id] = 0;
        }

        usage[child.item.id]++;
      }

      return usage;
    },
  },
};
</script>
