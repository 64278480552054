<template>
  <CrudCard>
    <template #content>
      <SelectField
        v-model="$store.state.form.country_id"
        label="Country"
        name="country_id"
        placeholder="Select a Country"
        :items="countries"
        :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
      />

      <MediaField
        v-model="$store.state.form.media_id"
        label="Media"
        name="media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        media-key="games"
      />

      <CheckboxField
        v-model="$store.state.form.active"
        label="Active"
        name="active"
        :required="false"
      />

      <SelectField
        v-model="$store.state.form.slug"
        label="Linked game"
        name="slug"
        :items="registeredGames"
        :disabled="game !== null"
      />

      <InputField
        v-model="$store.state.form.name"
        label="Title"
        name="name"
        help="In English"
      />

      <EditorField
        v-model="$store.state.form.description"
        label="Description"
        name="description"
        :required="false"
        help="In English"
      />

      <InputField
        v-model="$store.state.form.maximum_sessions_per_day"
        type="number"
        min="0"
        label="Maximum sessions per day"
        name="maximum_sessions_per_day"
        help="Maximum games a member can play per day, use 0 for unlimited"
      />

      <CurrencyAndPointsField
        v-model="$store.state.form.reward"
        :options="{...currencyOptions}"
        :points-conversion-ratio="countryPointsConversionRatio"
        label="Reward"
        name="reward"
      />
    </template>
  </CrudCard>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';
import EditorField from '../../Components/Form/EditorField.vue';

export default {
  components: {
    CurrencyAndPointsField,
    EditorField,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },

    registeredGames: {
      type: Array,
      required: true,
    },

    game: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const currencyOptions = ref({
      currency: props.game?.country?.currency?.iso || 'EUR',
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    });
    const countryPointsConversionRatio = ref('1');

    const form = useForm({
      country_id: props.game ? props.game.country_id : null,
      media_id: props.game ? props.game.media_id : null,
      active: props.game ? props.game.active : false,
      slug: props.game ? props.game.slug : null,
      name: props.game ? props.game.name : null,
      description: props.game ? props.game.description : '',
      maximum_sessions_per_day: props.game ? props.game.maximum_sessions_per_day : 3,
      reward: props.game ? props.game.reward : 1.00,
    });

    useStore().commit('form/setForm', form);

    return {
      currencyOptions,
      countryPointsConversionRatio,
    };
  },

  watch: {
    '$store.state.form.country_id': function (newCountryId) {
      this.updateCountry(newCountryId);
    },
  },

  mounted() {
    if (!this.game && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;

    }

    if (this.game) {
      this.updateCountry(this.game.country_id);
    }
  },

  methods: {
    updateCountry(newCountryId) {
      newCountryId = parseInt(newCountryId);
      const newCountry = this.countries.find((country) => country.id === newCountryId);
      this.currencyOptions.currency = newCountry.currency.iso;
      this.countryPointsConversionRatio = newCountry.settings.pointsConversionRatio;
    },
  },
};
</script>
