<template>
  <div
    v-if="Object.keys(faqItemPerPage).length"
  >
    <CrudIndex
      v-for="(items, key) in faqItemPerPage"
      :key="key"
      :title="toTitleCase(key)"
      :data="items"
      :columns="['ID', 'Question', 'Enabled']"
      :actions="true"
      :menu-items="[
        {name: 'Move up', icon: 'arrow_upward', action: (item) => move(item.id, 'up'), enabled: true},
        {name: 'Move down', icon: 'arrow_downward', action: (item) => move(item.id, 'down'), enabled: true},
        {name: 'Edit', action: (item) => routeToEdit(item.faqItem.id, item.faqItem.faq_category_id), enabled: true},
      ]"
    >
      <template #id="{ row }">
        {{ row.faq_item_id }}
      </template>

      <template #question="{ row }">
        {{ row.faqItem.question }}
      </template>

      <template #enabled="{ row }">
        <span
          v-if="row.faqItem.is_enabled"
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
        >
          Yes
        </span>

        <span
          v-else
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
        >
          No
        </span>
      </template>
    </CrudIndex>
  </div>

  <div v-else>
    <p>
      No FAQ item attached to a page
    </p>
  </div>

  <SiteHead
    title="FAQ page types"
  />
</template>

<script>
export default {

  props: {
    faqItemPerPage: {
      type: Object,
      required: true,
    },
  },

  methods: {
    move(faqPageTypeItemId, direction) {
      this.$inertia.post(
        this.route('admin.faq-item-page.move', [faqPageTypeItemId]),
        { move: direction },
      );
    },

    routeToEdit(itemId, categoryId) {
      this.$inertia.visit(this.route('admin.faq-categories.item.edit', {
        faq_category: categoryId,
        item: itemId,
      }));
    },
  },
};
</script>
