export default {
  props: {
    index: {
      required: false,
      type: Number,
      default: 0,
    },

    order: {
      required: false,
      type: Number,
      default: 0,
    },

    depth: {
      required: false,
      type: Number,
      default: 0,
    },

    genders: {
      type: Object,
      required: true,
    },

    provinces: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Object,
      required: true,
    },

    mailingFilterItems: {
      type: Object,
      required: true,
    },

    mailingFilterItemExplanations: {
      type: Object,
      required: true,
    },

    mailingFilterItemFileFields: {
      type: Object,
      required: true,
    },

    mailingFilterItemOperators: {
      type: Object,
      required: true,
    },
  },
};
