<template>
  <CrudEdit
    :title="ibanBlacklist.iban"
    sub-title="IBAN blacklist"
    :index-route="route('admin.iban-blacklist.index')"
    :update-route="route('admin.iban-blacklist.update', ibanBlacklist.id)"
  >
    <template #form>
      <IbanBlacklistForm :iban-blacklist="ibanBlacklist" />
    </template>
  </CrudEdit>
</template>

<script>
import IbanBlacklistForm from './Form.vue';

export default {
  components: {IbanBlacklistForm},

  props: {
    ibanBlacklist: {
      type: Object,
      required: true,
    },
  },
};
</script>
