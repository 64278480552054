<template>
  <CrudCreate
    title="Puzzle - Create"
    sub-title="Puzzles"
    :index-route="route('admin.puzzles.index')"
    :store-route="route('admin.puzzles.store')"
  >
    <template #cards>
      <PuzzleForm
        :countries="countries"
        :types="types"
      />
    </template>
  </CrudCreate>
</template>

<script>
import PuzzleForm from './Form.vue';

export default {
  components: {PuzzleForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },

    types: {
      type: Array,
      required: true,
    },
  },
};
</script>
