<template>
  <CrudIndex
    title="All clickouts"
    :columns="['ID', 'Country', 'Click datetime', 'Transaction datetime', 'Member', 'Advertiser', 'Promotion Type', 'Commission', 'Cashback']"
    :actions="true"
    :menu-items="[
      {name: 'Details', url: (item) => route('admin.members.clickouts.show', {member: item.member_id, clickout: item.id}), enabled: true},
      {name: 'History', url: (item) => route('admin.transactions.history', item.all_transaction_ids), enabled: (item) => transactionMenuItemEnabled(item)},
    ]"
    :data="clickouts.data"
    :paginator="clickouts.meta.pagination"
    :filters="[
      {
        type: 'search',
        defaultOperator: 'equals',
        fields: [
          {label: 'Member ID', value: 'clickouts.member_id', checked: false},
          {label: 'Click ID', value: 'clickouts.click_id', checked: false},
          {label: 'Advertisers ID', value: 'clickouts.advertiser_id', checked: false},
          {label: 'Type (Mailing, Webshop, ...) ID', value: 'clickouts.model_id', checked: false},
        ],
      },
      {
        type: 'dropdown',
        operator: 'types',
        label: 'Types',
        value: 'types',
        multiple: true,
        items: [
          {name: 'Mailing clickouts', value: 'mailing'},
          {name: 'Webshop clickouts', value: 'webshop'},
          {name: 'Free action clickouts', value: 'free_action'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'show_with',
        label: 'Show with',
        value: 'show_with',
        multiple: true,
        items: [
          {name: 'Clickouts with a transaction', value: 'with_transaction'},
          {name: 'Clickouts with a cashback', value: 'with_cashback'},
        ],
      },
      {
        type: 'date-range',
        label: 'Date',
        timezone: $page.props.auth.user.timezone,
        fields: [
          {label: 'Cashbacks created at', value: 'cashbacks.created_at'},
          {label: 'Clickouts created at', value: 'clickouts.created_at'},
        ]
      },
    ]"
  >
    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #advertiser="{ row }">
      <a
        :href="route('admin.advertisers.edit', row.advertiser.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.advertiser.name }}
      </a>
    </template>

    <template #promotion_type="{ item, row }">
      <PromotionActionLink
        :action-type="item"
        :action-name="row.promotion_action_name"
        :action-url="row.promotion_action_url"
      />
    </template>

    <template #click_datetime="{ row }">
      {{ row.created_at }}
    </template>

    <template #commission="{ row }">
      <span v-if="row.all_transaction_statuses.length === 0">n/a</span>
      <div
        v-else
        class="flex items-center"
      >
        <span
          v-if="Object.keys(row.all_transaction_statuses).length > 1"
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        >
          {{ row.total_transaction_value }} ({{ row.total_transactions }})
        </span>
        <span
          v-else
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : Object.keys(row.all_transaction_statuses)[0] === 'approved',
            'bg-red-100 text-red-800' : Object.keys(row.all_transaction_statuses)[0] === 'disapproved',
            'bg-yellow-100 text-yellow-800' : Object.keys(row.all_transaction_statuses)[0] === 'pending',
          }"
        >
          {{ row.total_transaction_value }} <span v-if="row.total_transactions > 1">({{ row.total_transactions }})</span>
        </span>
        <Popover
          v-if="row.all_transaction_disapprove_reasons.length > 0"
          class="h-5 w-5 ml-1"
        >
          <PopoverButton class="inline-block">
            <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
          </PopoverButton>
          <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
            <p class="w-full">
              {{ row.all_transaction_disapprove_reasons.join(', ') }}
            </p>
          </PopoverPanel>
        </Popover>
      </div>
    </template>

    <template #cashback="{ row }">
      <span v-if="row.all_cashback_statuses.length === 0">n/a</span>
      <div
        v-else
        class="flex items-center"
      >
        <span
          v-if="Object.keys(row.all_cashback_statuses).length > 1"
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        >
          {{ row.total_cashback_value }} ({{ row.total_cashbacks }})
        </span>
        <span
          v-else
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : Object.keys(row.all_cashback_statuses)[0] === 'approved',
            'bg-red-100 text-red-800' : Object.keys(row.all_cashback_statuses)[0] === 'disapproved',
            'bg-yellow-100 text-yellow-800' : Object.keys(row.all_cashback_statuses)[0] === 'pending',
          }"
        >
          {{ row.total_cashback_value }} <span v-if="row.total_cashbacks > 1">({{ row.total_cashbacks }})</span>
        </span>
        <Popover
          v-if="Object.keys(row.all_cashback_disapprove_reasons).length > 0"
          class="h-5 w-5 ml-1"
        >
          <PopoverButton class="inline-block">
            <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
          </PopoverButton>
          <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
            <p class="w-full">
              {{ Object.keys(row.all_cashback_disapprove_reasons).join(', ') }}
            </p>
          </PopoverPanel>
        </Popover>
      </div>
    </template>
  </CrudIndex>
</template>

<script>
import {InformationCircleIcon} from '@heroicons/vue/solid';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import PromotionActionLink from '../../Components/PromotionActionLink.vue';

export default {
  components: {
    PromotionActionLink,
    InformationCircleIcon,
    Popover, PopoverButton, PopoverPanel,
  },

  props: {
    clickouts: {
      type: Object,
      required: true,
    },
  },

  methods: {
    transactionMenuItemEnabled(item) {
      return item && item.all_transaction_ids.length === 1;
    },
  },
};
</script>
