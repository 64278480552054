<template>
  <div>
    <div class="flex">
      <span class="py-2">Minimum:</span>
      <input
        v-model="item.minimum_age"
        type="number"
        class="w-full ml-1 shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
    </div>
  </div>
  <div>
    <div class="flex">
      <span class="py-2">Maximum:</span>
      <input
        v-model="item.maximum_age"
        type="number"
        class="w-full ml-1 shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
    </div>
  </div>
  <div class="col-span-2" />
</template>

<script>
import {ref} from 'vue';
import Base from '../Base';

export default {
  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const item = ref(props.modelValue);
    item.value.minimum_age = props.modelValue?.minimum_age || null;
    item.value.maximum_age = props.modelValue?.maximum_age || null;

    return {item};
  },
};
</script>
