<template>
  <CrudEdit
    title="Charity"
    sub-title="Charities"
    :index-route="route('admin.charities.index')"
    :update-route="route('admin.charities.update', charity.id)"
  >
    <template #form>
      <CharityForm
        :charity="charity"
        :countries="countries"
      />
    </template>
  </CrudEdit>
</template>

<script>
import CharityForm from './Form.vue';

export default {
  components: {CharityForm},

  props: {
    charity: {
      type: Object,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },
  },
};
</script>
