<template>
  <CrudCreate
    title="Survey Waves - Create"
    sub-title="Survey Waves"
    :index-route="route('admin.survey-waves.index', survey.id)"
    :store-route="route('admin.survey-waves.store', survey.id)"
  >
    <template #form>
      <SurveyWaveForm />
    </template>
  </CrudCreate>
</template>

<script>
import SurveyWaveForm from './Form.vue';

export default {
  components: {
    SurveyWaveForm,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },
  },
};
</script>
