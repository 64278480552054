<template>
  <CrudIndex
    title="Currencies"
    create-route-path="admin.currencies.create"
    edit-route-path="admin.currencies.edit"
    row-route-path="admin.currencies.edit"
    delete-route-path="admin.currencies.destroy"
    :columns="['ID', 'Name', 'Iso', 'Symbol']"
    :actions="true"
    :data="currencies.data"
    :paginator="currencies.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}, {label: 'ISO', value: 'iso'}, {label: 'Symbol', value: 'symbol'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    currencies: {
      type: Object,
      required: true,
    },
  },
};
</script>
