<template>
  <HeadlessDialog
    as="div"
    class="fixed z-10 inset-0 overflow-y-auto"
    :open="open"
    @close="close"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <HeadlessDialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >&#8203;</span>

      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="">
            <div class="mt-3 text-center sm:mt-0 sm:text-left">
              <div v-show="loading">
                <div class="text-center text-2xl text-gray-600 mb-2">
                  <i class="fa fa-spinner fa-spin mr-2" />
                </div>
              </div>
              <div v-show="!loading">
                <div
                  v-for="tagCategory in tagCategories"
                  :key="`tag-category-list-${tagCategory.id}`"
                  class="mb-6"
                >
                  <div class="font-bold mb-2">
                    {{ tagCategory.description }}
                  </div>

                  <div class="grid grid-cols-3 gap-y-2 gap-x-4">
                    <span
                      v-for="tag in tagCategory.tags"
                      v-show="shouldShowTag(tag)"
                      :key="`tag-item-list-${tag.id}`"
                      class="text-sm underline cursor-pointer"
                      @click="$emit('select-tag', tag.id)"
                    >
                      {{ tag.description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <DefaultButton
            classes="text-white bg-indigo-600 hover:bg-indigo-700"
            @click="close"
          >
            Close
          </DefaultButton>
        </div>
      </div>
    </div>
  </HeadlessDialog>
</template>

<script>
import {Dialog as HeadlessDialog, DialogOverlay as HeadlessDialogOverlay} from '@headlessui/vue';
import axios from 'axios';

export default {
  components: {HeadlessDialog, HeadlessDialogOverlay},

  props: {
    open: {
      type: Boolean,
      required: true,
    },

    selectedTags: {
      type: Array,
      required: true,
    },
  },

  emits: ['update:open', 'select-tag'],

  data() {
    return {
      loading: false,
      tagCategories: [],
    };
  },

  watch: {
    open() {
      if (!this.open) {
        this.tagCategories = [];

        return;
      }

      this.loading = true;

      axios.get(this.route('admin.api.tags.details'))
        .then(({ data }) => {
          this.tagCategories = data;

          this.loading = false;
        });
    },
  },

  methods: {
    shouldShowTag(tag) {
      return !this.selectedTags
        .map((tag) => parseInt(tag))
        .includes(tag.id);
    },

    close() {
      this.$emit('update:open', false);
    },
  },
};
</script>
