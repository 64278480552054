<template>
  <CrudEdit
    :page-title="`Edit ${program.name}`"
    header-title="Edit program"
    sub-title="Programs"
    :index-route="route('admin.programs.index')"
    :update-route="route('admin.programs.update', program.id)"
  >
    <template #after-cancel>
      <div v-if="!program.is_deleted && program.unconnected_status === '' && program.advertisers.length === 0">
        <DropdownButton
          v-for="(reasons, status) in programUnconnectedReasons"
          :key="`unconnected-dropdown-${status}`"
          :items="
            reasons.map(reason => ({
              name: reason,
              action: () => doHandleUnconnectedStatus(status, reason)
            }))
          "
        >
          {{ status }}
        </DropdownButton>
      </div>

      <DefaultButton
        v-else-if="!program.is_deleted && program.unconnected_status !== ''"
        type="button"
        @click="doHandleUnconnectedStatus(null, null)"
      >
        Move to New
      </DefaultButton>
    </template>

    <template #cards>
      <div
        v-if="program.unconnected_status"
        class="rounded-md bg-yellow-200 border border-yellow-400 mb-6 p-4"
      >
        <p class="text-sm text-yellow-800">
          <span class="font-medium">Program status:</span> {{ program.unconnected_status }}
        </p>
      </div>
      <div
        v-if="program.is_deleted"
        class="rounded-md bg-red-200 border border-red-400 mb-6 p-4"
      >
        <p class="text-sm text-red-800">
          <span class="font-medium">Program is deleted, cannot save or connect this program.</span>
        </p>
      </div>

      <ProgramForm
        :program="program"
        :currencies="currencies"
        :commission-model-display-options="commissionModelDisplayOptions"
      />

      <ProgramTermsForm
        :program="program"
        :program-term-categories="programTermCategories"
      />

      <CashbackForm
        :program="program"
        :default-cashback-free-percentage="defaultCashbackFreePercentage"
        :default-cashback-premium-percentage="defaultCashbackPremiumPercentage"
        :points-conversion-ratio="pointsConversionRatio"
      />

      <CommissionModelForm
        v-for="commission_model in program.commission_models"
        :key="`comssion-model-card-${commission_model.id}`"
        :commission-model-id="commission_model.id"
        :informational-categories="commissionModelTagCategoriesInformational"
        :program-webshops="program.webshops"
        :cashback-info-options="cashbackInfoOptions"
      />
    </template>

    <template #after-action>
      <DefaultButton
        v-if="!program.is_deleted"
        type="submit"
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        @click="doConnect"
      >
        Save & Connect
      </DefaultButton>
    </template>
  </CrudEdit>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

import ProgramForm from './Form.vue';
import ProgramTermsForm from './ProgramTermsForm.vue';
import CashbackForm from './CashbackForm.vue';
import CommissionModelForm from './CommissionModelForm.vue';

export default {
  components: {ProgramForm, ProgramTermsForm, CashbackForm, CommissionModelForm},

  props: {
    program: {
      type: Object,
      required: true,
    },

    commissionModelTagCategoriesInformational: {
      type: Object,
      required: true,
    },

    programTermCategories: {
      type: Array,
      required: true,
    },

    programUnconnectedReasons: {
      type: Object,
      required: false,
      default: null,
    },

    commissionModelDisplayOptions: {
      type: Array,
      required: true,
    },

    cashbackInfoOptions: {
      type: Array,
      required: true,
    },

    currencies: {
      type: Array,
      required: true,
    },

    defaultCashbackFreePercentage: {
      type: String,
      required: true,
    },

    defaultCashbackPremiumPercentage: {
      type: String,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const programTermItems = props.programTermCategories.reduce((accumulator, category) => {
      accumulator[category.id] = [];
      return accumulator;
    }, {});

    let hasAttributionModel = 'Unknown';
    let commissionModels = {};

    if (props.program.has_attribution_model === true) {
      hasAttributionModel = 'Yes';
    } else if (props.program.has_attribution_model === false) {
      hasAttributionModel = 'No';
    }

    for (const item of props.program.term_items) {
      programTermItems[item.program_term_category_id].push(item.id);
    }

    commissionModels = props.program.commission_models.reduce((accumulator, commissionModel) => {
      accumulator[commissionModel.id] = {
        description: commissionModel.description,
        manual_description: commissionModel.manual_description,
        percentage: commissionModel.percentage,
        fixed: commissionModel.fixed,
        fixed_value: commissionModel.fixed_value,
        fixed_currency_id: commissionModel.fixed_currency_id,
        description_tag_ids: commissionModel.description_tag_ids,
        informational_tag_ids: commissionModel.informational_tag_ids,
        cashback_info: commissionModel.cashback_info ?? 'system',
        webshop_ids: commissionModel.webshop_ids, // indicates if this commissionmodel is linked to webshop(s)
      };
      return accumulator;
    }, {});

    const form = useForm({
      do_connect: false,

      currency_id: props.program.currency_id,

      custom_tracking_url: props.program.custom_tracking_url,
      attribution_model: props.program.attribution_model,
      has_attribution_model: hasAttributionModel,
      commission_model_display_option: props.program.commission_model_display_option,
      skip_transaction_mismatch_commissions: props.program.skip_transaction_mismatch_commissions,

      cashback_free_percentage: props.program.raw_cashback_free_percentage,
      cashback_free_fixed: props.program.cashback_free_fixed,
      cashback_premium_percentage: props.program.raw_cashback_premium_percentage,
      cashback_premium_fixed: props.program.cashback_premium_fixed,

      program_term_item_ids: programTermItems,

      commission_models: commissionModels,
    });

    useStore().commit('form/setForm', form);

    return {
      form,
    };
  },

  mounted() {
    this.disableCountrySelector();

    if (this.program.is_deleted) {
      this.$store.state.form.processing = true; // disables the "save" button
    }
  },

  methods: {
    doConnect() {
      this.$store.commit('form/setForm', { do_connect: true });
    },

    doHandleUnconnectedStatus(status, reason) {
      this.$inertia.post(
        this.route('admin.api.programs.update-status', [this.program]), {
          unconnected_status: status,
          unconnected_reason: reason,
        });
    },
  },
};
</script>
