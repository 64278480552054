<template>
  <div
    :class="{
      'grid gap-1 md:gap-4 items-baseline': !inline,
      'sm:grid-cols-6 py-6 first:pt-0 last:pb-0': !inline && label,
      'sm:grid-cols-2': !inline && !label,
    }"
  >
    <div
      v-if="label"
      class="col-span-2"
    >
      <div class="flex justify-between sm:justify-start">
        <label
          :for="id"
          class="block font-medium text-gray-700 mt-px"
          v-text="label"
        />

        <span
          v-if="!required"
          class="text-sm text-gray-500 sm:text-xs ml-1 mt-px"
        >Optional</span>
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="help"
        class="block mt-2 text-sm text-gray-400"
        v-html="help"
      />
    </div>

    <div
      class="relative max-w-xl w-full mt-1 sm:mt-0 prose"
      :class="fullWidth ? 'col-span-4' : 'col-span-2'"
    >
      <slot name="input-before" />

      <textarea
        :id="id"
        ref="input"
        class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        :class="$page.props.errors[name] ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'focus:ring-indigo-500 focus:border-indigo-500'"
        :name="name"
      />

      <slot name="input-after" />

      <div
        v-if="$page.props.errors[name]"
        class="absolute top-2.5 right-0 pr-3 flex items-center pointer-events-none"
      >
        <i
          class="fas fa-exclamation-circle h-5 text-red-500"
          aria-hidden="true"
        />
      </div>

      <p
        v-if="$page.props.errors[name]"
        class="mt-1 text-sm text-red-600"
        v-text="$page.props.errors[name]"
      />

      <slot name="input-end" />
    </div>

    <div
      v-if="!fullWidth && $slots['extra']"
      class="col-span-2"
    >
      <slot name="extra" />
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Field} from '@rocketcode/backoffice';
import {CkEditorUploadAdapter} from '../../Helpers/CkEditorUploadAdapter';

export default {
  extends: Field,

  props: {
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      default: true,
    },

    overrideEditorConfig: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: [
    'update:modelValue',
    'media-uploaded',
  ],

  data() {
    return {
      editorConfig: {
        initialData: this.modelValue || '',

        toolbar: [
          'bold', 'italic', '|',
          'numberedList', 'bulletedList', '|',
          'link', '|',
          'undo', 'redo',
        ],

        link: {
          // Let the users control the "download" attribute of each link.
          decorators: {
            openInNewTab: {
              mode: 'manual',
              label: 'Open in a new tab',
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },

            downloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'download',
              },
            },
          },
        },

        ...this.overrideEditorConfig,
      },
    };
  },

  mounted() {
    ClassicEditor.create(this.$refs.input, this.editorConfig)
      .then((editor) => {
        editor.model.document
          .on('change:data', () => this.$emit('update:modelValue', editor.getData()));

        if (this.editorConfig.ckfinder?.uploadUrl) {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            loader.on('change:uploadResponse', (_, __, value) => {
              if (value && value.mediaId) {
                this.$emit('media-uploaded', value.mediaId);
              }
            });

            return new CkEditorUploadAdapter(loader, this.editorConfig.ckfinder.uploadUrl);
          };
        }
      });
  },
};
</script>
