<template>
  <CrudCard>
    <template #content>
      <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Webshop
          </div>
        </div>
        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <InertiaLink
            :href="route('admin.webshops.edit', webshop.id)"
            class="text-indigo-600 hover:text-indigo-900"
            @click.stop
          >
            {{ webshop.name }} (#{{ webshop.id }})
          </InertiaLink>
        </div>
      </div>

      <CheckboxField
        v-model="$store.state.form.is_active"
        label="Is active"
        name="is_active"
        :required="false"
      />

      <CurrencyAndPointsField
        v-model="$store.state.form.reward_value"
        :options="rewardCurrencyOptions"
        :points-conversion-ratio="pointsConversionRatio"
        :minimum-value="0.0"
        :maximum-value="maxRewardValue"
        label="Click reward"
        name="reward_value"
      />

      <TextareaField
        v-model="$store.state.form.description"
        label="Description"
        name="description"
        rows="6"
        help="Will not be translated"
      />

      <InputField
        v-model="$store.state.form.start_at"
        label="Start date"
        name="start_at"
        type="datetime-local"
      />

      <InputField
        v-model="$store.state.form.end_at"
        label="End date"
        name="end_at"
        type="datetime-local"
        :required="false"
      />
    </template>
  </CrudCard>
</template>

<script>
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';

export default {
  components: {
    CurrencyAndPointsField,
  },

  props: {
    webshop: {
      type: Object,
      required: true,
    },

    defaultRewardValue: {
      type: String,
      required: true,
    },

    maxRewardValue: {
      type: String,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },

    clickAction: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      webshop_id: props.webshop.id,
      is_active: props.clickAction?.is_active ?? true,
      description: props.clickAction?.description ?? null,
      start_at: props.clickAction?.start_at ?? null,
      end_at: props.clickAction?.end_at ?? null,
      reward_value: props.clickAction?.reward_value ?? props.defaultRewardValue,
      reward_currency_id: props.clickAction?.reward_currency_id ?? props.webshop.country_currency.id,
    });

    useStore().commit('form/setForm', form);
  },

  data() {
    let currencyIso = this.webshop.country_currency.iso;
    if (this.clickAction) {
      currencyIso = this.clickAction.reward_currency_iso;
    }

    return {
      rewardCurrencyOptions: {
        currency: currencyIso,
        precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
      },
    };
  },
};
</script>
