<template>
  <InputField
    v-model="advertiserName"
    name="advertiser_name"
    label="Advertiser"
    :disabled="true"
  />

  <InputField
    v-model="programName"
    name="program_name"
    label="Program"
    :disabled="true"
  />

  <CheckboxField
    v-model="useProgramUrl"
    name="use_program_url"
    label="Use Program Url"
  />

  <InputField
    v-model="$store.state.form.url"
    label="Url"
    name="url"
    :disabled="useProgramUrl"
  />
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

const props = defineProps({
  blogLink: {
    type: Object,
    required: true,
  },
  programUrl: {
    type: String,
    required: true,
  },
});

const advertiserName = ref(props.blogLink.advertiser_name);
const programName = ref(props.blogLink.program_name);
const useProgramUrl = ref(!props.blogLink.url);

const form = useForm({
  url: props.blogLink.url ?? props.programUrl,
  use_program_url: useProgramUrl.value,
});

const store = useStore();

useStore().commit('form/setForm', form);

watch(useProgramUrl, (value) => {
  store.state.form.url = props.programUrl;
  store.state.form.use_program_url = value;
});
</script>
