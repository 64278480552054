<template>
  <ModalForm
    :id="`email_preview_form_${mailingId}`"
    v-model:open="modalOpen"
    :submit-route="emailPreviewUrl"
    :is-inertia-request="false"
  >
    <template #fields>
      <TextareaField
        v-model="emailAddresses"
        label="Email addresses"
        name="email_addresses"
        rows="6"
        :required="true"
        help="Use newline (enter) to separate email addresses"
      />
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from '../ModalForm.vue';

export default {
  components: {ModalForm},

  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    emailPreviewUrl: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:mailingId',
    'update:emailPreviewUrl',
  ],

  data() {
    return {
      modalOpen: this.mailingId > 0,
      emailAddresses: '',
    };
  },

  watch: {
    modalOpen: function (newValue) {
      if (newValue === false) {
        this.$emit('update:mailingId', null);
        this.$emit('update:emailPreviewUrl', null);
      }
    },
  },
};
</script>
