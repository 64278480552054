<template>
  <SelectField
    v-model="$store.state.form.country_id"
    label="Country"
    name="country_id"
    placeholder="Select a Country"
    :items="countries"
    :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
  />

  <MediaField
    v-model="$store.state.form.media_id"
    label="Media"
    name="media_id"
    index-route="admin.api.media.index"
    show-route="admin.api.media.show"
    upload-route="admin.api.media.store"
    delete-route="admin.api.media.destroy"
    :required="false"
    :crop-config="{
      enabled: true,
      aspectRatio: 16 / 9,
    }"
    media-key="news"
  />

  <InputField
    v-model="$store.state.form.title"
    label="Title"
    name="title"
  />

  <CheckboxField
    v-model="$store.state.form.is_puzzle_result"
    label="News is result of a puzzle"
    name="is_puzzle_result"
    :required="false"
  />

  <MultiSelectField
    v-if="$store.state.form.is_puzzle_result"
    v-model="$store.state.form.puzzle_ids"
    label="Puzzles"
    name="puzzle_ids"
    placeholder="Select puzzles"
    mode="tags"
    :options="filteredPuzzles"
  />

  <EditorField
    v-model="$store.state.form.content"
    label="Content"
    name="content"
    help="In English<br>
<br>
Replace tags:<br>
Routes: <code>:route[frontend.index]</code><br>
Setting: <code>:setting[cashback_max_cashback]</code><br>
TaF setting: <code>:taf-setting[bonus]</code><br>
Common: <code>:common[webshopCount]</code><br>
<br>
Formatting:<br>
#currency: <code>:setting[key#currency]</code><br>
#number: <code>:setting[key#number]</code><br>"
  >
    <template #extra>
      <DefaultButton @click.prevent="routesModalOpen = true">
        <span class="material-icons md-18">fork_right</span>
        Available routes
      </DefaultButton>
    </template>
  </EditorField>
  <RoutesModal v-model:open="routesModalOpen" />

  <InputField
    v-model="$store.state.form.start_at"
    label="Start date"
    name="start_at"
    type="datetime-local"
  />

  <InputField
    v-model="$store.state.form.end_at"
    label="End date"
    name="end_at"
    type="datetime-local"
    :required="false"
  />
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import RoutesModal from '../../Components/RoutesModal.vue';
import EditorField from '../../Components/Form/EditorField.vue';

export default {
  components: {
    RoutesModal,
    EditorField,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },

    puzzles: {
      type: Array,
      required: true,
    },

    news: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      country_id: props.news ? props.news.country_id : null,
      media_id: props.news ? props.news.media_id : null,
      title: props.news ? props.news.title : null,
      is_puzzle_result: props.news ? props.news.is_puzzle_result : false,
      puzzle_ids: props.news ? props.news.puzzle_ids : null,
      content: props.news ? props.news.content : '',
      start_at: props.news ? props.news.start_at : null,
      end_at: props.news ? props.news.end_at : null,
    });

    useStore().commit('form/setForm', form);

    return {
      routesModalOpen: ref(false),
    };
  },

  computed: {
    filteredPuzzles() {
      if (this.$store.state.form.country_id === null) {
        return this.puzzles;
      }

      return this.puzzles
        .filter((puzzle) => puzzle.country_id = this.$store.state.form.country_id)
        .reduce((accumulator, puzzle) => {
          accumulator[puzzle.id] = puzzle.title;
          return accumulator;
        }, {});
    },
  },

  watch: {
    '$store.state.form.is_puzzle_result': function(newIsPuzzleResult) {
      if (!newIsPuzzleResult) {
        this.$store.state.form.puzzle_ids = null;
      }
    },
  },

  mounted() {
    if (!this.news && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }
  },
};
</script>
