<template>
  <InputField
    v-model="$store.state.form.question"
    label="Question"
    name="question"
    :required="true"
  />

  <CheckboxField
    v-model="$store.state.form.has_multiple_answers"
    label="Has multiple answers"
    name="has_multiple_answers"
    :required="false"
  />

  <InputField
    v-show="$store.state.form.has_multiple_answers"
    v-model="$store.state.form.max_selected_answers"
    type="number"
    label="Max. selected answers"
    name="max_selected_answers"
    min="1"
    :required="false"
  />

  <CheckboxField
    v-model="$store.state.form.has_priority"
    label="Has priority"
    name="has_priority"
    :required="false"
  />

  <SelectField
    v-model="$store.state.form.retention"
    label="Retention"
    name="retention"
    placeholder="Select"
    :items="[
      {id: '', name: 'Never'},
      {id: '30', name: '30'},
      {id: '90', name: '90'},
      {id: '180', name: '180'},
      {id: '365', name: '365'},
    ]"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    selectedCountryId: {
      type: Number,
      required: true,
    },

    question: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      country_id: props.question?.country_id ?? props.selectedCountryId,
      question: props.question?.question ?? '',
      has_multiple_answers: props.question?.has_multiple_answers ?? null,
      max_selected_answers: props.question?.max_selected_answers ?? null,
      has_priority: props.question?.has_priority ?? null,
      retention: props.question?.retention ?? null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
