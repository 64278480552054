<template>
  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
  />
  <InputField
    v-model="$store.state.form.symbol"
    label="Symbol"
    name="symbol"
  />
  <InputField
    v-model="$store.state.form.iso"
    label="ISO"
    name="iso"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    currency: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.currency ? props.currency.name : null,
      symbol: props.currency ? props.currency.symbol : null,
      iso: props.currency ? props.currency.iso : null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
