<template>
  <CheckboxField
    v-model="$store.state.form.is_default"
    label="Is default"
    name="is_default"
    :required="false"
  />

  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
  />

  <InputField
    v-model="$store.state.form.iso"
    label="ISO code"
    name="iso"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    language: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      is_default: props.language ? props.language.is_default : false,
      name: props.language ? props.language.name : null,
      iso: props.language ? props.language.iso : null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
