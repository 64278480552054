<template>
  <CrudIndex
    title="Payout batches"
    :columns="['Batch ID', 'Status', 'Created by', 'Created at', 'Paid at', 'Payouts amount', 'Payouts height']"
    :actions="true"
    :menu-items="[
      {name: 'Details', url: (item) => item.status === 'PAID' ? route('admin.payouts.done', {payout_batch_id: item.id}) : route('admin.payouts.approved', {payout_batch_id: item.id}), enabled: true},
      {name: 'Download', action: (item) => downloadFile(item.id), enabled: true},
      {name: 'Paid', url: (item) => route('admin.payout-batches.paid', item.id), enabled: (item) => paidMenuItemEnabled(item)},
    ]"
    :data="batches.data"
    :paginator="batches.meta.pagination"
    :filters="[
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Status',
        value: 'status',
        multiple: false,
        items: filterableBatchStatuses,
      },
    ]"
  >
    <template #batch_id="{ row }">
      {{ row.id }}
    </template>

    <template #status="{ item }">
      <span
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item === 'PAID',
          'bg-yellow-100 text-yellow-800' : item === 'BUSY',
        }"
      >
        {{ item }}
      </span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    batches: {
      type: Object,
      required: true,
    },

    batchStatuses: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let filterableBatchStatuses = [];
    for (const status of props.batchStatuses) {
      filterableBatchStatuses.push({name: status, value: status});
    }

    return {
      filterableBatchStatuses: filterableBatchStatuses,
    };
  },

  methods: {
    downloadFile(batchId) {
      window.location.href = this.route('admin.payout-batches.download', batchId);
    },

    paidMenuItemEnabled(batch) {
      return batch.status !== 'PAID';
    },
  },
};
</script>
