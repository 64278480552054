<template>
  <CrudShow
    :title="`Cashback #${cashback.id}`"
    sub-title="Cashbacks"
    :rows="['ID', 'Advertiser', 'Clickout', 'Cashback', 'Status', 'Status by', 'Hidden', 'Manual review', 'Date', 'Last update']"
    :data="cashback"
  >
    <template #advertiser="{ item }">
      <a
        :href="route('admin.advertisers.edit', item.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ item.name }}
      </a>
    </template>

    <template #clickout="{ item }">
      <a
        :href="route('admin.members.clickouts.show', [cashback.member_id, item.id])"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        Clickout #{{ item.id }}
      </a>
    </template>

    <template #status="{ item }">
      <p class="flex items-center text-sm">
        <span
          class="mr-2 px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : item.status === 'APPROVED',
            'bg-red-100 text-red-800' : item.status === 'DISAPPROVED',
            'bg-yellow-100 text-yellow-800' : item.status === 'PENDING',
          }"
        >
          {{ item.status }}
        </span>
        {{ item.status_reason }}
      </p>
    </template>
  </CrudShow>
</template>

<script>
export default {
  props: {
    cashback: {
      type: Object,
      required: true,
    },
  },
};
</script>
