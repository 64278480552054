<template>
  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
  />

  <InputField
    v-model="$store.state.form.email"
    label="Email"
    name="email"
    type="email"
  />

  <InputField
    v-model="$store.state.form.password"
    label="Password"
    name="password"
    type="password"
    :required="!administrator"
    :help="administrator ? 'Leave blank to keep current password.' : ''"
  />

  <SelectField
    v-model="$store.state.form.role_id"
    label="Role"
    name="role_id"
    placeholder="Select a role"
    :items="roles"
    :required="true"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    administrator: {
      type: Object,
      required: false,
      default: null,
    },

    roles: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.administrator?.name ?? null,
      email: props.administrator?.email ?? null,
      password: null,
      role_id: props.administrator?.role?.id ?? null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
