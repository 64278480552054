<template>
  <CrudEdit
    :title="`Member #${member.id}`"
    sub-title="Members"
    :index-route="route('admin.members.index')"
    :update-route="route('admin.members.update', member.id)"
  >
    <template #before-cancel>
      <DropdownButton
        :items="[
          {
            name: member.noteCount > 0 ? `Notes (${member.noteCount})` : 'Notes',
            action: () => $inertia.visit(route('admin.members.notes.index', member.id)),
          },
          {
            name: 'Clickouts & Cashbacks',
            action: () => $inertia.visit(route('admin.members.clickouts.index', member.id)),
          },
          {
            name: 'Balance Mutations',
            action: () => $inertia.visit(route('admin.members.balance-mutations.index', member.id)),
          },
          {
            name: 'Referrals',
            action: () => $inertia.visit(route('admin.members.referrals.index', member.id)),
          },
          {
            name: 'History',
            action: () => $inertia.visit(route('admin.members.history', member.id)),
          },
          {
            name: 'Notifications',
            action: () => $inertia.visit(route('admin.members.notifications.index', member.id)),
          },
          {
            name: 'Reviews',
            action: () => $inertia.visit(route('admin.members.webshop-reviews.index', member.id)),
          },
          {
            name: 'Profile',
            action: () => $inertia.visit(route('admin.members.profile', member.id)),
          },
          {
            name: 'Payout Accounts',
            action: () => $inertia.visit(route('admin.members.payout-accounts', member.id)),
          },
          {
            name: 'Duplicates',
            action: () => $inertia.visit(route('admin.members.duplicates', member.id)),
          },
          {
            name: 'Survey responses',
            action: () => $inertia.visit(route('admin.members.survey-responses', member.id)),
          },
        ]"
        classes="text-white bg-blue-700 hover:bg-blue-900"
      >
        Navigate
      </DropdownButton>

      <a
        :href="route('admin.members.impersonate', member.id)"
        target="_blank"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Impersonate
      </a>

      <InertiaLink
        class="inline-flex ml-3 items-center px-4 py-2 mr-3 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900"
        @click="destroy()"
      >
        Delete
      </InertiaLink>
    </template>

    <template #cards>
      <MemberForm
        :member="member"
        :genders="genders"
      />
    </template>
  </CrudEdit>
</template>

<script>
import MemberForm from './Form.vue';

export default {
  components: {
    MemberForm,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },
  },

  methods: {
    destroy() {
      if (confirm('Are you sure you wan to delete this member?')) {
        this.$inertia.delete(this.route('admin.members.destroy', [this.member.id]));
      }
    },
  },
};
</script>
