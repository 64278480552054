<template>
  <CrudIndex
    :title="`Balance Mutations for member #${member.id}`"
    create-route-path="admin.members.balance-mutations.create"
    :route-parameters="[member.id]"
    :columns="['ID', 'Created by', 'Type', 'Money', 'Description', 'Created at', 'Note']"
    :actions="true"
    :menu-items="[
      {name: 'Delete', icon: 'delete_forever', action: (item) => destroy(item.id), enabled: (item) => 'canDelete' in item ? item.canDelete : true},
    ]"
    create-action="Add"
    :data="balanceMutations.data"
    :paginator="balanceMutations.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Description', value: 'description'}]},
      {type: 'date-range', timezone: $page.props.auth.user.timezone, fields: [{label: 'Created At', value: 'created_at'}]},
    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.members.edit', member.id)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Member
      </InertiaLink>
    </template>

    <template #id="{ row }">
      <div class="flex items-center">
        {{ row.id }}
        <span
          v-if="row.is_hidden"
          class="material-icons md-18 ml-2 text-red-500"
          title="Balance mution is hidden for member"
        >
          visibility_off
        </span>
      </div>
    </template>

    <template #type="{ row }">
      <div v-if="row.type">
        {{ row.type }}
        <template v-if="row.isSurvey">
          <InertiaLink
            :href="route('admin.survey-waves.index', row.model_id)"
            class="text-indigo-600 hover:text-indigo-900"
          >
            - Survey #{{ row.model_id }}
          </InertiaLink>
        </template>
      </div>
      <div v-else-if="row.isCashback">
        <InertiaLink
          :href="route('admin.cashbacks.show', row.model_id)"
          class="text-indigo-600 hover:text-indigo-900"
        >
          Cashback #{{ row.model_id }}
        </InertiaLink>
      </div>
      <div v-else-if="row.isPayout">
        <InertiaLink
          :href="route('admin.payouts.show', row.model_id)"
          class="text-indigo-600 hover:text-indigo-900"
        >
          Payout #{{ row.model_id }}
        </InertiaLink>
      </div>
      <div v-else-if="row.isMailing">
        <InertiaLink
          :href="route('admin.mailings.edit', row.model_id)"
          class="text-indigo-600 hover:text-indigo-900"
        >
          Mailing #{{ row.model_id }}
        </InertiaLink>
      </div>
      <div v-else-if="row.isFreeAction">
        <InertiaLink
          :href="route('admin.free-actions.edit', row.model_id)"
          class="text-indigo-600 hover:text-indigo-900"
        >
          Free Action #{{ row.model_id }}
        </InertiaLink>
      </div>
      <div v-else-if="row.isGame">
        Game
      </div>
      <div v-else-if="row.isClickAction">
        <InertiaLink
          :href="route('admin.click-actions.edit', row.model_id)"
          class="text-indigo-600 hover:text-indigo-900"
        >
          Click Action #{{ row.model_id }}
        </InertiaLink>
      </div>
      <div v-else-if="row.isGiffy">
        <InertiaLink
          :href="route('admin.giffy-purchases.show', row.model_id)"
          class="text-indigo-600 hover:text-indigo-900"
        >
          Giffy Purchase #{{ row.model_id }}
        </InertiaLink>
      </div>
    </template>

    <template #note="{ item }">
      <a
        v-if="item"
        :href="route('admin.members.notes.edit', [member.id, item.id])"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        <span
          class="material-icons"
          :title="item.note"
        >
          sticky_note_2
        </span>
      </a>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },

    balanceMutations: {
      type: Object,
      required: true,
    },
  },

  methods: {
    destroy(balanceMutationId) {
      let reason = prompt('Are you sure you want to delete this balance mutation? Please enter a reason.');

      if (reason) {
        this.$inertia.delete(
          this.route('admin.members.balance-mutations.destroy', {member: this.member.id, balance_mutation: balanceMutationId}),
          {data: {reason: reason}},
        );
      }
    },
  },
};
</script>
