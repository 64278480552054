<template>
  <CrudIndex
    title="Advertiser Types"
    create-route-path="admin.advertiser-types.create"
    edit-route-path="admin.advertiser-types.edit"
    row-route-path="admin.advertiser-types.edit"
    delete-route-path="admin.advertiser-types.destroy"
    :columns="['ID', 'Name', 'Is Default']"
    :actions="true"
    :data="advertiserTypes.data"
    :paginator="advertiserTypes.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    advertiserTypes: {
      type: Object,
      required: true,
    },
  },
};
</script>
