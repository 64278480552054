<template>
  <InputField
    v-model="$store.state.form.answer"
    label="Answer"
    name="answer"
    :required="true"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    question: {
      type: Object,
      required: false,
      default: null,
    },

    answer: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      profile_question_id: props.answer?.profile_question_id ?? props.question.id,
      answer: props.answer?.answer ?? '',
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
