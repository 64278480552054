<template>
  <CrudIndex
    :title="network.name + ' - Audits'"
    :data="network.audits"
    :columns="['Mutated at', 'Editor', 'New value', 'Old value', 'Updated data']"
    :actions="true"
  >
    <template #after-create>
      <InertiaLink
        :href="route('admin.network-revenue-statistics.index')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Back
      </InertiaLink>
    </template>

    <template #mutated_at="{ row }">
      {{ row.created_at }}
    </template>

    <template #updated_data="{ row }">
      {{ row.year }}/{{ row.month }} - {{ row.status }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    network: {
      type: Object,
      required: true,
    },
  },
};
</script>
