<template>
  <CrudIndex
    title="Advertisers"
    create-route-path="admin.advertisers.create"
    edit-route-path="admin.advertisers.edit"
    row-route-path="admin.advertisers.edit"
    delete-route-path="admin.advertisers.destroy"
    :columns="['ID', 'Name', 'Type', 'Country', 'Cashback communication', 'Is active']"
    :actions="true"
    :data="advertisers.data"
    :paginator="advertisers.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Is active',
        value: 'is_active',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
    ]"
  />
</template>

<script>
export default {
  props: {
    advertisers: {
      type: Object,
      required: true,
    },
  },
};
</script>
