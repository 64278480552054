<template>
  <CrudCreate
    title="Mailing filter - Create"
    sub-title="Mailing filters"
    :index-route="route('admin.mailing-filters.index')"
    :store-route="route('admin.mailing-filters.store')"
  >
    <template #cards>
      <MailingFilterForm
        :selected-country-id="selectedCountryId"
        :genders="genders"
        :provinces="provinces"
        :survey-statuses="surveyStatuses"
        :mailing-filter-items="mailingFilterItems"
        :mailing-filter-item-explanations="mailingFilterItemExplanations"
        :mailing-filter-item-file-fields="mailingFilterItemFileFields"
        :mailing-filter-item-operators="mailingFilterItemOperators"
      />
    </template>

    <template #after-action>
      <DefaultButton
        type="submit"
        name="create_and_new"
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        :disabled="$store.state.form.processing"
        @click="$store.state.form.action = 'create_and_duplicate'"
      >
        Create & Duplicate
      </DefaultButton>
    </template>
  </CrudCreate>
</template>

<script>
import MailingFilterForm from './Form.vue';

export default {
  components: {
    MailingFilterForm,
  },

  props: {
    selectedCountryId: {
      type: Number,
      required: true,
    },

    genders: {
      type: Object,
      required: true,
    },

    provinces: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Object,
      required: true,
    },

    mailingFilterItems: {
      type: Object,
      required: true,
    },

    mailingFilterItemExplanations: {
      type: Object,
      required: true,
    },

    mailingFilterItemFileFields: {
      type: Object,
      required: true,
    },

    mailingFilterItemOperators: {
      type: Object,
      required: true,
    },
  },
};
</script>
