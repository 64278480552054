<template>
  <div class="col-span-4 flex">
    <label
      v-for="(label, key) of genders"
      :key="`gender-${key}`"
      class="mr-2 flex items-center"
    >
      <input
        v-model="item.gender"
        :value="key"
        type="radio"
        class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
      <span class="ml-2">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import {ref} from 'vue';
import Base from '../Base';

export default {
  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const item = ref(props.modelValue);
    item.value.gender = props.modelValue?.gender || Object.keys(props.genders)[0];

    return {item};
  },
};
</script>
