<template>
  <CrudCard>
    <template #header>
      <span class="font-medium">
        Profile
      </span>
    </template>

    <template #content>
      <InputField
        label="Username"
        name="username"
        :placeholder="username"
        :disabled="true"
      />
      <SelectField
        v-model="$store.state.form.gender"
        label="Gender"
        name="gender"
        placeholder="Select a Gender"
        :items="genders"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.birth_date"
        label="Birth Date"
        name="birth_date"
        type="date"
      />

      <InputField
        v-model="$store.state.form.zipcode"
        label="Zipcode"
        name="zipcode"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.city"
        label="City"
        name="city"
        :required="false"
        :disabled="true"
      />

      <InputField
        v-model="$store.state.form.province"
        label="Province"
        name="province"
        :required="false"
        :disabled="true"
      />

      <InputField
        v-model="$store.state.form.municipality"
        label="Municipality"
        name="municipality"
        :required="false"
        :disabled="true"
      />

      <SelectField
        v-if="educationLevels"
        v-model="$store.state.form.education"
        label="Education"
        name="education"
        placeholder="Select education"
        :items="educationLevels.map((value) => ({id: value, name: value}))"
        :required="false"
      />

      <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            <label
              class="block font-medium text-gray-700 mt-px"
            >Profile picture</label>
          </div>
        </div>
        <div class="col-span-2">
          <div class="mb-8">
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              class="hidden"
              @change="selectImage"
            >
            <label
              for="file-upload"
              class="flex text-blue-600 group cursor-pointer"
            >
              <span class="ml-2 underline group-hover:no-underline">Select photo</span>
            </label>
            <p
              v-if="$store.state.form.errors.length"
              class="text-red text-xs italic"
            >
              {{ $store.state.form.errors }}
            </p>

            <div class="my-4">
              <img
                ref="image"
                :src="pictureUrl"
                class="max-w-full"
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </CrudCard>
</template>

<script>
import Cropper from 'cropperjs';
import {getCanvasResultForCroppedCanvas} from '../../../../../global/js/cropHelpers';

export default {
  props: {
    genders: {
      type: Array,
      required: true,
    },

    educationLevels: {
      type: Array,
      required: false,
      default: null,
    },

    pictureUrl: {
      type: String,
      required: false,
      default: null,
    },

    username: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      cropper: null,
    };
  },

  mounted() {
    this.resetCropper();
  },

  methods: {
    resetCropper() {
      this.cropper?.destroy();

      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        zoomable: false,
        minCropBoxWidth: 250,
        minCropBoxHeight: 250,
        autoCropArea: 1,
        crop: () => {
          const canvas = getCanvasResultForCroppedCanvas(this.cropper?.getCroppedCanvas());

          if (canvas) {
            this.$store.state.form.picture = canvas.toDataURL().split(';base64,')[1];
          }
        },
      });
    },

    selectImage(e) {
      if (e.target.files.length !== 1) {
        return;
      }

      const file = e.target.files[0];
      this.$refs.image.src = URL.createObjectURL(file);

      this.resetCropper();
    },
  },
};
</script>
