<template>
  <CrudCard>
    <template #content>
      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />
      <InputField
        v-model="$store.state.form.slug"
        label="Slug"
        name="slug"
      />
      <MultiSelectField
        v-model="$store.state.form.country_ids"
        label="Active in countries"
        name="country_ids"
        placeholder="Select countries"
        mode="tags"
        :options="countries"
      />
      <CheckboxField
        v-model="$store.state.form.use_in_category_suggestions"
        label="Use in category suggestions"
        name="use_in_category_suggestions"
        :required="false"
      />
    </template>
  </CrudCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Media
      </span>
    </template>
    <template #content>
      <MediaField
        v-model="$store.state.form.position1_media_id"
        label="Media #1"
        name="position1_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :help="minimumMediaSize.join('x')"
        :media-key="`webshop-category-${category?.id}`"
        :crop-config="cropConfig"
      />
      <MediaField
        v-model="$store.state.form.position2_media_id"
        label="Media #2"
        name="position2_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :help="minimumMediaSize.join('x')"
        :media-key="`webshop-category-${category?.id}`"
        :crop-config="cropConfig"
      />
      <MediaField
        v-model="$store.state.form.position3_media_id"
        label="Media #3"
        name="position3_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :help="minimumMediaSize.join('x')"
        :media-key="`webshop-category-${category?.id}`"
        :crop-config="cropConfig"
      />
    </template>
  </CrudToggleCard>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    category: {
      type: Object,
      required: false,
      default: null,
    },

    countries: {
      type: Object,
      required: true,
    },

    minimumMediaSize: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const cropConfig = {
      enabled: true,
      minWidth: props.minimumMediaSize[0],
      minHeight: props.minimumMediaSize[1],
      aspectRatio: props.minimumMediaSize[0] / props.minimumMediaSize[1],
    };

    const form = useForm({
      name: props.category ? props.category.name : '',
      slug: props.category ? props.category.slug : '',
      country_ids: props.category ? props.category.country_ids : [],
      use_in_category_suggestions: props.category ? props.category.use_in_category_suggestions : false,
      position1_media_id: props.category ? props.category.position1_media_id : null,
      position2_media_id: props.category ? props.category.position2_media_id : null,
      position3_media_id: props.category ? props.category.position3_media_id : null,
    });

    useStore().commit('form/setForm', form);

    return {cropConfig};
  },

  watch: {
    '$store.state.form.name': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },

    '$store.state.form.slug': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },
  },
};
</script>
