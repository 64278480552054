<template>
  <CrudCreate
    title="Network Guarantee - Create"
    sub-title="Network Guarantees"
    :index-route="route('admin.network-revenue-statistics.network-guarantees.index')"
    :store-route="route('admin.network-revenue-statistics.network-guarantees.store')"
  >
    <template #form>
      <NetworkGuaranteeForm
        :affiliwings-networks="affiliwingsNetworks"
        :currency="currency"
      />
    </template>
  </CrudCreate>
</template>

<script>
import NetworkGuaranteeForm from './Form.vue';

export default {
  components: {
    NetworkGuaranteeForm,
  },

  props: {
    affiliwingsNetworks: {
      type: Object,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },
  },
};
</script>
