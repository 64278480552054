<template>
  <CrudEdit
    title="FAQ Category"
    sub-title="FAQ Category"
    :index-route="route('admin.faq-categories.index')"
    :update-route="route('admin.faq-categories.update', category.id)"
  >
    <template #form>
      <FaqCategoryForm
        :category="category"
        :countries="countries"
      />
    </template>
  </CrudEdit>
</template>

<script>
import FaqCategoryForm from './Form.vue';

export default {
  components: {FaqCategoryForm},

  props: {
    category: {
      type: Object,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },
  },
};
</script>
