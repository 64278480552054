<template>
  <CrudEdit
    title="Profile answers - Edit"
    sub-title="Profile answers"
    :index-route="route('admin.profile-answers.index', question.id)"
    :update-route="route('admin.profile-answers.update', [question.id, answer.id])"
  >
    <template #form>
      <AnswerForm
        :question="question"
        :answer="answer"
      />
    </template>
  </CrudEdit>
</template>

<script>
import AnswerForm from './Form.vue';

export default {
  components: {
    AnswerForm,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },

    answer: {
      type: Object,
      required: true,
    },
  },
};
</script>
