<template>
  <CrudIndex
    title="Free actions"
    create-route-path="admin.free-actions.create"
    edit-route-path="admin.free-actions.edit"
    row-route-path="admin.free-actions.edit"
    delete-route-path="admin.free-actions.destroy"
    :columns="['ID', 'Name', 'Advertiser name', 'Is active', 'Programs']"
    :actions="true"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', color: 'blue-400', action: (item) => move(item.id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', color: 'blue-400', action: (item) => move(item.id, 'down'), enabled: true},
    ]"
    :data="actions.data"
    :paginator="actions.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
      {
        type: 'relation',
        label: 'Free Action Categories',
        value: 'categories.free_action_category_id',
        multiple: true,
        api: route('admin.api.free-action-category.index'),
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Is active',
        value: 'is_active',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
    ]"
  >
    <template #advertiser_name="{ row, item }">
      <a
        :href="route('admin.advertisers.edit', row.advertiser_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ item }}
      </a>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    actions: {
      type: Object,
      required: true,
    },
  },

  methods: {
    move(freeActionId, direction) {
      this.$inertia.post(
        this.route('admin.free-actions.move', [freeActionId]),
        {move: direction},
      );
    },
  },
};
</script>
