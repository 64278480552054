<template>
  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
    :required="true"
  />

  <InputField
    v-model="$store.state.form.fileName"
    type="file"
    accept=".csv"
    label="File"
    name="file"
    :required="!mailingFilterFile"
    :help="`File must be CSV. The maximum file size is ${fileMaxSizeInMb} MB. To reduce size, keep only the first column. The maximum row count is ${fileMaxRowsFormatted} and the first row is skipped for headers.`"
    class="!rounded-none !shadow-none"
    @change.stop="fileChanged($event)"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import {ref} from 'vue';

export default {
  props: {
    mailingFilterFile: {
      type: Object,
      required: false,
      default: null,
    },

    selectedCountryId: {
      type: Number,
      required: true,
    },

    fileMaxSizeInKb: {
      type: Number,
      required: true,
    },

    fileMaxRows: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      country_id: props.mailingFilterFile?.country_id ?? props.selectedCountryId,
      name: props.mailingFilterFile?.name ?? '',
      fileName: null,
      file: null,
    });

    useStore().commit('form/setForm', form);

    const fileMaxSizeInMb = ref(props.fileMaxSizeInKb / 1024);
    const fileMaxRowsFormatted = ref(props.fileMaxRows.toLocaleString(undefined, {minimumFractionDigits: 0}));

    return {fileMaxSizeInMb, fileMaxRowsFormatted};
  },

  methods: {
    fileChanged(event) {
      this.$page.props.errors['file'] = null;

      if (event.target.files[0].size > this.fileMaxSizeInKb * 1024) {
        this.$store.state.form.fileName = null;
        this.$store.state.form.file = null;
        this.$page.props.errors['file'] = `${this.toTitleCase('file')} must not be greater than ${this.fileMaxSizeInMb} MB.`;

        return;
      }

      this.$store.state.form.file = event.target.files[0];
    },
  },
};
</script>
