<template>
  <CrudIndex
    :title="`Note history: #${note.id}`"
    :columns="['Modified at', 'Old', 'New', 'Modified by']"
    :data="history"
    :actions="false"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.mailings.notes.index', note.mailing_id)"
        class="inline-flex items-center px-4 py-2 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-purple-500"
      >
        Notes
      </InertiaLink>
    </template>

    <template #old="{ item }">
      <span class="whitespace-pre">{{ item }}</span>
    </template>

    <template #new="{ item }">
      <span class="whitespace-pre">{{ item }}</span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    note: {
      type: Object,
      required: true,
    },

    history: {
      type: Array,
      required: true,
    },
  },
};
</script>
