<template>
  <CrudEdit
    title="Tag Item"
    :sub-title="`Tag Category - ${tag.description}`"
    :index-route="route('admin.tag-categories.show', [category.id])"
    :update-route="route('admin.tag-categories.item.update', [category.id, tag.id])"
  >
    <template #form>
      <ErrorList :fields="['description']" />

      <TagItemForm :tag="tag" />
    </template>
  </CrudEdit>
</template>

<script>
import TagItemForm from './Form.vue';

export default {
  components: {TagItemForm},

  props: {
    category: {
      type: Object,
      required: true,
    },

    tag: {
      type: Object,
      required: true,
    },
  },
};
</script>
