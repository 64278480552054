<template>
  <div class="flex items-center">
    <DefaultButton
      classes="px-2 py-0 text-xs leading-5 font-semibold rounded-full bg-red-400 hover:bg-red-500 text-gray-800 border-none ml-1"
      @click.stop="openStatusChangeModal()"
    >
      <span class="material-icons md-18 mr-2">cancel_schedule_send</span> Stop
    </DefaultButton>
  </div>

  <ModalForm
    :id="`mailing_stop_form_${mailingId}`"
    v-model:open="modalOpen"
    :submit-route="route('admin.mailings.stop', mailingId)"
  >
    <template #fields>
      Are you sure to stop the mailing?
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from '../ModalForm.vue';

export default {
  components: {
    ModalForm,
  },

  props: {
    mailingId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    };
  },

  methods: {
    openStatusChangeModal() {
      this.modalOpen = true;
    },
  },
};
</script>
