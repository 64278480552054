<template>
  <ModalForm
    :id="`overrule_mailing_form_${mailingId}`"
    v-model:open="modalOpen"
    :submit-route="route('admin.survey-mailing-planning.set-overrule-mailing', mailingId)"
  >
    <template #fields>
      Set the overwrite mailing for this mailing.

      <input
        v-model="overruleMailingId"
        type="hidden"
        name="overrule_mailing_id"
      >

      <MultiSelectField
        v-model="overruleMailingId"
        label="Overrule with mailing"
        name="overrule_mailing_id"
        placeholder="Select a mailing"
        mode="single"
        :searchable="true"
        :can-deselect="true"
        :can-clear="true"
        :resolve-on-load="true"
        :required="false"
        :min-chars="2"
        :delay="150"
        :loading="loadingOverruleMailings"
        :options="async (query) => await fetchOverruleMailings(query)"
      />
    </template>
  </ModalForm>
</template>

<script>
import axios from 'axios';
import ModalForm from '../ModalForm.vue';

export default {
  components: {ModalForm},

  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    currentOverruleMailingId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  emits: [
    'update:mailingId',
    'update:currentOverruleMailingId',
  ],

  data() {
    return {
      modalOpen: this.mailingId > 0,
      loadingOverruleMailings: false,
      overruleMailingId: this.currentOverruleMailingId ?? null,
    };
  },

  watch: {
    modalOpen: function (newValue) {
      if (newValue === false) {
        this.$emit('update:mailingId', null);
        this.$emit('update:currentOverruleMailingId', null);
      }
    },
  },

  methods: {
    async fetchOverruleMailings(query) {
      this.loadingOverruleMailings = true;

      return await axios.get(this.route('admin.api.mailings.search.overrule-mailings', {id: this.overruleMailingId, query: query}))
        .then(({data}) => {
          this.loadingOverruleMailings = false;
          return data;
        });
    },
  },
};
</script>
