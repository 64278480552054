<template>
  <SelectField
    v-model="$store.state.form.type"
    label="Type"
    name="type"
    placeholder="Select a type"
    :items="types"
    :required="true"
  />

  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
    :required="true"
  />

  <InputField
    v-model="$store.state.form.duration"
    type="number"
    label="Duration"
    name="duration"
    min="0"
    :required="true"
    help="In minutes."
  />

  <InputField
    v-model="$store.state.form.target"
    type="number"
    label="Target"
    name="target"
    min="0"
    :required="false"
  />

  <CurrencyAndPointsField
    v-model="$store.state.form.participation_reward_value"
    :options="participationRewardOptions"
    :points-conversion-ratio="pointsConversionRatio"
    label="Participation reward"
    name="participation_reward_value"
    :required="true"
  />

  <CurrencyField
    v-model="$store.state.form.lead_price_value"
    :options="leadPriceOptions"
    label="Lead price"
    name="lead_price_value"
    :required="true"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';

export default {
  components: {CurrencyAndPointsField},

  props: {
    survey: {
      type: Object,
      required: false,
      default: null,
    },

    types: {
      type: Array,
      required: true,
    },

    selectedCountryId: {
      type: Number,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    leadPriceCurrency: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      country_id: props.survey?.country_id ?? props.selectedCountryId,
      type: props.survey?.type ?? props.types[0]?.id ?? null,
      name: props.survey?.name ?? '',
      duration: props.survey?.duration ?? null,
      target: props.survey?.target ?? null,
      participation_reward_value: props.survey?.participation_reward_value ?? '',
      participation_reward_currency_id: props.survey?.participation_reward_currency_id ?? props.countryCurrency.id,
      lead_price_value: props.survey?.lead_price_value ?? '',
    });

    useStore().commit('form/setForm', form);
  },

  data() {
    const participationRewardOptions = {
      currency: this.countryCurrency.iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };
    const leadPriceOptions = {
      currency: this.leadPriceCurrency.iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    return {
      participationRewardOptions,
      leadPriceOptions,
    };
  },
};
</script>
