<template>
  <CrudIndex
    title="Mailbanners"
    edit-route-path="admin.mailbanners.edit"
    row-route-path="admin.mailbanners.edit"
    delete-route-path="admin.mailbanners.destroy"
    :columns="['ID', 'Advertiser name', 'Name', 'Is active', 'Programs']"
    :actions="true"
    :data="mailbanners.data"
    :paginator="mailbanners.meta.pagination"
    :filters="[
      {type: 'search', fields: [
        {label: 'Name', value: 'name'},
      ]
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Is active',
        value: 'is_active',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },

    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.mailbanners.create', {advertiserId: advertiserId})"
        class="inline-flex ml-3 items-center px-4 py-2 border text-white text-sm font-medium rounded-md bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-indigo-600"
      >
        Create
      </InertiaLink>
    </template>

    <template #advertiser_name="{ row, item }">
      <InertiaLink
        :href="route('admin.advertisers.edit', row.advertiser_id)"
        class="text-indigo-600 hover:text-indigo-900"
        @click.stop
      >
        {{ item }}
      </InertiaLink>
    </template>
  </CrudIndex>
</template>

<script>

export default {
  props: {
    mailbanners: {
      type: Object,
      required: true,
    },

    advertiserId: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
