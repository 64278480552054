<template>
  <CrudIndex
    title="Administrators"
    create-route-path="admin.administrators.create"
    edit-route-path="admin.administrators.edit"
    :columns="['ID', 'Name', 'Email']"
    :actions="true"
    :data="administrators.data"
    :paginator="administrators.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}, {label: 'Email', value: 'email'}]},
      {type: 'date-range', timezone: $page.props.auth.user.timezone, fields: [{label: 'Created At', value: 'created_at'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    administrators: {
      type: Object,
      required: true,
    },
  },
};
</script>
