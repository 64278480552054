<template>
  <CrudEdit
    title="Edit"
    :sub-title="`Notes: #${mailingId}`"
    :index-route="route('admin.mailings.notes.index', mailingId)"
    :update-route="route('admin.mailings.notes.update', [mailingId, note.id])"
  >
    <template #before-cancel>
      <InertiaLink
        v-if="note.updated_by"
        :href="route('admin.mailings.notes.history', [mailingId, note.id])"
        class="inline-flex mr-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        History
      </InertiaLink>
    </template>

    <template #form>
      <TextareaField
        v-model="$store.state.form.note"
        label="Note"
        name="note"
      />
    </template>
  </CrudEdit>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    note: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      note: props.note.note,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
