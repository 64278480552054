<template>
  <div>
    <InputField
      v-model="$store.state.form.tracking_url"
      label="Tracking URL"
      name="tracking_url"
    >
      <template #extra>
        <DefaultButton
          title="Copy"
          @click.prevent="copyTrackingUrl"
        >
          <span
            class="material-icons md-18"
          >content_copy</span>
        </DefaultButton>

        <DefaultButton
          :internal-link="false"
          :href="safeProgramTrackingUrl($store.state.form.tracking_url)"
          target="_blank"
          title="Open (in new tab)"
        >
          <span
            class="material-icons md-18"
          >launch</span>
        </DefaultButton>

        <DefaultButton @click.prevent="$emit('openTrackingUrlVariablesModal')">
          <span class="material-icons md-18">fork_right</span>
          Variables
        </DefaultButton>

        <DefaultButton
          v-if="!isSurvey"
          @click.prevent="$emit('openRoutesModal')"
        >
          <span class="material-icons md-18">fork_right</span>
          Routes
        </DefaultButton>
      </template>
    </InputField>

    <SelectField
      v-if="!$store.state.mailingData.hasOneTemplate"
      v-model="$store.state.form.template"
      label="Template"
      name="template"
      placeholder="Select a template"
      :items="templates"
      :disabled="$store.state.mailingData.isSent"
    />

    <MultiSelectField
      v-if="!isSurvey"
      v-model="$store.state.form.mailbanner_id"
      label="Mailbanner"
      name="mailbanner_id"
      placeholder="Select a mailbanner"
      mode="single"
      :required="false"
      :searchable="true"
      :can-deselect="true"
      :can-clear="true"
      :options="mailbanners"
    />

    <InputField
      v-model="$store.state.form.pre_header_text"
      label="Pre-header"
      name="pre_header_text"
      maxlength="50"
      :required="false"
    />

    <EditorField
      v-if="!isSurvey"
      v-model="$store.state.form.custom_text"
      label="Custom text"
      name="custom_text"
      :required="false"
    />

    <InputField
      v-model="$store.state.form.subject"
      label="Subject"
      name="subject"
      maxlength="100"
      help="Max 100 characters allowed. No double spaces and must end with either of ., !, ?"
    >
      <template #extra>
        <DefaultButton @click.prevent="$emit('openSubjectVariablesModal')">
          <span class="material-icons md-18">fork_right</span>
          Variables
        </DefaultButton>
      </template>
    </InputField>

    <template
      v-if="!isSurvey && $store.state.form.advertiser_id"
    >
      <MediaField
        v-model="$store.state.form.logo_media_id"
        label="Logo Media"
        help="Recommended: 300x76 72DPI"
        name="logo_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :media-key="`advertiser-${$store.state.form.advertiser_id}-mailing-logo`"
      >
        <template #extra>
          <MediaField
            v-if="$store.state.mailingData.advertiserLogoId"
            name="advertiser-logo"
            label="Advertiser"
            help="Will be used if mailing logo is empty"
            index-route="admin.api.media.index"
            show-route="admin.api.media.show"
            upload-route="admin.api.media.store"
            delete-route="admin.api.media.destroy"
            :inline="true"
            :model-value="$store.state.mailingData.advertiserLogoId"
            :disabled="true"
          />
        </template>
      </MediaField>
      <ErrorList :fields="['logo_media_id']" />

      <CheckboxField
        v-model="$store.state.form.show_advertiser_logo"
        label="Show logo in the mailing"
        name="show_advertiser_logo"
        :required="false"
      />
    </template>

    <MediaField
      v-if="!isSurvey && $store.state.form.advertiser_id"
      v-model="$store.state.form.media_id"
      label="Media"
      name="media"
      index-route="admin.api.media.index"
      show-route="admin.api.media.show"
      upload-route="admin.api.media.store"
      delete-route="admin.api.media.destroy"
      :media-key="`advertiser-${$store.state.form.advertiser_id}-mailing`"
      :required="false"
    />
    <ErrorList :fields="['media_id']" />

    <MediaField
      v-if="!isSurvey && $store.state.form.advertiser_id"
      v-model="$store.state.form.html_media_id"
      label="Html"
      name="html_media"
      index-route="admin.api.media.index"
      show-route="admin.api.media.show"
      upload-route="admin.api.media.store"
      delete-route="admin.api.media.destroy"
      :media-key="`advertiser-${$store.state.form.advertiser_id}-mailing-html`"
      :allowed-mime-types="['text/html']"
      :required="false"
    />
    <ErrorList :fields="['html_media_id']" />

    <InputField
      v-if="!isSurvey"
      v-model="$store.state.form.tracking_pixel"
      label="Tracking pixel"
      name="tracking_pixel"
      :required="false"
    />

    <InputField
      v-if="!isSurvey"
      v-model="$store.state.form.tracking_pixel_2"
      label="Tracking pixel 2"
      name="tracking_pixel_2"
      :required="false"
    />

    <TextareaField
      v-model="$store.state.form.conditions"
      label="Conditions"
      name="conditions"
      rows="6"
      :required="false"
    />

    <SelectField
      v-if="!isSurvey"
      v-model="$store.state.form.commission_model_display"
      label="Commission model display"
      name="commission_model_display"
      placeholder="Select a display option"
      :items="commissionModelDisplayOptions"
    />

    <MultiCheckboxField
      v-if="!isSurvey && $store.state.form.commission_model_display === 'only_selected' && selectableCommissionModels.length > 0"
      v-model="$store.state.form.commission_model_ids"
      label="Commission Model(s)"
      name="commission_model_ids"
      :required="false"
      :options="selectableCommissionModels"
    />

    <CheckboxField
      v-if="!isSurvey"
      v-model="$store.state.form.show_max_cashback"
      label="Show max cashback"
      name="show_max_cashback"
      :required="false"
    />

    <CheckboxField
      v-if="!isSurvey"
      v-model="$store.state.form.show_commission_model_table"
      label="Show commission model table"
      name="show_commission_model_table"
      :required="false"
    />

    <EditorField
      v-if="textNotificationTypes.includes($store.state.form.notification_type)"
      ref="textEditor"
      v-model="$store.state.form.text"
      label="Text"
      name="text"
      help="Routes: <code>:route[frontend.index]</code>"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    >
      <template #extra>
        <DefaultButton @click.prevent="$emit('openRoutesModal')">
          <span class="material-icons md-18">fork_right</span>
          Available routes
        </DefaultButton>
      </template>
    </EditorField>

    <InputField
      v-model="$store.state.form.button_text"
      label="Button text"
      name="button_text"
      maxlength="100"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />
  </div>
</template>

<script>
import EditorField from '../../../Components/Form/EditorField.vue';

export default {
  components: {
    EditorField,
  },

  props: {
    isSurvey: {
      type: Boolean,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },

    commissionModelDisplayOptions: {
      type: Array,
      required: true,
    },

    mailbanners: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ['openTrackingUrlVariablesModal', 'openRoutesModal', 'openSubjectVariablesModal'],

  data() {
    return {
      selectableCommissionModels: this.doBuildSelectableCommissionModels(),

      textNotificationTypes: ['newsletter_weekly', 'commercial_weekly', 'all_members'],
    };
  },

  watch: {
    '$store.state.form.commission_model_display': function () {
      if (this.$store.state.form.commission_model_display !== 'only_selected') {
        this.$store.state.form.commission_model_ids = [];
      }
    },

    '$store.state.form.program_id': function () {
      this.selectableCommissionModels = [];

      if (this.$store.state.form.commission_model_display === 'only_selected') {
        this.$store.state.form.commission_model_ids = [];
      }
    },

    '$store.state.mailingData.programCommissionModels': function () {
      this.selectableCommissionModels = this.doBuildSelectableCommissionModels();
    },

    '$store.state.form.notification_type': function (newValue) {
      if (!this.textNotificationTypes.includes(newValue)) {
        this.$store.state.form.text = '';
        this.$store.state.form.button_text = null;
        // this.$refs.textEditor.editor.reload();
      }
    },
  },

  methods: {
    copyTrackingUrl() {
      navigator.clipboard.writeText(this.$store.state.form.tracking_url);
    },

    doBuildSelectableCommissionModels() {
      if (!this.$store.state.mailingData.programCommissionModels) {
        return [];
      }

      const selectedCommissionModels = this.$store.state.form.commission_model_ids;

      return this.buildSelectableCommissionModels(
        this.$store.state.mailingData.programCommissionModels,
        selectedCommissionModels,
        {
          checkCanUse: false,
        },
      );
    },
  },
};
</script>
