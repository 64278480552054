<template>
  <CrudEdit
    title="Daily Mailings"
    sub-title="Daily Mailings"
    :update-route="route('admin.daily-survey-mailings.update')"
  >
    <template #form>
      <MultiSelectField
        v-for="(day, index) in days"
        :key="index"
        v-model="$store.state.form.values[index] "
        :label="day"
        :name="`values[${index}]`"
        placeholder="Select a Mailing"
        mode="single"
        :searchable="true"
        :can-deselect="true"
        :can-clear="true"
        :resolve-on-load="$store.state.form.values[index]"
        :min-chars="2"
        :delay="150"
        :required="false"
        :loading="loadingMailings[index]"
        :options="async (query) => await fetchMailings(index, query)"
      >
        <template
          v-if="$store.state.form.values[index]"
          #extra
        >
          <DefaultButton
            :internal-link="false"
            :href="route('admin.mailings.edit', $store.state.form.values[index])"
            target="_blank"
          >
            <span class="material-icons md-18">open_in_new</span>
          </DefaultButton>
        </template>
      </MultiSelectField>
    </template>
  </CrudEdit>
</template>

<script>
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';
import {ref} from 'vue';
import axios from 'axios';

export default {
  props: {
    days: {
      type: Object,
      required: true,
    },

    values: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let loadingMailings = ref({});

    for (let day in props.days) {
      loadingMailings[day] = false;
    }

    const form = useForm({
      values: props.values ?? null,
    });

    useStore().commit('form/setForm', form);

    return {loadingMailings};
  },

  methods: {
    async fetchMailings(index, query) {
      this.loadingMailings[index] = true;

      let fetchInitialSelectedAdvertiser = query === null;
      if (fetchInitialSelectedAdvertiser) {
        return await axios.get(this.route('admin.api.mailings.find', {id: this.$store.state.form.values[index]}))
            .then(({data}) => {
              this.loadingMailings[index] = false;
              return data;
            });
      }

      return await axios.get(this.route('admin.api.mailings.search.name', {name: query, survey: true}))
          .then(({data}) => {
            this.loadingMailings[index] = false;
            return data;
          });
    },
  },
};
</script>
