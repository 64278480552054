<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <CrudIndex
    v-else
    title="Surveys"
    create-route-path="admin.surveys.create"
    edit-route-path="admin.surveys.edit"
    row-route-path="admin.survey-waves.index"
    :columns="['id', 'type', 'name']"
    :actions="true"
    :data="surveys.data"
    :paginator="surveys.meta.pagination"
    :filters="[
      {
        type: 'search',
        fields: [
          {label: 'Name', value: 'name'},
          {label: 'Wave Name', value: 'surveyWaves.name'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Types',
        value: 'type',
        multiple: true,
        items: types,
      },
    ]"
  />
</template>

<script>
export default {
  props: {
    surveys: {
      type: Object,
      required: true,
    },

    types: {
      type: Array,
      required: true,
    },
  },
};
</script>
