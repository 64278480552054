<template>
  <SelectField
    v-model="$store.state.form.country_id"
    label="Country"
    name="country_id"
    placeholder="Select a Country"
    :items="countries"
    :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
  />

  <CheckboxField
    v-model="$store.state.form.is_enabled"
    label="Is enabled"
    name="is_enabled"
    :required="false"
  />

  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
  />

  <TextareaField
    v-model="$store.state.form.description"
    label="Description"
    name="description"
    rows="6"
  />

  <MediaField
    v-model="$store.state.form.logo_media_id"
    label="Logo Media"
    help="Recommended: 300x76 72DPI"
    name="logo_media_id"
    index-route="admin.api.media.index"
    show-route="admin.api.media.show"
    upload-route="admin.api.media.store"
    delete-route="admin.api.media.destroy"
    :required="false"
    media-key="giftcard_shop_logo"
  />

  <EditorField
    v-model="$store.state.form.extra_email_info"
    label="Extra email info"
    name="extra_email_info"
    :required="false"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import EditorField from '../../Components/Form/EditorField.vue';

export default {
  components: {EditorField},
  props: {
    shop: {
      type: Object,
      required: false,
      default: null,
    },

    countries: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      country_id: props.shop ? props.shop.country_id : null,
      is_enabled: props.shop ? props.shop.is_enabled : false,
      name: props.shop ? props.shop.name : '',
      description: props.shop ? props.shop.description : '',
      logo_media_id: props.shop ? props.shop.logo_media_id : null,
      extra_email_info: props.shop ? props.shop.extra_email_info : null,
    });

    useStore().commit('form/setForm', form);
  },

  mounted() {
    if (!this.shop && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }
  },
};
</script>
