<template>
  <HeadlessTransitionRoot
    appear
    :show="open"
    as="div"
  >
    <HeadlessDialog
      :initial-focus="buttonRef"
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="close"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <HeadlessDialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>

        <HeadlessTransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
          >
            <form
              ref="form"
              enctype="multipart/form-data"
              class="min-w-full"
              @submit.prevent="submit"
            >
              <div class="bg-white">
                <div class="">
                  <div class="mt-3 text-center sm:mt-0 sm:text-left">
                    <div class="px-6 py-6">
                      <slot name="fields" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="bg-gray-50 px-4 py-3">
                <div v-if="submitting">
                  <div class="inline-flex justify-center w-full">
                    <i class="far fa-spin fa-spinner text-2xl" />
                  </div>
                </div>
                <div
                  v-else
                  class="sm:px-6 sm:flex sm:flex-row-reverse"
                >
                  <DefaultButton
                    v-if="showClose"
                    ref="buttonRef"
                    classes="text-white bg-indigo-600 hover:bg-indigo-700"
                    type="button"
                    @click.stop="close"
                  >
                    Close
                  </DefaultButton>
                  <DefaultButton
                    v-if="submitRoute || submitHandler"
                    classes="text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Submit
                  </DefaultButton>
                </div>
              </div>
            </form>
          </div>
        </HeadlessTransitionChild>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script>
import {
  Dialog as HeadlessDialog,
  DialogOverlay as HeadlessDialogOverlay,
  TransitionChild as HeadlessTransitionChild,
  TransitionRoot as HeadlessTransitionRoot,
} from '@headlessui/vue';
import axios from 'axios';
import {ref} from 'vue';

export default {
  components: {HeadlessDialog, HeadlessDialogOverlay, HeadlessTransitionRoot, HeadlessTransitionChild},

  props: {
    id: {
      type: String,
      required: true,
    },

    open: {
      type: Boolean,
      required: true,
    },

    submitRoute: {
      type: String,
      required: false,
      default: null,
    },

    isInertiaRequest: {
      type: Boolean,
      required: false,
      default: true,
    },

    submitHandler: {
      type: Function,
      required: false,
      default: null,
    },

    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },

    canClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['update:open'],

  setup() {
    return {
      buttonRef: ref(null),
    };
  },

  data() {
    return {
      submitting: false,
    };
  },

  methods: {
    close() {
      if (!this.canClose) {
        return;
      }

      this.$emit('update:open', false);
    },

    submit() {
      if (!this.submitRoute && !this.submitHandler) {
        return;
      }

      this.submitting = true;

      if (this.submitHandler) {
        this.submitHandler({
          success: () => {
            this.submitting = false;
            this.close();
          },

          error: () => {
            this.submitting = false;
          },

          close: () => {
            this.close();
          },
        });

        return;
      }

      const formData = new FormData(this.$refs.form);

      if (this.isInertiaRequest) {
        this.$inertia.post(this.submitRoute, formData, {
          preserveState: true,
          preserveScroll: true,

          onFinish: () => {
            this.submitting = false;
          },

          onSuccess: () => {
            this.close();
          },
        });

        return;
      }

      axios.post(this.submitRoute, formData)
        .then((response) => {
          // success
          this.$page.props.flash.success = response.data ?? 'OK';

          this.close();
        })
        .catch((error) => {
          // error
          if (error?.response?.data?.errors) {
            this.$page.props.errors = error.response.data.errors;
          }
        })
        .then(() => {
          // always executed
          this.submitting = false;
        });
    },
  },
};
</script>
