<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        General
      </span>
    </template>

    <template #content>
      <CheckboxField
        v-model="$store.state.form.is_active"
        label="Is active"
        name="is_active"
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.cashback_once"
        label="Cashback once"
        name="cashback_once"
        :required="false"
      />

      <MultiSelectField
        v-model="$store.state.form.advertiser_id"
        label="Advertiser"
        name="advertiser_id"
        placeholder="Select an advertiser"
        mode="single"
        :searchable="true"
        :can-deselect="false"
        :can-clear="false"
        :resolve-on-load="$store.state.form.advertiser_id > 0"
        :min-chars="2"
        :delay="150"
        :loading="loadingAdvertisers"
        :options="async (query) => await fetchAdvertisers(query)"
        @select="advertiserChanged"
      >
        <template
          v-if="$store.state.form.advertiser_id"
          #extra
        >
          <DefaultButton
            :internal-link="false"
            :href="route('admin.advertisers.edit', $store.state.form.advertiser_id)"
            target="_blank"
          >
            <span class="material-icons md-18">open_in_new</span>
          </DefaultButton>
        </template>
      </MultiSelectField>

      <InputField
        v-if="$store.state.form.advertiser_id"
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />

      <InputField
        v-if="$store.state.form.advertiser_id"
        v-model="$store.state.form.slug"
        label="Slug"
        name="slug"
      />

      <TextareaField
        v-model="$store.state.form.description"
        label="Description"
        name="description"
        rows="6"
        :required="false"
        help="Will not be translated"
      />

      <MultiSelectField
        v-if="$store.state.form.advertiser_id"
        ref="programInput"
        v-model="programIds"
        label="Program"
        name="program_id"
        placeholder="Select a program"
        mode="tags"
        :searchable="true"
        :can-deselect="true"
        :loading="loadingAdvertiserPrograms"
        :options="advertiserPrograms"
      />

      <div>
        <div v-if="loadingAdvertiserSettings">
          <div class="text-2xl text-gray-600 mb-2">
            <span class="material-icons md-18 mr-2 animate-spin">autorenew</span>
          </div>
        </div>
        <div v-else>
          <InputField
            v-if="useDefaultPreferredNetworkWhenTie"
            v-model="defaultPreferredNetworkWhenTie"
            :disabled="true"
            label="Default preferred network (in case of tie)"
            name="preferred_network_id_tie"
            help="Only in case of tie (i.e. same commission)"
          >
            <template #extra>
              <DefaultButton @click.prevent="useDefaultPreferredNetworkWhenTie = false">
                <span
                  class="material-icons md-18"
                  title="Don't use default"
                >edit</span>
              </DefaultButton>
              <DefaultButton
                title="Show possible values"
                @click.prevent="showNetworkIds = true"
              >
                <span class="material-icons md-18">list</span>
              </DefaultButton>
            </template>
          </InputField>

          <InputField
            v-else
            v-model="$store.state.form.preferred_network_id_tie"
            label="Preferred network (in case of tie)"
            name="preferred_network_id_tie"
            help="Only in case of tie (i.e. same commission)"
          >
            <template #extra>
              <DefaultButton @click.prevent="doUseDefaultPreferredNetworkWhenTie">
                <span
                  class="material-icons md-18"
                  title="Use default"
                >edit_off</span>
              </DefaultButton>
              <DefaultButton
                title="Show possible values"
                @click.prevent="showNetworkIds = true"
              >
                <span class="material-icons md-18">list</span>
              </DefaultButton>
            </template>
          </InputField>
        </div>
      </div>

      <InputField
        v-model="$store.state.form.preferred_network_id"
        label="Preferred network (without battling)"
        name="preferred_network_id"
        help="Uses attached active program of this network without battling (i.e. comparing commissions)"
        :required="false"
      >
        <template #extra>
          <DefaultButton
            title="Show possible values"
            @click.prevent="showNetworkIds = true"
          >
            <span class="material-icons md-18">list</span>
          </DefaultButton>
        </template>
      </InputField>
    </template>
  </CrudToggleCard>

  <CrudToggleCard :default-open="!freeAction">
    <template #header>
      <span class="font-medium">
        Promotions
      </span>
    </template>

    <template #content>
      <MultiSelectField
        v-model="$store.state.form.free_action_category_ids"
        label="Free action Categories"
        name="free_action_category_ids"
        placeholder="Select a category"
        mode="tags"
        :searchable="true"
        :can-deselect="true"
        :options="freeActionCategories"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Segmentation
      </span>
    </template>

    <template #content>
      <InputField
        v-model="$store.state.form.minimum_age"
        type="number"
        label="Minimum Age"
        name="minimum_lage"
        min="0"
        max="255"
        help="Leave empty to disable segmentation, inclusive number"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.maximum_age"
        type="number"
        label="Maximum Age"
        name="maximum_age"
        min="0"
        max="255"
        help="Leave empty to disable segmentation, inclusive number"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <PromotionActionProgramForm
    v-for="programId in programIds"
    :key="`free-action-programs-form-${programId}`"
    :program-id="parseInt(programId)"
    :promotion-action-id="freeAction ? freeAction.id : null"
    :promotion-action-type="freeAction ? freeAction.promotion_action_type : null"
    :needs-commission-models="false"
  />

  <Modal
    v-model:open="showNetworkIds"
    :items="networkIds"
  />
</template>

<script>
import {ref} from 'vue';
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';
import axios from 'axios';
import PromotionActionProgramForm from '../../Components/PromotionActionProgramForm.vue';
import Modal from '../../Components/Modal.vue';

export default {
  components: {PromotionActionProgramForm, Modal},

  props: {
    freeAction: {
      type: Object,
      required: false,
      default: null,
    },

    freeActionCategories: {
      type: Object,
      required: true,
    },

    programs: {
      type: Object,
      required: false,
      default: null,
    },

    program: {
      type: Object,
      required: false,
      default: null,
    },

    networkIds: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let advertiser_id = null;
    let free_action_category_ids = [];

    if (props.program) {
      advertiser_id = ref(props.program.advertisers[0].id);
    }

    if (props.freeAction) {
      advertiser_id = ref(props.freeAction.advertiser_id);
      free_action_category_ids = props.freeAction.categories.map(category => category.id);
    }

    const form = useForm({
      advertiser_id,
      name: props.freeAction?.name ?? null,
      slug: props.freeAction?.slug ?? null,
      description: props.freeAction?.description ?? null,
      minimum_age: props.freeAction?.minimum_age ?? null,
      maximum_age: props.freeAction?.maximum_age ?? null,
      free_action_category_ids,
      programs: props.programs ? props.programs : {},
      is_active: props.freeAction ? props.freeAction.is_active : true,
      cashback_once: props.freeAction ? props.freeAction.cashback_once : false,
      preferred_network_id_tie: props.freeAction?.preferred_network_id_tie ?? null,
      preferred_network_id: props.freeAction?.preferred_network_id ?? null,
    });

    useStore().commit('form/setForm', form);

    return {
      defaultPreferredNetworkWhenTie: ref(null),
    };
  },

  data() {
    const initialProgramIds = this.programs ? Object.keys(this.programs) : [];
    if (this.program) {
      initialProgramIds.push(this.program.id);
    }

    return {
      loadingAdvertisers: false,
      loadingAdvertiserSettings: false,
      loadingAdvertiserPrograms: false,
      advertiserPrograms: [],
      programIds: initialProgramIds,

      useDefaultPreferredNetworkWhenTie: ref(this.$store.state.form.preferred_network_id_tie === null),
      showNetworkIds: ref(false),
    };
  },

  watch: {
    '$store.state.form.name': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },

    '$store.state.form.slug': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },
  },

  mounted() {
    if (this.$store.state.form.advertiser_id) {
      if (this.program) {
        let advertiserName = this.program.advertisers.find(advertiser => advertiser.id === this.$store.state.form.advertiser_id).name;
        this.$store.state.form.name = advertiserName;
        this.$store.state.form.slug = this.sluggify(advertiserName);
      }

      this.loadAdvertiserSettingsAndPrograms(this.$store.state.form.advertiser_id);
    }
  },

  methods: {
    advertiserChanged(advertiserId, selectedOption) {
      advertiserId = parseInt(advertiserId);
      if (isNaN(advertiserId)) {
        return;
      }
      let advertiserName = selectedOption.label;

      this.$store.state.form.name = advertiserName;
      this.$store.state.form.slug = this.sluggify(advertiserName);

      this.$nextTick(() => {
        this.programIds = [];
        this.loadAdvertiserSettingsAndPrograms(advertiserId);

        this.$refs.programInput.clear();
      });
    },

    async fetchAdvertisers(query) {
      this.loadingAdvertisers = true;

      let fetchInitialSelectedAdvertiser = query === null;
      if (fetchInitialSelectedAdvertiser) {
        return await axios.get(this.route('admin.api.advertisers.find', {id: this.$store.state.form.advertiser_id}))
          .then(({data}) => {
            this.loadingAdvertisers = false;
            return data;
          });
      }

      return await axios.get(this.route('admin.api.advertisers.search.name', {name: query}))
        .then(({data}) => {
          this.loadingAdvertisers = false;
          return data;
        });
    },

    loadAdvertiserSettingsAndPrograms(advertiserId) {
      this.loadingAdvertiserSettings = true;
      this.loadingAdvertiserPrograms = true;

      this.advertiserPrograms = [];
      this.defaultPreferredNetworkWhenTie = null;

      axios.get(this.route('admin.api.advertisers.show', [advertiserId]))
        .then(({data}) => {
          this.defaultPreferredNetworkWhenTie = data.preferred_network_id_when_tie;

          this.loadingAdvertiserSettings = false;
        });

      return axios.get(this.route('admin.api.programs.find.advertiser', [advertiserId]))
        .then(({data}) => {
          this.advertiserPrograms = data;

          this.loadingAdvertiserPrograms = false;
        });
    },

    doUseDefaultPreferredNetworkWhenTie() {
      this.useDefaultPreferredNetworkWhenTie = true;
      this.$store.state.form.preferred_network_id_tie = null;
    },
  },
};
</script>
