<template>
  <CrudCreate
    title="Profile questions - Create"
    sub-title="Profile questions"
    :index-route="route('admin.profile-questions.index')"
    :store-route="route('admin.profile-questions.store')"
  >
    <template #form>
      <QuestionForm
        :selected-country-id="selectedCountryId"
      />
    </template>
  </CrudCreate>
</template>

<script>
import QuestionForm from './Form.vue';

export default {
  components: {
    QuestionForm,
  },

  props: {
    selectedCountryId: {
      type: Number,
      required: true,
    },
  },
};
</script>
