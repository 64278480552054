<template>
  <div
    class="relative"
    @click="form.activeModal === item.id ? $emit('save', item.id) : $emit('toggle-modal', item.id)"
  >
    <div class="flex">
      <div class="flex-grow">
        <slot name="content" />
      </div>

      <div class="ml-2 text-right">
        <i
          class="fal fa-pen hover:text-indigo-600 hover:cursor-pointer"
          :class="{ 'text-indigo-600': form.activeModal === item.id }"
        />

        <div
          v-if="form.activeModal === item.id"
          class="absolute bottom-full w-80"
          :class="{ '-right-56': isFirst, '-right-24': !isFirst }"
          @click.stop
        >
          <div class="bg-gray-100 p-2 flex items-center border w-full">
            <span class="w-full">
              <slot name="form" />
            </span>

            <div
              class="ml-1 hover:cursor-pointer"
              @click="$emit('save', item.id)"
            >
              <i class="fal fa-times" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps} from 'vue';

defineProps({
  item: {
    type: Object,
    required: true,
  },

  form: {
    type: Object,
    required: true,
  },

  isFirst: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const $emit = defineEmits(['toggle-modal', 'save']);

</script>
