<template>
  <CrudCreate
    title="Bulk balance mutations"
    sub-title="Bulk balance mutations"
    :index-route="route('admin.bulk-balance-mutation.index')"
    :store-route="route('admin.bulk-balance-mutation.store')"
    :store-options="{
      onBefore: () => confirmBeforeSubmit(),
      onSuccess: () => resetForm(),
    }"
  >
    'admin.bulk-balance-mutation.index'
    <template #form>
      <CurrencyAndPointsField
        v-model="$store.state.form.value"
        :options="currencyOptions"
        :points-conversion-ratio="pointsConversionRatio"
        label="Value"
        name="value"
        help="The amount the members will receive"
      />

      <InputField
        v-model="$store.state.form.description"
        label="Description"
        name="description"
        help="This is what the members will see"
      />

      <TextareaField
        v-model="$store.state.form.note"
        label="Note"
        name="note"
        help="Members won’t see this text."
      />

      <TextareaField
        v-model="inputIds"
        label="IDs"
        name="ids"
        help="The IDs you want to give balance mutation to. Can be clickout IDs and member IDs (combined)."
        rows="6"
        :wider="true"
      />
    </template>
  </CrudCreate>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {ref} from 'vue';
import {useStore} from 'vuex';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';

export default {
  components: {
    CurrencyAndPointsField,
  },

  props: {
    currency: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const currencyOptions = {
      currency: props.currency.iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    const form = useForm({
      value: null,
      description: '',
      note: '',
      ids: [],
    });

    useStore().commit('form/setForm', form);

    return {
      currencyOptions,
      separators: [',', ';', ' ', '\n', '\t'],
      inputIds: ref(''),
    };
  },

  watch: {
    inputIds: function (newValue) {
      if (!newValue || newValue.length === 0) {
        this.$store.state.form.ids = [];
        return;
      }

      let ids = this.separators.reduce((old, separator) => old.map(v => v.split(separator)).flat(), [newValue]);
      ids = ids.filter(n => n); // remove empty values
      this.$store.state.form.ids = ids;
    },
  },

  methods: {
    confirmBeforeSubmit() {
      if (this.$store.state.form.ids.length === 0) {
        return false;
      }

      return confirm(`Are you sure you want to give a balance mutation of ${this.currency.symbol}${this.$store.state.form.value} to (possible) ${this.$store.state.form.ids.length} members?`);
    },

    resetForm() {
      this.inputIds = '';
      this.$store.state.form.reset();
    },
  },
};
</script>
