<template>
  <CrudIndex
    title="Transactions mismatch"
    row-route-path="admin.transactions.show"
    :columns="['ID', 'Transaction datetime', 'Country', 'Network', 'Program', 'Commission', 'Description', 'Sub IDs']"
    :actions="true"
    :data="transactions.data"
    :paginator="transactions.meta.pagination"
    :menu-items="[
      {name: 'Details', url: (item) => route('admin.transactions.show', item.id), enabled: true},
      {name: 'Hide transaction', icon: 'delete', action: (item) => hideTransaction(item.id), enabled: true},
    ]"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}]
      },
    ]"
    :can-select-rows="true"
  >
    <template #selected-actions="{ selected, disabled, selectedIds }">
      <DefaultButton
        :classes="(disabled ? 'bg-gray-400 text-gray-800 cursor-not-allowed disabled' : 'text-white bg-indigo-600 hover:bg-indigo-700')"
        :disabled="disabled"
        @click="disabled ? null : hideSelected(selectedIds)"
      >
        Hide transactions ({{ selected }})
      </DefaultButton>
    </template>

    <template #country="{ row }">
      {{ row.country.code }}
    </template>

    <template #network="{ row }">
      {{ row.network_name }}
    </template>

    <template #program="{ row }">
      <span v-if="!row.network_program">n/a</span>
      <a
        v-else
        :href="route('admin.programs.edit', row.network_program.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.network_program.name }}
      </a>
    </template>

    <template #commission="{ row }">
      <TransactionCommission :row="row" />
    </template>

    <template #description="{ row }">
      {{ row.description }}
    </template>

    <template #sub_ids="{ row }">
      {{ subIdsToString(row.raw_sub_ids) }}
    </template>
  </CrudIndex>
</template>

<script>
import TransactionCommission from '../../Components/TransactionCommission.vue';

export default {
  components: {
    TransactionCommission,
  },

  props: {
    transactions: {
      type: Object,
      required: true,
    },
  },

  methods: {
    hideTransaction(transactionId) {
      this.$inertia.post(this.route('admin.transactions-mismatch.hide', [transactionId]));
    },

    subIdsToString(rawSubIds) {
      return rawSubIds.map((rawSubId) => rawSubId.value).join(';');
    },

    hideSelected(transactionIds) {
      this.$inertia.patch(this.route('admin.transactions-mismatch.hide-selected', {transaction_ids: transactionIds}), {}, {
        preserveState: true,
        preserveScroll: true,
      });
    },
  },
};
</script>
