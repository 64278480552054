<template>
  <CrudIndex
    title="Translations"
    edit-route-path="admin.translations.edit"
    row-route-path="admin.translations.edit"
    :columns="{
      group: {
        label: 'Group',
        orderKey: 'group',
      },
      key: {
        label: 'Key',
        orderKey: 'key',
      },
      translation: {
        label: `Translation (${locale})`,
      },
    }"
    :order="order"
    :actions="true"
    :data="translations.data"
    :paginator="translations.meta.pagination"
    :filters="[
      {type: 'search', fields: [
        {label: 'Group', value: 'group'},
        {label: 'Key', value: 'key'},
        {label: 'Translation', value: 'text'},
      ]},
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Group',
        value: 'group',
        multiple: true,
        items: filterableGroups,
      },
      {
        type: 'dropdown',
        operator: 'translated_locale',
        label: 'Is not translated in',
        value: 'not',
        multiple: true,
        items: filterableLocales,
      },
    ]"
  />
</template>

<script setup>
import {defineProps, ref} from 'vue';

const props = defineProps({
  translations: {
    type: Object,
    required: true,
  },

  order: {
    type: Object,
    required: true,
  },

  groups: {
    type: Array,
    required: true,
  },

  locale: {
    type: String,
    required: true,
  },

  locales: {
    type: Array,
    required: true,
  },
});

const filterableGroups = ref([]);
for (const group of props.groups) {
  filterableGroups.value.push({name: group, value: group});
}

const filterableLocales = ref([]);
for (const locale of props.locales) {
  filterableLocales.value.push({name: locale, value: locale});
}
</script>
