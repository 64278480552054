<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Member
      </span>

      <span
        class="text-xs text-gray-400"
      >
        <a
          :href="route('admin.members.edit', member.id)"
          class="text-indigo-600 hover:text-indigo-900"
          target="_blank"
          @click.stop
        >
          #{{ member.id }}
        </a>
      </span>
    </template>

    <template #content>
      <CrudShowContent
        :rows="['ID', 'Country', 'First name', 'Last name', 'Email', 'Is possible cheater', 'Registration date']"
        :data="member"
      >
        <template #country="{ data }">
          {{ data.country_name }}
        </template>

        <template #id="{ data }">
          <a
            :href="route('admin.members.edit', data.id)"
            class="text-indigo-600 hover:text-indigo-900"
            target="_blank"
            @click.stop
          >
            #{{ data.id }}
          </a>
        </template>

        <template #registration_date="{ data }">
          {{ data.created_at }}
        </template>
      </CrudShowContent>
    </template>
  </CrudToggleCard>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
};
</script>
