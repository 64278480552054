<template>
  <CrudIndex
    :title="`${theme.name} - Webshops`"
    edit-route-path="admin.webshops.edit"
    row-route-path="admin.webshops.edit"
    :columns="['country', 'name', 'is active']"
    :actions="true"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', color: 'blue-400', action: (item) => move(item.webshop_theme_webshop_id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', color: 'blue-400', action: (item) => move(item.webshop_theme_webshop_id, 'down'), enabled: true},
    ]"
    :data="webshops"
  >
    <template #country="{ row }">
      {{ row.country_name }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    theme: {
      type: Object,
      required: true,
    },

    webshops: {
      type: Object,
      required: true,
    },
  },

  methods: {
    move(webshopThemeWebshopId, direction) {
      this.$inertia.post(
        this.route('admin.webshop-themes.webshops.move', [webshopThemeWebshopId]),
        {move: direction},
      );
    },
  },
};
</script>
