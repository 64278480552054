<template>
  <CrudCreate
    title="News - Create"
    sub-title="News"
    :index-route="route('admin.news.index')"
    :store-route="route('admin.news.store')"
  >
    <template #form>
      <NewsForm
        :countries="countries"
        :puzzles="puzzles"
      />
    </template>
  </CrudCreate>
</template>

<script>
import NewsForm from './Form.vue';

export default {
  components: {
    NewsForm,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },

    puzzles: {
      type: Array,
      required: true,
    },
  },
};
</script>
