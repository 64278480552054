<template>
  <CrudToggleCard class="!p-0">
    <template #header>
      <span
        class="text-sm col-span-2"
      >
        Advertiser:
        <span
          v-if="!advertiser"
          class="material-icons md-18 mr-2 animate-spin"
        >
          autorenew
        </span>
        <a
          v-else
          :href="route('admin.advertisers.edit', advertiser.id)"
          class="text-indigo-600 hover:text-indigo-900"
          target="_blank"
        >
          {{ advertiser.name }}
        </a>
      </span>
    </template>

    <template #content>
      <div v-if="!advertiser">
        <div class="inline-flex justify-center w-full py-4">
          <i class="far fa-spin fa-spinner text-2xl" />
        </div>
      </div>
      <div v-else>
        <InputField
          v-model="advertiser.name"
          label="Name"
          name=""
          :disabled="true"
        />

        <InputField
          v-model="advertiser.sort"
          label="Sort"
          name="sort"
          type="number"
        />

        <MultiSelectField
          v-model="advertiser.media_type"
          label="Media type"
          name="media_type"
          placeholder="Select media type"
          mode="single"
          :options="multiAdvertiserMediaTypes"
          :required="false"
          :can-clear="true"
        />

        <MediaField
          v-if="advertiser.media_type === 'cover'"
          v-model="advertiser.media_id"
          label="Cover Media"
          help="Minimum size: 244x659px"
          name="media_id"
          index-route="admin.api.media.index"
          show-route="admin.api.media.show"
          upload-route="admin.api.media.store"
          delete-route="admin.api.media.destroy"
          :media-key="`advertiser-${advertiser.id}-cover`"
          :crop-config="{
            enabled: true,
            minHeight: 244,
            minWidth: 659,
            aspectRatio: 659 / 244,
          }"
          :required="false"
        />
        <MediaField
          v-else-if="advertiser.media_type === 'rectangle'"
          v-model="advertiser.media_id"
          label="Rectangle Media"
          help="300x250"
          name="media_id"
          index-route="admin.api.media.index"
          show-route="admin.api.media.show"
          upload-route="admin.api.media.store"
          delete-route="admin.api.media.destroy"
          :media-key="`advertiser-${advertiser.id}-rectangle`"
          :required="false"
        />
        <MediaField
          v-else-if="advertiser.media_type === 'billboard'"
          v-model="advertiser.media_id"
          label="Billboard Media"
          help="970x250"
          name="media_id"
          index-route="admin.api.media.index"
          show-route="admin.api.media.show"
          upload-route="admin.api.media.store"
          delete-route="admin.api.media.destroy"
          :media-key="`advertiser-${advertiser.id}-billboard`"
          :required="false"
        />
        <MediaField
          v-else-if="advertiser.media_type === 'large_leaderboard'"
          v-model="advertiser.media_id"
          label="Large Leaderboard Media"
          help="970x90"
          name="media_id"
          index-route="admin.api.media.index"
          show-route="admin.api.media.show"
          upload-route="admin.api.media.store"
          delete-route="admin.api.media.destroy"
          :media-key="`advertiser-${advertiser.id}-large-leaderboard`"
          :required="false"
        />
        <MultiSelectField
          ref="programInput"
          v-model="advertiser.program_id"
          label="Program"
          name="program_id"
          placeholder="Select a program"
          mode="single"
          :searchable="false"
          :can-deselect="true"
          :can-clear="true"
          :loading="loadingPrograms"
          :options="programs"
          @select="loadProgramTrackingUrl"
        />
        <CheckboxField
          v-if="advertiser.program_id"
          v-model="advertiser.use_program_tracking_url"
          label="Use program tracking-url"
          :name="`advertiser.${advertiserId}.use_program_tracking_url`"
          :required="false"
          @change="loadProgramTrackingUrl"
        />

        <InputField
          v-if="advertiser.use_program_tracking_url && advertiser.program_id"
          :model-value="advertiser.tracking_url"
          label="Program Tracking URL"
          name="program_tracking_url"
          :loading="loadingTrackingUrl"
          :disabled="true"
        />

        <InputField
          v-if="!advertiser.use_program_tracking_url"
          v-model="advertiser.tracking_url"
          label="Tracking URL"
          name="tracking_url"
        >
          <template #extra>
            <DefaultButton
              title="Copy"
              @click.prevent="copyTrackingUrl(advertiser.tracking_url)"
            >
              <span class="material-icons md-18">content_copy</span>
            </DefaultButton>

            <DefaultButton
              :internal-link="false"
              :href="safeProgramTrackingUrl(advertiser.tracking_url)"
              target="_blank"
              title="Open (in new tab)"
            >
              <span class="material-icons md-18">launch</span>
            </DefaultButton>

            <DefaultButton @click.prevent="$emit('openTrackingUrlVariablesModal')">
              <span class="material-icons md-18">fork_right</span>
              Variables
            </DefaultButton>

            <DefaultButton @click.prevent="$emit('openRoutesModal')">
              <span class="material-icons md-18">fork_right</span>
              Routes
            </DefaultButton>
          </template>
        </InputField>
      </div>
    </template>
  </CrudToggleCard>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    advertiserId: {
      type: Number,
      required: true,
    },

    multiAdvertiserMediaTypes: {
      type: Array,
      required: true,
    },
  },

  emits: ['openTrackingUrlVariablesModal', 'openRoutesModal'],

  data() {
    return {
      commissionModels: [],
      loadingPrograms: false,
      loadingTrackingUrl: false,
      programs: [],
    };
  },

  computed: {
    advertiser() {
      return this.$store.state.form.advertisers[this.advertiserId];
    },
  },

  unmounted() {
    // advertiser deselected, so remove it from the advertisers array
    delete this.$store.state.form.advertisers[this.advertiserId];
  },

  mounted() {
    if (!this.$store.state.form.advertisers[this.advertiserId]) {
      axios.get(this.route('admin.api.advertisers.details', {
        advertiser: this.advertiserId,
      }))
        .then(({ data }) => this.$store.state.form.advertisers[this.advertiserId] = {
          id: data.id,
          name: data.name,
          use_program_tracking_url: true,
        });
    }

    this.loadPrograms();
  },

  methods: {
    copyTrackingUrl(trackingUrl) {
      navigator.clipboard.writeText(trackingUrl);
    },

    loadPrograms() {
      this.loadingPrograms = true;
      this.programs = [];

      return axios.get(this.route('admin.api.programs.find.advertiser', [this.advertiserId]))
        .then(({data}) => {
          this.programs = data;

          this.loadingPrograms = false;
        });
    },

    loadProgramTrackingUrl() {
      if (!this.advertiser.use_program_tracking_url) {
        return;
      }

      this.loadingTrackingUrl = true;

      axios.get(this.route('admin.api.programs.details', [this.advertiser.program_id]))
        .then(({data}) => {
          this.$store.state.form.advertisers[this.advertiserId].tracking_url = data.used_tracking_url;
          this.loadingTrackingUrl = false;
        });
    },
  },
};
</script>
