<template>
  <ModalForm
    :id="`edit_status_form_${editableId}`"
    v-model:open="modalOpen"
    :submit-route="submitRoute"
  >
    <template #fields>
      <SelectField
        v-if="statuses"
        label="Status"
        name="status"
        placeholder="Select a status"
        :items="statuses"
      />

      <TextareaField
        label="Reason"
        name="reason"
        rows="6"
      />
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from '../../../Components/ModalForm.vue';

export default {
  components: {ModalForm},

  props: {
    memberId: {
      type: Number,
      required: true,
    },

    editableId: {
      type: Number,
      required: true,
    },

    statuses: {
      type: [Object, null],
      required: true,
    },

    submitRoute: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:editableId',
  ],

  data() {
    return {
      modalOpen: this.editableId > 0,
    };
  },

  watch: {
    modalOpen: function (newValue) {
      if (newValue === false) {
        this.$emit('update:editableId', null);
      }
    },
  },
};
</script>
