<template>
  <CrudEdit
    :title="`Giftcard - Edit ${shop.name}`"
    sub-title="Giftcards"
    :index-route="route('admin.giftcard-shops.index')"
    :update-route="route('admin.giftcard-shops.update', shop.id)"
  >
    <template #form>
      <GiftcardForm
        :shop="shop"
        :countries="countries"
      />
    </template>
  </CrudEdit>
</template>

<script>
import GiftcardForm from './Form.vue';

export default {
  components: {GiftcardForm},

  props: {
    shop: {
      type: Object,
      required: true,
    },

    countries: {
      type: Array,
      required: true,
    },
  },
};
</script>
