<template>
  <CrudIndex
    title="Rewards"
    :columns="['Advertiser', 'Total rewarded', 'Total rewarded automatically', 'Total rewarded manually', 'Total cashback', 'Total automatic cashback', 'Total manual cashback']"
    :data="totals"
    :actions="false"
  >
    <template #advertiser="{ row }">
      <div class="flex items-center">
        {{ row.advertiser }}

        <template v-if="row.advertiser === 'Other'">
          <Popover class="h-5 w-5 ml-1">
            <PopoverButton class="inline-block">
              <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
            </PopoverButton>
            <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
              <p class="w-full whitespace-normal">
                Manually added balance which is added to a user his balance in general. Not to a specific clickout.
              </p>
            </PopoverPanel>
          </Popover>
        </template>
      </div>
    </template>

    <template #total_rewarded_automatically="{ row }">
      {{ row.total_rewarded_automatically }} <span v-if="row.total_rewarded_automatically_percentage">({{ row.total_rewarded_automatically_percentage }})</span>
    </template>
    <template #total_rewarded_manually="{ row }">
      {{ row.total_rewarded_manually }} <span v-if="row.total_rewarded_manually_percentage">({{ row.total_rewarded_manually_percentage }})</span>
    </template>

    <template #total_automatic_cashback="{ row }">
      {{ row.total_automatic_cashback }} <span v-if="row.total_automatic_cashback_percentage">({{ row.total_automatic_cashback_percentage }})</span>
    </template>
    <template #total_manual_cashback="{ row }">
      {{ row.total_manual_cashback }} <span v-if="row.total_manual_cashback_percentage">({{ row.total_manual_cashback_percentage }})</span>
    </template>
  </CrudIndex>

  <CrudIndex
    :columns="{
      advertiser: {
        label: 'Advertiser',
      },
      total_rewarded: {
        label: 'Total rewarded',
        orderKey: 'total_rewarded',
      },
      total_rewarded_automatically: {
        label: 'Total rewarded automatically',
        orderKey: 'total_rewarded_automatically',
      },
      total_rewarded_manually: {
        label: 'Total rewarded manually',
        orderKey: 'total_rewarded_manually',
      },
      total_cashback: {
        label: 'Total cashback',
        orderKey: 'total_cashback',
      },
      total_automatic_cashback: {
        label: 'Total automatic cashback',
        orderKey: 'total_automatic_cashback',
      },
      total_manual_cashback: {
        label: 'Total manual cashback',
        orderKey: 'total_manual_cashback',
      },
    }"
    :order="order"
    :data="statistics?.data ?? []"
    :paginator="statistics?.meta?.pagination ?? null"
    :actions="false"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'cashbacks.created_at'}],
      },
    ]"
  >
    <template #advertiser="{ row }">
      <a
        :href="route('admin.advertisers.edit', row.advertiser.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.advertiser.name }}
      </a>
    </template>

    <template #total_rewarded_automatically="{ row }">
      {{ row.total_rewarded_automatically }} ({{ row.total_rewarded_automatically_percentage }})
    </template>
    <template #total_rewarded_manually="{ row }">
      {{ row.total_rewarded_manually }} ({{ row.total_rewarded_manually_percentage }})
    </template>

    <template #total_automatic_cashback="{ row }">
      {{ row.total_automatic_cashback }} ({{ row.total_automatic_cashback_percentage }})
    </template>
    <template #total_manual_cashback="{ row }">
      {{ row.total_manual_cashback }} ({{ row.total_manual_cashback_percentage }})
    </template>
  </CrudIndex>
</template>

<script>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {InformationCircleIcon} from '@heroicons/vue/solid';

export default {
  components: {
    Popover, PopoverButton, PopoverPanel, InformationCircleIcon,
  },

  props: {
    totals: {
      type: Object,
      required: true,
    },

    statistics: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
