<template>
  <CrudIndex
    title="Programs"
    edit-route-path="admin.programs.edit"
    row-route-path="admin.programs.edit"
    :columns="['ID', 'Name', 'connected_status', 'unconnected_status']"
    :actions="true"
    :data="programs.data"
    :paginator="programs.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
      {
        type: 'dropdown',
        label: 'Connection status',
        operator: 'program-connection',
        value: 'program-connection',
        multiple: false,
        items: [
          {name: 'Connected', value: 'connected'},
          {name: 'Unconnected', value: 'unconnected'},
        ],
      },
      {
        type: 'dropdown',
        label: 'Program unconnected status',
        operator: 'program-unconnected-status',
        value: 'program-unconnected-status',
        items: [
          {name: 'New', value: 'new'},
          {name: 'Not now', value: 'not_now'},
          {name: 'Won\'t promote', value: 'wont_promote'},
        ]
      },
      {
        type: 'dropdown',
        label: 'Program status',
        operator: 'equalsArray',
        value: 'status',
        items: programStatuses.map((type) => ({name: type, value: type})),
      },
      {
        type: 'dropdown',
        label: 'Reasons',
        operator: 'equalsArray',
        value: 'unconnected_reason',
        items: programUnconnectedReason.map((type) => ({name: type, value: type})),
      },
      {
        type: 'dropdown',
        label: 'Channel',
        operator: 'affiliwings-channel-type',
        value: 'affiliwings-channel-type',
        multiple: true,
        items: affiliwingsChannelTypes.map((type) => ({name: type, value: type})),
      },
    ]"
    :can-select-rows="true"
  >
    <template #selected-actions="{ selected, disabled, selectedIds }">
      <DropdownButton
        v-for="(reasons, status) in programUnconnectedReasons"
        :key="`unconnected-dropdown-${status}`"
        :items=" reasons.map(reason => ({
          name: reason,
          action: () => updateStatusForSelected(status, reason, selectedIds)
        }))"
        :classes="(disabled ? 'bg-gray-400 text-gray-800 cursor-not-allowed disabled' : 'text-gray-700 bg-white hover:bg-gray-50')"
        :disabled="disabled"
      >
        {{ status }} ({{ selected }})
      </DropdownButton>
    </template>

    <template #connected_status="{ item, row }">
      <span
        v-for="(advertiser, i) in row.advertisers"
        :key="`program-${item.id}-advertiser-${advertiser.id}-link`"
      >
        <span v-if="i > 0">
          &mdash;
        </span>

        <a
          :href="route('admin.advertisers.edit', advertiser.id)"
          class="text-indigo-600 hover:text-indigo-900"
          target="_blank"
          @click.stop
        >
          {{ advertiser.name }}
        </a>
      </span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    programs: {
      type: Object,
      required: true,
    },

    affiliwingsChannelTypes: {
      type: Array,
      required: true,
    },

    programUnconnectedReason: {
      type: Array,
      required: true,
    },

    programStatuses: {
      type: Array,
      required: true,
    },

    programUnconnectedReasons: {
      type: Object,
      required: true,
    },
  },

  methods: {
    updateStatusForSelected(status, reason, selectedIds) {
      this.$inertia.post(
        this.route('admin.api.programs.update-status-selected'), {
          program_ids: selectedIds,
          unconnected_status: status,
          unconnected_reason: reason,
        });
    },
  },
};
</script>
