<template>
  <CrudCard>
    <template #content>
      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />
      <InputField
        v-model="$store.state.form.slug"
        label="Slug"
        name="slug"
      />
      <TextareaField
        v-model="$store.state.form.description"
        label="Description"
        name="description"
        rows="5"
      />

      <SelectField
        v-model="$store.state.form.block_type"
        label="Webshop block type"
        name="block_type"
        placeholder="Select a block type"
        :items="blockTypes.map((type) => ({id: type, name: toTitleCase(type)}))"
      />

      <MultiSelectField
        v-model="$store.state.form.sort_options"
        label="Sort options"
        name="sort_options"
        mode="tags"
        :options="sortOptions.map((option) => ({label: option.name, value: option.value}))"
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.show_in_menu"
        label="Show in menu"
        name="show_in_menu"
        :required="false"
      />

      <MultiSelectField
        v-model="$store.state.form.page_types"
        label="Page types"
        name="page_types"
        placeholder="Select a page type"
        :options="pageTypes"
        mode="tags"
        :required="false"
      />

      <SelectField
        v-model="$store.state.form.country_id"
        label="Country"
        name="country_id"
        placeholder="Select a Country"
        :items="countries"
        :disabled="parseInt($page.props.selectedAdminFilterCountry) > 0"
      />

      <InputField
        v-model="$store.state.form.start_at"
        label="Start at"
        type="datetime-local"
        step="60"
        name="start_at"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.end_at"
        label="End at"
        type="datetime-local"
        step="60"
        name="end_at"
        :required="false"
      />
    </template>
  </CrudCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Media
      </span>
    </template>
    <template #content>
      <MediaField
        v-model="$store.state.form.position1_media_id"
        label="Media #1"
        name="position1_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :help="minimumMediaSize.join('x')"
        :media-key="`webshop-theme-${theme?.id}`"
        :crop-config="cropConfig"
      />
      <MediaField
        v-model="$store.state.form.position2_media_id"
        label="Media #2"
        name="position2_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :help="minimumMediaSize.join('x')"
        :media-key="`webshop-theme-${theme?.id}`"
        :crop-config="cropConfig"
      />
      <MediaField
        v-model="$store.state.form.position3_media_id"
        label="Media #3"
        name="position3_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :help="minimumMediaSize.join('x')"
        :media-key="`webshop-theme-${theme?.id}`"
        :crop-config="cropConfig"
      />
    </template>
  </CrudToggleCard>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    theme: {
      type: Object,
      required: false,
      default: null,
    },

    countries: {
      type: Object,
      required: true,
    },

    minimumMediaSize: {
      type: Array,
      required: true,
    },

    sortOptions: {
      type: Array,
      required: true,
    },

    blockTypes: {
      type: Array,
      required: true,
    },

    pageTypes: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const cropConfig = {
      enabled: true,
      minWidth: props.minimumMediaSize[0],
      minHeight: props.minimumMediaSize[1],
      aspectRatio: props.minimumMediaSize[0] / props.minimumMediaSize[1],
    };

    const form = useForm({
      name: props.theme ? props.theme.name : '',
      slug: props.theme ? props.theme.slug : '',
      description: props.theme ? props.theme.description : '',
      sort_options: props.theme?.sort_options ?? [],
      show_in_menu: props.theme ? props.theme.show_in_menu : true,
      page_types: props.theme ? props.theme.page_types : [],
      block_type: props.theme ? props.theme.block_type : null,
      position1_media_id: props.theme ? props.theme.position1_media_id : '',
      position2_media_id: props.theme ? props.theme.position2_media_id : '',
      position3_media_id: props.theme ? props.theme.position3_media_id : '',
      country_id: props.theme ? props.theme.country_id : null,
      start_at: props.theme ? props.theme.start_at : null,
      end_at: props.theme ? props.theme.end_at : null,
    });

    useStore().commit('form/setForm', form);

    return {cropConfig};
  },

  watch: {
    '$store.state.form.name': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },

    '$store.state.form.slug': function (newValue) {
      this.$store.state.form.slug = this.sluggify(newValue);
    },
  },

  mounted() {
    if (!this.theme && parseInt(this.$page.props.selectedAdminFilterCountry) > 0) {
      this.$store.state.form.country_id = this.$page.props.selectedAdminFilterCountry;
    }
  },
};
</script>
