<template>
  <CrudIndex
    title="IBAN blacklist"
    create-route-path="admin.iban-blacklist.create"
    edit-route-path="admin.iban-blacklist.edit"
    row-route-path="admin.iban-blacklist.edit"
    delete-route-path="admin.iban-blacklist.destroy"
    :columns="['ID', 'IBAN', 'Date']"
    :actions="true"
    :data="blacklists.data"
    :paginator="blacklists.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'IBAN', value: 'iban'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    blacklists: {
      type: Object,
      required: true,
    },
  },
};
</script>
