<template>
  <CrudIndex
    title="Current Balance"
    :columns="['Group', 'Members']"
    :actions="false"
    :data="balanceGroups"
  />
</template>

<script>
export default {
  props: {
    balanceGroups: {
      type: Object,
      required: true,
    },
  },
};
</script>
