<template>
  <CrudIndex
    title="Webshop Categories"
    create-route-path="admin.webshop-categories.create"
    edit-route-path="admin.webshop-categories.edit"
    row-route-path="admin.webshop-categories.edit"
    delete-route-path="admin.webshop-categories.destroy"
    :columns="['id', 'name', 'countries active']"
    :actions="true"
    :data="categories"
    :filters="[{type: 'search', fields: [{label: 'Name', value: 'name'}, {label: 'Slug', value: 'slug'}]}]"
  />
</template>

<script>
export default {
  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
};
</script>
