<template>
  <CrudIndex
    title="Affiliwings networks"
    edit-route-path="admin.affiliwings-networks.edit"
    row-route-path="admin.affiliwings-networks.edit"
    :columns="['Network name', 'Network ID', 'Channel name', 'Channel ID', 'Type', 'Average turnover approval rate', 'Notes']"
    :actions="true"
    :data="channels.data"
    :paginator="channels.meta.pagination"
    :filters="[
      {
        type: 'search',
        fields: [
          {label: 'Network name', value: 'network_name'},
          {label: 'Channel name', value: 'channel_name'},
        ]
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Type',
        value: 'type',
        multiple: true,
        items: filterableChannelTypes,
      },
    ]"
  >
    <template #network_name="{ row }">
      <span v-if="row.network_abbreviation">
        [{{ row.network_abbreviation }}] {{ row.network_name }}
      </span>
      <span v-else>
        {{ row.network_name }}
      </span>
    </template>
    <template #notes="{ item }">
      <span
        v-if="item && item.length > 0"
        class="material-icons md-18"
        :title="item"
      >
        notes
      </span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    channelTypes: {
      type: Array,
      required: true,
    },

    channels: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let filterableChannelTypes = [];
    for (const status of props.channelTypes) {
      filterableChannelTypes.push({name: status, value: status});
    }

    return {
      filterableChannelTypes: filterableChannelTypes,
    };
  },
};
</script>
