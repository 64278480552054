<template>
  <CrudCreate
    :title="`Giftcard - Upload for: ${shop.name}`"
    sub-title="Giftcards"
    :index-route="route('admin.giftcard-shops.index')"
    :store-route="route('admin.giftcard-shops.upload.store', shop.id)"
  >
    <template #form>
      <SelectField
        v-model="$store.state.form.value"
        label="Value"
        name="value"
        help="The value of the giftcard the user receives."
        placeholder="Select value"
        :items="[
          {id: 10, name: '€10,-'},
          {id: 20, name: '€20,-'},
          {id: 30, name: '€30,-'},
          {id: 40, name: '€40,-'},
          {id: 50, name: '€50,-'},
        ]"
        :required="true"
      />

      <InputField
        v-model="$store.state.form.expires_at"
        label="Expiration date"
        name="expires_at"
        type="date"
        help="Leave empty when giftcard has no expiration date."
        :required="false"
      />

      <InputField
        type="file"
        accept=".csv"
        label="File"
        name="file"
        help="The file must be CSV. The first column contains the code the second is the pin (optional)."
        class="!rounded-none !shadow-none"
        @input="$store.state.form.file = $event.target.files[0]"
      />
    </template>
  </CrudCreate>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    shop: {
      type: Object,
      required: true,
    },

    countries: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      value: null,
      expires_at: null,
      file: null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
