<template>
  <CrudEdit
    :title="webshop.name"
    sub-title="Webshops"
    :index-route="route('admin.webshops.index')"
    :update-route="route('admin.webshops.update', webshop.id)"
  >
    <template #after-cancel>
      <InertiaLink
        :href="route('admin.webshops.battle-logs', webshop.id)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Battle logs
      </InertiaLink>

      <InertiaLink
        :href="route('admin.click-actions.create', {webshop: webshop.id})"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Add click action
      </InertiaLink>
    </template>
    <template #cards>
      <WebshopForm
        :webshop="webshop"
        :webshop-categories="webshopCategories"
        :webshop-themes="webshopThemes"
        :programs="programsWithKeys()"
        :cashback-communication-types="cashbackCommunicationTypes"
        :network-ids="networkIds"
      />
    </template>
  </CrudEdit>
</template>

<script>
import WebshopForm from './Form.vue';

export default {
  components: {WebshopForm},

  props: {
    webshop: {
      type: Object,
      required: true,
    },

    webshopCategories: {
      type: Object,
      required: true,
    },

    webshopThemes: {
      type: Object,
      required: true,
    },

    programs: {
      type: Array,
      required: true,
    },

    cashbackCommunicationTypes: {
      type: Array,
      required: true,
    },

    networkIds: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.disableCountrySelector();
  },

  methods: {
    programsWithKeys() {
      let programsWithKeys = {};
      this.programs.forEach(program => programsWithKeys[program.id] = program);

      return programsWithKeys;
    },
  },
};
</script>
