<template>
  <CrudCard>
    <template #header>
      <div class="font-medium">
        General
      </div>
    </template>
    <template #content>
      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />

      <EditorField
        v-model="$store.state.form.description"
        label="Description"
        name="description"
        :required="false"
        help="Will not be translated so use country language"
      />

      <SelectField
        v-model="$store.state.form.country_id"
        label="Country"
        name="country_id"
        placeholder="Select a Country"
        :items="countries"
        :disabled="program !== null"
      />

      <SelectField
        v-model="$store.state.form.type_id"
        label="Type"
        name="type_id"
        placeholder="Select a Type"
        :items="advertiserTypes"
        :required="true"
      />
    </template>
  </CrudCard>

  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Media
      </span>
    </template>

    <template #content>
      <MediaField
        v-model="$store.state.form.logo_media_id"
        label="Logo Media"
        :help="mediaHelp('logo')"
        name="logo_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :media-key="`advertiser-${advertiser?.id}-logo`"
      />

      <MediaField
        v-model="$store.state.form.cover_media_id"
        label="Cover Media"
        :help="mediaHelp('cover')"
        name="cover_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :media-key="`advertiser-${advertiser?.id}-cover`"
        :crop-config="cropConfig('cover')"
      />

      <MediaField
        v-model="$store.state.form.rectangle_media_id"
        label="Rectangle Media"
        :help="mediaHelp('rectangle')"
        name="rectangle_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :media-key="`advertiser-${advertiser?.id}-rectangle`"
      />

      <MediaField
        v-model="$store.state.form.billboard_media_id"
        label="Billboard"
        :help="mediaHelp('billboard')"
        name="billboard_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :media-key="`advertiser-${advertiser?.id}-billboard`"
        :crop-config="cropConfig('billboard')"
      />

      <MediaField
        v-model="$store.state.form.large_leaderboard_media_id"
        label="Large leaderboard"
        :help="mediaHelp('large_leaderboard')"
        name="large_leaderboard_media_id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :media-key="`advertiser-${advertiser?.id}-large-leaderboard`"
        :crop-config="cropConfig('large_leaderboard')"
      />

      <InputField
        v-model="$store.state.form.youtube_token"
        label="Youtube video"
        name="youtube_token"
        help="Fill in the token of the Youtube video."
        :required="false"
      />

      <div>
        <p class="font-semibold">
          Temporary medias
        </p>
        <span class="text-xs">
          Will overwrite the media fields above for the given period. These will repeat each year.
        </span>
      </div>

      <div
        v-for="(temporaryMedia, index) in $store.state.form.temporary_medias"
        :key="index"
        class="grid sm:grid-cols-2 sm:gap-4"
      >
        <SelectField
          v-model="temporaryMedia.type"
          label="Type"
          name="temporary_media_type"
          placeholder="Select a Type"
          :items="mediaTypes.map((type) => ({id: type, name: toTitleCase(type)}))"
          :required="true"
          :disabled="!!temporaryMedia.media_id"
          :title="!!temporaryMedia.media_id ? 'Please remove the media first before changing the type.' : ''"
          :inline="true"
        />

        <InputField
          v-model="temporaryMedia.start"
          label="Start"
          :name="`temporary_medias.${index}.start`"
          type="datetime-local"
          :inline="true"
        />

        <InputField
          v-model="temporaryMedia.end"
          label="End"
          name="temporary_media_end"
          type="datetime-local"
          :inline="true"
        />

        <template v-if="temporaryMedia.type">
          <MediaField
            v-model="temporaryMedia.media_id"
            :label="`${toTitleCase(temporaryMedia.type)} Media`"
            :help="mediaHelp(temporaryMedia.type)"
            name="temporary_media_id"
            index-route="admin.api.media.index"
            show-route="admin.api.media.show"
            upload-route="admin.api.media.store"
            delete-route="admin.api.media.destroy"
            :media-key="`advertiser-${advertiser?.id}-${temporaryMedia.type}`"
            :inline="true"
            :crop-config="cropConfig(temporaryMedia.type)"
          />
        </template>
        <template v-else>
          <p class="py-8">
            Please select a type.
          </p>
        </template>

        <DefaultButton
          type="button"
          class="w-24"
          @click.prevent="() => $store.state.form.temporary_medias.splice(index, 1)"
        >
          Remove
        </DefaultButton>
      </div>

      <div>
        <DefaultButton
          type="button"
          @click.prevent="addTemporaryMedia"
        >
          <p class="p-1">
            Add temporary media
          </p>
        </DefaultButton>
      </div>
    </template>
  </CrudToggleCard>

  <CrudToggleCard>
    <template #header>
      <div class="font-medium">
        Extra details
      </div>
    </template>
    <template #content>
      <RadioField
        v-model="$store.state.form.cashback_allowed"
        label="Cashback allowed"
        name="cashback_allowed"
        :items="[
          {value: 'yes', name: 'Yes, all members'},
          {value: 'premium_only', name: 'Only for Premium members'},
          {value: 'no', name: 'No'},
        ]"
      />

      <SelectField
        v-model="$store.state.form.cashback_communication"
        label="Cashback communication"
        name="cashback_communication"
        placeholder="Select a Type"
        :items="selectableCashbackCommunicationTypes"
        :required="true"
      />

      <CurrencyField
        v-model="$store.state.form.minimum_commission_for_cashback"
        label="Minimum commission for cashback"
        name="minimum_commission_for_cashback"
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.is_active"
        label="Is active"
        name="is_active"
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.cashback_once"
        label="Cashback once"
        name="cashback_once"
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.show_voucher_codes"
        label="Show voucher codes"
        name="show_voucher_codes"
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.opt_in_required"
        label="Opt-in required for mailings"
        name="opt_in_required"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.booster_start_at"
        label="Booster start datetime"
        name="booster_start_at"
        type="datetime-local"
        step="60"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.booster_end_at"
        label="Booster end datetime"
        name="booster_end_at"
        type="datetime-local"
        step="60"
        :required="false"
      />

      <MultiSelectField
        ref="programs"
        v-model="$store.state.form.programs"
        label="Program(s)"
        name="programs"
        placeholder="Select one or more programs"
        mode="tags"
        :required="false"
        :can-clear="false"
        :resolve-on-load="$store.state.form.programs.length > 0"
        :min-chars="2"
        :delay="150"
        :loading="loading"
        :options="async (query) => await fetchPrograms(query)"
        @deselect="(programId, option) => deselectProgram(programId, option)"
      />

      <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Webshop
          </div>
        </div>

        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <InertiaLink
            v-if="webshop"
            :href="route('admin.webshops.edit', webshop.id)"
            class="text-indigo-600 hover:text-indigo-900"
            @click.stop
          >
            {{ webshop.name }} (#{{ webshop.id }})
          </InertiaLink>
        </div>
      </div>

      <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Free actions
          </div>
        </div>

        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <ul v-if="freeActions">
            <li
              v-for="(name, id) of freeActions"
              :key="id"
            >
              <InertiaLink
                :href="route('admin.free-actions.edit', id)"
                class="text-indigo-600 hover:text-indigo-900"
                @click.stop
              >
                {{ name }} (#{{ id }})
              </InertiaLink>
            </li>
          </ul>
          <span v-else>-</span>
        </div>
      </div>

      <InputField
        v-model="$store.state.form.communication_value"
        label="Value to communicate on platform"
        name="minimum_days_registered"
        help="Must be a number. Use a dot to make it a decimal number. Can be used to show at on online store for example."
        :required="false"
      />

      <SelectField
        v-model="$store.state.form.button_type"
        label="Button type"
        name="button_type"
        placeholder="Select a button type"
        :items="buttonTypes.map((type) => ({id: type, name: toTitleCase(type)}))"
        :required="true"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard>
    <template #header>
      <div class="font-medium">
        Segmentation & Fraud prevention
      </div>
    </template>
    <template #content>
      <InputField
        v-model="$store.state.form.minimum_days_registered"
        type="number"
        label="Min XX days member"
        name="minimum_days_registered"
        min="0"
        max="9999"
        help="Leave empty to disable this. Inclusive number."
        :required="false"
      />

      <CheckboxField
        v-model="$store.state.form.exclude_possible_cheaters"
        label="Exclude possible cheaters"
        name="exclude_possible_cheaters"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <SettingsForm
    :required="requiredSettings"
    :labels="settingLabels"
    :descriptions="settingDescriptions"
    :setting-categories="settingCategories"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {ref, unref} from 'vue';
import {useStore} from 'vuex';
import axios from 'axios';
import cookies from 'js-cookie';
import SettingsForm from '../../Components/SettingsForm.vue';
import EditorField from '../../Components/Form/EditorField.vue';

export default {
  components: {
    SettingsForm,
    EditorField,
  },

  props: {
    advertiser: {
      type: Object,
      required: false,
      default: null,
    },

    countries: {
      type: Array,
      required: true,
    },

    defaultAdvertiserType: {
      type: Number,
      required: false,
      default: null,
    },

    defaultCashbackCommunication: {
      type: String,
      required: false,
      default: null,
    },

    advertiserTypes: {
      type: Array,
      required: true,
    },

    program: {
      type: Object,
      required: false,
      default: null,
    },

    settings: {
      type: Object,
      required: true,
    },

    settingCategories: {
      type: Object,
      required: true,
    },

    requiredSettings: {
      type: Array,
      required: true,
    },

    settingLabels: {
      type: Object,
      required: true,
    },

    settingDescriptions: {
      type: Object,
      required: true,
    },

    cashbackCommunicationTypes: {
      type: Array,
      required: true,
    },

    buttonTypes: {
      type: Array,
      required: true,
    },

    webshop: {
      type: Object,
      required: false,
      default: null,
    },

    freeActions: {
      type: Object,
      required: false,
      default: null,
    },

    mediaTypes: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let name = null;
    let description = '';
    let type_id = unref(props.defaultAdvertiserType);
    let country_id = unref(cookies.get('admin_country'));
    let logo_media_id = null;
    let cover_media_id = null;
    let rectangle_media_id = null;
    let billboard_media_id = null;
    let large_leaderboard_media_id = null;
    let youtube_token = null;
    let program_ids = [];
    let cashback_allowed = 'yes';
    let cashback_communication = unref(props.defaultCashbackCommunication);
    let is_active = true; // default is active
    let cashback_once = false;
    let show_voucher_codes = true;
    let booster_start_at = null;
    let booster_end_at = null;
    let opt_in_required = false;
    let minimum_days_registered = null;
    let exclude_possible_cheaters = false;
    let communication_value = null;
    let button_type = 'default';
    let temporary_medias = [];
    let minimum_commission_for_cashback = null;

    if (props.program) {
      name = unref(props.program.name);
      country_id = unref(props.program.country_id);
      program_ids = [props.program.id];
    }

    if (props.advertiser) {
      name = unref(props.advertiser.name);
      description = unref(props.advertiser.description);
      type_id = unref(props.advertiser.type_id);
      country_id = unref(props.advertiser.country_id);
      logo_media_id = unref(props.advertiser.logo_media_id);
      cover_media_id = unref(props.advertiser.cover_media_id);
      rectangle_media_id = unref(props.advertiser.rectangle_media_id);
      billboard_media_id = unref(props.advertiser.billboard_media_id);
      large_leaderboard_media_id = unref(props.advertiser.large_leaderboard_media_id);
      youtube_token = unref(props.advertiser.youtube_token);
      program_ids = ref(props.advertiser.program_ids);
      cashback_allowed = ref(props.advertiser.cashback_allowed);
      cashback_communication = ref(props.advertiser.cashback_communication);
      is_active = ref(props.advertiser.is_active);
      cashback_once = ref(props.advertiser.cashback_once);
      show_voucher_codes = ref(props.advertiser.show_voucher_codes);
      booster_start_at = ref(props.advertiser.booster_start_at);
      booster_end_at = ref(props.advertiser.booster_end_at);
      opt_in_required = ref(props.advertiser.opt_in_required);
      minimum_days_registered = ref(props.advertiser.minimum_days_registered);
      exclude_possible_cheaters = ref(props.advertiser.exclude_possible_cheaters);
      communication_value = ref(props.advertiser.communication_value);
      button_type = ref(props.advertiser.button_type);
      temporary_medias = ref(props.advertiser.temporary_medias);
      minimum_commission_for_cashback = ref(props.advertiser.minimum_commission_for_cashback);
    }

    const form = useForm({
      name,
      description,
      country_id: country_id,
      logo_media_id: logo_media_id,
      cover_media_id: cover_media_id,
      rectangle_media_id: rectangle_media_id,
      billboard_media_id: billboard_media_id,
      large_leaderboard_media_id: large_leaderboard_media_id,
      youtube_token: youtube_token,
      programs: program_ids,
      type_id: type_id,
      cashback_allowed: cashback_allowed,
      cashback_communication: cashback_communication,
      is_active: is_active,
      cashback_once: cashback_once,
      show_voucher_codes: show_voucher_codes,
      booster_start_at: booster_start_at,
      booster_end_at: booster_end_at,
      opt_in_required: opt_in_required,
      minimum_days_registered: minimum_days_registered,
      exclude_possible_cheaters: exclude_possible_cheaters,
      communication_value: communication_value,
      button_type: button_type,
      temporary_medias: temporary_medias,
      minimum_commission_for_cashback: minimum_commission_for_cashback,

      connecting_program: props.program ? props.program.id : null,
      do_create_webshop: false,
      do_create_free_action: false,
      settings: props.settings,
    });

    useStore().commit('form/setForm', form);
  },

  data() {
    let selectableCashbackCommunicationTypes = [];
    for (const type of this.cashbackCommunicationTypes) {
      selectableCashbackCommunicationTypes.push({id: type, name: type});
    }

    return {
      loading: false,
      hideProgramWarning: null,
      selectableCashbackCommunicationTypes: selectableCashbackCommunicationTypes,
    };
  },

  methods: {
    async fetchPrograms(query) {
      this.loading = true;

      let fetchInitialSelectedPrograms = query === null;
      if (fetchInitialSelectedPrograms) {
        return await axios.get(this.route('admin.api.programs.find', {ids: this.$store.state.form.programs}))
          .then(({data}) => {
            this.loading = false;
            return data;
          });
      }

      return await axios.get(this.route('admin.api.programs.search.name', {name: query}))
        .then(({data}) => {
          this.loading = false;
          return data;
        });
    },

    deselectProgram(programId, option) {
      const canDeselectProgram = this.advertiser.promotion_action_program_ids.indexOf(parseInt(programId)) === -1; // program not used in any promotion action, can be deselected
      if (canDeselectProgram) {
        return;
      }

      this.$store.state.form.programs.push(programId);

      if (this.hideProgramWarning) {
        this.hideProgramWarning = null;
      }

      this.$page.props.errors[this.$refs.programs.name] = `Program ${option.label} is still connected to 1 or more promotion action(s).`;
      this.hideProgramWarning = setTimeout((() => delete this.$page.props.errors[this.$refs.programs.name]), 3000);
    },

    addTemporaryMedia() {
      if (!this.$store.state.form.temporary_medias) {
        this.$store.state.form.temporary_medias = [];
      }

      this.$store.state.form.temporary_medias.push({
        media_id: null,
        type: null,
        start: null,
        end: null,
      });
    },

    cropConfig(type) {
      switch (type) {
        case 'cover':
          return {
            enabled: true,
            minHeight: 244,
            minWidth: 659,
            aspectRatio: 659 / 244,
          };
        case 'billboard':
          return {
            enabled: true,
            minHeight: 250,
            minWidth: 970,
            aspectRatio: 970 / 250,
          };
        case 'large_leaderboard':
          return {
            enabled: true,
            minHeight: 90,
            minWidth: 970,
            aspectRatio: 970 / 90,
          };
        default:
          return {};
      }
    },

    mediaHelp(type) {
      switch (type) {
        case 'cover':
          return 'Minimum size: 244x659px';
        case 'billboard':
          return 'Minimum size: 970x250px';
        case 'large_leaderboard':
          return 'Minimum size: 970x90px';
        case 'rectangle':
          return '300x250';
        case 'logo':
          return 'Recommended: 300x76 72DPI';
        default:
          return '';
      }
    },
  },
};

</script>
