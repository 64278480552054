<template>
  <CrudIndex
    :title="`Referrals: #${memberId}`"
    :columns="['ID', 'Member ID', 'Country', 'Status', 'Bonus', 'Balance', 'Logins', 'Click date', 'Registration date']"
    :actions="true"
    :data="referrals.data"
    :paginator="referrals.meta.pagination"
    :filters="[
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Status',
        value: 'status',
        multiple: true,
        items: filterableReferralStatuses,
      },
    ]"
    :menu-items="[
      {name: 'Approve', action: (item) => approve(item.id), enabled: (item) => item.can_update_status},
      {name: 'Disapprove', action: (item) => disapprove(item.id), enabled: (item) => item.can_update_status},
    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.members.edit', memberId)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Member
      </InertiaLink>
    </template>

    <template #member_id="{ item }">
      <a
        :href="route('admin.members.edit', item)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ item }}
      </a>
    </template>
  </CrudIndex>
</template>

<script>

export default {
  props: {
    memberId: {
      type: Number,
      required: true,
    },

    referrals: {
      type: Object,
      required: true,
    },

    referralStatuses: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let filterableReferralStatuses = [];
    for (const status of props.referralStatuses) {
      filterableReferralStatuses.push({name: status, value: status});
    }

    return {
      filterableReferralStatuses: filterableReferralStatuses,
    };
  },

  methods: {
    approve(id) {
      if (confirm('Are you sure you want to approve this referral?')) {
        this.$inertia.patch(this.route('admin.members.referrals.update-status', [this.memberId, id]), {action: 'approve'});
      }
    },

    disapprove(id) {
      if (confirm('Are you sure you want to disapprove this referral?')) {
        this.$inertia.patch(this.route('admin.members.referrals.update-status', [this.memberId, id]), {action: 'disapprove'});
      }
    },
  },
};
</script>
