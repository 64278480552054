<template>
  <CrudIndex
    :title="title"
    :columns="['Modified at', 'Type', 'Old', 'New', 'Modified by']"
    :actions="true"
    :data="history"
  >
    <template #old="{ item }">
      <table>
        <tr
          v-for="(value, key) in item"
          :key="`old-${key}`"
        >
          <td>
            {{ key }}
          </td>
          <td class="pl-8">
            {{ value }}
          </td>
        </tr>
      </table>
    </template>

    <template #new="{ item }">
      <table>
        <tr
          v-for="(value, key) in item"
          :key="`new-${key}`"
        >
          <td>
            {{ key }}
          </td>
          <td class="pl-8">
            {{ value }}
          </td>
        </tr>
      </table>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    history: {
      type: Array,
      required: true,
    },
  },
};
</script>
