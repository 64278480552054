<template>
  <CrudCreate
    :title="`Mailing - Duplicate multiple ${mailing.name}`"
    sub-title="Mailings"
    :index-route="indexRoute"
    :store-route="route('admin.mailings.duplicate-multiple.store', mailing.id)"
    action="Duplicate"
  >
    <template #form>
      <div class="divide-y">
        <div class="flex space-x-4 py-6 bg-gray-100">
          <InputField
            :model-value="mailing.scheduled_at"
            label="Original scheduled at"
            name="original_scheduled_at"
            type="datetime-local"
            :inline="true"
            :disabled="true"
          />

          <div class="flex-1">
            <SelectField
              :model-value="mailing.percentiles"
              label="Original percentiles"
              name="original_percentiles"
              :items="percentiles.map((percentile) => ({id: percentile, name: percentile}))"
              :required="false"
              :disabled="true"
              :multiple="true"
              field-classes="h-96 float-left"
              :inline="true"
            >
              <template #input-after>
                <div
                  v-if="percentileDetails"
                  class="h-96 float-left ml-4 rounded-md border border-gray-300 w-10 text-sm leading-tight"
                >
                  <div
                    v-for="(percentile) in percentiles"
                    :key="percentile"
                    class="m-0 w-10 border-b border-gray-300"
                    :class="{
                      'bg-[#f08080]' : percentileDetails?.scheduled?.includes(percentile) && !percentileDetails?.sent?.includes(percentile),
                      'bg-[#90ee90]' : percentileDetails?.sent?.includes(percentile),
                    }"
                  >
                    {{ percentile }}
                  </div>
                </div>
              </template>
            </SelectField>
          </div>
        </div>
      </div>

      <div class="divide-y">
        <div
          v-for="(duplication, index) of $store.state.form.duplications"
          :key="`duplication_${index}`"
          class="flex space-x-4 py-6"
        >
          <InputField
            v-model="$store.state.form.duplications[index].scheduled_at"
            label="Scheduled at"
            :name="`duplications.${index}.scheduled_at`"
            type="datetime-local"
            :inline="true"
          />

          <SelectField
            v-model="$store.state.form.duplications[index].percentiles"
            label="Percentiles"
            :name="`duplications.${index}.percentiles`"
            :items="percentiles.map((percentile) => ({id: percentile, name: percentile}))"
            :required="false"
            :multiple="true"
            field-classes="h-96"
            :inline="true"
          />

          <div class="flex justify-end items-end">
            <DefaultButton
              type="button"
              classes="text-white bg-indigo-600 hover:bg-indigo-700 !ml-0"
              title="Remove duplication"
              @click="removeDuplication(index)"
            >
              <span class="material-icons md-18">delete</span>
            </DefaultButton>
          </div>
        </div>
      </div>

      <div
        class="flex justify-end"
        :class="{ 'mt-6': $store.state.form.duplications.length > 0 }"
      >
        <DefaultButton
          type="button"
          classes="text-white bg-indigo-600 hover:bg-indigo-700 !ml-0"
          title="Add duplication"
          @click="addDuplication"
        >
          <span class="material-icons md-18">add</span>
        </DefaultButton>
      </div>
    </template>
  </CrudCreate>
</template>

<script>
import {useStore} from 'vuex';
import {useForm} from '@inertiajs/vue3';

export default {
  props: {
    indexRoute: {
      type: String,
      required: true,
    },

    mailing: {
      type: Object,
      required: true,
    },

    percentileDetails: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup() {
    const form = useForm({
      duplications: [],
    });

    useStore().commit('form/setForm', form);

    return {
      percentiles: Array.from({length: 20}, (_, i) => 5 + (i * 5)),
    };
  },

  mounted() {
    this.addDuplication();
  },

  methods: {
    addDuplication() {
      this.$store.state.form.duplications.push({
        percentiles: this.mailing.percentiles,
        scheduled_at: null,
      });
    },

    removeDuplication(index) {
      this.$store.state.form.duplications.splice(index, 1);
    },
  },
};
</script>
<script setup>
</script>
