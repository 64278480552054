<template>
  <CrudEdit
    :title="`Click action ${clickAction.name}`"
    sub-title="Click actions"
    :index-route="route('admin.click-actions.index')"
    :update-route="route('admin.click-actions.update', clickAction.id)"
  >
    <template #cards>
      <ClickActionForm
        :webshop="webshop"
        :default-reward-value="defaultRewardValue"
        :max-reward-value="maxRewardValue"
        :points-conversion-ratio="pointsConversionRatio"
        :click-action="clickAction"
      />
    </template>
  </CrudEdit>
</template>

<script>
import ClickActionForm from './Form.vue';

export default {
  components: {
    ClickActionForm,
  },

  props: {
    webshop: {
      type: Object,
      required: true,
    },

    defaultRewardValue: {
      type: String,
      required: true,
    },

    maxRewardValue: {
      type: String,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },

    clickAction: {
      type: Object,
      required: true,
    },
  },
};
</script>
