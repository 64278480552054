<template>
  <SiteHead />

  <div class="h-screen flex overflow-hidden bg-white">
    <!-- Static sidebar for desktop -->
    <Sidebar
      :collapse-desktop="!desktopSidebarOpen"
      :sidebar-open="sidebarOpen"
      index-route-path="admin.index"
      logout-route-path="admin.logout"
      @closeSidebar="sidebarOpen = false"
    >
      <template #before-logo>
        <div class="invisible group-hover/sidebar:visible absolute w-6 left-60 rounded-full hover:bg-indigo-700 hover:text-white z-10 bg-white flex justify-center shadow-box">
          <button
            class="items-center"
            @click="sidebarDesktopBehaviour"
          >
            <span class="material-icons md-12">
              chevron_left
            </span>
          </button>
        </div>
      </template>

      <template #before-menu>
        <div class="shrink-0 flex p-4 mt-4 -mb-6 z-10">
          <select
            v-model="selectedCountry"
            class="block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
            name="country"
            :disabled="countrySelectorDisabled.value"
            @change="updateCountry"
          >
            <option value="0">
              No Country Selected
            </option>
            <option
              v-for="country in countries"
              :key="`admin-country-${country.key}`"
              :value="country.key"
            >
              Country: {{ country.value }}
            </option>
          </select>
        </div>
      </template>
    </Sidebar>

    <!-- Main column -->
    <div
      class="flex flex-col lg:flex-row w-0 flex-1 overflow-hidden"
    >
      <div
        class="flex relative z-10 shrink-0 flex-row lg:flex-col h-16 lg:w-16 lg:h-full bg-white border-b border-gray-200 lg:space-y-8"
        :class="{ 'lg:hidden': desktopSidebarOpen }"
      >
        <button
          class="px-4 h-full border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>

          <i
            class="far fa-bars w-6"
            aria-hidden="true"
          />
        </button>

        <button
          class="px-4 lg:px-0 lg:w-full text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 hidden lg:block"
          @click="sidebarDesktopBehaviour"
        >
          <span class="sr-only">Open sidebar</span>

          <span class="material-icons">
            chevron_right
          </span>
        </button>

        <div class="flex-1 flex lg:px-4 px-6 justify-end">
          <div class="flex items-center lg:items-end lg:pb-4">
            <!-- Profile dropdown -->
            <HeadlessMenu
              as="div"
              class="ml-3 lg:ml-0 relative"
            >
              <div>
                <HeadlessMenuButton
                  class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    src="/admin/images/mascotte.png"
                    alt=""
                  >
                </HeadlessMenuButton>
              </div>

              <HeadlessMenuItems
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none lg:left-14 lg:bottom-0"
              >
                <div class="py-1">
                  <HeadlessMenuItem>
                    <InertiaLink
                      :href="route('admin.logout')"
                      class="text-gray-700 block px-4 py-2 text-sm"
                    >
                      Logout
                    </InertiaLink>
                  </HeadlessMenuItem>
                </div>
              </HeadlessMenuItems>
            </HeadlessMenu>
          </div>
        </div>
      </div>

      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-100 px-4">
        <FlashMessages />

        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';

import {
  Menu as HeadlessMenu,
  MenuButton as HeadlessMenuButton,
  MenuItem as HeadlessMenuItem,
  MenuItems as HeadlessMenuItems,
} from '@headlessui/vue';

export default {
  components: {
    HeadlessMenu,
    HeadlessMenuButton,
    HeadlessMenuItem,
    HeadlessMenuItems,
  },

  setup() {
    const sidebarOpen = ref(true);
    const desktopSidebarOpen = ref(true);

    return {
      sidebarOpen,
      desktopSidebarOpen,
    };
  },

  computed: {
    csrfToken() {
      return this.$page.props.csrfToken;
    },

    countries() {
      return this.$page.props.adminFilterCountries;
    },
  },

  methods: {
    sidebarDesktopBehaviour() {
      this.desktopSidebarOpen = !this.desktopSidebarOpen;
    },
  },
};
</script>
