<template>
  <ErrorList />

  <CrudToggleCard>
    <template #header>
      <span class="flex items-center font-medium">
        Settings
      </span>
    </template>

    <template #content>
      <div>
        <CrudToggleCard
          v-for="category in Object.values(settingCategories).filter((value, index, self) => self.indexOf(value) === index)"
          :key="category"
        >
          <template #header>
            <span class="flex items-center font-medium">
              {{ category }}
            </span>
          </template>

          <template #content>
            <div
              v-for="(value, key) in settingsInCategory(category)"
              :key="`setting_${key}`"
            >
              <template v-if="settingCategories[key] === category">
                <InputField
                  v-model="$store.state.form.settings[key]"
                  :label="labels ? (labels[key] ?? key) : key"
                  :help="descriptions ? (descriptions[key] ?? key) : key"
                  :name="key"
                  :required="required ? required.includes(key) : false"
                >
                  <template #extra>
                    <DefaultButton
                      v-if="showCopyToClipboard"
                      title="Copy key to clipboard"
                      @click.prevent="copyToClipboard(key);"
                    >
                      <span class="material-icons md-18">content_paste_go</span>
                    </DefaultButton>
                    <DefaultButton
                      v-if="possibleValues && possibleValues.hasOwnProperty(key)"
                      title="Show possible values"
                      @click.prevent="showPossibleValues(key)"
                    >
                      <span class="material-icons md-18">list</span>
                    </DefaultButton>
                  </template>
                </InputField>
              </template>
            </div>
          </template>
        </CrudToggleCard>
      </div>
    </template>
  </CrudToggleCard>

  <Modal
    v-model:open="modalOpen"
    :items="modalItems"
  />
</template>

<script>

import {ref} from 'vue';
import Modal from './Modal.vue';

export default {
  components: { Modal },

  props: {
    required: {
      type: Array,
      required: false,
      default: null,
    },

    labels: {
      type: Object,
      required: false,
      default: null,
    },

    descriptions: {
      type: Object,
      required: false,
      default: null,
    },

    showCopyToClipboard: {
      type: Boolean,
      required: false,
      default: false,
    },

    possibleValues: {
      type: Object,
      required: false,
      default: () => {},
    },

    settingCategories: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      modalOpen: ref(false),
      modalItems: ref([]),
    };
  },

  methods: {
    async copyToClipboard(value) {
      await navigator.clipboard.writeText(value);
    },

    showPossibleValues(key) {
      this.modalItems = this.possibleValues[key];
      this.modalOpen = true;
    },

    settingsInCategory(category) {
      return Object.fromEntries(Object.entries(this.$store.state.form.settings).filter(([key]) => this.settingCategories[key] === category));
    },
  },
};
</script>
