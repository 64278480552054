import sluggify from './Helpers/sluggify';
export default {
  computed: {
    selectedCountry: {
      get() {
        return this.$page.props.selectedAdminFilterCountry;
      },

      set(value) {
        window.open(this.route(this.route().current(), {
          ...this.route().params,
          ...{adminCountryId: value},
        }), '_self');
      },
    },
  },

  methods: {
    sluggify(value) {
      return sluggify(value);
    },

    toTitleCase(string) {
      return (
        string
          .split('_')
          .map(word => {
            return word.slice(0, 1).toUpperCase() + word.slice(1);
          })
          .join(' ')
      );
    },

    hasSelectedCountry() {
      return !!this.selectedCountry;
    },

    hasNotSelectedCountry() {
      return !this.hasSelectedCountry();
    },

    safeProgramTrackingUrl(trackingUrl) {
      if (!trackingUrl) {
        return '';
      }

      for (const subId of this.$page.props.subIdPlaceholders) {
        trackingUrl = trackingUrl.replaceAll(subId, 'MM');
      }
      return trackingUrl;
    },

    /** @see \App\Transformers\Admin\CommissionModelTransformer */
    buildSelectableCommissionModels(commissionModels, selectedCommissionModels, options) {
      const isArray = typeof commissionModels === 'object' && commissionModels.constructor === Array;
      if (!isArray) {
        return [];
      }

      const defaultOptions = {
        checkCanUse: true,
      };
      options = options ?? defaultOptions;
      options.checkCanUse = options.checkCanUse ?? defaultOptions.checkCanUse;

      return commissionModels.map(commissionModel => {
        const isSelected = selectedCommissionModels && selectedCommissionModels.indexOf(commissionModel.id) >= 0;

        let helpText = `${commissionModel.fixed} - ${commissionModel.percentage} (cashback: ${commissionModel.cashback})`;
        let groupedTags = {};
        for (const tag of commissionModel.tags ?? []) {
          if (!groupedTags[tag.category_description]) {
            groupedTags[tag.category_description] = [];
          }

          groupedTags[tag.category_description].push(tag.description);
        }

        for (const [tagCategory, tags] of Object.entries(groupedTags)) {
          helpText += `<br />${tagCategory} tags: ` + tags.join(', ');
        }

        if (commissionModel.manual_description) {
          helpText += `<br />Description: ${commissionModel.manual_description}`;
        }

        let item = {
          key: commissionModel.id,
          value: isSelected,
          label: commissionModel.description, // due to min-max, we must show the original description, otherwise all items would show "min" or "max"
          help: helpText,
        };

        if (options.checkCanUse) {
          item.disabled = !commissionModel.can_use;
          item.info_text = commissionModel.info_text;
        }

        return item;
      });
    },
  },
};
