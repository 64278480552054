<template>
  <div class="flex items-center">
    <span
      v-if="canBeChanged"
      class="text-indigo-600 hover:text-indigo-900"
      @click.stop="openModal()"
    >
      {{ scheduledAt }}
    </span>

    <span v-else>{{ scheduledAt }}</span>
  </div>

  <ModalForm
    :id="`mailing_scheduled_at_form_${mailingId}`"
    v-model:open="modalOpen"
    :submit-route="route('admin.mailings.update-scheduled-at', mailingId)"
  >
    <template #fields>
      <InputField
        v-model="newScheduledAt"
        label="Scheduled at"
        name="scheduled_at"
        type="datetime-local"
        :required="true"
      />
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from '../ModalForm.vue';

export default {
  components: {
    ModalForm,
  },

  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    scheduledAt: {
      type: String,
      required: true,
    },

    canBeChanged: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
      newScheduledAt: this.scheduledAt,
    };
  },

  methods: {
    openModal() {
      this.modalOpen = true;
    },
  },
};
</script>
