<template>
  <div class="flex items-center">
    <DefaultButton
      classes="px-2 py-0 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 border-none ml-1"
      :class="{
        '!cursor-not-allowed disabled' : !allowedStatuses.includes(status),
        '!bg-[#4cb7ff]' : status === 'advertiser_check',
        '!bg-[#f7a32b]' : status === 'first_internal_check',
        '!bg-[#9c65e5]' : status === 'final_internal_check',
        '!bg-[#1cdc1c]' : status === 'ready',
        '!bg-[#59d38c]' : status === 'starting' || status === 'busy',
        '!bg-[#926628]' : status === 'sent',
        '!bg-[#f7501c]' : status === 'hold' || status === 'disapproved',
        '!bg-gradient-to-r !from-[#e73827] !from-0% !via-[#000000] !via-40% !to-[#e73827] !to-100% !text-white' : status === 'crashed',
      }"
      @click.stop="allowedStatuses.includes(status) && openStatusChangeModal()"
    >
      {{ status.replaceAll('_', ' ') }}
    </DefaultButton>

    <Popover
      v-if="status === 'hold' || status === 'crashed'"
      class="h-5 w-5 ml-1"
    >
      <PopoverButton class="inline-block">
        <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
      </PopoverButton>
      <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
        <p class="w-full">
          {{ holdReason }}
        </p>
      </PopoverPanel>
    </Popover>

    <Popover
      v-if="isStopped"
      class="h-5 w-5 ml-1"
    >
      <PopoverButton class="inline-block">
        <span class="material-icons md-18 text-red-400">cancel</span>
      </PopoverButton>
      <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
        <p class="w-full">
          Mailing is stopped
        </p>
      </PopoverPanel>
    </Popover>

    <Popover
      v-if="overruleMailingId"
      class="h-5 w-5 ml-1"
    >
      <PopoverButton class="inline-block">
        <span class="material-icons md-18 text-red-500">link_off</span>
      </PopoverButton>
      <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4 break-words">
        <p class="w-full">
          Has overrule mailing:
          <a
            :href="route('admin.mailings.edit', overruleMailingId)"
            class="text-indigo-600 hover:text-indigo-900"
            target="_blank"
            @click.stop
          >
            #{{ overruleMailingId }}
          </a>
        </p>
      </PopoverPanel>
    </Popover>
  </div>

  <ModalForm
    :id="`mailing_status_form_${mailingId}`"
    v-model:open="modalOpen"
    :submit-handler="submitStatusChange"
  >
    <template #fields>
      <SelectField
        v-model="selectedStatus"
        label="Status"
        name="status"
        placeholder="Select a status"
        :items="allowedStatuses.map((value) => ({id: value, name: value}))"
        :required="true"
      />

      <div
        v-if="selectedStatus === 'ready'"
        class="grid space-y-4"
      >
        <div class="grid grid-cols-5">
          <p>
            Tracking link:
          </p>
          <a
            :href="trackingUrl"
            target="_blank"
            class="col-span-4 text-blue-500 underline hover:no-underline"
          >
            {{ trackingUrl }}
          </a>
        </div>
        <div class="grid grid-cols-5">
          <p>
            Pre-header:
          </p>
          <p class="col-span-4">
            {{ preHeaderText ?? '-' }}
          </p>
        </div>
        <div class="grid grid-cols-5">
          <p>
            Subject:
          </p>
          <p class="col-span-4">
            {{ subject }}
          </p>
        </div>
        <div class="grid grid-cols-5">
          <p>
            Filter:
          </p>
          <p class="col-span-4">
            {{ mailingFilterName ?? '-' }}
          </p>
        </div>
        <div class="grid grid-cols-5">
          <p>
            Percentiles:
          </p>
          <p class="col-span-4">
            {{ percentiles?.join(', ') ?? '-' }}
          </p>
        </div>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import {InformationCircleIcon} from '@heroicons/vue/solid';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import ModalForm from '../ModalForm.vue';

export default {
  components: {
    InformationCircleIcon,
    Popover, PopoverButton, PopoverPanel,
    ModalForm,
  },

  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    status: {
      type: String,
      required: true,
    },

    holdReason: {
      type: String,
      required: false,
      default: null,
    },

    isStopped: {
      type: Boolean,
      required: false,
      default: false,
    },

    overruleMailingId: {
      type: Number,
      required: false,
      default: null,
    },

    allowedStatuses: {
      type: Array,
      required: true,
    },

    trackingUrl: {
      type: String,
      required: true,
    },

    preHeaderText: {
      type: String,
      required: false,
      default: null,
    },

    subject: {
      type: String,
      required: true,
    },

    mailingFilterName: {
      type: [String, null],
      required: false,
      default: null,
    },

    percentiles: {
      type: [Array, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      modalOpen: false,
      selectedStatus: this.status,
    };
  },

  methods: {
    openStatusChangeModal() {
      this.modalOpen = true;
    },

    submitStatusChange(submitData) {
      if (this.selectedStatus !== 'ready'
        || confirm('Are you sure you want to move this mailing to ready? '
          + '\nPlease check if all fields are entered correctly.')
      ) {
        this.submitStatusChangeForm(submitData);
        return;
      }

      submitData.error();
    },

    submitStatusChangeForm(submitData) {
      this.$inertia.post(route('admin.mailings.update-status', this.mailingId), {
        status: this.selectedStatus,
      }, {
        preserveState: true,
        preserveScroll: true,

        onSuccess: () => {
          submitData.success();
        },

        onError: () => {
          submitData.error();
        },
      });
    },
  },
};
</script>
