<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <CrudIndex
    v-else
    title="Member activity"
    :columns="['Metric', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10-24', '25-49', '50+']"
    :actions="false"
    :data="stats"
    :filters="[
      {
        type: 'relation',
        label: 'Referred By',
        value: 'members.referred_by',
        multiple: true,
        api: route('admin.api.referrals.referrers.filter'),
      },
      {
        type: 'date-range',
        label: 'Date',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Created at', value: 'members.created_at'}],
      },
    ]"
    :no-results-found-text="null"
  >
    <template #header>
      <div class="font-medium">
        <div>Total users in filter in database: {{ totalMembers.database }}</div>
        <div>Total users in filter in elasticsearch: {{ totalMembers.elasticsearch }}</div>
      </div>
    </template>

    <template
      v-if="isLoading"
      #tr
    >
      <tr>
        <td
          class="px-6 py-4 text-center"
          colspan="14"
        >
          <div class="text-center">
            <i class="far fa-spin fa-spinner text-xl" />
          </div>
        </td>
      </tr>
    </template>
  </CrudIndex>

  <div class="bg-white border border-gray-200 rounded-lg shadow-sm p-4">
    * = Comes from Elasticsearch. It can take up to a day for this to be renewed.
  </div>
</template>

<script>

export default {
  props: {
    totalMembers: {
      type: Object,
      required: true,
    },

    stats: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  mounted() {
    this.isLoading = true;

    this.$inertia.reload({
      only: ['stats'],
      onSuccess: () => {
        this.isLoading = false;
      },
    });
  },
};
</script>
