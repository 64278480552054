<template>
  <CrudIndex
    title="Countries"
    create-route-path="admin.countries.create"
    edit-route-path="admin.countries.edit"
    row-route-path="admin.countries.edit"
    delete-route-path="admin.countries.destroy"
    :columns="['ID', 'Is Default', 'Name', 'Code', 'ISO', 'Currency', 'Language']"
    :actions="true"
    :data="countries.data"
    :paginator="countries.meta.pagination"
  >
    <template #currency="{ row }">
      {{ row.currency.name }} ({{ row.currency.symbol }})
    </template>

    <template #language="{ row }">
      {{ row.language.name }} ({{ row.language.iso }})
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    countries: {
      type: Object,
      required: true,
    },
  },
};
</script>
