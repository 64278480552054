<template>
  <CrudIndex
    title="Active Puzzles"
    create-route-path="admin.puzzles.create"
    edit-route-path="admin.puzzles.edit"
    row-route-path="admin.puzzles.edit"
    :columns="['ID', 'Country', 'Type', 'Title', '# of participants', '% of correct answers', 'Poll result (easy, challenging, difficult)', 'Start at', 'Finished at']"
    :actions="true"
    :data="activePuzzles.data"
    :paginator="activePuzzles.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Title', value: 'title'}]},
    ]"
  >
    <template #country="{ row }">
      {{ row.country.code }}
    </template>

    <template ##_of_participants="{ row }">
      {{ row.participants_count }}
    </template>

    <template #%_of_correct_answers="{ row }">
      <span class="text-green-500">{{ row.answer_results.correct }}%</span>
      /
      <span class="text-red-500">{{ row.answer_results.incorrect }}%</span>
    </template>

    <template #poll_result_(easy,_challenging,_difficult)="{ row }">
      <span class="text-green-500">{{ row.poll_results.easy }}%</span>
      /
      <span class="text-yellow-500">{{ row.poll_results.challenging }}%</span>
      /
      <span class="text-red-500">{{ row.poll_results.difficult }}%</span>
    </template>

    <template #type="{ row }">
      {{ row.type_label }}
    </template>
  </CrudIndex>

  <CrudIndex
    title="Pending Puzzles"
    edit-route-path="admin.puzzles.edit"
    row-route-path="admin.puzzles.edit"
    delete-route-path="admin.puzzles.destroy"
    :columns="['ID', 'Country', 'Type', 'Title', '# of participants', '% of correct answers', 'Poll result (easy, challenging, difficult)', 'Start at', 'Finished at']"
    :actions="true"
    :data="pendingPuzzles.data"
    :paginator="pendingPuzzles.meta.pagination"
  >
    <template #country="{ row }">
      {{ row.country.code }}
    </template>

    <template ##_of_participants>
      -
    </template>

    <template #%_of_correct_answers>
      -
    </template>

    <template #poll_result_(easy,_challenging,_difficult)>
      -
    </template>

    <template #type="{ row }">
      {{ row.type_label }}
    </template>
  </CrudIndex>

  <CrudIndex
    title="Finished Puzzles"
    row-route-path="admin.puzzles.show"
    delete-route-path="admin.puzzles.destroy"
    :columns="['ID', 'Country', 'Type', 'Title', '# of participants', '% of correct answers', 'Poll result (easy, challenging, difficult)', 'Start at', 'Finished at']"
    :menu-items="[
      {name: 'Show', url: (item) => route('admin.puzzles.show', item.id), enabled: true},
    ]"
    :actions="true"
    :data="finishedPuzzles.data"
    :paginator="finishedPuzzles.meta.pagination"
  >
    <template #country="{ row }">
      {{ row.country.code }}
    </template>

    <template ##_of_participants="{ row }">
      {{ row.participants_count }}
    </template>

    <template #%_of_correct_answers="{ row }">
      <span class="text-green-500">{{ row.answer_results.correct }}%</span>
      /
      <span class="text-red-500">{{ row.answer_results.incorrect }}%</span>
    </template>

    <template #poll_result_(easy,_challenging,_difficult)="{ row }">
      <span class="text-green-500">{{ row.poll_results.easy }}%</span>
      /
      <span class="text-yellow-500">{{ row.poll_results.challenging }}%</span>
      /
      <span class="text-red-500">{{ row.poll_results.difficult }}%</span>
    </template>

    <template #type="{ row }">
      {{ row.type_label }}
    </template>
  </CrudIndex>

  <SiteHead
    title="Puzzles"
  />
</template>

<script>
export default {
  props: {
    activePuzzles: {
      type: Object,
      required: true,
    },

    pendingPuzzles: {
      type: Object,
      required: true,
    },

    finishedPuzzles: {
      type: Object,
      required: true,
    },
  },
};
</script>
