<template>
  <template v-if="advertisers.length > 0">
    <table class="min-w-full divide-y divide-gray-200 ml-4">
      <thead>
        <tr>
          <th
            v-for="column in ['Name', 'Clicks', 'ECPC', 'Revenue', 'Approved', 'Pending', 'Disapproved', 'Timeout']"
            :key="column"
            class="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(advertiser, i) in advertisers"
          :key="`advertiser-${i}`"
        >
          <tr>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.advertiser_name }}
            </td>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.clicks }} ({{ advertiser.clicks_percentage }})
            </td>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.ecpc }}
            </td>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.revenue }} - {{ advertiser.revenue_count }}
            </td>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.approved }} - {{ advertiser.approved_count }} ({{ advertiser.approved_percentage }})
            </td>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.pending }} - {{ advertiser.pending_count }} ({{ advertiser.pending_percentage }})
            </td>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.disapproved }} - {{ advertiser.disapproved_count }} ({{ advertiser.disapproved_percentage }})
            </td>
            <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
              {{ advertiser.timeout }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div class="ml-4">
      <hr>
    </div>
  </template>

  <table class="min-w-full divide-y divide-gray-200 ml-4">
    <thead>
      <tr>
        <th
          v-for="column in ['Revenue', 'Approved', 'Pending', 'Disapproved', 'Timeout']"
          :key="column"
          class="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {{ column }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
          {{ total.revenue }} - {{ total.revenue_count }}
        </td>
        <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
          {{ total.approved }} - {{ total.approved_count }} ({{ total.approved_percentage }})
        </td>
        <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
          {{ total.pending }} - {{ total.pending_count }} ({{ total.pending_percentage }})
        </td>
        <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
          {{ total.disapproved }} - {{ total.disapproved_count }} ({{ total.disapproved_percentage }})
        </td>
        <td class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top">
          {{ total.timeout }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    advertisers: {
      type: Array,
      required: true,
    },

    total: {
      type: Object,
      required: true,
    },
  },
};
</script>
