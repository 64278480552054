<template>
  <CrudEdit
    :title="puzzle.title"
    sub-title="Puzzles"
    :index-route="route('admin.puzzles.index')"
    :update-route="route('admin.puzzles.update', puzzle.id)"
  >
    <template #cards>
      <PuzzleForm
        :countries="countries"
        :types="types"
        :puzzle="puzzle"
      />
    </template>
  </CrudEdit>
</template>

<script>
import PuzzleForm from './Form.vue';

export default {
  components: {PuzzleForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },

    types: {
      type: Array,
      required: true,
    },

    puzzle: {
      type: Object,
      required: true,
    },
  },
};
</script>
