<template>
  <CrudCreate
    title="Manual Networks - Create"
    sub-title="Manual Networks"
    :index-route="route('admin.network-revenue-statistics.index')"
    :store-route="route('admin.network-revenue-statistics.manual-networks.store')"
  >
    <template #cards>
      <ManualNetworkForm :countries="countries" />
    </template>
  </CrudCreate>
</template>

<script>
import ManualNetworkForm from './Form.vue';

export default {
  components: {ManualNetworkForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },
  },
};
</script>
