<template>
  <CrudCreate
    title="Survey - Create"
    sub-title="Survey"
    :index-route="route('admin.surveys.index')"
    :store-route="route('admin.surveys.store')"
  >
    <template #form>
      <SurveyForm
        :types="types"
        :country-currency="countryCurrency"
        :lead-price-currency="leadPriceCurrency"
        :points-conversion-ratio="pointsConversionRatio"
        :selected-country-id="selectedCountryId"
      />
    </template>
  </CrudCreate>
</template>

<script>
import SurveyForm from './Form.vue';

export default {
  components: {
    SurveyForm,
  },

  props: {
    types: {
      type: Array,
      required: true,
    },

    selectedCountryId: {
      type: Number,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    leadPriceCurrency: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },
};
</script>
