<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <div v-else>
    <CrudIndex
      title="Webshop Themes"
      create-route-path="admin.webshop-themes.create"
      edit-route-path="admin.webshop-themes.edit"
      row-route-path="admin.webshop-themes.edit"
      delete-route-path="admin.webshop-themes.destroy"
      :columns="['id', 'country', 'name', 'start_at', 'end_at', 'webshops_count']"
      :actions="true"
      :data="themes"
      :filters="[{type: 'search', fields: [{label: 'Name', value: 'name'}, {label: 'Slug', value: 'slug'}]}]"
      :order-columns="{
        'id': 'id',
      }"
      :order="order"
    >
      <template #webshops_count="{ row }">
        <template v-if="!isNaN(parseInt(row.webshops_count))">
          <InertiaLink
            :href="route('admin.webshop-themes.webshops.index', row.id)"
            class="text-indigo-600 hover:text-indigo-900"
            @click.stop
          >
            {{ row.webshops_count }}
          </InertiaLink>
        </template>
        <template v-else>
          {{ row.webshops_count }}
        </template>
      </template>
    </CrudIndex>
  </div>
</template>

<script>
export default {
  props: {
    themes: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
