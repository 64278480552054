<template>
  <CrudEdit
    title="Tag Category"
    sub-title="Tag Category"
    :index-route="route('admin.tag-categories.index')"
    :update-route="route('admin.tag-categories.update', category.id)"
  >
    <template #form>
      <CategoryForm :category="category" />
    </template>
  </CrudEdit>
</template>

<script>
import CategoryForm from './Form.vue';

export default {
  components: {CategoryForm},

  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>
