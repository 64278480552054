<template>
  <CrudEdit
    :title="program.name"
    sub-title="Programs"
    action="Connect"
    :index-route="route('admin.programs.edit', program.id)"
    :update-route="route('admin.programs.connect-advertiser', program.id)"
  >
    <template #form>
      <MultiSelectField
        ref="advertiser"
        v-model="$store.state.form.advertiser_id"
        label="Advertiser"
        name="advertiser_id"
        placeholder="Create new advertiser (leave blank)"
        mode="single"
        :searchable="true"
        :can-deselect="false"
        :can-clear="true"
        :min-chars="2"
        :delay="150"
        :loading="loading"
        :options="async (query) => await fetchAdvertisers(query)"
        :required="false"
      />
    </template>
  </CrudEdit>

  <template v-if="loadingWebshop">
    <div class="text-center">
      <i class="far fa-spin fa-spinner text-xl" />
    </div>
  </template>
  <template v-else>
    <CrudToggleCard
      v-if="webshop"
      :key="`webshop-${webshop.id}`"
    >
      <template #header>
        <label
          class="flex items-center"
          @click.stop
        >
          <input
            v-model="$store.state.form.webshop_ids"
            type="checkbox"
            class="h-5 w-5 mr-3 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            :value="webshop.id"
          >
          {{ webshop.name }}
        </label>
      </template>
      <template #content>
        <MultiCheckboxField
          v-model="$store.state.form.commission_model_ids[webshop.id]"
          label="Commission Models"
          :name="`commission_model_ids.${webshop.id}`"
          :required="false"
          :options="webshop.selectableCommissionModels"
        />
      </template>
    </CrudToggleCard>
  </template>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import axios from 'axios';

export default {
  props: {
    program: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      advertiser_id: 0,
      webshop_ids: [],
      commission_model_ids: {},
    });

    useStore().commit('form/setForm', form);
  },

  data() {
    return {
      loading: false,
      loadingWebshop: false,

      already_connected_advertisers: this.program.advertisers.map(advertiser => advertiser.id),
      webshop: null,
    };
  },

  watch: {
    '$store.state.form.advertiser_id': function() { this.loadWebshopForAdvertiser(); },
  },

  mounted() {
    this.disableCountrySelector();
  },

  methods: {
    async fetchAdvertisers(query) {
      this.loading = true;

      let searchForName = query;
      if (searchForName === null) {
        searchForName = this.program.name;
      }

      return await axios.get(this.route('admin.api.advertisers.search.name-by-program', {program: this.program.id, name: searchForName}))
        .then(({data}) => {
          this.loading = false;

          return Object.keys(data)
            .filter(id => !this.already_connected_advertisers.includes(parseInt(id)))
            .reduce((advertiser, id) => {
              advertiser[id] = data[id];
              return advertiser;
            }, {});
        });
    },

    loadWebshopForAdvertiser() {
      this.webshop = null;

      if (!this.$store.state.form.advertiser_id) {
        return;
      }

      this.loadingWebshop = true;

      axios.get(this.route('admin.api.advertisers.webshop', {advertiser: this.$store.state.form.advertiser_id}))
        .then(({data}) => {
          this.webshop = data;

          if (this.webshop) {
            this.$store.state.form.commission_model_ids[this.webshop.id] = [];
            for (const commissionModel of this.program.commission_models) {
              if (commissionModel.can_connect) {
                this.$store.state.form.commission_model_ids[this.webshop.id].push(commissionModel.id);
              }
            }

            const selectableCommissionModels = this.$store.state.form.commission_model_ids[this.webshop.id] ?? [];
            this.webshop.selectableCommissionModels = this.buildSelectableCommissionModels(
              this.program.commission_models,
              selectableCommissionModels,
            );
          }

          this.loadingWebshop = false;
        });
    },
  },
};
</script>
