<template>
  <span>
    {{ actionType }}

    <a
      :href="actionUrl"
      class="text-indigo-600 hover:text-indigo-900"
      target="_blank"
      @click.stop
    >
      {{ actionName }}
    </a>
  </span>
</template>

<script>
export default {
  props: {
    actionType: {
      type: String,
      required: true,
    },

    actionName: {
      type: String,
      required: true,
    },

    actionUrl: {
      type: String,
      required: true,
    },
  },
};
</script>
