<template>
  <div class="grid gap-1 md:gap-4 items-start sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
    <div class="col-span-2">
      <div class="flex justify-between sm:justify-start">
        <label class="block font-medium text-gray-700 mt-px pt-2">
          &nbsp;
        </label>
      </div>
    </div>
    <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
      <div class="relative space-y-8">
        <div
          v-for="(_, index) in $store.state.form.commission_models"
          :key="`commission_model-${index}`"
          class="flex items-start flex-col"
        >
          <input
            type="hidden"
            :name="`commission_models.${index}.id`"
            :value="$store.state.form.commission_models[index].id"
          >
          <div
            class="w-full flex-row inline-flex"
          >
            <InputField
              v-model="$store.state.form.commission_models[index].description"
              :name="`commission_models.${index}.description`"
              label="Description"
              class="w-full"
              :inline="true"
            />

            <DefaultButton
              type="button"
              classes="text-white bg-indigo-600 hover:bg-indigo-700 h-full mt-6"
              title="Remove commission model"
              @click="removeCommissionModel(index)"
            >
              <i class="fas fa-minus" />
            </DefaultButton>
          </div>

          <CurrencyField
            ref="percentage"
            v-model="$store.state.form.commission_models[index].percentage"
            v-model:options="percentageFieldOptions"
            :name="`commission_models.${index}.percentage`"
            label="Percentage"
            class="w-full grow mt-1"
            :inline="true"
            :required="false"
          />

          <CurrencyField
            ref="fixed_value"
            v-model="$store.state.form.commission_models[index].fixed_value"
            v-model:options="fixedFieldOptions"
            :name="`commission_models.${index}.fixed_value`"
            label="Fixed"
            class="w-full grow mt-1"
            :inline="true"
            :required="false"
          />
        </div>

        <div
          class="flex justify-end"
          :class="{ 'mt-6': $store.state.form.commission_models.length > 0 }"
        >
          <DefaultButton
            :disabled="!$store.state.form.currency_iso"
            :class="{
              'opacity-50' : !$store.state.form.currency_iso,
            }"
            type="button"
            classes="text-white bg-indigo-600 hover:bg-indigo-700 !ml-0"
            title="Add condition"
            @click="addCommissionModel"
          >
            <i class="fas fa-plus" />
          </DefaultButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const percentageFieldOptions = {
      currency: this.$store.state.form.currency_iso,
      precision: {min: 2, max: 3},
      valueRange: {min: 0, max: 100},
      currencyDisplay: 'hidden',
    };
    const fixedFieldOptions = {
      currency: this.$store.state.form.currency_iso,
      precision: {min: 2, max: 3},
      valueRange: {min: 0, max: 1000},
    };

    return {
      percentageFieldOptions: percentageFieldOptions,
      fixedFieldOptions: fixedFieldOptions,
    };
  },

  watch: {
    '$store.state.form.currency_iso': function (newIso) {
      this.fixedFieldOptions.currency = newIso;
      this.percentageFieldOptions.currency = newIso;

      for (const ref of this.$refs.percentage ?? []) {
        ref.options.currency = newIso;
      }
      for (const ref of this.$refs.fixed_value ?? []) {
        ref.options.currency = newIso;
      }
    },
  },

  methods: {
    addCommissionModel() {
      this.$store.state.form.commission_models.push({
        id: null,
        description: '',
        percentage: '0.000',
        fixed_value: '0.000',
      });
    },

    removeCommissionModel(index) {
      this.$store.state.form.commission_models.splice(index, 1);
    },
  },
};
</script>
