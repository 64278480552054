<template>
  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
  />
  <InputField
    v-model="$store.state.form.subject"
    label="Subject"
    name="subject"
  />
  <InputField
    v-if="$store.state.form.subject"
    v-model="$store.state.form.slug"
    label="Slug"
    name="slug"
  />
  <MediaField
    v-model="$store.state.form.header_media_id"
    label="Header image"
    name="header_media_id"
    index-route="admin.api.media.index"
    show-route="admin.api.media.show"
    upload-route="admin.api.media.store"
    delete-route="admin.api.media.destroy"
    :required="true"
    media-key="blog-header"
  />
  <InputField
    v-model="$store.state.form.pre_header"
    label="Pre header"
    name="pre_header"
  />
  <CheckboxField
    v-model="$store.state.form.is_enabled"
    label="Enabled"
    name="is_enabled"
    :required="false"
  />
  <InputField
    v-model="$store.state.form.active_from"
    :required="false"
    label="Active from"
    name="active_from"
    type="datetime-local"
  />
  <InputField
    v-model="$store.state.form.active_until"
    :required="false"
    label="Active until"
    name="active_until"
    type="datetime-local"
  />
  <CheckboxField
    v-model="$store.state.form.is_pinned"
    label="Pinned"
    name="is_pinned"
    :required="false"
  />
  <TextareaField
    v-model="$store.state.form.meta_description"
    label="Meta description"
    name="meta_description"
    :required="false"
    help="Description for search engines."
  />
  <MultiSelectField
    v-model="$store.state.form.blogTags"
    label="Tags"
    name="blogTags"
    placeholder="Select tags"
    mode="tags"
    :create-tag="true"
    :options="props.tags.reduce((acc, {name}) => ({...acc, [name]: name}), {})"
    :required="false"
  />
</template>

<script setup>
import {defineProps, watch} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import sluggify from '../../Helpers/sluggify';

const props = defineProps({
  tags: {
    type: Object,
    required: true,
  },
});

const form = useForm({
  name: null,
  subject: null,
  slug: null,
  header_media_id: null,
  pre_header: null,
  is_enabled: false,
  active_from: null,
  active_until: null,
  is_pinned: false,
  meta_description: null,
  blogTags: [],
  blog_media_ids: [],
});

const store = useStore();

watch(() => store.state.form.subject, (value) => {
  store.commit('form/setForm', {slug: sluggify(value)});
});

useStore().commit('form/setForm', form);
</script>
