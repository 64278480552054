<template>
  <CrudCreate
    title="Program Term Item - Create"
    :sub-title="category.name"
    :index-route="route('admin.term-categories.show', [category.id])"
    :store-route="route('admin.term-categories.term-item.store', [category.id])"
  >
    <template #form>
      <ErrorList :fields="['description']" />

      <ItemForm />
    </template>
  </CrudCreate>
</template>

<script>

import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import ItemForm from './Form.vue';

export default {
  components: {
    ItemForm,
  },

  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      description: '',
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
