<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Clickout
      </span>

      <span
        class="text-xs text-gray-400"
      >
        <a
          :href="route('admin.members.clickouts.show', [clickout.member_id, clickout.id])"
          class="text-indigo-600 hover:text-indigo-900"
          target="_blank"
          @click.stop
        >
          #{{ clickout.id }}
        </a>
      </span>
    </template>

    <template #content>
      <CrudShowContent
        :rows="['ID', 'Click id', 'URL', 'Promotion action', 'Program', 'Cashback percentage', 'Cashback fixed', 'IP address', 'User agent', 'Created at']"
        :data="clickout"
      >
        <template #promotion_action="{ data }">
          <PromotionActionLink
            :action-type="data.promotion_type"
            :action-name="data.promotion_action_name"
            :action-url="data.promotion_action_url"
          />
        </template>

        <template #program="{ data }">
          <a
            :href="route('admin.programs.edit', data.program_id)"
            class="text-indigo-600 hover:text-indigo-900"
            target="_blank"
            @click.stop
          >
            {{ data.program_name }}
          </a>
        </template>
      </CrudShowContent>
    </template>
  </CrudToggleCard>
</template>

<script>
import PromotionActionLink from './PromotionActionLink.vue';

export default {
  components: {PromotionActionLink},
  props: {
    clickout: {
      type: Object,
      required: true,
    },
  },
};
</script>
