<template>
  <CrudIndex
    title="Giftcard shops"
    create-route-path="admin.giftcard-shops.create"
    edit-route-path="admin.giftcard-shops.edit"
    row-route-path="admin.giftcard-shops.edit"
    delete-route-path="admin.giftcard-shops.destroy"
    :columns="['ID', 'Enabled', 'Name', 'Country', 'Amount']"
    :actions="true"
    :menu-items="[
      {name: 'Upload', url: (item) => route('admin.giftcard-shops.upload', {giftcard_shop: item.id}), enabled: true},
      {name: 'Preview Mail', url: (item) => item.previewMailUrl, target: '_blank', enabled: true},
    ]"
    :data="shops.data"
    :paginator="shops.meta.pagination"
  >
    <template #enabled="{ row }">
      <span
        v-if="row.is_enabled"
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        Yes
      </span>
      <span
        v-else
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        No
      </span>
    </template>

    <template #amount="{ row }">
      <ul>
        <li
          v-for="(amount, value) of row.giftcardAmount"
          :key="value"
          :class="{
            'text-red-500': amount <= 10,
            'text-yellow-500': amount <= 25,
          }"
        >
          {{ value }}: {{ amount }}
        </li>
      </ul>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    shops: {
      type: Object,
      required: true,
    },
  },
};
</script>
