<template>
  <CrudEdit
    :title="advertiser.name"
    sub-title="Advertisers"
    :index-route="route('admin.advertisers.index')"
    :update-route="route('admin.advertisers.update', advertiser.id)"
  >
    <template #after-cancel>
      <InertiaLink
        :href="route('admin.advertisers.mailings.index', {advertiser: advertiser.id})"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Mailings
      </InertiaLink>

      <InertiaLink
        :href="route('admin.mailbanners.index', {advertiserId: advertiser.id})"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Mailbanners
      </InertiaLink>
    </template>

    <template #cards>
      <AdvertiserForm
        :advertiser="advertiser"
        :countries="countries"
        :default-advertiser-type="defaultAdvertiserType"
        :advertiser-types="advertiserTypes"
        :settings="settings"
        :setting-categories="settingCategories"
        :required-settings="requiredSettings"
        :setting-labels="settingLabels"
        :setting-descriptions="settingDescriptions"
        :cashback-communication-types="cashbackCommunicationTypes"
        :button-types="buttonTypes"
        :webshop="webshop"
        :free-actions="freeActions"
        :media-types="mediaTypes"
      />
    </template>
  </CrudEdit>
</template>

<script>
import AdvertiserForm from './Form.vue';

export default {
  components: {
    AdvertiserForm,
  },

  props: {
    advertiser: {
      type: Object,
      required: true,
    },

    countries: {
      type: Array,
      required: true,
    },

    defaultAdvertiserType: {
      type: Number,
      required: false,
      default: null,
    },

    advertiserTypes: {
      type: Array,
      required: true,
    },

    settings: {
      type: Object,
      required: true,
    },

    settingCategories: {
      type: Object,
      required: true,
    },

    requiredSettings: {
      type: Array,
      required: true,
    },

    settingLabels: {
      type: Object,
      required: true,
    },

    settingDescriptions: {
      type: Object,
      required: true,
    },

    cashbackCommunicationTypes: {
      type: Array,
      required: true,
    },

    buttonTypes: {
      type: Array,
      required: true,
    },

    webshop: {
      type: Object,
      required: true,
    },

    freeActions: {
      type: Object,
      required: true,
    },

    mediaTypes: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.disableCountrySelector();
  },
};
</script>
