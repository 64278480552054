<template>
  <CrudShow
    :title="`Giffy Purchase #${purchase.id}`"
    sub-title="Giffy purchases"
    :rows="['ID', 'Member', 'Clickout', 'Purchased amount', 'Promotion action', 'Balance mutation', 'Created at']"
    :data="purchase"
  >
    <template #member>
      <a
        :href="route('admin.members.edit', purchase.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ purchase.member_id }}
      </a>
    </template>

    <template #clickout>
      <a
        :href="route('admin.members.clickouts.show', {
          member: purchase.member_id,
          clickout: purchase.clickout_id,
        })"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ purchase.clickout_id }}
      </a>
    </template>

    <template #promotion_action>
      <PromotionActionLink
        :action-type="purchase.promotion_type"
        :action-name="purchase.promotion_action_name"
        :action-url="purchase.promotion_action_url"
      />
    </template>
  </CrudShow>
</template>

<script>
import PromotionActionLink from '../../Components/PromotionActionLink.vue';

export default {
  components: {
    PromotionActionLink,
  },

  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
};
</script>
