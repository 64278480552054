<template>
  <CrudIndex
    title="Famous webshops"
    :columns="['ID', 'Webshop', 'Country']"
    :actions="true"
    delete-route-path="admin.famous-webshops.destroy"
    :data="famousWebshops"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', color: 'blue-400', action: (item) => move(item.id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', color: 'blue-400', action: (item) => move(item.id, 'down'), enabled: true},
    ]"
  >
    <template #after-filter>
      <CrudCard>
        <template #header>
          <span class="font-medium">
            Add webshop
          </span>
        </template>

        <template #content>
          <MultiSelectField
            v-model="form.webshop_id"
            label="Webshop"
            name="webshop_id"
            placeholder="Select a webshop"
            mode="single"
            :searchable="true"
            :can-deselect="false"
            :can-clear="false"
            :min-chars="2"
            :delay="150"
            :loading="loadingAWebshops"
            :options="async (query) => await fetchWebshops(query)"
          >
            <template
              v-if="form.webshop_id"
              #extra
            >
              <DefaultButton
                classes="text-white bg-indigo-600 hover:bg-indigo-700"
                @click.prevent="addWebshop"
              >
                <span class="material-icons md-18">add</span>
              </DefaultButton>
            </template>
          </MultiSelectField>
        </template>
      </CrudCard>
    </template>

    <template #webshop="{ row }">
      <a
        :href="route('admin.webshops.edit', row.webshop_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.webshop_name }}
      </a>
    </template>
  </CrudIndex>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import axios from 'axios';

export default {
  props: {
    famousWebshops: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      webshop_id: null,
    });

    return {
      form,
      loadingAWebshops: ref(false),
    };
  },

  methods: {
    async fetchWebshops(query) {
      this.loadingAWebshops = true;

      return await axios.get(this.route('admin.api.webshop.search.name', {name: query}))
        .then(({data}) => {
          this.loadingAWebshops = false;
          return data;
        });
    },

    addWebshop() {
      this.form.post(this.route('admin.famous-webshops.store'), {
        onSuccess: () => this.form.reset(),
      });
    },

    move(famousWebshopId, direction) {
      this.$inertia.post(
        this.route('admin.famous-webshops.move', [famousWebshopId]),
        {move: direction},
      );
    },
  },
};
</script>
