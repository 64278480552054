import {ref} from 'vue';

export default {
  install(app) {
    app.config.globalProperties.countrySelectorDisabled = ref(false);

    app.config.globalProperties.disableCountrySelector = function(disable = true) {
      app.config.globalProperties.countrySelectorDisabled.value = disable;
    };

    app.mixin({
      created() {
        this.disableCountrySelector(false);
      },
    });
  },
};
