<template>
  <CrudEdit
    :title="role.name"
    sub-title="Roles"
    :index-route="route('admin.roles.index')"
    :update-route="route('admin.roles.update', [role.id])"
  >
    <template #form>
      <RoleForm
        :role="role"
      />
    </template>
  </CrudEdit>
</template>

<script>
import RoleForm from './Form.vue';

export default {
  components: {
    RoleForm,
  },

  props: {
    role: {
      type: Object,
      required: true,
    },
  },
};
</script>
