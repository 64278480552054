<template>
  <CrudIndex
    title="Webshops"
    create-route-path="admin.webshops.create"
    edit-route-path="admin.webshops.edit"
    row-route-path="admin.webshops.edit"
    delete-route-path="admin.webshops.destroy"
    :columns="['ID', 'Name', 'Advertiser name', 'Network', 'Is active', 'Programs', 'Commission models']"
    :actions="true"
    :menu-items="[
      {name: 'Battle logs', url: (item) => route('admin.webshops.battle-logs', item.id), enabled: true},
    ]"
    :data="webshops.data"
    :paginator="webshops.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
      {
        type: 'relation',
        label: 'Webshop Categories',
        value: 'categories.webshop_category_id',
        multiple: true,
        api: route('admin.api.webshop-category.index'),
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Is active',
        value: 'is_active',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
    ]"
  >
    <template #advertiser_name="{ row, item }">
      <a
        :href="route('admin.advertisers.edit', row.advertiser_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ item }}
      </a>
    </template>

    <template #network="{ row }">
      {{ row.active_network }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    webshops: {
      type: Object,
      required: true,
    },
  },
};
</script>
