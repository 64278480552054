<template>
  <CrudShow
    :title="`Survey Wave #${surveyWave.id}`"
    sub-title="Survey waves"
    :back-route-path="route('admin.survey-waves.index', surveyWave.survey_id)"
    :rows="['ID', 'Name', 'Responses', 'Survey waves']"
    :data="surveyWave"
  >
    <template #before-back>
      <DefaultButton
        classes="text-white bg-indigo-600 hover:bg-indigo-700 mr-3"
        :href="route('admin.mailings.create', {'survey': true, 'survey_wave': surveyWave.id})"
      >
        Create mailing
      </DefaultButton>
    </template>

    <template #responses>
      <table class="min-w-full table-fixed p-4">
        <thead class="bg-white">
          <tr>
            <td
              v-for="status of surveyWave.response_statistics.statuses"
              :key="status.status"
              class="pr-4"
            >
              {{ status.status }}
            </td>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr>
            <td
              v-for="status of surveyWave.response_statistics.statuses"
              :key="status.status"
              class="pr-4"
            >
              {{ status.count }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template #survey_waves>
      <ul>
        <li
          v-for="(extraSurveyWave, key) in allSurveyWaves"
          :key="key"
        >
          <InertiaLink
            :href="route('admin.survey-waves.show', {
              survey: extraSurveyWave.survey_id,
              survey_wave: extraSurveyWave.id,
            })"
            class="text-indigo-600 hover:text-indigo-900"
            @click.stop
          >
            {{ extraSurveyWave.name }} (#{{ extraSurveyWave.id }})
          </InertiaLink>
        </li>
      </ul>
    </template>
  </CrudShow>

  <MailingOverviewPlanning
    :mailings="plannedMailings"
    :disable-refresher="true"
    :columns="plannedColumns"
    :allowed-statuses="allowedStatuses"
    :non-editable-scheduled-at-statuses="nonEditableScheduledAtStatuses"
    :disable-filters="true"
    :show-duplicate-menu-item="true"
    :enable-inactive-link="enableInactiveLink"
    :can-advertiser-check="canAdvertiserCheck"
  />
  <MailingOverviewStatistics
    :mailings="statisticMailings"
    :columns="statisticColumns"
    :show-revenue="false"
    :disable-filters="true"
    :show-duplicate-menu-item="true"
    :enable-survey-statistics="true"
    :order="[]"
  />

  <SiteHead
    :title="`Survey Wave #${surveyWave.id}`"
  />
</template>

<script>
import MailingOverviewStatistics from '../../Components/Mailing/Overview/Statistics.vue';
import MailingOverviewPlanning from '../../Components/Mailing/Overview/Planning.vue';

export default {
  components: {
    MailingOverviewStatistics,
    MailingOverviewPlanning,
  },

  props: {
    surveyWave: {
      type: Object,
      required: true,
    },

    allSurveyWaves: {
      type: Array,
      required: true,
    },

    allowedStatuses: {
      type: Array,
      required: true,
    },

    nonEditableScheduledAtStatuses: {
      type: Array,
      required: true,
    },

    plannedMailings: {
      type: Object,
      required: true,
    },

    statisticMailings: {
      type: Object,
      required: true,
    },

    enableInactiveLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    canAdvertiserCheck: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      plannedColumns: ['ID', 'Name', 'Segmentation', 'Planner', 'Scheduled At', 'Status', 'Recipients'],
      statisticColumns: ['ID', 'Name', 'Segmentation', 'Planner', 'Sent at', 'Sent', 'Clicks', 'Clicks/ Sent', 'Views', 'Views/ Sent', 'Clicks/ Views', 'eCPC'],
    };
  },
};
</script>
