<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <CrudIndex
    v-else
    title="Mailing filters"
    create-route-path="admin.mailing-filters.create"
    edit-route-path="admin.mailing-filters.edit"
    delete-route-path="admin.mailing-filters.destroy"
    :columns="['id', 'name', 'count']"
    :actions="true"
    :data="mailingFilters.data"
    :paginator="mailingFilters.meta.pagination"
    :menu-items="[
      {name: 'Duplicate', icon: 'content_copy', url: (item) => route('admin.mailing-filters.duplicate', item.id), enabled: true},
      {name: 'Refresh counter', icon: 'sync', action: (item) => refreshCounter(item.id), enabled: true},
    ]"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}]},
    ]"
    :order-columns="{
      'id': 'id',
      'name': 'name',
      'count': 'count',
    }"
    :order="order"
  />
</template>

<script>
export default {
  props: {
    mailingFilters: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  methods: {
    refreshCounter(filterId) {
      this.$inertia.patch(this.route('admin.mailing-filters.refresh-counter', [filterId]));
    },
  },
};
</script>
