<template>
  <CrudToggleCard>
    <template #header>
      <span class="flex items-center font-medium">
        <span
          class="inline-block w-2.5 h-2.5 rounded-full mr-2"
          :class="storeCommissionModel.webshop_ids.length > 0 ? 'bg-green-500' : 'bg-yellow-500'"
          :title="`Used by ` + storeCommissionModel.webshop_ids.length + ` webshop(s)`"
        />

        Commission Model

        <span class="text-xs text-gray-400 ml-2">
          {{ storeCommissionModel.description }}
          ({{ storeCommissionModel.fixed }} - {{ storeCommissionModel.percentage }})
        </span>
      </span>
    </template>

    <template #content>
      <InputField
        :model-value="storeCommissionModel.description"
        label="Description"
        name="description"
        :disabled="true"
      />

      <InputField
        :model-value="storeCommissionModel.percentage"
        label="Percentage"
        name="percentage"
        :disabled="true"
      />

      <InputField
        :model-value="storeCommissionModel.fixed"
        label="Fixed"
        name="fixed"
        :disabled="true"
      />

      <TextareaField
        v-model="storeCommissionModel.manual_description"
        label="Manual Description"
        name="manual_description"
        :required="false"
        help="In country language"
      />

      <MultiSelectField
        v-model="storeCommissionModel.description_tag_ids"
        label="Description Tags"
        name="tags"
        placeholder="Search description tags"
        mode="tags"
        :required="false"
        :can-clear="true"
        :min-chars="2"
        :delay="150"
        :loading="searchingDescriptionTags"
        :options="async (query) => await searchDescriptionTags(query)"
      >
        <template #extra>
          <DefaultButton @click.prevent="openTagsModal">
            Show all tags
          </DefaultButton>
        </template>
      </MultiSelectField>

      <MultiSelectField
        v-for="category in informationalCategories"
        :key="`informational-category-${category.id}`"
        v-model="storeCommissionModel.informational_tag_ids[category.id]"
        :label="`${category.description} Tags`"
        :name="`informational-category-${category.id}`"
        placeholder="Select a tag"
        mode="tags"
        :can-clear="true"
        :options="parseInformationalTags(category)"
        :required="false"
      />

      <SelectField
        v-model="storeCommissionModel.cashback_info"
        label="Cashback info"
        name="cashback_info"
        help="Show the 'per Euro spent' or 'per purchase' text"
        :items="cashbackInfoOptions.map((option) => ({id: option, name: option}))"
        :required="false"
      />

      <MultiSelectField
        v-model="storeCommissionModel.webshop_ids"
        label="Attach to webshop(s)"
        placeholder="Select webshop(s)"
        :disabled="!storeCommissionModel.can_use"
        :title="storeCommissionModel.can_use_reason"
        mode="tags"
        name="webshop_ids"
        :can-clear="true"
        :options="programWebshops"
        :required="false"
      />
    </template>
  </CrudToggleCard>

  <CommissionModelAllTagsModal
    v-model:open="open"
    v-model:selectedTags="storeCommissionModel.description_tag_ids"
    @select-tag="addTagFromModal"
  />
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import CommissionModelAllTagsModal from './CommissionModelAllTagsModal.vue';

export default {
  components: {CommissionModelAllTagsModal},

  props: {
    commissionModelId: {
      type: Number,
      required: true,
    },

    informationalCategories: {
      type: Object,
      required: true,
    },

    programWebshops: {
      type: Object,
      required: true,
    },

    cashbackInfoOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const open = ref(false);

    return {
      open,
    };
  },

  data() {
    const storeCommissionModel = this.$store.state.form.commission_models[this.commissionModelId];

    for (const informationalCategory of this.informationalCategories) {
      if (Object.keys(storeCommissionModel.informational_tag_ids).length === 0) {
        storeCommissionModel.informational_tag_ids = {};
      }

      if (!(informationalCategory.id in storeCommissionModel.informational_tag_ids)) {
        storeCommissionModel.informational_tag_ids[informationalCategory.id] = [];
      }

      this.canUse(storeCommissionModel);
    }

    return {
      searchingDescriptionTags: false,
    };
  },

  computed: {
    storeCommissionModel() {
      return this.$store.state.form.commission_models[this.commissionModelId];
    },
  },

  watch: {
    '$store.state.form.commission_model_display_option': function () {
      this.canUse(this.storeCommissionModel);
    },

    '$store.state.form.currency_id': function () {
      this.canUse(this.storeCommissionModel);
    },
  },

  methods: {
    parseInformationalTags(category) {
      return category.tags.reduce((accumulator, tag) => {
        accumulator[tag.id] = tag.description;
        return accumulator;
      }, {});
    },

    async searchDescriptionTags(query) {
      this.searchingDescriptionTags = true;

      return await axios.get(this.route('admin.api.tags.search.description', {description: query}))
        .then(({data}) => {
          this.searchingDescriptionTags = false;

          return data;
        });
    },

    openTagsModal() {
      this.open = true;
    },

    addTagFromModal(tagId) {
      if (!this.storeCommissionModel.description_tag_ids.includes(tagId)) {
        this.storeCommissionModel.description_tag_ids.push(tagId);
      }
    },

    canUse(storeCommissionModel) {
      /** @see app/Transformers/Admin/CommissionModelTransformer.php */
      storeCommissionModel.can_use = true;
      storeCommissionModel.can_use_reason = '';

      if (!this.programWebshops || Object.keys(this.programWebshops).length === 0) {
        storeCommissionModel.can_use = false;
        storeCommissionModel.can_use_reason = 'No webshop(s) attached to this program.';
        return;
      }

      if (this.$store.state.form.commission_model_display_option === 'numbered_categories') {
        storeCommissionModel.can_use = false;
        storeCommissionModel.can_use_reason = 'Cannot select because the program\'s commission-model-display-option is set to \'numbered categories\'.';
        return;
      }

      if (this.$store.state.form.commission_model_display_option === 'min_max') {
        storeCommissionModel.can_use = false;
        storeCommissionModel.can_use_reason = 'Cannot select because the program\'s commission-model-display-option is set to \'min-max\'.';
        return;
      }

      const hasFixedValue = storeCommissionModel.fixed_value && storeCommissionModel.fixed_value.length > 0;
      const hasCurrency = storeCommissionModel.fixed_currency_id || this.$store.state.form.currency_id;
      const canConnect = !hasFixedValue || (hasFixedValue && hasCurrency);
      if (!canConnect) {
        storeCommissionModel.can_use = false;
        storeCommissionModel.can_use_reason = 'Unable to connect due to missing currency. First enter a program currency.';
        return;
      }
    },
  },
};
</script>
