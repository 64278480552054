<template>
  <CrudCreate
    :title="`Add balance mutation for member #${member.id}`"
    sub-title="Balance mutations"
    :index-route="route('admin.members.balance-mutations.index', member.id)"
    :store-route="route('admin.members.balance-mutations.store', member.id)"
    action="Add"
    :store-options="{
      onBefore: () => confirmIfMoneyToHigh(),
    }"
  >
    <template #form>
      <CurrencyAndPointsField
        v-model="$store.state.form.value"
        :options="currencyOptions"
        :points-conversion-ratio="pointsConversionRatio"
        label="Value"
        name="value"
        help="The amount the member will receive"
      />

      <CurrencyField
        v-model="newBalance"
        :options="currencyOptions"
        label="New balance"
        name="new_balance"
        :disabled="true"
      />

      <MultiSelectField
        v-model="$store.state.form.survey_id"
        label="Survey"
        name="survey_id"
        placeholder="Survey"
        mode="single"
        help="Select a survey if you want to reward the member for this survey"
        :required="false"
        :searchable="true"
        :can-deselect="true"
        :can-clear="true"
        :resolve-on-load="$store.state.form.survey_id"
        :min-chars="2"
        :delay="150"
        :loading="loadingSurveys"
        :options="async (query) => await fetchSurveys(query)"
        @select="surveyChanged"
        @clear="surveyChanged"
      />

      <InputField
        v-model="$store.state.form.description"
        label="Description"
        name="description"
        help="This is what the member will see"
      />

      <TextareaField
        v-model="$store.state.form.note"
        label="Note"
        name="note"
        help="Members won’t see this text."
      />
    </template>
  </CrudCreate>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import Decimal from '@agrora/decimal';
import axios from 'axios';
import CurrencyAndPointsField from '../../../Components/CurrencyAndPointsField.vue';

export default {
  components: {
    CurrencyAndPointsField,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },

    surveyId: {
      type: Number,
      required: false,
      default: null,
    },

    maxMoney: {
      type: String,
      required: false,
      default: '0',
    },
  },

  setup(props) {
    const currencyOptions = {
      currency: props.member.currency.iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    const form = useForm({
      value: null,
      survey_id: props.surveyId,
      description: '',
      note: null,
    });

    useStore().commit('form/setForm', form);

    return {currencyOptions};
  },

  data() {
    return {
      currentBalance: this.member.currentBalance.value,
      newBalance: this.member.currentBalance.value,

      loadingSurveys: false,
      loadedSurveys: {},
    };
  },

  watch: {
    '$store.state.form.value': function (newValue) {
      if (!newValue) {
        this.newBalance = this.currentBalance;
        return;
      }

      const decimal = Decimal.from(this.currentBalance);
      this.newBalance = decimal.add(newValue, 2);
    },
  },

  methods: {
    surveyChanged(surveyId = null) {
      if (surveyId in this.loadedSurveys) {
        this.$store.state.form.description = this.loadedSurveys[surveyId];
      }

      if (surveyId === null) {
        this.$store.state.form.description = '';
      }
    },

    async fetchSurveys(query) {
      this.loadingSurveys = true;

      let fetchInitial = query === null;
      if (fetchInitial) {
        return axios.get(this.route('admin.api.surveys.find', {id: this.surveyId}))
          .then(({data}) => {
            this.loadingSurveys = false;
            this.loadedSurveys = data;
            this.surveyChanged(Object.keys(data)[0]);
            return data;
          });
      }

      return await axios.get(this.route('admin.api.surveys.search.name', {name: query}))
        .then(({data}) => {
          this.loadingSurveys = false;
          this.loadedSurveys = data;
          return data;
        });
    },

    confirmIfMoneyToHigh() {
      if (!this.maxMoney) {
        return true;
      }

      const moneyValue = Decimal.from(this.$store.state.form.value);
      const maxValue = Decimal.from(this.maxMoney);

      if (moneyValue.isGreaterThan(maxValue)) {
        return confirm(`Are you sure? The money value (${moneyValue.toString()}) is higher than the max (${maxValue.toString()}).`);
      }

      return true;
    },
  },
};
</script>
