<template>
  <CrudIndex
    title="Transactions"
    :columns="['Status', 'Commission']"
    :data="transactions"
    :actions="false"
  >
    <template #after-filter>
      <div class="flex bg-white border border-gray-200 rounded-lg shadow-sm px-4 pt-4 pb-4 mb-4">
        <SelectField
          v-model="year"
          name="year"
          placeholder="Select a year"
          :items="years"
          :inline="true"
        />

        <SelectField
          v-model="month"
          name="month"
          placeholder="Select a month"
          :items="months"
          :inline="true"
          field-classes="ml-4"
        />

        <DefaultButton
          :classes="(!year || !month ? 'bg-gray-400 text-gray-800 cursor-not-allowed disabled ml-8' : 'text-white bg-indigo-600 hover:bg-indigo-700 ml-8')"
          :disabled="!year || !month"
          :href="route('admin.transactions-cashbacks-statistics.index', {
            year: year,
            month: month,
          })"
        >
          Submit
        </DefaultButton>
      </div>
    </template>
  </CrudIndex>

  <CrudIndex
    title="Cashbacks"
    :columns="['Status', 'Cashback', 'Members']"
    :data="cashbacks"
    :actions="false"
  />

  <CrudIndex
    title="Balance mutations"
    :columns="['Type', 'Amount', 'Members']"
    :data="balanceMutations"
    :actions="false"
  />

  <SiteHead title="Transactions & Cashbacks statistics" />
</template>

<script>
export default {
  props: {
    years: {
      type: Array,
      required: true,
    },

    months: {
      type: Array,
      required: true,
    },

    selectedYear: {
      type: Number,
      required: false,
      default: null,
    },

    selectedMonth: {
      type: Number,
      required: false,
      default: null,
    },

    transactions: {
      type: Array,
      required: true,
    },

    cashbacks: {
      type: Object,
      required: true,
    },

    balanceMutations: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      year: this.selectedYear ?? null,
      month: this.selectedMonth ?? null,
    };
  },
};
</script>
