<template>
  <CrudCreate
    title="Program Term Category - Create"
    sub-title="Program Term Category"
    :index-route="route('admin.term-categories.index')"
    :store-route="route('admin.term-categories.store')"
  >
    <template #form>
      <CategoryForm />
    </template>
  </CrudCreate>
</template>

<script>

import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import CategoryForm from './Form.vue';

export default {
  components: {CategoryForm},

  setup() {
    const form = useForm({
      name: '',
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
