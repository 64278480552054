<template>
  <span v-if="!row">n/a</span>
  <div
    v-else
    class="flex items-center"
  >
    <span
      class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
      :class="{
        'bg-green-100 text-green-800' : row.status.status === 'APPROVED',
        'bg-red-100 text-red-800' : row.status.status === 'DISAPPROVED',
        'bg-yellow-100 text-yellow-800' : row.status.status === 'PENDING',
      }"
    >
      {{ row.commission }}
    </span>
    <Popover
      v-if="row.status.status === 'DISAPPROVED'"
      class="h-5 w-5 ml-1"
    >
      <PopoverButton class="inline-block">
        <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
      </PopoverButton>
      <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
        <p class="w-full">
          {{ row.status.disapproval_reason }}
        </p>
      </PopoverPanel>
    </Popover>
  </div>
</template>
<script>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {InformationCircleIcon} from '@heroicons/vue/solid';

export default {
  components: {
    Popover, PopoverButton, PopoverPanel,
    InformationCircleIcon,
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
  },
};
</script>
