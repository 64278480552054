<template>
  <ModalForm
    id="money-calculator"
    :open="true"
    :submit-handler="submitForm"
    @update:open="$emit('closed')"
  >
    <template #fields>
      <template v-if="commissionModels">
        <SelectField
          v-model="selectedCommissionModel"
          label="Commission Model"
          name="commission_model"
          placeholder="Select a Commission Model"
          :items="mappedCommissionModels"
          :required="false"
          @change="updateCommissionPercentageFromCommissionModel"
        />
      </template>

      <CurrencyField
        v-model="orderValue"
        :options="currencyOptions"
        name="orderValue"
        label="Order value (ex VAT, shipping costs, etc.)"
        :required="true"
        @change="calculateMoney"
      />

      <CurrencyField
        v-model="commissionPercentage"
        :options="{
          currencyDisplay: 'hidden',
        }"
        name="commissionPercentage"
        label="Commission percentage"
        :required="true"
        @change="calculateMoney"
      >
        <template #extra>
          %
        </template>
      </CurrencyField>

      <CurrencyField
        v-model="cashbackPercentage"
        :options="{
          currencyDisplay: 'hidden',
        }"
        name="cashbackPercentage"
        label="Cashback percentage"
        :required="true"
        @change="calculateMoney"
      >
        <template #extra>
          %
        </template>
      </CurrencyField>

      <CurrencyField
        v-model="money"
        :options="currencyOptions"
        name="money"
        label="Calculated money value"
        :required="true"
        :disabled="true"
      />
    </template>
  </ModalForm>
</template>

<script>
import Decimal from '@agrora/decimal';
import ModalForm from './ModalForm.vue';

export default {
  components: {
    ModalForm,
  },

  props: {
    currencyOptions: {
      type: Object,
      required: true,
    },

    commissionModels: {
      type: Object,
      required: false,
      default: () => {},
    },

    defaultCashbackPercentage: {
      type: [String, Number],
      required: true,
    },
  },

  emits: [
    'closed',
    'update:money',
  ],

  data() {
    return {
      orderValue: 0,
      commissionPercentage: 0,
      cashbackPercentage: Decimal.from(this.defaultCashbackPercentage ?? '0').toString(),
      money: 0,
      selectedCommissionModel: null,
    };
  },

  computed: {
    mappedCommissionModels() {
      return this.commissionModels.map(function (model) {
        const isFixed = !parseFloat(model.percentage);
        const description = model.manual_description ?? model.description;

        if (isFixed) {
          return {
            id: model.id,
            name: description + ' - ' + model.fixed,
          };
        }

        return {
          id: model.id,
          name: description + ' - ' + model.percentage,
        };
      });
    },
  },

  methods: {
    calculateMoney() {
      if (this.orderValue === null || this.commissionPercentage === null || this.cashbackPercentage === null) {
        return;
      }

      const orderValue = Decimal.from(this.orderValue);
      const commissionPercentage = Decimal.from(this.commissionPercentage).divide(Decimal.fromNumber(100), 5);
      const cashbackPercentage = Decimal.from(this.cashbackPercentage).divide(Decimal.fromNumber(100), 5);

      this.money = orderValue.multiply(commissionPercentage).multiply(cashbackPercentage);
    },

    submitForm(submitData) {
      this.$emit('update:money', this.money);

      submitData.success();
    },

    updateCommissionPercentageFromCommissionModel() {
      this.commissionModels.forEach((model) => {
        if (parseInt(model.id) === parseInt(this.selectedCommissionModel)) {
          const isFixed = !parseFloat(model.percentage);

          const value = isFixed ? model.fixed_value : model.percentage;

          const floatableValue = value
            .replace(',', '.')
            .match('[+-]?\\d+(\\.\\d+)?')[0];

          const floatValue = parseFloat(floatableValue);

          if (isFixed) {
            this.commissionPercentage = 100;
            this.orderValue = floatValue;
            return;
          }

          this.commissionPercentage = floatValue;
        }
      });

      this.calculateMoney();
    },
  },
};
</script>
