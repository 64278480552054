<template>
  <CrudIndex
    :title="`Clickouts & Cashbacks: #${member.id}`"
    row-route-path="admin.members.clickouts.show"
    :route-parameters="[member.id]"
    :columns="['Clickout ID', 'Advertiser', 'Promotion Type', 'Click datetime', 'Transaction datetime', 'Commission', 'Cashback', 'Order Value']"
    :actions="true"
    :menu-items="[
      {name: 'Transaction', url: (item) => route('admin.transactions.show', item.all_transaction_ids), enabled: (item) => hasSingleTransaction(item)},
      {name: 'Clickout', url: (item) => route('admin.members.clickouts.show', {member: member.id, clickout: item.clickout_id}), enabled: true},
      {name: 'Add cashback', url: (item) => route('admin.cashbacks.create', {clickout: item.clickout_id}), enabled: true},
      {name: 'Add survey cashback', url: (item) => route('admin.members.balance-mutations.create', {member: member.id, survey: item.survey_id}), enabled: (item) => item.survey_id},
      {name: 'Decline survey response', icon: 'thumb_down', action: (item) => openEditStatusModal(item, 'member-survey-response'), enabled: (item) => hasSingleMemberSurveyResponse(item) && (item.first_member_survey_response?.may_reward && !item.first_member_survey_response?.has_balance_mutation)},
      {name: 'Edit', icon: 'edit', action: (item) => openEditStatusModal(item, 'cashback'), enabled: (item) => hasSingleCashback(item) && Object.keys(item.all_cashback_statuses)[0] === 'pending'},
    ]"
    :data="clickouts.data"
    :paginator="clickouts.meta.pagination"
    :filters="[
      {
        type: 'dropdown',
        operator: 'advertisers',
        label: 'advertisers',
        value: 'advertiser_id',
        multiple: true,
        api: route('admin.api.member.clickouts.advertiser.filter', member.id),
      },
      {
        type: 'dropdown',
        operator: 'webshops',
        label: 'Webshop',
        value: 'webshop-id',
        multiple: true,
        api: route('admin.api.member.clickouts.webshop.filter', member.id),
      },
      {
        type: 'dropdown',
        operator: 'free-actions',
        label: 'Free action',
        value: 'free-action-id',
        multiple: true,
        api: route('admin.api.member.clickouts.free-action.filter', member.id),
      },
      {
        type: 'dropdown',
        operator: 'mailings',
        label: 'Mailing',
        value: 'mailing-id',
        multiple: true,
        api: route('admin.api.member.clickouts.mailing.filter', member.id),
      },
      {
        type: 'date-range',
        label: 'Date',
        timezone: $page.props.auth.user.timezone,
        fields: [
          {label: 'Cashbacks created at', value: 'cashbacks.created_at'},
          {label: 'Clickouts created at', value: 'clickouts.created_at'},
        ]
      },
      {
        type: 'dropdown',
        operator: 'show_with',
        label: 'Show with',
        value: 'show_with',
        multiple: true,
        items: [
          {name: 'Clickouts with a transaction', value: 'with_transaction'},
          {name: 'Clickouts with a cashback', value: 'with_cashback'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'types',
        label: 'Types',
        value: 'types',
        multiple: true,
        items: [
          {name: 'Mailing clickouts', value: 'mailing'},
          {name: 'Webshop clickouts', value: 'webshop'},
          {name: 'Free action clickouts', value: 'free_action'},
        ],
      },
    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.members.edit', member.id)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Member
      </InertiaLink>
    </template>

    <template #advertiser="{ row }">
      <a
        :href="route('admin.advertisers.edit', row.advertiser.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.advertiser.name }}
      </a>
    </template>

    <template #promotion_type="{ item, row }">
      <PromotionActionLink
        :action-type="item"
        :action-name="row.promotion_action_name"
        :action-url="row.promotion_action_url"
      />
    </template>

    <template #click_datetime="{ row }">
      {{ row.created_at }}
    </template>

    <template #commission="{ row }">
      <span v-if="row.all_transaction_statuses.length === 0">n/a</span>
      <div
        v-else
        class="flex items-center"
      >
        <span
          v-if="Object.keys(row.all_transaction_statuses).length > 1"
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        >
          {{ row.total_transaction_value }} ({{ row.total_transactions }})
        </span>
        <span
          v-else
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : Object.keys(row.all_transaction_statuses)[0] === 'approved',
            'bg-red-100 text-red-800' : Object.keys(row.all_transaction_statuses)[0] === 'disapproved',
            'bg-yellow-100 text-yellow-800' : Object.keys(row.all_transaction_statuses)[0] === 'pending',
          }"
        >
          {{ row.total_transaction_value }} <span v-if="row.total_transactions > 1">({{ row.total_transactions }})</span>
        </span>
        <Popover
          v-if="row.all_transaction_disapprove_reasons.length > 0"
          class="h-5 w-5 ml-1"
        >
          <PopoverButton class="inline-block">
            <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
          </PopoverButton>
          <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
            <p class="w-full">
              {{ row.all_transaction_disapprove_reasons.join(', ') }}
            </p>
          </PopoverPanel>
        </Popover>
      </div>
    </template>

    <template #cashback="{ row }">
      <span v-if="row.all_cashback_statuses.length === 0">n/a</span>
      <div
        v-else
        class="flex items-center"
      >
        <span
          v-if="Object.keys(row.all_cashback_statuses).length > 1"
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        >
          {{ row.total_cashback_value }} ({{ row.total_cashbacks }})
        </span>
        <span
          v-else
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : Object.keys(row.all_cashback_statuses)[0] === 'approved',
            'bg-red-100 text-red-800' : Object.keys(row.all_cashback_statuses)[0] === 'disapproved',
            'bg-yellow-100 text-yellow-800' : Object.keys(row.all_cashback_statuses)[0] === 'pending',
          }"
        >
          {{ row.total_cashback_value }} <span v-if="row.total_cashbacks > 1">({{ row.total_cashbacks }})</span>
        </span>
        <Popover
          v-if="Object.keys(row.all_cashback_disapprove_reasons).length > 0"
          class="h-5 w-5 ml-1"
        >
          <PopoverButton class="inline-block">
            <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
          </PopoverButton>
          <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
            <p class="w-full">
              {{ Object.keys(row.all_cashback_disapprove_reasons).join(', ') }}
            </p>
          </PopoverPanel>
        </Popover>
      </div>
    </template>

    <template #order_value="{row}">
      <span v-if="row.total_order_value === null">n/a</span>
      <span v-else>{{ row.total_order_value }}</span>
    </template>
  </CrudIndex>

  <EditStatusModal
    v-if="editModalId"
    v-model:editable-id="editModalId"
    :member-id="member.id"
    :statuses="editStatuses"
    :submit-route="editModalRoute"
  />
</template>

<script>
import {InformationCircleIcon} from '@heroicons/vue/solid';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import PromotionActionLink from '../../../Components/PromotionActionLink.vue';
import EditStatusModal from './EditStatusModal.vue';

export default {
  components: {
    PromotionActionLink,
    InformationCircleIcon,
    Popover, PopoverButton, PopoverPanel,
    EditStatusModal,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    clickouts: {
      type: Object,
      required: true,
    },

    cashbackStatuses: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editModalId: null,
      editModalRoute: null,
      editStatuses: null,
    };
  },

  methods: {
    hasSingleTransaction(item) {
      return item && item.all_transaction_ids.length === 1;
    },

    hasSingleMemberSurveyResponse(item) {
      return item && item.all_member_survey_response_ids.length === 1;
    },

    hasSingleCashback(item) {
      return item && Object.keys(item.all_cashback_ids).length === 1;
    },

    openEditStatusModal(item, type) {
      switch (type) {
        case 'cashback':
          this.editModalId = item.all_cashback_ids[0];
          this.editModalRoute = route('admin.members.clickouts.update-cashback-status', [this.member.id, this.editModalId]);
          this.editStatuses = this.cashbackStatuses;
          break;
        case 'member-survey-response':
          this.editModalId = item.all_member_survey_response_ids[0];
          this.editModalRoute = route('admin.members.clickouts.decline-member-survey-response', [this.member.id, this.editModalId]);
          this.editStatuses = null;
          break;
      }
    },
  },
};
</script>
