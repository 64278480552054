<template>
  <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
    Sign-up stats
  </h1>

  <CrudIndex
    title="Stats last 30 days"
    :columns="['Total sign ups', 'Total deleted accounts', 'Result']"
    :data="last30DayStats"
  >
    <template #result="{ item }">
      <span :class="parseInt(item) >= 0 ? 'text-green-500' : 'text-red-500'">{{ item }}</span>
    </template>
  </CrudIndex>

  <CrudIndex
    title="Stats based on filter"
    :columns="['Total sign ups', 'Total deleted accounts', 'Result']"
    :data="totalStats"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}],
      },
    ]"
  >
    <template #result="{ item }">
      <span :class="parseInt(item) >= 0 ? 'text-green-500' : 'text-red-500'">{{ item }}</span>
    </template>
  </CrudIndex>

  <CrudIndex
    title="Last 100 days matching with date filter"
    :columns="['Date', 'Sign ups', 'Sign ups confirmed', 'Sign ups unconfirmed', 'Deleted accounts']"
    :data="dayStats"
  />

  <SiteHead
    title="Sign-up stats"
  />
</template>

<script>
export default {
  props: {
    last30DayStats: {
      type: Object,
      required: true,
    },

    totalStats: {
      type: Object,
      required: true,
    },

    dayStats: {
      type: Object,
      required: true,
    },
  },
};
</script>
