<template>
  <CrudEdit
    :title="country.name"
    sub-title="Countries"
    :index-route="route('admin.countries.index')"
    :update-route="route('admin.countries.update', country.id)"
  >
    <template #cards>
      <CountryForm
        :country="country"
        :currencies="currencies"
        :languages="languages"
        :settings="settings"
        :setting-categories="settingCategories"
        :timezones="timezones"
        :required-settings="requiredSettings"
        :setting-labels="settingLabels"
        :setting-descriptions="settingDescriptions"
        :tell-a-friend-settings="tellAFriendSettings"
        :possible-setting-values="possibleSettingValues"
        :available-countries="availableCountries"
      />
    </template>
  </CrudEdit>
</template>

<script>
import CountryForm from './Form.vue';

export default {
  components: {CountryForm},

  props: {
    country: {
      type: Object,
      required: true,
    },

    currencies: {
      type: Array,
      required: true,
    },

    languages: {
      type: Array,
      required: true,
    },

    settings: {
      type: Object,
      required: true,
    },

    settingCategories: {
      type: Object,
      required: true,
    },

    timezones: {
      type: Object,
      required: true,
    },

    requiredSettings: {
      type: Array,
      required: true,
    },

    settingLabels: {
      type: Object,
      required: true,
    },

    settingDescriptions: {
      type: Object,
      required: true,
    },

    tellAFriendSettings: {
      type: Object,
      required: true,
    },

    possibleSettingValues: {
      type: Object,
      required: true,
    },

    availableCountries: {
      type: Object,
      required: true,
    },
  },
};
</script>
