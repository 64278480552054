<template>
  <CrudIndex
    title="Mailing statistics"
    :columns="columns"
    :actions="true"
    :menu-items="menuItems"
    :additional-item-data="enableSurveyStatistics ? surveyStatsData : revenueData"
    :data="mailings.data"
    :paginator="mailings.meta.pagination"
    :filters="disableFilters ? [] : filters"
    :order-columns="{
      'Name': 'name',
      'Sent at': 'sent_at',
      'Sent': 'sent',
      'Clicks': 'clicks',
      'Clicks/ Sent': 'clicks_over_sent',
      'Views': 'views',
      'Views/ Sent': 'views_over_sent',
      'Clicks/ Views': 'clicks_over_views',
      'eCPC': 'ecpc',
    }"
    :order="order"
  >
    <template #name="{ row }">
      <template v-if="!row.auto_remail_mailing_id">
        {{ row.name }}
      </template>
      <template v-else>
        <div class="bg-blue-600 text-white">
          {{ row.name }}
        </div>
      </template>
    </template>

    <template #planner="{ row }">
      {{ row.planner_name }}
    </template>

    <template #segmentation="{ row }">
      {{ row.segmentation_name }}
    </template>

    <template #additional-item-data="{ item }">
      <SurveyStatsTable
        v-if="enableSurveyStatistics"
        :stats="item.data"
      />

      <RevenueTable
        v-else
        :advertisers="item.data.advertisers"
        :total="item.data.total"
      />
    </template>

    <template #ecpc="{ item, row }">
      <a
        :href="route('admin.mailing-statistics.ecpc', row.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ item }}
      </a>
    </template>
  </CrudIndex>

  <Modal
    v-model:open="segmentationModalOpen"
    :items="segmentationData"
  />
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import Modal from '../../Modal.vue';
import RevenueTable from '../RevenueTable.vue';
import SurveyStatsTable from '../SurveyStatsTable.vue';

export default {
  components: {
    Modal,
    RevenueTable,
    SurveyStatsTable,
  },

  props: {
    mailings: {
      type: Object,
      required: true,
    },

    columns: {
      type: Array,
      required: false,
      default: () => ['Name', 'Sent at', 'Sent', 'Clicks', 'Clicks/ Sent', 'Views', 'Views/ Sent', 'Clicks/ Views', 'eCPC', 'Revenue'],
    },

    filterableNotificationTypes: {
      type: Array,
      required: false,
      default: () => [],
    },

    showRevenue: {
      type: Boolean,
      required: false,
      default: false,
    },

    showDuplicateMenuItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableFilters: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableAdvertiserFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableNotificationTypeFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyStatistics: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyWaveFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    let filters = [
      {
        type: 'date-range',
        timezone: this.$page.props.auth.user.timezone,
        fields: [
          {label: 'Sent at', value: 'sent_at'},
        ],
      },
      {
        type: 'search',
        fields: [
          {label: 'Mailing name', value: 'mailing.name'},
        ],
      },
    ];
    let menuItems = [
      {name: 'Preview', icon: 'preview', url: (item) => item.preview_url, target: '_blank', enabled: true},
      {name: 'Spamscore', icon: 'scoreboard', url: (item) => this.route('admin.mailings.tests.index', item.id), enabled: true},
      {name: 'Segmentation', icon: 'filter_alt', action: (item) => this.openSegmentationModal(item), enabled: (item) => !!item.segmentation },
    ];

    if (this.showDuplicateMenuItem) {
      menuItems.push(
          {name: 'Duplicate', icon: 'content_copy', url: (item) => this.route('admin.mailings.duplicate', item.id), enabled: true},
      );
      menuItems.push(
          {name: 'Duplicate multiple', icon: 'dynamic_feed', url: (item) => this.route('admin.mailings.duplicate-multiple', {mailing: item.id}), enabled: true},
      );
    }

    if (!this.disableAdvertiserFilter) {
      filters.push({
        type: 'relation',
        label: 'Advertiser',
        value: 'mailing.advertiser_id',
        multiple: true,
        api: this.route('admin.api.mailings.advertiser.filter'),
      });
    }

    if (!this.disableNotificationTypeFilter) {
      filters.push({
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Notification types',
        value: 'notification_type',
        multiple: true,
        items: this.filterableNotificationTypes,
      });
    }

    if (this.enableSurveyFilter) {
      filters.push({
        type: 'relation',
        label: 'Survey',
        value: 'mailing.survey_id',
        multiple: true,
        api: this.route('admin.api.surveys.filter'),
      });
    }

    if (this.enableSurveyWaveFilter) {
      filters.push({
        type: 'relation',
        label: 'Survey Wave',
        value: 'mailing.survey_wave_id',
        multiple: true,
        api: this.route('admin.api.survey-waves.filter'),
      });
    }

    if (this.showRevenue) {
      menuItems.push({
        name: (item) => item.id in this.revenueData && this.revenueData[item.id].visible ? 'Hide revenue' : 'Show revenue',
        action: (item) => this.toggleRevenue(item.id),
        enabled: true,
      });
    }

    if (this.enableSurveyStatistics) {
      menuItems.push({
        name: (item) => item.id in this.surveyStatsData && this.surveyStatsData[item.id].visible ? 'Hide survey stats' : 'Show survey stats',
        action: (item) => this.toggleSurveyStats(item.id),
        icon: 'query_stats',
        enabled: true,
      });
    }

    return {
      filters,
      menuItems,
      segmentationModalOpen: ref(false),
      segmentationData: ref([]),
      revenueData: {},
      surveyStatsData: {},
    };
  },

  methods: {
    openSegmentationModal(segmentationData) {
      this.segmentationModalOpen = true;
      this.segmentationData = segmentationData.segmentation;
    },

    toggleRevenue(mailingId) {
      if (mailingId in this.revenueData) {
        this.revenueData[mailingId].visible = !this.revenueData[mailingId].visible;
        return;
      }

      this.revenueData[mailingId] = {
        visible: true,
        loading: true,
        data: null,
      };

      axios.get(this.route('admin.mailing-statistics.revenue', mailingId))
        .then(({data}) => {
          this.revenueData[mailingId].data = data;
          this.revenueData[mailingId].loading = false;
        });
    },

    toggleSurveyStats(mailingId) {
      if (mailingId in this.surveyStatsData) {
        this.surveyStatsData[mailingId].visible = !this.surveyStatsData[mailingId].visible;
        return;
      }

      this.surveyStatsData[mailingId] = {
        visible: true,
        loading: true,
        data: null,
      };

      axios.get(this.route('admin.mailing-statistics.survey-stats', mailingId))
        .then(({data}) => {
          this.surveyStatsData[mailingId].data = data;
          this.surveyStatsData[mailingId].loading = false;
        });
    },
  },
};
</script>
