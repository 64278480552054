<template>
  <CrudEdit
    :title="administrator.name"
    sub-title="Administrators"
    :index-route="route('admin.administrators.index')"
    :update-route="route('admin.administrators.update', [administrator.id])"
  >
    <template #form>
      <AdministratorForm
        :administrator="administrator"
        :roles="roles"
      />
    </template>
  </CrudEdit>
</template>

<script>
import AdministratorForm from './Form.vue';

export default {
  components: {
    AdministratorForm,
  },

  props: {
    administrator: {
      type: Object,
      required: true,
    },

    roles: {
      type: Object,
      required: true,
    },
  },
};
</script>
