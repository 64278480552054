<template>
  <InputField
    v-model="$store.state.form.iban"
    label="IBAN"
    name="iban"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    ibanBlacklist: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      iban: props.ibanBlacklist ? props.ibanBlacklist.iban : null,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
