<template>
  <table class="min-w-full divide-y divide-gray-200 ml-4">
    <thead>
      <tr>
        <th
          v-for="(_, key) of stats"
          :key="key"
          class="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {{ key }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          v-for="(count, key) of stats"
          :key="key"
          class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900 align-top"
        >
          {{ count }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
};
</script>
