<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Program Terms
      </span>
    </template>

    <template #content>
      <div
        v-for="category in termCategories"
        :key="`program-terms-category-${category.id}`"
      >
        <MultiSelectField
          v-model="$store.state.form.program_term_item_ids[category.id]"
          :label="`${category.name} Terms`"
          :name="`program_term_item_ids[${category.id}]`"
          placeholder="Select terms"
          mode="tags"
          :can-clear="true"
          :loading="loadingProgramTermItems[category.id]"
          :options="categoryItems[category.id]"
          :required="false"
        />

        <TextareaField
          v-model="customTexts[category.id]"
          :name="`custom_text[${category.id}]`"
          :required="false"
          label="Custom Text"
        >
          <template #extra>
            <DefaultButton
              classes="text-white bg-indigo-600 hover:bg-indigo-700"
              @click.prevent="createCustomText(category.id)"
            >
              <i
                v-show="savingCustomText[category.id]"
                class="fa fa-spinner fa-spin text-gray-300 mr-2"
              />

              Create
            </DefaultButton>
          </template>
        </TextareaField>
      </div>
    </template>
  </CrudToggleCard>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    program: {
      type: Object,
      required: true,
    },

    programTermCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      termCategories: this.programTermCategories,
      categoryItems: this.buildCategoryItems(this.programTermCategories),
      loadingProgramTermItems: {},

      customTexts: {},
      savingCustomText: {},
    };
  },

  methods: {
    buildCategoryItems(termCategories) {
      const categoryItems = {};

      for (const termCategory of termCategories) {
        categoryItems[termCategory.id] = termCategory.items.reduce((accumulator, item) => {
          accumulator[item.id] = item.description;
          return accumulator;
        }, {});
      }

      return categoryItems;
    },

    createCustomText(categoryId) {
      if (!this.customTexts[categoryId]) {
        return;
      }

      this.createCustomProgramTermItem(categoryId, this.customTexts[categoryId])
        .then(({data}) => {
          this.customTexts[categoryId] = '';

          this.reloadProgramTermCategories(categoryId)
            .then(() => {
              this.$nextTick(() => this.$store.state.form.program_term_item_ids[categoryId].push(data.id));
            });
        });
    },

    createCustomProgramTermItem(categoryId, description) {
      this.savingCustomText[categoryId] = true;

      return axios.post(
        this.route('admin.api.program-term-items.create-custom', [this.program.id, categoryId]),
        {description},
      )
        .then((response) => {
          this.savingCustomText[categoryId] = false;

          return response;
        });
    },

    async reloadProgramTermCategories(categoryId) {
      this.loadingProgramTermItems[categoryId] = true;

      return axios.get(this.route('admin.api.program-term-categories.index'))
        .then(({data}) => {
          this.categoryItems = this.buildCategoryItems(data);

          this.loadingProgramTermItems[categoryId] = false;
        });
    },
  },
};
</script>
