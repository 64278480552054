<template>
  <CrudIndex
    title="Mailing clicks"
    :columns="columns"
    :data="statistics"
    :actions="false"
    :filters="[
      {
        type: 'dropdown',
        operator: 'custom',
        label: 'Year',
        value: 'year',
        items: years.map((type) => ({name: type, value: type})),
        multiple: false,
      },
    ]"
  />
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },

    statistics: {
      type: Object,
      required: true,
    },

    years: {
      type: Array,
      required: true,
    },
  },
};
</script>
