<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <CrudIndex
    v-else
    title="Webshop Suggestions"
    create-route-path="admin.webshop-suggestions.create"
    edit-route-path="admin.webshop-suggestions.edit"
    row-route-path="admin.webshop-suggestions.edit"
    delete-route-path="admin.webshop-suggestions.destroy"
    :columns="['id', 'Country', 'name', 'title', 'start_at', 'end_at', 'type', 'block_type']"
    :actions="true"
    :menu-items="[
      {name: 'Move up', icon: 'arrow_upward', color: 'blue-400', action: (item) => move(item.id, 'up'), enabled: true},
      {name: 'Move down', icon: 'arrow_downward', color: 'blue-400', action: (item) => move(item.id, 'down'), enabled: true},
    ]"
    :data="suggestions"
    :filters="[
      {type: 'search', fields: [{label: 'Name', value: 'name'}, {label: 'Title', value: 'title'}]},
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Is active',
        value: 'is_active',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Block type',
        value: 'block_type',
        multiple: true,
        items: blockTypes.map((type) => ({value: type, name: toTitleCase(type)})),
      },
    ]"
  >
    <template #title="{ row }">
      {{ row.title ?? '-' }}
    </template>

    <template #type="{ row }">
      {{ row.type_label }}
    </template>

    <template #block_type="{ row }">
      {{ toTitleCase(row.block_type) }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    suggestions: {
      type: Object,
      required: true,
    },

    blockTypes: {
      type: Array,
      required: true,
    },
  },

  methods: {
    move(webshopSuggestionId, direction) {
      this.$inertia.post(
        this.route('admin.webshop-suggestions.move', [webshopSuggestionId]),
        {move: direction},
      );
    },
  },
};
</script>
