<template>
  <CrudIndex
    title="Charities"
    create-route-path="admin.charities.create"
    edit-route-path="admin.charities.edit"
    delete-route-path="admin.charities.destroy"
    row-route-path="admin.charities.edit"
    :columns="['ID', 'Name', 'Bank account name', 'Total payout', 'IBAN', 'Countries']"
    :actions="true"
    :data="charities.data"
    :paginator="charities.meta.pagination"
  >
    <template #total_payout="{ row }">
      {{ row.total_payout_amount }} ({{ row.total_payout_count }})
    </template>
    <template #countries="{ row }">
      <span
        v-for="(country, i) in row.countries"
        :key="`country-${row.id}-${country.id}`"
      >
        <InertiaLink
          :href="route('admin.countries.edit', country.id)"
          class="text-indigo-600 hover:text-indigo-900"
          @click.stop
        >
          {{ country.name }}
        </InertiaLink>

        <span v-if="i < row.countries.length - 1">
          -
        </span>
      </span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    charities: {
      type: Object,
      required: true,
    },
  },
};
</script>
