<template>
  <CrudCreate
    title="Webshop Suggestion - Create"
    sub-title="Webshop Suggestion"
    :index-route="route('admin.webshop-suggestions.index')"
    :store-route="route('admin.webshop-suggestions.store')"
  >
    <template #cards>
      <WebshopSuggestionsForm
        :countries="countries"
        :suggestion-types="webshopSuggestionTypes"
        :advertiser-types="advertiserTypes"
        :block-types="blockTypes"
        :billboard-pages="billboardPages"
      />
    </template>
  </CrudCreate>
</template>

<script>
import WebshopSuggestionsForm from './Form.vue';

export default {
  components: {WebshopSuggestionsForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },

    webshopSuggestionTypes: {
      type: Object,
      required: true,
    },

    advertiserTypes: {
      type: Object,
      required: true,
    },

    blockTypes: {
      type: Array,
      required: true,
    },

    billboardPages: {
      type: Object,
      required: true,
    },
  },
};
</script>
