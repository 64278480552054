<template>
  <CrudIndex
    :title="payoutBatchId ? `Payouts - Approved in batch #${payoutBatchId}` : 'Payouts - Approved'"
    row-route-path="admin.payouts.show"
    :columns="['ID', 'Member', 'Payout type', 'Receiver name', 'Receiver account', 'Value', 'Status', 'Request date']"
    :actions="true"
    :data="payouts.data"
    :paginator="payouts.meta.pagination"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}],
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Payout type',
        value: 'type',
        multiple: true,
        items: filterablePayoutTypes,
      },
    ]"
    :order-columns="{
      'ID': 'id',
      'Member': 'member_id',
      'Value': 'value',
      'Status': 'status',
      'Request date': 'created_at',
    }"
    :order="order"
    :menu-items="[
      {name: 'Details', icon: 'info', url: (item) => route('admin.payouts.show', item.id), enabled: true},
      {name: 'Paid', icon: 'paid', action: (item) => done(item.id), enabled: (item) => menuItemEnabledIfNotInBatch(item)},
      {name: 'In process', icon: 'engineering', action: (item) => busy(item.id), enabled: (item) => menuItemEnabledIfNotInBatch(item)},
      {name: 'In review', icon: 'pending_actions', action: (item) => inReview(item.id), enabled: (item) => menuItemEnabledIfNotInBatch(item)},
    ]"
  >
    <template #before-create>
      <DefaultButton
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        :class="hasSelectedCountry() ? '' : 'cursor-not-allowed disabled'"
        :title="hasSelectedCountry() ? '' : 'No country selected'"
        @click="() => hasSelectedCountry() ? createPayoutBatch() : null"
      >
        Create bank payout batch
      </DefaultButton>
    </template>

    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #receiver_name="{ row }">
      {{ row.payout_receiver_name }}
    </template>

    <template #receiver_account="{ row }">
      {{ row.payout_receiver_account }}
    </template>

    <template #status="{ item }">
      <span
        class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item === 'APPROVED',
          'bg-red-100 text-red-800' : item === 'DISAPPROVED',
          'bg-yellow-100 text-yellow-800' : item === 'PENDING',
        }"
      >
        {{ item }}
      </span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    payouts: {
      type: Object,
      required: true,
    },

    payoutBatchId: {
      type: Number,
      required: true,
    },

    payoutTypes: {
      type: Array,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let filterablePayoutTypes = [];
    for (const payoutType of props.payoutTypes) {
      filterablePayoutTypes.push({name: payoutType, value: payoutType});
    }

    return {
      filterablePayoutTypes: filterablePayoutTypes,
    };
  },

  methods: {
    done(payoutId) {
      this.$inertia.patch(this.route('admin.payouts.approved.process', [payoutId]), {status: 'done'});
    },

    busy(payoutId) {
      this.$inertia.patch(this.route('admin.payouts.approved.process', [payoutId]), {status: 'busy'});
    },

    menuItemEnabledIfNotInBatch(item) {
      return !item.payout_batch_id;
    },

    createPayoutBatch() {
      this.$inertia.post(this.route('admin.payout-batches.store'));
    },

    inReview(payoutId) {
      this.$inertia.patch(this.route('admin.payouts.manual.review', [payoutId]));
    },

  },
};
</script>
