<template>
  <Popover
    v-slot="{ open }"
    class="relative"
  >
    <PopoverButton class="text-indigo-600 hover:text-indigo-900">
      {{ data.payout_type }}
    </PopoverButton>
    <PopoverOverlay
      class="bg-black z-10"
      :class="open ? 'opacity-30 fixed inset-0' : 'opacity-0'"
    />

    <PopoverPanel class="absolute z-20 bg-white w-96">
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Account data
          </h3>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div
              v-for="(value, key) in data.payout_account_data"
              :key="`${data.id}_${key}`"
              class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                {{ key }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ value }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<script>
import {Popover, PopoverButton, PopoverOverlay, PopoverPanel} from '@headlessui/vue';

export default {
  components: {Popover, PopoverButton, PopoverOverlay, PopoverPanel},

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
