<template>
  <CrudCreate
    title="FAQ Item - Create"
    :sub-title="'FAQ Category: ' + category.name"
    :index-route="route('admin.faq-categories.show', [category.id])"
    :store-route="route('admin.faq-categories.item.store', [category.id])"
  >
    <template #form>
      <ErrorList :fields="['question', 'answer']" />

      <FaqItemForm
        :countries="countries"
        :page-types="pageTypes"
      />
    </template>
  </CrudCreate>
</template>

<script>
import FaqItemForm from './Form.vue';

export default {
  components: {FaqItemForm},

  props: {
    category: {
      type: Object,
      required: true,
    },

    countries: {
      type: Object,
      required: true,
    },

    pageTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
