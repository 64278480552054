<template>
  <CrudIndex
    title="Bank Accounts"
    :columns="['Receiver name', 'Iban', 'Verified at', 'Deleted']"
    :data="ibanBankAccounts"
  >
    <template #verified_at="{ item }">
      <span
        v-if="item"
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        {{ item }}
      </span>

      <span
        v-else
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800"
      >
        unverified
      </span>
    </template>

    <template #deleted="{ item }">
      <span
        v-if="item"
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        Yes
      </span>

      <span
        v-else
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        No
      </span>
    </template>
  </CrudIndex>

  <SiteHead
    title="Payout Accounts"
  />
</template>

<script>
export default {
  props: {
    ibanBankAccounts: {
      type: Object,
      Required: false,
      default: null,
    },
  },
};
</script>

