<template>
  <div
    :class="{
      'grid gap-1 md:gap-4 items-center': !inline,
      'sm:grid-cols-6 py-6 first:pt-0 last:pb-0': !inline && label,
      'sm:grid-cols-2': !inline && !label,
    }"
  >
    <div
      v-if="label"
      class="col-span-2"
    >
      <div class="flex justify-between sm:justify-start">
        <label
          :for="id"
          class="block font-medium text-gray-700 mt-px"
          :class="{ 'pt-2': !required }"
          v-text="label"
        />

        <span
          v-if="!required"
          class="text-sm text-gray-500 sm:text-xs ml-1 mt-px pt-2"
        >Optional</span>
      </div>

      <p
        v-if="help"
        class="block mt-2 text-sm text-gray-400"
        v-text="help"
      />
    </div>

    <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
      <div class="flex space-x-2">
        <BaseCurrencyField
          v-model="value"
          :options="options"
          :name="`${name}_currency`"
          :required="required"
          :placeholder="currencyPlaceholder"
          :disabled="disabled"
          @keyup="currencyChanged"
        />
        <BaseCurrencyField
          v-model="points"
          :options="{
            currencyDisplay: 'hidden',
          }"
          :name="`${name}_points`"
          :required="required"
          :placeholder="pointsPlaceholder"
          :disabled="disabled"
          @keyup="pointsChanged"
        />
      </div>

      <div
        v-if="$page.props.errors[name]"
        class="absolute top-2.5 right-0 pr-3 flex items-center pointer-events-none"
      >
        <i
          class="fas fa-exclamation-circle h-5 text-red-500"
          aria-hidden="true"
        />
      </div>

      <p
        v-if="$page.props.errors[name]"
        class="mt-1 text-sm text-red-600"
        v-text="$page.props.errors[name]"
      />
    </div>

    <div class="col-span-2">
      <DefaultButton
        title="Calculate money"
        @click.prevent="openCalculatorModal"
      >
        <span
          class="material-icons md-18"
        >calculate</span>
      </DefaultButton>
    </div>
  </div>

  <MoneyCalculatorModal
    v-if="calculatorModalOpen"
    :currency-options="options"
    :commission-models="commissionModels"
    :default-cashback-percentage="cashbackPercentage"
    @closed="calculatorModalOpen = false"
    @update:money="moneyCalculated"
  />
</template>

<script>
import {Field} from '@rocketcode/backoffice';
import Decimal from '@agrora/decimal';
import MoneyCalculatorModal from './MoneyCalculatorModal.vue';

export default {
  components: {
    MoneyCalculatorModal,
  },

  extends: Field,

  props: {
    required: {
      type: Boolean,
      required: false,
      default: true,
    },

    options: {
      type: Object,
      required: false,
      default: () => {},
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },

    currencyPlaceholder: {
      type: [String, Number],
      required: false,
      default: 'Money',
    },

    pointsPlaceholder: {
      type: [String, Number],
      required: false,
      default: 'Points/ Qoins',
    },

    minimumValue: {
      type: [String, Number],
      required: false,
      default: null,
    },

    maximumValue: {
      type: [String, Number],
      required: false,
      default: null,
    },

    commissionModels: {
      type: Object,
      required: false,
      default: () => {},
    },

    cashbackPercentage: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      value: this.modelValue,
      points: this.calculatePoints(this.modelValue),
      calculatorModalOpen: false,
    };
  },

  watch: {
    'pointsConversionRatio': function () {
      this.currencyChanged();
    },
  },

  methods: {
    calculatePoints(currencyValue) {
      if (!currencyValue) {
        this.points = null;
        return;
      }

      const decimal = Decimal.from(currencyValue);
      const conversionRatioDecimal = Decimal.from(this.pointsConversionRatio);

      return decimal.multiply(conversionRatioDecimal);
    },

    currencyChanged() {
      this.checkMinMaxValue(this.value);

      this.$emit('update:modelValue', this.value);

      this.points = this.calculatePoints(this.value);
    },

    pointsChanged() {
      if (!this.points) {
        this.value = null;
        this.$emit('update:modelValue', this.value);
        return;
      }

      const decimal = Decimal.from(this.points);
      const conversionRatioDecimal = Decimal.from(this.pointsConversionRatio);
      let decimalPoints = 2;
      if (this.options && this.options.precision) {
        decimalPoints = Math.max(this.options.precision.min, this.options.precision.max, decimalPoints);
      }

      if (conversionRatioDecimal.isOne()) {
        this.value = decimal.toString();
      }

      if (conversionRatioDecimal.isZero()) {
        this.value = null;
      }

      if (!conversionRatioDecimal.isZero() && !conversionRatioDecimal.isOne()) {
        this.value = decimal.divide(conversionRatioDecimal, decimalPoints).toString();
      }

      this.$emit('update:modelValue', this.value);
    },

    checkMinMaxValue(value) {
      if (value !== null && (this.minimumValue || this.maximumValue)) {
        const decimalValue = Decimal.from(value);
        const minimumDecimalValue = Decimal.from(this.minimumValue);
        const maximumDecimalValue = Decimal.from(this.maximumValue);

        if (decimalValue.isLowerThan(minimumDecimalValue) || decimalValue.isGreaterThan(maximumDecimalValue)) {
          this.$page.props.errors[this.name] =  `${this.toTitleCase(this.name)} must be between ${this.minimumValue} and ${this.maximumValue}`;
        } else {
          this.$page.props.errors[this.name] = null;
        }
      }
    },

    openCalculatorModal() {
      this.calculatorModalOpen = true;
    },

    moneyCalculated(money) {
      this.value = money;
      this.currencyChanged();
    },
  },
};
</script>
