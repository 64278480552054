<template>
  <CrudCreate
    title="Administrator - Create"
    sub-title="Administrators"
    :index-route="route('admin.administrators.index')"
    :store-route="route('admin.administrators.store')"
  >
    <template #form>
      <AdministratorForm
        :roles="roles"
      />
    </template>
  </CrudCreate>
</template>

<script>
import AdministratorForm from './Form.vue';

export default {
  components: {
    AdministratorForm,
  },

  props: {
    roles: {
      type: Object,
      required: true,
    },
  },
};
</script>
