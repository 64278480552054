<template>
  <MultiSelectField
    ref="advertiser"
    v-model="$store.state.form.advertiser_ids"
    label="Advertiser(s)"
    name="advertiser_ids"
    placeholder="Select one or more advertisers"
    mode="tags"
    :required="false"
    :searchable="true"
    :can-deselect="true"
    :min-chars="2"
    :delay="150"
    :loading="loadingAdvertisers"
    :options="async (query) => await fetchAdvertisers(query)"
  />

  <MultiAdvertiserForm
    v-for="advertiserId in $store.state.form.advertiser_ids"
    :key="advertiserId"
    :advertiser-id="advertiserId"
    :multi-advertiser-media-types="multiAdvertiserMediaTypes"
    @openTrackingUrlVariablesModal="$emit('openTrackingUrlVariablesModal')"
    @openRoutesModal="$emit('openRoutesModal')"
  />
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import MultiAdvertiserForm from '../Components/MultiAdvertiserForm.vue';

export default {
  components: {
    MultiAdvertiserForm,
  },

  props: {
    multiAdvertiserMediaTypes: {
      type: Array,
      required: true,
    },
  },

  emits: ['openTrackingUrlVariablesModal', 'openRoutesModal'],

  setup() {
    return {
      loadingAdvertisers: ref(false),
    };
  },

  methods: {
    async fetchAdvertisers(query) {
      this.loadingAdvertisers = true;

      return await axios.get(this.route('admin.api.advertisers.find', {id: this.$store.state.form.advertiser_ids, name: query}))
        .then(({data}) => {
          this.loadingAdvertisers = false;
          return data;
        });
    },
  },
};
</script>
