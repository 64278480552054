<template>
  <CrudCard>
    <template #content>
      <CheckboxField
        v-model="$store.state.form.is_default"
        label="Is default"
        name="is_default"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
      />

      <InputField
        v-model="$store.state.form.code"
        label="Code"
        name="code"
      />

      <InputField
        v-model="$store.state.form.iso"
        label="ISO code"
        name="iso"
      />

      <SelectField
        v-model="$store.state.form.currency_id"
        label="Currency"
        name="currency_id"
        placeholder="Select a Currency"
        :items="currencies"
      />

      <SelectField
        v-model="$store.state.form.language_id"
        label="Language"
        name="language_id"
        placeholder="Select a language"
        :items="languages"
      />

      <SelectField
        v-model="$store.state.form.default_timezone"
        label="Default timezone"
        name="default_timezone"
        placeholder="Select a timezone"
        :items="timezones"
      />
    </template>
  </CrudCard>

  <CrudToggleCard>
    <template #header>
      <span class="flex items-center font-medium">
        Content
      </span>
    </template>

    <template #content>
      <MediaField
        :key="`country-file-conditions-of-participation-${$store.state.form.country_id}`"
        v-model="$store.state.form.condition_of_participation_media_id"
        label="Conditions of participation"
        name="country-file-conditions-of-participation"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :allowed-mime-types="['application/pdf']"
        :media-key="`country-file-conditions-of-participation-${$store.state.form.country_id}`"
        :required="false"
      >
        <template #extra>
          <a
            class="text-indigo-600 hover:text-indigo-900"
            :href="$store.state.form.condition_of_participation_media_url"
            target="_blank"
          >
            Show File
          </a>
        </template>
      </MediaField>

      <MediaField
        :key="`country-file-cookies-statement-${$store.state.form.country_id}`"
        v-model="$store.state.form.cookies_statement_media_id"
        label="Cookies Statement"
        name="country-file-cookies-statement"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :allowed-mime-types="['application/pdf']"
        :media-key="`country-file-cookies-statement-${$store.state.form.country_id}`"
        :required="false"
      >
        <template #extra>
          <a
            class="text-indigo-600 hover:text-indigo-900"
            :href="$store.state.form.cookies_statement_media_url"
            target="_blank"
          >
            Show File
          </a>
        </template>
      </MediaField>

      <MediaField
        :key="`country-file-privacy-statement-${$store.state.form.country_id}`"
        v-model="$store.state.form.privacy_statement_media_id"
        label="Privacy Statement"
        name="country-file-privacy-statement-id"
        index-route="admin.api.media.index"
        show-route="admin.api.media.show"
        upload-route="admin.api.media.store"
        delete-route="admin.api.media.destroy"
        :allowed-mime-types="['application/pdf']"
        :media-key="`country-file-privacy-statement-id-${$store.state.form.country_id}`"
        :required="false"
      >
        <template #extra>
          <a
            class="text-indigo-600 hover:text-indigo-900"
            :href="$store.state.form.privacy_statement_media_url"
            target="_blank"
          >
            Show File
          </a>
        </template>
      </MediaField>
    </template>
  </CrudToggleCard>

  <SettingsForm
    :required="requiredSettings"
    :labels="settingLabels"
    :descriptions="settingDescriptions"
    :show-copy-to-clipboard="true"
    :possible-values="possibleSettingValues"
    :setting-categories="settingCategories"
  />

  <CrudToggleCard>
    <template #header>
      <span class="flex items-center font-medium">
        Tell-a-friend settings
      </span>
    </template>

    <template #content>
      <!-- Settings -->
      <InputField
        v-model="$store.state.form.tell_a_friend_settings.bonus"
        label="Bonus height"
        help="As number in country currency."
        name="tell_a_friend_settings[bonus]"
        :required="true"
      >
        <template #extra>
          <DefaultButton
            title="Copy key to clipboard"
            @click.prevent="copyToClipboard('bonus');"
          >
            <span class="material-icons md-18">content_paste_go</span>
          </DefaultButton>
        </template>
      </InputField>
      <InputField
        v-model="$store.state.form.tell_a_friend_settings.minimum_approved_cashback"
        label="New member minimum approved cashback"
        help="Minimum approved cashback required by the new member for referral approval. Cashback = rewards attached to a transaction and/or manually rewarded based on a clickout. As number in country currency."
        name="tell_a_friend_settings[minimum_approved_cashback]"
        :required="true"
      >
        <template #extra>
          <DefaultButton
            title="Copy key to clipboard"
            @click.prevent="copyToClipboard('minimum_approved_cashback');"
          >
            <span class="material-icons md-18">content_paste_go</span>
          </DefaultButton>
        </template>
      </InputField>
      <InputField
        v-model="$store.state.form.tell_a_friend_settings.maximum_pending_period"
        label="Maximum pending period"
        help="Period (days) in which the referral has to meet all the conditions. As number in days."
        name="tell_a_friend_settings[maximum_pending_period]"
        :required="true"
      >
        <template #extra>
          <DefaultButton
            title="Copy key to clipboard"
            @click.prevent="copyToClipboard('maximum_pending_period');"
          >
            <span class="material-icons md-18">content_paste_go</span>
          </DefaultButton>
        </template>
      </InputField>
      <CheckboxField
        v-model="$store.state.form.tell_a_friend_settings.same_country_required"
        label="Same country required"
        help="If the new member and referrer have to be from the same country."
        name="tell_a_friend_settings[same_country_required]"
        :required="false"
      />

      <div class="grid gap-1 md:gap-4 items-start sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            <label class="block font-medium text-gray-700 mt-px pt-2">
              Textual conditions (in English)
            </label>
          </div>
        </div>
        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <div class="relative space-y-8">
            <div
              v-for="(condition, index) in $store.state.form.tell_a_friend_settings.textual_conditions"
              :key="`textual-conditions-${index}`"
              class="flex items-start flex-col"
            >
              <input
                type="hidden"
                name="textual_conditions[index][id]"
                :value="condition.id"
              >
              <div
                class="w-full flex-row inline-flex"
              >
                <MultiSelectField
                  v-model="$store.state.form.tell_a_friend_settings.textual_conditions[index].country_ids"
                  name="textual_conditions[index][country_ids]"
                  placeholder="Select countries"
                  mode="tags"
                  class="w-full"
                  :inline="true"
                  :options="availableCountries"
                />

                <DefaultButton
                  type="button"
                  classes="text-white bg-indigo-600 hover:bg-indigo-700 h-full"
                  title="Remove condition"
                  @click="removeTellAFriendTextualCondition(index)"
                >
                  <i class="fas fa-minus" />
                </DefaultButton>
              </div>
              <TextareaField
                v-model="$store.state.form.tell_a_friend_settings.textual_conditions[index].text"
                name="textual_conditions[index][text]"
                class="w-full grow mt-1"
                rows="6"
                :inline="true"
                :required="false"
              />
            </div>

            <div
              class="flex justify-end"
              :class="{ 'mt-6': $store.state.form.tell_a_friend_settings.textual_conditions.length > 0 }"
            >
              <DefaultButton
                type="button"
                classes="text-white bg-indigo-600 hover:bg-indigo-700 !ml-0"
                title="Add condition"
                @click="addTellAFriendTextualCondition"
              >
                <i class="fas fa-plus" />
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CrudToggleCard>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import SettingsForm from '../../Components/SettingsForm.vue';

export default {
  components: {SettingsForm},

  props: {
    country: {
      type: Object,
      required: false,
      default: null,
    },

    currencies: {
      type: Array,
      required: true,
    },

    languages: {
      type: Array,
      required: true,
    },

    settings: {
      type: Object,
      required: true,
    },

    settingCategories: {
      type: Object,
      required: true,
    },

    timezones: {
      type: Object,
      required: true,
    },

    requiredSettings: {
      type: Array,
      required: true,
    },

    settingLabels: {
      type: Object,
      required: true,
    },

    settingDescriptions: {
      type: Object,
      required: true,
    },

    tellAFriendSettings: {
      type: Object,
      required: true,
    },

    possibleSettingValues: {
      type: Object,
      required: true,
    },

    availableCountries: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      country_id: props.country ? props.country.id : null,
      condition_of_participation_media_id: props.country ? props.country.condition_of_participation_media_id : null,
      condition_of_participation_media_url: props.country ? props.country.files.condition_of_participation_media_url : null,
      cookies_statement_media_id: props.country ? props.country.cookies_statement_media_id : null,
      cookies_statement_media_url: props.country ? props.country.files.cookies_statement_media_url : null,
      privacy_statement_media_id: props.country ? props.country.privacy_statement_media_id : null,
      privacy_statement_media_url: props.country ? props.country.files.privacy_statement_media_url : null,
      is_default: props.country ? props.country.is_default : false,
      name: props.country ? props.country.name : null,
      code: props.country ? props.country.code : null,
      iso: props.country ? props.country.iso : null,
      currency_id: props.country ? props.country.currency_id : null,
      language_id: props.country ? props.country.language_id : null,
      default_timezone: props.country ? props.country.default_timezone : null,
      settings: props.settings,
      tell_a_friend_settings: props.tellAFriendSettings,
    });

    useStore().commit('form/setForm', form);
  },

  methods: {
    async copyToClipboard(value) {
      await navigator.clipboard.writeText(value);
    },

    addTellAFriendTextualCondition() {
      this.$store.state.form.tell_a_friend_settings.textual_conditions.push({
        id: null,
        text: '',
        country_ids: [],
      });
    },

    removeTellAFriendTextualCondition(index) {
      this.$store.state.form.tell_a_friend_settings.textual_conditions.splice(index, 1);
    },
  },
};
</script>
