<template>
  <CrudCreate
    title="Games - Create"
    sub-title="Games"
    :index-route="route('admin.games.index')"
    :store-route="route('admin.games.store')"
  >
    <template #cards>
      <GameForm
        :countries="countries"
        :registered-games="registeredGames"
      />
    </template>
  </CrudCreate>
</template>

<script>
import GameForm from './Form.vue';

export default {
  components: {GameForm},

  props: {
    countries: {
      type: Array,
      required: true,
    },

    registeredGames: {
      type: Array,
      required: true,
    },
  },
};
</script>
