<template>
  <CrudIndex
    title="User Notification Preferences"
    :columns="['Type', 'Total', 'Percentage']"
    :data="userNotificationPreferences"
    :actions="false"
    :filters="[
      {
        type: 'dropdown',
        operator: 'custom',
        label: 'Member verified status',
        value: 'member_verified_status',
        items: [{name: 'Confirmed', value: 'confirmed'}, {name: 'Unconfirmed', value: 'unconfirmed'}, {name: 'Both', value: 'both'}],
        multiple: false,
      },
    ]"
  />
</template>

<script>
export default {
  props: {
    userNotificationPreferences: {
      type: Array,
      required: true,
    },
  },
};
</script>
