<template>
  <div class="grid gap-1 md:gap-4 items-center grid-cols-6 py-6 first:pt-0 last:pb-0">
    <div class="col-span-2">
      <div class="flex justify-between sm:justify-start">
        <label
          class="block font-medium text-gray-700 mt-px"
          v-text="label"
        />

        <span
          v-if="!required"
          class="text-sm text-gray-500 sm:text-xs ml-1 mt-px"
        >Optional</span>
      </div>
    </div>

    <template
      v-for="(_, index) in excludedWaves"
      :key="index"
    >
      <div class="col-span-2">
        <SelectField
          v-model="excludedWaves[index].survey_wave_id"
          :name="`exclude_waves.${index}.survey_wave_id`"
          placeholder="Select wave"
          :items="selectableWaves(excludedWaves[index].survey_wave_id)"
          :required="true"
          @change="updated"
        />
        <p
          v-if="$page.props.errors[`exclude_waves.${surveyWaveId}.survey_wave_id`]"
          class="mt-1 text-sm text-red-600"
          v-text="$page.props.errors[`exclude_waves.${surveyWaveId}.survey_wave_id`]"
        />
      </div>
      <div class="col-span-1">
        <MultiSelectField
          v-model="excludedWaves[index].survey_statuses"
          :name="`exclude_waves.${index}.survey_statuses`"
          placeholder="Select a status"
          :options="surveyStatuses.map((status) => ({label: status, value: status}))"
          :required="true"
          :inline="true"
          mode="tags"
          @change="updated"
        />
      </div>
      <p
        v-if="$page.props.errors[`exclude_waves.${surveyWaveId}.survey_statuses`]"
        class="mt-1 text-sm text-red-600"
        v-text="$page.props.errors[`exclude_waves.${surveyWaveId}.survey_statuses`]"
      />

      <div class="col-span-1 text-right">
        <DefaultButton
          class="!ml-0"
          @click.prevent="remove(index)"
        >
          <span
            class="material-icons md-18"
            title="Delete"
          >delete</span>
        </DefaultButton>
      </div>

      <div class="col-span-2" />
    </template>

    <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-4">
      <DefaultButton
        class="!ml-0"
        @click.prevent="addWave"
      >
        <span
          class="material-icons md-18"
          title="Add Wave"
        >add</span>
      </DefaultButton>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },

    required: {
      type: Boolean,
      required: false,
    },

    modelValue: {
      type: Array,
      required: true,
    },

    availableWaves: {
      type: Object,
      required: true,
    },

    surveyStatuses: {
      type: Array,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    return {
      excludedWaves: ref(props.modelValue),
    };
  },

  methods: {
    selectableWaves(selectedSurveyWaveId) {
      const wavesInUse = [];
      for (const index in this.excludedWaves) {
        if (this.excludedWaves[index].survey_wave_id) {
          wavesInUse.push(this.excludedWaves[index].survey_wave_id);
        }
      }

      const selectableWaves = [];
      for (const [surveyWaveId, surveyWaveName] of Object.entries(this.availableWaves)) {
        if (wavesInUse.includes(surveyWaveId) && surveyWaveId != selectedSurveyWaveId) {
          continue;
        }

        selectableWaves.push({
          id: surveyWaveId,
          name: surveyWaveName,
        });
      }

      return selectableWaves;
    },

    updated() {
      this.$emit('update:modelValue', this.excludedWaves);
    },

    addWave() {
      this.excludedWaves.push({
        survey_wave_id: null,
        survey_statuses: [],
      });
    },

    remove(index) {
      this.excludedWaves.splice(index, 1);
    },
  },
};
</script>
