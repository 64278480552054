<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        <span
          class="inline-block w-2.5 h-2.5 rounded-full mr-2"
          :class="isProgramActiveForPromotionAction(program) ? 'bg-green-500' : 'bg-yellow-500'"
          :title="isProgramActiveForPromotionAction(program) ? 'Is active for this promotion action' : 'Is not active for this promotion action'"
        />
        Program
      </span>

      <span
        v-if="!loading"
        class="text-sm col-span-2"
      >
        <span class="ml-6">
          <InertiaLink
            :href="route('admin.programs.edit', program.id)"
            class="text-indigo-600 hover:text-indigo-900"
            @click.stop
          >
            {{ program.name }}
          </InertiaLink>
        </span>
        <span class="ml-6">
          {{ program.network_name }} ({{ program.status }})
        </span>
      </span>
    </template>

    <template #content>
      <div v-if="loading">
        <div class="inline-flex justify-center w-full py-4">
          <i class="far fa-spin fa-spinner text-2xl" />
        </div>
      </div>
      <div v-else>
        <InputField
          v-model="program.name"
          label="Name"
          name=""
          :disabled="true"
        />
        <InputField
          :model-value="program.program_tracking_url"
          label="Program tracking URL"
          name="program_tracking_url"
          :disabled="true"
        >
          <template #extra>
            <DefaultButton
              title="Copy"
              @click.prevent="copyTrackingUrl(program.program_tracking_url)"
            >
              <span
                class="material-icons md-18"
              >content_copy</span>
            </DefaultButton>

            <DefaultButton
              :internal-link="false"
              :href="safeProgramTrackingUrl(program.program_tracking_url)"
              target="_blank"
              title="Open (in new tab)"
            >
              <span
                class="material-icons md-18"
              >launch</span>
            </DefaultButton>
          </template>
        </InputField>
        <CheckboxField
          v-model="program.use_program_tracking_url"
          label="Use program tracking-url"
          :name="`programs.${programId}.use_program_tracking_url`"
          :required="false"
        />
        <InputField
          v-if="!program.use_program_tracking_url"
          v-model="program.tracking_url"
          label="Tracking URL"
          :name="`programs.${programId}.tracking_url`"
          :required="false"
        >
          <template #extra>
            <DefaultButton
              title="Copy"
              @click.prevent="copyTrackingUrl(program.tracking_url)"
            >
              <span
                class="material-icons md-18"
              >content_copy</span>
            </DefaultButton>

            <DefaultButton
              :internal-link="false"
              :href="safeProgramTrackingUrl(program.tracking_url)"
              target="_blank"
              title="Open (in new tab)"
            >
              <span
                class="material-icons md-18"
              >launch</span>
            </DefaultButton>
          </template>
        </InputField>

        <MultiCheckboxField
          v-if="needsCommissionModels"
          v-model="$store.state.form.commission_model_ids[programId]"
          label="Commission Models"
          :name="`commission_model_ids.${programId}`"
          :required="false"
          :options="commissionModels"
        />
      </div>
    </template>
  </CrudToggleCard>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    programId: {
      type: Number,
      required: true,
    },

    promotionActionId: {
      type: Number,
      required: false,
      default: null,
    },

    promotionActionType: {
      type: String,
      required: false,
      default: null,
    },

    needsCommissionModels: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      loading: true,

      commissionModels: [],
    };
  },

  computed: {
    program() {
      return this.$store.state.form.programs[this.programId];
    },
  },

  watch: {
    '$page.props.errors': function (newValue) {
      const commissionModelErrors = [];
      for (const errorKey in newValue) {
        if (errorKey && errorKey.indexOf(`commission_model_ids.${this.programId}.`) === 0) {
          commissionModelErrors.push(newValue[errorKey]);
        }
      }

      if (commissionModelErrors.length > 0) {
        this.$page.props.errors[`commission_model_ids.${this.programId}`] = commissionModelErrors.join(', ');
      }
    },
  },

  unmounted() {
    // program deselected, so remove it from the programs array
    delete this.$store.state.form.programs[this.programId];
    if (this.needsCommissionModels) {
      delete this.$store.state.form.commission_model_ids[this.programId];
    }
  },

  mounted() {
    let route = '';

    if (this.promotionActionId) {
      route = this.route('admin.api.program-promotion-action.details', {
        program: this.programId,
        promotion_action_type: this.promotionActionType,
        promotion_action_id: this.promotionActionId,
        include: this.needsCommissionModels ? 'webshop_commission_models,commission_models.tags.informational,commission_models.tags.category_description' : null,
      });
    } else {
      route = this.route('admin.api.programs.details', {
        program: this.programId,
        include: this.needsCommissionModels ? 'commission_models.tags.informational,commission_models.tags.category_description' : null,
      });
    }

    axios.get(route)
      .then(({data}) => {
        let useProgramTrackingUrl = true;
        let trackingUrl = data.used_tracking_url;
        let programTrackingUrl = trackingUrl;

        if (this.promotionActionId) {
          if (data.promotion_action_tracking_url) {
            useProgramTrackingUrl = false;
            trackingUrl = data.promotion_action_tracking_url;
            programTrackingUrl = data.program_tracking_url;
          } else if (data.program_tracking_url) {
            useProgramTrackingUrl = true;
            trackingUrl = data.program_tracking_url;
            programTrackingUrl = trackingUrl;
          }
        }

        this.$store.state.form.programs[this.programId] = {
          id: data.id,
          name: data.name,
          status: data.status,
          network_name: data.network_name,
          use_program_tracking_url: useProgramTrackingUrl,
          program_tracking_url: programTrackingUrl,
          tracking_url: trackingUrl,
          is_active_for_promotion_action: data.is_active_for_promotion_action,
        };

        if (this.needsCommissionModels) {
          if (this.promotionActionId && data.webshop_commission_models) {
            // (p)re-fill any selected webshop-commissionmodel
            this.$store.state.form.commission_model_ids[this.programId] = [];
            for (const commissionModel of data.commission_models) {
              if (data.webshop_commission_models.includes(commissionModel.id)) {
                this.$store.state.form.commission_model_ids[this.programId].push(commissionModel.id);
              }
            }
          }

          if (!this.promotionActionId && data.is_automatically_linked) {
            this.$store.state.form.commission_model_ids[this.programId] = [];
            for (const commissionModel of data.commission_models) {
              if (commissionModel.can_connect) {
                this.$store.state.form.commission_model_ids[this.programId].push(commissionModel.id);
              }
            }
          }

          const selectedCommissionModels = this.$store.state.form.commission_model_ids[this.programId] ?? [];
          this.commissionModels = this.buildSelectableCommissionModels(
            data.commission_models,
            selectedCommissionModels,
          );
        }

        this.loading = false;
      });
  },

  methods: {
    copyTrackingUrl(trackingUrl) {
      navigator.clipboard.writeText(trackingUrl);
    },

    isProgramActiveForPromotionAction(program) {
      if (!program) {
        return false;
      }

      if (Object.keys(program).includes('is_active_for_promotion_action')) {
        return program.is_active_for_promotion_action;
      }

      return false;
    },
  },
};
</script>
