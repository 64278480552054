<template>
  <CrudCreate
    title="Mailbanners - Create"
    sub-title="Mailbanner"
    :index-route="route('admin.mailbanners.index')"
    :store-route="route('admin.mailbanners.store')"
  >
    <template #cards>
      <MailbannerForm
        :advertiser-id="advertiserId"
      />
    </template>
  </CrudCreate>
</template>

<script>
import MailbannerForm from './Form.vue';

export default {
  components: {
    MailbannerForm,
  },

  props: {
    mailbanner: {
      type: Object,
      required: false,
      default: null,
    },

    advertiserId: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
