<template>
  <CrudIndex
    :title="title"
    :columns="['ID', 'Member', 'Date', 'Webshop', 'General score', 'Text', 'Status']"
    :actions="true"
    :menu-items="[
      {name: 'Approve', icon: 'thumb_up', url: (item) => route('admin.webshop-reviews.approve', item.id), enabled: (item) => item.status !== 'approved'},
      {name: 'Dispprove', icon: 'thumb_down', url: (item) => route('admin.webshop-reviews.disapprove', item.id), enabled: (item) => item.status !== 'disapproved'},
    ]"
    :data="reviews.data"
    :paginator="reviews.meta.pagination"
    :filters="filters"
  >
    <template #before-create>
      <slot name="before-create" />
    </template>

    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #date="{ row }">
      {{ row.created_at }}
    </template>

    <template #webshop="{ row }">
      {{ row.webshop_name }}
    </template>

    <template #status="{ item }">
      <span
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :class="{
          'bg-green-100 text-green-800' : item.toLowerCase() === 'approved',
          'bg-red-100 text-red-800' : item.toLowerCase() === 'disapproved',
          'bg-yellow-100 text-yellow-800' : item.toLowerCase() === 'in_review',
        }"
      >
        {{ item }}
      </span>
    </template>
  </CrudIndex>
</template>

<script setup>
import {defineProps} from 'vue';
import {usePage} from '@inertiajs/vue3';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },

  reviews: {
    type: Object,
    required: true,
  },

  needsFilters: {
    type: Boolean,
    required: true,
  },
});

const $page = usePage();

let filters = [];
if (props.needsFilters) {
  filters = [
    {
      type: 'dropdown',
      operator: 'equals-array',
      label: 'Status',
      value: 'status',
      multiple: true,
      items: [
        {name: 'In review', value: 'in_review'},
        {name: 'Approved', value: 'approved'},
        {name: 'Disapproved', value: 'disapproved'},
      ],
    },
    {type: 'search', fields: [{label: 'Member ID', value: 'member_id'}]},
    {
      type: 'relation',
      label: 'Webshop',
      value: 'webshop_id',
      multiple: true,
      api: route('admin.api.webshop-reviews.webshops.filter'),
    },
    {
      type: 'date-range',
      timezone: $page.props.auth.user.timezone,
      fields: [{label: 'Date', value: 'created_at'}],
    },
  ];
}

</script>
