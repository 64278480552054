<template>
  <CrudIndex
    title="Automatic mail statistics"
    :columns="['Type', 'Sent', 'Clicks', 'Clicks/Sent', 'Views', 'Views/Sent', 'Clicks/Views']"
    :data="automaticMails"
    :actions="true"
    :menu-items="[
      {name: 'Preview', icon: 'preview', url: (item) => item.preview_url, target: '_blank', enabled: true},
    ]"
    :filters="[
      {
        type: 'relation',
        label: 'Referred By',
        value: 'referred_by',
        multiple: false,
        api: route('admin.api.referrals.referrers.filter'),
      },
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'date'}],
      },
    ]"
  />
</template>

<script>
export default {
  props: {
    automaticMails: {
      type: Array,
      required: true,
    },
  },
};
</script>
