<template>
  <div class="pt-4">
    <InputField
      v-model="$store.state.form.description"
      label="Description"
      name="description"
      help="In English"
    />
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    tag: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      description: props.tag ? props.tag.description : '',
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
