<template>
  <MailingOverviewPlanning
    :mailings="mailings"
    :allowed-statuses="allowedStatuses"
    :non-editable-scheduled-at-statuses="nonEditableScheduledAtStatuses"
    :filterable-notification-types="filterableNotificationTypes"
    :disable-advertiser-filter="disableAdvertiserFilter"
    :disable-notification-type-filter="disableNotificationTypeFilter"
    :enable-survey-filter="enableSurveyFilter"
    :enable-survey-wave-filter="enableSurveyWaveFilter"
    :show-duplicate-menu-item="showDuplicateMenuItem"
    :enable-inactive-link="enableInactiveLink"
    :can-advertiser-check="canAdvertiserCheck"
  />
</template>

<script>
import MailingOverviewPlanning from '../../Components/Mailing/Overview/Planning.vue';

export default {
  components: {MailingOverviewPlanning},

  props: {
    mailings: {
      type: Object,
      required: true,
    },

    allowedStatuses: {
      type: Array,
      required: true,
    },

    nonEditableScheduledAtStatuses: {
      type: Array,
      required: true,
    },

    filterableNotificationTypes: {
      type: Array,
      required: true,
    },

    disableAdvertiserFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableNotificationTypeFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableSurveyWaveFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    showDuplicateMenuItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableInactiveLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    canAdvertiserCheck: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
