<template>
  <div v-if="hasNotSelectedCountry()">
    Please select a country
  </div>
  <div v-else>
    <CrudIndex
      title="Notification Bars"
      create-route-path="admin.notification-bars.create"
      edit-route-path="admin.notification-bars.edit"
      delete-route-path="admin.notification-bars.destroy"
      :columns="['ID', 'Name', 'Text', 'Start At', 'End At', 'Country', 'Enabled']"
      :actions="true"
      :data="notificationBars.data"
      :paginator="notificationBars.meta.pagination"
    >
      <template #start_at="{ row }">
        {{ row.start_at ?? '-' }}
      </template>
      <template #end_at="{ row }">
        {{ row.end_at ?? '-' }}
      </template>

      <template #countries="{ row }">
        <span
          v-for="(country, i) in row.countries"
          :key="`country-${row.id}-${country.id}`"
        >
          <InertiaLink
            :href="route('admin.countries.edit', country.id)"
            class="text-indigo-600 hover:text-indigo-900"
            @click.stop
          >
            {{ country.name }}
          </InertiaLink>

          <span v-if="i < row.countries.length - 1">
            -
          </span>
        </span>
      </template>

      <template #enabled="{ row }">
        <span
          v-if="row.is_enabled"
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
        >
          Yes
        </span>
        <span
          v-else
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
        >
          No
        </span>
      </template>
    </CrudIndex>
  </div>
</template>

<script>
export default {
  props: {
    notificationBars: {
      type: Object,
      required: true,
    },
  },
};
</script>
