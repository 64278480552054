<template>
  <CrudIndex
    title="Webshop statistics"
    :columns="['Webshop', 'Favorites', 'Clickouts', 'Transactions', 'Turnover', 'Advertiser has logo', 'Advertiser has cover', 'Advertiser has rectangle', 'Famous webshop', 'Top category']"
    :data="webshops.data"
    :paginator="webshops.meta.pagination"
    :actions="false"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}],
      },
    ]"
    :order-columns="{
      'Webshop': 'webshops.name',
      'Favorites': 'members_count',
      'Clickouts': 'clickouts_count',
      'Transactions': 'transactions_count',
      'Turnover': 'transactions_commission_value',
    }"
    :order="order"
  >
    <template #webshop="{ row }">
      <a
        :href="route('admin.webshops.edit', row.webshop_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.webshop_name }}
      </a>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    webshops: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
