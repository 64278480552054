<template>
  <ModalForm
    :id="`advertiser_check_form_${mailingId}`"
    v-model:open="modalOpen"
    :submit-route="submitUrl"
    :is-inertia-request="false"
  >
    <template #fields>
      <InputField
        v-model="emailAddress"
        label="Email address"
        name="email_address"
        type="email"
        :required="true"
      />

      <InputField
        v-model="expectedClicks"
        label="Expected clicks"
        name="expected_clicks"
        type="number"
        :required="false"
      />
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from '../ModalForm.vue';

export default {
  components: {ModalForm},

  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    submitUrl: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:mailingId',
    'update:submitUrl',
  ],

  data() {
    return {
      modalOpen: this.mailingId > 0,
      emailAddress: '',
      expectedClicks: null,
    };
  },

  watch: {
    modalOpen: function (newValue) {
      if (newValue === false) {
        this.$emit('update:mailingId', null);
        this.$emit('update:submitUrl', null);
      }
    },
  },
};
</script>
