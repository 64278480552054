<template>
  <CrudCard>
    <template #content>
      <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Balance
          </div>
        </div>
        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          {{ member.currentBalance.formatted_with_points }}
        </div>
      </div>

      <InputField
        v-model="$store.state.form.id"
        label="Member ID"
        name="id"
        disabled
      />

      <InputField
        v-model="$store.state.form.country_name"
        label="Country"
        name="country_name"
        disabled
      />

      <InputField
        v-model="$store.state.form.external_id"
        label="External ID"
        name="external_id"
        disabled
      />

      <InputField
        v-model="$store.state.form.email"
        label="Email"
        name="email"
        type="email"
      />
      <CheckboxField
        v-model="$store.state.form.is_deliverable"
        label="Is deliverable"
        name="is_deliverable"
        :required="false"
      />

      <InputField
        :model-value="member.deliverable_reason"
        label="Deliverable reason"
        name="deliverable_reason"
        disabled
      />

      <SelectField
        v-model="$store.state.form.gender"
        label="Gender"
        name="gender"
        placeholder="Select a Gender"
        :items="genders"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.first_name"
        label="First Name"
        name="first_name"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.last_name"
        label="Last Name"
        name="last_name"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.birth_date"
        label="Birth Date"
        name="birth_date"
        type="date"
        :required="false"
      />
    </template>
  </CrudCard>

  <CrudCard>
    <template #header>
      <span class="font-medium">
        Settings
      </span>
    </template>

    <template #content>
      <CheckboxField
        v-model="$store.state.form.is_possible_cheater"
        label="Possible cheater"
        name="is_possible_cheater"
        :required="false"
      >
        <template #extra>
          <DefaultButton
            type="button"
            classes="text-white bg-indigo-600 hover:bg-indigo-700 px-2.5 py-1.5"
            @click="openIsPossibleCheaterHistoryModal"
          >
            <span
              class="material-icons md-24"
              title="Show history"
            >
              history
            </span>
          </DefaultButton>
        </template>
      </CheckboxField>
      <CheckboxField
        v-model="$store.state.form.is_fraudster"
        label="Fraudster"
        name="is_fraudster"
        :required="false"
      >
        <template #extra>
          <DefaultButton
            type="button"
            classes="text-white bg-indigo-600 hover:bg-indigo-700 px-2.5 py-1.5"
            @click="openIsFraudsterHistoryModal"
          >
            <span
              class="material-icons md-24"
              title="Show history"
            >
              history
            </span>
          </DefaultButton>
        </template>
      </CheckboxField>
      <CheckboxField
        v-model="$store.state.form.referred_free_premium"
        label="Referred free premium"
        name="referred_free_premium"
        help="Referred members get 'Free Premium' subscription."
        :required="false"
      />
      <InputField
        v-if="$store.state.form.referred_free_premium"
        v-model="$store.state.form.referred_free_premium_days"
        label="Referred free premium days"
        name="referred_free_premium_days"
        help="Empty is 'lifetime'"
        :required="false"
      />
      <CheckboxField
        v-model="$store.state.form.show_on_duplicates_overview"
        label="Show on duplicates overview"
        name="show_on_duplicates_overview"
        help="Show on 'Duplicate members' overview page."
        :required="false"
      />
    </template>
  </CrudCard>

  <CrudCard>
    <template #header>
      <span class="font-medium">
        Update Password
      </span>
    </template>

    <template #content>
      <InputField
        v-model="$store.state.form.password"
        type="password"
        autocomplete="new-password"
        label="Password"
        name="password"
        :required="false"
      />

      <InputField
        v-model="$store.state.form.password_confirmation"
        type="password"
        autocomplete="new-password"
        label="Password Confirmation"
        name="password_confirmation"
        :required="false"
      />
    </template>
  </CrudCard>

  <CrudCard>
    <template #header>
      <span class="font-medium">
        Info
      </span>
    </template>

    <template #content>
      <CrudShowContent
        :rows="[
          'Balance',
          'Total earned',
          'Total approved payouts',
          'Total disapproved payouts',
          'Total open payouts',
          'First payment succeeded',
          'Login count',
          'Last login at',
          'Last clickout',
        ]"
        :data="{
          balance: member.currentBalance.formatted_with_points,
          total_earned: member.totalEarnedBalance.formatted,
          total_approved_payouts: member.payoutInfo.total_approved,
          total_disapproved_payouts: member.payoutInfo.total_disapproved,
          total_open_payouts: member.payoutInfo.total_pending,
          first_payment_succeeded: member.first_payment_succeeded,
          login_count: member.login_count,
          last_login_at: member.last_login_at,
          last_clickout: member.lastClickout && member.lastClickout.model_type ? `${member.lastClickout.created_at} (${member.lastClickout.model_type})` : 'n/a',
        }"
      />

      <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Is duplicate
          </div>
        </div>

        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <span
            v-if="member.isDuplicate"
            class="px-2 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            Yes
          </span>
          <span
            v-else
            class="px-2 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
          >
            No
          </span>
        </div>
        <div
          v-if="member.isDuplicate"
          class="col-span-2"
        >
          <div class="float-left">
            <DefaultButton
              :internal-link="false"
              :href="route('admin.duplicate-members.index', {filter: {member_id: `equals:${member.id}`}})"
              target="_blank"
            >
              <span class="material-icons md-18">open_in_new</span>
            </DefaultButton>
          </div>
        </div>
      </div>

      <div
        class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0"
      >
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Subscription type
          </div>
        </div>

        <div
          class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2"
        >
          {{ subscription_type }}
        </div>
        <div
          class="col-span-2"
        >
          <div
            class="float-left"
          >
            <DefaultButton
              type="button"
              classes="text-white bg-indigo-600 hover:bg-indigo-700 px-2.5 py-1.5"
              @click="openSubscriptionHistoryModal"
            >
              <span
                class="material-icons md-24"
                title="Show history"
              >history</span>
            </DefaultButton>
          </div>

          <div v-if="updatingSubscriptionType">
            <div class="text-2xl text-gray-600 mb-2 float-left">
              <i class="fa fa-spinner fa-spin mr-2" />
            </div>
          </div>
          <div
            v-if="!updatingSubscriptionType"
            class="float-left"
          >
            <DefaultButton
              v-if="subscription_type === 'free'"
              type="button"
              classes="text-white bg-amber-600 hover:bg-amber-700 "
              @click.prevent="updateSubscriptionType('free_premium')"
            >
              Switch to 'free premium'
            </DefaultButton>
            <DefaultButton
              v-if="subscription_type === 'free_premium'"
              type="button"
              classes="text-white bg-amber-600 hover:bg-amber-700"
              @click.prevent="updateSubscriptionType('free')"
            >
              Switch to 'free'
            </DefaultButton>
          </div>
        </div>
      </div>

      <InputField
        v-if="subscription_type === 'free_premium'"
        :model-value="member.subscription_expires_at"
        label="Free premium end date"
        name="subscription_expires_at"
        :disabled="true"
      />
    </template>
  </CrudCard>

  <CrudCard>
    <template #header>
      <span class="font-medium">
        Registration Details
      </span>
    </template>

    <template #content>
      <InputField
        v-model="$store.state.form.registration_date"
        label="Date/Time"
        name="registration_date"
        disabled
      />

      <InputField
        v-model="$store.state.form.email_verified_at"
        label="Email Verified Date/Time"
        name="email_verified_at"
        disabled
      />

      <InputField
        v-model="$store.state.form.registration_ip"
        label="IP Address"
        name="registration_ip"
        disabled
      />

      <InputField
        v-model="$store.state.form.registration_user_agent"
        label="User Agent"
        name="registration_user_agent"
        disabled
      />

      <div
        class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0"
      >
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Referred by
          </div>
        </div>

        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <a
            v-if="member.referredByMember.id"
            :href="route('admin.members.edit', member.referredByMember.id)"
            class="text-indigo-600 hover:text-indigo-900"
            target="_blank"
            @click.stop
          >
            #{{ member.referredByMember.id }} {{ member.referredByMember.first_name }} {{ member.referredByMember.last_name }} ({{ member.referredByMember.email }})
          </a>
        </div>
      </div>
    </template>
  </CrudCard>

  <CrudCard>
    <template #header>
      <span class="font-medium">
        Share information
      </span>
    </template>

    <template #content>
      <CrudShowContent
        :rows="[
          'Clicks',
          'Approved',
          'Pending',
          'Disapproved',
        ]"
        :data="{
          clicks: member.tellAFriendInfo.clicks,
          approved: member.tellAFriendInfo.approved,
          pending: member.tellAFriendInfo.pending,
          disapproved: member.tellAFriendInfo.disapproved,
        }"
      />
    </template>
  </CrudCard>

  <HistoryModal
    :id="member.id"
    v-model:open="subscriptionHistoryOpen"
    :history-route="'admin.members.subscription.history.get'"
  />

  <HistoryModal
    :id="member.id"
    v-model:open="isPossibleCheaterHistoryOpen"
    :history-route="'admin.members.is_possible_cheater.history.get'"
  />

  <HistoryModal
    :id="member.id"
    v-model:open="isFraudsterHistoryOpen"
    :history-route="'admin.members.is_fraudster.history.get'"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import axios from 'axios';
import {ref} from 'vue';
import HistoryModal from '../../Components/HistoryModal.vue';

export default {
  components: {HistoryModal},

  props: {
    member: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      id: props.member.id,
      country_name: props.member.country_name,
      external_id: props.member.external_id,
      email: props.member.email,
      gender: props.member.gender,
      first_name: props.member.first_name,
      last_name: props.member.last_name,
      birth_date: props.member.birth_date,

      is_deliverable: props.member.is_deliverable,
      is_possible_cheater: props.member.is_possible_cheater,
      is_fraudster: props.member.is_fraudster,
      referred_free_premium: props.member.referred_free_premium,
      referred_free_premium_days: props.member.referred_free_premium_days,
      show_on_duplicates_overview: props.member.show_on_duplicates_overview,

      password: null,
      password_confirmation: null,

      registration_date: props.member.created_at,
      email_verified_at: props.member.email_verified_at,
      registration_ip: props.member.registration_ip,
      registration_user_agent: props.member.registration_user_agent,
    });

    useStore().commit('form/setForm', form);

    return {
      subscription_type: ref(props.member.subscription_type),
      updatingSubscriptionType: ref(false),
      subscriptionHistoryOpen: ref(false),
      isPossibleCheaterHistoryOpen: ref(false),
      isFraudsterHistoryOpen: ref(false),
    };
  },

  methods: {
    updateSubscriptionType(newType) {
      this.updatingSubscriptionType = true;

      axios.patch(
        this.route('admin.members.subscription.type.update', [this.member.id]),
        {type: newType},
      )
        .then((response) => {
          this.updatingSubscriptionType = false;
          this.subscription_type = response.data.type;

          return response;
        });
    },

    openSubscriptionHistoryModal() {
      this.subscriptionHistoryOpen = true;
    },

    openIsPossibleCheaterHistoryModal() {
      this.isPossibleCheaterHistoryOpen = true;
    },

    openIsFraudsterHistoryModal() {
      this.isFraudsterHistoryOpen = true;
    },
  },
};
</script>
