<template>
  <CrudIndex
    :title="`Payout history #${payout.id}`"
    :columns="['Modified at', 'Type', 'Old', 'New', 'Modified by']"
    :actions="true"
    :data="history"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.payouts.show', payout.id)"
        class="inline-flex items-center px-4 py-2 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-purple-500"
      >
        Back
      </InertiaLink>
    </template>

    <template #old="{ item }">
      <table>
        <tr
          v-for="(value, key) in item"
          :key="`old-${key}`"
        >
          <td>
            {{ key }}
          </td>
          <td class="pl-8">
            {{ value }}
          </td>
        </tr>
      </table>
    </template>

    <template #new="{ item }">
      <table>
        <tr
          v-for="(value, key) in item"
          :key="`new-${key}`"
        >
          <td>
            {{ key }}
          </td>
          <td class="pl-8">
            {{ value }}
          </td>
        </tr>
      </table>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    payout: {
      type: Object,
      required: true,
    },

    history: {
      type: Array,
      required: true,
    },
  },
};
</script>
