<template>
  <MultiSelectField
    v-model="$store.state.form.country_ids"
    label="Active countries"
    name="country_ids"
    placeholder="Select countries"
    mode="tags"
    :options="availableCountries"
    :required="false"
  />

  <InputField
    v-model="$store.state.form.name"
    label="Name"
    name="name"
  />

  <InputField
    v-model="$store.state.form.bank_account_name"
    label="Bank account name"
    name="bank_account_name"
  />

  <InputField
    v-model="$store.state.form.iban"
    label="IBAN"
    name="iban"
    maxlength="34"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    charity: {
      type: Object,
      required: false,
      default: null,
    },

    countries: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      country_ids: props.charity ? props.charity.country_ids : null,
      name: props.charity ? props.charity.name : null,
      bank_account_name: props.charity ? props.charity.bank_account_name : null,
      iban: props.charity ? props.charity.iban : null,
    });

    useStore().commit('form/setForm', form);

    return {
      availableCountries: props.countries.reduce((accumulator, country) => {
        accumulator[country.id] = country.name;
        return accumulator;
      }, {}),
    };
  },
};
</script>
