<template>
  <div>
    <MultiSelectField
      v-model="$store.state.form.planner_id"
      label="Planner"
      name="planner_id"
      placeholder="Select planner"
      mode="single"
      :searchable="false"
      :can-deselect="false"
      :can-clear="false"
      :options="administrators"
      :disabled="true"
      :required="true"
    />

    <CheckboxField
      v-model="$store.state.mailingData.first_control"
      label="First control"
      name="first_control"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />
    <MultiSelectField
      v-if="$store.state.mailingData.first_control"
      v-model="$store.state.form.first_controller_id"
      label="First controller"
      name="first_controller_id"
      placeholder="Select controller"
      mode="single"
      :searchable="true"
      :can-deselect="true"
      :can-clear="true"
      :options="administrators"
      :disabled="$store.state.mailingData.isSent"
    />

    <CheckboxField
      v-model="$store.state.mailingData.final_control"
      label="Final control"
      name="final_control"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />
    <MultiSelectField
      v-if="$store.state.mailingData.final_control"
      v-model="$store.state.form.final_controller_id"
      label="Final controller"
      name="final_controller_id"
      placeholder="Select controller"
      mode="single"
      :searchable="true"
      :can-deselect="true"
      :can-clear="true"
      :options="administrators"
      :disabled="$store.state.mailingData.isSent"
    />

    <TextareaField
      v-model="$store.state.form.advertiser_agreements"
      label="Advertiser agreements"
      name="advertiser_agreements"
      rows="6"
      :required="false"
      :disabled="$store.state.mailingData.isSent"
    />
  </div>
</template>

<script>
export default {
  props: {
    administrators: {
      type: Object,
      required: true,
    },
  },
};
</script>
