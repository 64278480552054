<template>
  <CrudEdit
    :title="freeAction.name"
    sub-title="Free actions"
    :index-route="route('admin.free-actions.index')"
    :update-route="route('admin.free-actions.update', freeAction.id)"
  >
    <template #cards>
      <FreeActionForm
        :free-action="freeAction"
        :free-action-categories="freeActionCategories"
        :programs="programsWithKeys()"
        :network-ids="networkIds"
      />
    </template>
  </CrudEdit>
</template>

<script>
import FreeActionForm from './Form.vue';

export default {
  components: {FreeActionForm},

  props: {
    freeAction: {
      type: Object,
      required: true,
    },

    freeActionCategories: {
      type: Object,
      required: true,
    },

    programs: {
      type: Array,
      required: true,
    },

    networkIds: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.disableCountrySelector();
  },

  methods: {
    programsWithKeys() {
      let programsWithKeys = {};
      this.programs.forEach(program => programsWithKeys[program.id] = program);

      return programsWithKeys;
    },
  },
};
</script>
