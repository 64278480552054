<template>
  <CrudCreate
    title="Profile answers - Create"
    sub-title="Profile answers"
    :index-route="route('admin.profile-answers.index', question.id)"
    :store-route="route('admin.profile-answers.store', question.id)"
  >
    <template #form>
      <AnswerForm
        :question="question"
      />
    </template>
  </CrudCreate>
</template>

<script>
import AnswerForm from './Form.vue';

export default {
  components: {
    AnswerForm,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },
};
</script>
