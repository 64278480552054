<template>
  <CrudIndex
    title="Manual Programs"
    create-route-path="admin.manual-programs.create"
    row-route-path="admin.manual-programs.edit"
    delete-route-path="admin.manual-programs.destroy"
    :columns="['ID', 'Name', 'Country', 'Program ID']"
    :data="programs.data"
    :paginator="programs.meta.pagination"
    :actions="true"
    :menu-items="[
      {name: 'Edit', url: (item) => route('admin.manual-programs.edit', [item.id]), enabled: true},
    ]"
    :filters="[
      {type: 'search', fields: [
        {label: 'Name', value: 'name'},
        {label: 'Program ID', value: 'program_id'},
        {label: 'Tracking URL', value: 'tracking_url'},
      ]},
    ]"
  />
</template>

<script>
export default {
  props: {
    programs: {
      type: Object,
      required: true,
    },
  },
};
</script>
