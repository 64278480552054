<template>
  <MultiSelectField
    v-model="$store.state.form.network_id"
    label="Affiliwings Network"
    name="network_id"
    placeholder="Select network"
    mode="single"
    :options="affiliwingsNetworks"
    :can-deselect="false"
    :can-clear="false"
  />

  <InputField
    v-model="$store.state.form.year"
    label="Year"
    name="year"
    type="number"
    min="2000"
  />

  <InputField
    v-model="$store.state.form.month"
    label="Month"
    name="month"
    type="number"
    min="1"
    max="12"
  />

  <CurrencyField
    v-model="$store.state.form.value"
    name="value"
    label="Value"
    :options="currencyOptions"
  />

  <TextareaField
    v-model="$store.state.form.note"
    name="note"
    label="Note"
    rows="6"
    :required="false"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    affiliwingsNetworks: {
      type: Object,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    networkGuarantee: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const currentDate = new Date();

    const form = useForm({
      network_id: props.networkGuarantee?.network_id ?? null,
      year: props.networkGuarantee?.year ?? currentDate.getFullYear(),
      month: props.networkGuarantee?.month ?? currentDate.getMonth() + 1,
      value: props.networkGuarantee?.value ?? null,
      note: props.networkGuarantee?.note ?? null,
    });

    useStore().commit('form/setForm', form);

    return {
      currencyOptions: { currency: props.currency },
    };
  },
};
</script>
