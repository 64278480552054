<template>
  <HeadlessDialog
    as="div"
    class="fixed z-10 inset-0 overflow-y-auto"
    :open="open"
    @close="close"
  >
    <HeadlessTransitionRoot
      appear
      :show="open"
      as="div"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <HeadlessDialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>

        <HeadlessTransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
            <div class="bg-white">
              <div class="p-8">
                <div v-if="!replaceTag">
                  <p class="pb-4 font-semibold">
                    Create a new blog link:
                  </p>

                  <div class="border-b">
                    <MultiSelectField
                      v-model="advertiser"
                      label="Advertiser"
                      name="advertiser"
                      placeholder="Select advertiser"
                      mode="single"
                      :searchable="true"
                      :can-deselect="true"
                      :min-chars="2"
                      :delay="150"
                      :loading="loadingAdvertisers.value"
                      :options="async (query) => await fetchAdvertisers(query)"
                      :class="error?.response?.data?.errors?.advertiser_id ? 'border-red-500' : ''"
                    />

                    <MultiSelectField
                      v-model="program"
                      label="Program"
                      name="program"
                      placeholder="Select program"
                      mode="single"
                      :searchable="true"
                      :can-deselect="true"
                      :min-chars="2"
                      :delay="150"
                      :loading="loadingPrograms.value"
                      :options="async (query) => await fetchPrograms(query)"
                      :class="error?.response?.data?.errors?.program_id ? 'border-red-500' : ''"
                      @change="(value) => fetchProgramTrackingUrl(value)"
                    />

                    <CheckboxField
                      v-model="useProgramUrl"
                      name="use_program_url"
                      label="Use Program Url"
                      :required="false"
                    />

                    <InputField
                      v-model="url"
                      label="Url"
                      name="url"
                      :required="false"
                      :disabled="useProgramUrl || loadingProgramUrl"
                      :class="{
                        'border-red-500': error?.response?.data?.errors?.url,
                      }"
                    />

                    <div v-if="error">
                      <p class="text-red-500 font-semibold text-sm">
                        Failed to generate blog link, please check your input data.
                      </p>
                    </div>

                    <div class="pb-4 w-full text-right">
                      <DefaultButton @click.prevent="generateNewBlogLink">
                        Generate replace tag
                      </DefaultButton>
                    </div>
                  </div>

                  <div class="pt-4 font-semibold">
                    Select existing blog link:
                  </div>

                  <MultiSelectField
                    v-model="blogLink"
                    label="Blog link"
                    name="blog_link"
                    placeholder="Select blog link"
                    mode="single"
                    :searchable="true"
                    :can-deselect="true"
                    :min-chars="2"
                    :delay="150"
                    :loading="loadingBlogLinks.value"
                    :options="async (query) => await fetchBlogLinks(query)"
                  />

                  <div class="pb-4 w-full text-right">
                    <DefaultButton @click.prevent="generateTag(blogLink)">
                      Generate replace tag
                    </DefaultButton>
                  </div>
                </div>
                <div v-if="replaceTag">
                  <p class="pb-2">
                    Replace tag:
                  </p>
                  <code class="mr-2">{{ replaceTag }}</code>
                  <i
                    class="far fa-copy cursor-pointer"
                    title="Copy to clipboard"
                    @click="copyReplaceTagToClipboard"
                  />
                  <p class="text-xs pt-2">
                    Copy and paste the replace tag into your content. <br>
                    The replace tag will be replaced with the generated link when the content is displayed.
                  </p>

                  <div class="w-full text-right">
                    <DefaultButton @click.prevent="clearTag">
                      Generate new replace tag
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <DefaultButton
                classes="text-white bg-indigo-600 hover:bg-indigo-700"
                @click="close"
              >
                Close
              </DefaultButton>
            </div>
          </div>
        </HeadlessTransitionChild>
      </div>
    </HeadlessTransitionRoot>
  </HeadlessDialog>
</template>

<script>
import {
  Dialog as HeadlessDialog,
  DialogOverlay as HeadlessDialogOverlay,
  TransitionChild as HeadlessTransitionChild,
  TransitionRoot as HeadlessTransitionRoot,
} from '@headlessui/vue';
import axios from 'axios';
import {ref} from 'vue';

export default {
  components: {HeadlessDialog, HeadlessDialogOverlay, HeadlessTransitionRoot, HeadlessTransitionChild},

  props: {
    open: {
      type: Boolean,
      required: true,
    },

    blog: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:open'],

  data() {
    return {
      advertiser: null,
      program: null,
      blogLink: null,
      url: null,
      loadingAdvertisers: ref(false),
      loadingPrograms: ref(false),
      loadingProgramUrl: ref(false),
      programUrl: null,
      loadingBlogLinks: ref(false),
      replaceTag: ref(''),
      error: ref(null),
      useProgramUrl: ref(true),
    };
  },

  watch: {
    useProgramUrl() {
      this.url = this.programUrl;
    },
  },

  methods: {
    close() {
      this.$emit('update:open', false);
    },

    async fetchAdvertisers(query) {
      this.loadingAdvertisers = true;

      return await axios.get(this.route('admin.api.advertisers.find', {name: query}))
        .then(({data}) => {
          this.loadingAdvertisers = false;
          return data;
        });
    },

    async fetchPrograms(query) {
      this.loadingPrograms = true;

      return await axios.get(this.route('admin.api.programs.search.name', {name: query}))
        .then(({data}) => {
          this.loadingPrograms = false;
          return data;
        });
    },

    async fetchBlogLinks(query) {
      this.loadingBlogLinks = true;

      return await axios.get(this.route('admin.api.blogs.links.search.url', {
        url: query,
        blog: this.blog.id,
      }))
        .then(({data}) => {
          this.loadingBlogLinks = false;
          return data;
        });
    },

    async generateNewBlogLink() {
      if (!this.advertiser || !this.program) {
        return;
      }

      this.error = null;

      await axios.post(this.route('admin.api.blogs.links.store', this.blog.id), {
        advertiser_id: this.advertiser,
        program_id: this.program,
        url: this.url,
        use_program_url: this.useProgramUrl,
      }).then(({data}) => {
        this.generateTag(data.id);
      }).catch((error) => {
        this.error = error;
      });
    },

    async fetchProgramTrackingUrl(programId) {
      this.loadingProgramUrl = true;

      return await axios.get(route('admin.api.programs.details', programId))
        .then(({data}) => {
          this.loadingProgramUrl = false;
          this.programUrl = data.tracking_url;
          this.url = data.tracking_url;
        });
    },

    generateTag(blogLinkId) {
      if (!blogLinkId) {
        return;
      }

      this.replaceTag = `:blog-link[${blogLinkId}]`;
    },

    clearTag() {
      this.replaceTag = '';

      this.advertiser = null;
      this.program = null;
      this.url = null;
      this.blogLink = null;
    },

    copyReplaceTagToClipboard() {
      navigator.clipboard.writeText(this.replaceTag);
      alert(`Replace tag copied to clipboard: ${this.replaceTag}`);
    },
  },
};
</script>
