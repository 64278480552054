<template>
  <CrudCreate
    title="Tag Item - Create"
    :sub-title="category.description"
    :index-route="route('admin.tag-categories.show', [category.id])"
    :store-route="route('admin.tag-categories.item.store', [category.id])"
  >
    <template #form>
      <ErrorList :fields="['description']" />

      <TagItemForm />
    </template>
  </CrudCreate>
</template>

<script>
import TagItemForm from './Form.vue';

export default {
  components: {TagItemForm},

  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>
