<template>
  <CrudShow
    :title="`Clickout #${clickout.id}`"
    :sub-title="`Clickouts: #${member.id}`"
    :back-route-path="route('admin.members.clickouts.index', member.id)"
    :rows="['ID', 'Click id', 'URL', 'Promotion type', 'Program', 'Cashback percentage', 'Cashback fixed', 'IP address', 'User agent', 'Created at', 'Order value']"
    :data="clickout"
  >
    <template #after-back>
      <InertiaLink
        :href="route('admin.cashbacks.create', {clickout: clickout.id})"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Add cashback
      </InertiaLink>
    </template>

    <template #promotion_type="{ item, data }">
      <PromotionActionLink
        :action-type="item"
        :action-name="data.promotion_action_name"
        :action-url="data.promotion_action_url"
      />
    </template>

    <template #program="{ data }">
      <a
        :href="route('admin.programs.edit', data.program_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ data.program_name }}
      </a>
    </template>

    <template #order_value="{data}">
      <template v-if="data.order_value === null">
        n/a
      </template>
      <template v-else>
        {{ data.order_value }}
      </template>
    </template>
  </CrudShow>

  <CrudIndex
    title="Cashbacks"
    :columns="['Transaction ID', 'Cashback ID', 'Advertiser', 'Commission', 'Cashback', 'Transaction datetime', 'Cashback datetime']"
    :data="clickout.cashbacks"
    :actions="true"
    :menu-items="[
      {name: 'Edit', icon: 'edit', action: (item) => editModalId = item.id, enabled: (item) => item.status.status === 'PENDING'},
    ]"
  >
    <template #transaction_id="{ row }">
      <span v-if="!row.transaction">
        n/a
      </span>
      <a
        v-else
        :href="route('admin.transactions.show', row.transaction.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.transaction.id }}
      </a>
    </template>

    <template #cashback_id="{ row }">
      <a
        :href="route('admin.cashbacks.show', row.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.id }}
      </a>
    </template>

    <template #advertiser="{ item }">
      <a
        :href="route('admin.advertisers.edit', item.id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ item.name }}
      </a>
    </template>

    <template #commission="{ row }">
      <div class="flex items-center">
        <span
          v-if="!row.transaction"
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        >
          Manual
        </span>
        <span
          v-else
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : row.transaction.status.status === 'APPROVED',
            'bg-red-100 text-red-800' : row.transaction.status.status === 'DISAPPROVED',
            'bg-yellow-100 text-yellow-800' : row.transaction.status.status === 'PENDING',
          }"
        >
          {{ row.transaction.commission }}
        </span>
        <Popover
          v-if="row.transaction && row.transaction.status.disapproval_reason"
          class="h-5 w-5 ml-1"
        >
          <PopoverButton class="inline-block">
            <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
          </PopoverButton>
          <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
            <p class="w-full">
              {{ row.transaction.status.disapproval_reason }}
            </p>
          </PopoverPanel>
        </Popover>
      </div>
    </template>

    <template #cashback="{ row }">
      <div class="flex items-center">
        <span
          class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          :class="{
            'bg-green-100 text-green-800' : row.status.status === 'APPROVED',
            'bg-red-100 text-red-800' : row.status.status === 'DISAPPROVED',
            'bg-yellow-100 text-yellow-800' : row.status.status === 'PENDING',
          }"
        >
          {{ row.cashback }}
        </span>
        <Popover
          v-if="row.status.status_reason"
          class="h-5 w-5 ml-1"
        >
          <PopoverButton class="inline-block">
            <InformationCircleIcon class="h-5 w-5 text-blue-500 mr-2" />
          </PopoverButton>
          <PopoverPanel class="absolute z-10 bg-white shadow-lg rounded-lg w-full max-w-sm p-4">
            <p class="w-full">
              {{ row.status.status_reason }}
            </p>
          </PopoverPanel>
        </Popover>
      </div>
    </template>

    <template #transaction_datetime="{ row }">
      <span v-if="!row.transaction">
        n/a
      </span>
      <span v-else>
        {{ row.transaction.transaction_datetime }}
      </span>
    </template>

    <template #cashback_datetime="{ row }">
      {{ row.date }}
    </template>
  </CrudIndex>

  <SiteHead
    :title="`Clickout #${clickout.id}`"
  />

  <EditStatusModal
    v-if="editModalId"
    v-model:editable-id="editModalId"
    :member-id="member.id"
    :statuses="cashbackStatuses"
    :submit-route="route('admin.members.clickouts.update-cashback-status', [member.id, editModalId])"
  />
</template>

<script>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {InformationCircleIcon} from '@heroicons/vue/solid';
import PromotionActionLink from '../../../Components/PromotionActionLink.vue';
import EditStatusModal from './EditStatusModal.vue';

export default {
  components: {
    EditStatusModal,
    Popover,
    PopoverButton,
    PopoverPanel,
    InformationCircleIcon,
    PromotionActionLink,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    clickout: {
      type: Object,
      required: true,
    },

    cashbackStatuses: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editModalId: null,
    };
  },
};
</script>
