<template>
  <CrudIndex
    title="Giffy Purchases"
    row-route-path="admin.giffy-purchases.show"
    :columns="{
      id: {
        label: 'ID',
      },
      member: {
        label: 'Member',
      },
      clickout: {
        label: 'Clickout',
      },
      purchased_amount: {
        label: 'Purchased amount',
      },
      promotion_action: {
        label: 'Promotion action',
      },
      balance_mutation: {
        label: 'Balance mutation',
      },
      created_at: {
        label: 'Created at',
      },
    }"
    :actions="false"
    :data="purchases.data"
    :paginator="purchases.meta.pagination"
  >
    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #clickout="{ row }">
      <a
        :href="route('admin.members.clickouts.show', {
          member: row.member_id,
          clickout: row.clickout_id,
        })"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.clickout_id }}
      </a>
    </template>

    <template #promotion_action="{ row }">
      <PromotionActionLink
        :action-type="row.promotion_type"
        :action-name="row.promotion_action_name"
        :action-url="row.promotion_action_url"
      />
    </template>
  </CrudIndex>
</template>

<script>
import PromotionActionLink from '../../Components/PromotionActionLink.vue';

export default {
  components: {
    PromotionActionLink,
  },

  props: {
    purchases: {
      type: Object,
      required: true,
    },
  },
};
</script>
