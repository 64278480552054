<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Transaction
      </span>
    </template>

    <template #content>
      <CrudShowContent
        :rows="['ID', 'Transaction ID', 'Transaction datetime', 'Created at', 'Updated at', 'Network', 'Network transaction ID', 'Status', 'Description', 'On hold reason', 'Commission', 'Cashback', 'Transaction currency', 'Country', 'Program', 'Advertiser', 'Promotion action']"
        :data="transaction"
      >
        <template #network="{ data }">
          {{ data.network_name }} ({{ data.account_name }}, {{ data.channel_name }})
        </template>

        <template #status="{ data }">
          <span
            class="px-2 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
            :title="data.status.status === 'DISAPPROVED' ? data.status.disapproval_reason : ''"
            :class="{
              'bg-green-100 text-green-800' : data.status.status === 'APPROVED',
              'bg-red-100 text-red-800' : data.status.status === 'DISAPPROVED',
              'bg-yellow-100 text-yellow-800' : data.status.status === 'PENDING',
            }"
          >
            {{ data.status.status }}
          </span>
          <span
            v-if="data.on_hold"
            class="material-icons md-18 align-middle"
            :title="`On hold: ${data.on_hold_reason}`"
          >search</span>

          <a
            :href="route('admin.transactions.history', transaction.id)"
            class="text-indigo-600 hover:text-indigo-900"
            target="_blank"
            @click.stop
          >
            <span
              class="material-icons md-24"
              title="Transaction history"
            >
              history
            </span>
          </a>
        </template>

        <template #transaction_currency="{ item }">
          <span v-if="item">{{ item.iso }}</span>
          <span v-else>n/a</span>
        </template>

        <template #country="{ data }">
          {{ data.country.code }}
        </template>

        <template #program="{ data }">
          <span v-if="!data.clickout">n/a</span>
          <a
            v-else
            :href="route('admin.programs.edit', data.clickout.program_id)"
            class="text-indigo-600 hover:text-indigo-900"
            target="_blank"
            @click.stop
          >
            {{ data.clickout.program_name }}
          </a>
        </template>

        <template #advertiser="{ data }">
          <span v-if="!data.clickout">n/a</span>
          <a
            v-else
            :href="route('admin.advertisers.edit', data.clickout.advertiser_id)"
            class="text-indigo-600 hover:text-indigo-900"
            target="_blank"
            @click.stop
          >
            {{ data.clickout.advertiser_name }}
          </a>
        </template>

        <template #promotion_action="{ data }">
          <span v-if="!data.clickout">n/a</span>
          <PromotionActionLink
            v-else
            :action-type="data.clickout.promotion_type"
            :action-name="data.clickout.promotion_action_name"
            :action-url="data.clickout.promotion_action_url"
          />
        </template>
      </CrudShowContent>
    </template>
  </CrudToggleCard>
</template>

<script>
import PromotionActionLink from './PromotionActionLink.vue';

export default {
  components: {PromotionActionLink},
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
};
</script>
