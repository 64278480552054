<template>
  <CrudCreate
    title="Free action - Create"
    sub-title="Free actions"
    :index-route="route('admin.free-actions.index')"
    :store-route="storeRoute()"
  >
    <template #cards>
      <FreeActionForm
        :free-action-categories="freeActionCategories"
        :program="program"
        :network-ids="networkIds"
      />
    </template>
  </CrudCreate>
</template>

<script>
import FreeActionForm from './Form.vue';

export default {
  components: {FreeActionForm},

  props: {
    freeActionCategories: {
      type: Object,
      required: true,
    },

    program: {
      type: Object,
      required: false,
      default: null,
    },

    networkIds: {
      type: Array,
      required: true,
    },
  },

  methods: {
    storeRoute() {
      let storeRoute = this.route('admin.free-actions.store');
      if (this.program) {
        storeRoute += `?program=${this.program.id}`;
      }

      return storeRoute;
    },
  },
};
</script>
