<template>
  <div>
    <template
      v-for="segmentationAttribute in segmentationAttributes"
      :key="segmentationAttribute"
    >
      <SelectField
        v-if="segmentationAttribute === 'gender'"
        v-model="$store.state.form.gender"
        label="Gender"
        name="gender"
        placeholder="Select a Gender"
        :items="genders"
        help="Leave empty to disable segmentation"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <InputField
        v-if="segmentationAttribute === 'minimum_age'"
        v-model="$store.state.form.minimum_age"
        type="number"
        label="Minimum Age"
        name="minimum_lage"
        min="0"
        max="255"
        help="Leave empty to disable segmentation, inclusive number"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <InputField
        v-if="segmentationAttribute === 'maximum_age'"
        v-model="$store.state.form.maximum_age"
        type="number"
        label="Maximum Age"
        name="maximum_age"
        min="0"
        max="255"
        help="Leave empty to disable segmentation, inclusive number"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <SelectField
        v-if="segmentationAttribute === 'percentiles'"
        v-model="$store.state.form.percentiles"
        label="Percentiles"
        name="percentiles"
        help="Leave empty to disable segmentation"
        :items="percentiles"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
        :multiple="true"
        field-classes="h-96 float-left"
      >
        <template #input-after>
          <div
            v-if="$store.state.form.mailing_filter_id"
            class="flex h-96 float-left ml-4 rounded-md border border-gray-300 w-10 text-sm leading-tight"
          >
            <div
              v-if="loadingPercentileDetails"
              class="text-center"
            >
              <i class="far fa-spin fa-spinner text-xl" />
            </div>
            <div v-else>
              <div
                v-for="(percentile) in percentiles"
                :key="percentile.id"
                class="m-0 w-10 border-b border-gray-300"
                :class="{
                  'bg-[#f08080]' : percentileDetails?.scheduled?.includes(percentile.id) && !percentileDetails?.sent?.includes(percentile.id),
                  'bg-[#90ee90]' : percentileDetails?.sent?.includes(percentile.id),
                }"
              >
                {{ percentile.name }}
              </div>
            </div>
          </div>
        </template>
      </SelectField>

      <MultiSelectField
        v-if="isSubscriptionSegmentationAllowed() && segmentationAttribute === 'subscription_types'"
        v-model="$store.state.form.subscription_types"
        label="Subscription types"
        name="subscription_types"
        placeholder="Select subscription type"
        help="Leave empty to disable segmentation"
        mode="tags"
        :options="subscriptionTypes"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <ExcludeWave
        v-if="segmentationAttribute === 'exclude_waves' && Object.keys($store.state.mailingData.surveyWaves).length > 0"
        v-model="$store.state.form.exclude_waves"
        label="Exclude waves"
        :required="false"
        :available-waves="$store.state.mailingData.surveyWaves"
        :survey-statuses="surveyStatuses"
      />

      <MultiSelectField
        v-if="segmentationAttribute === 'filter'"
        v-model="$store.state.form.mailing_filter_id"
        label="Mailing Filter"
        name="mailing_filter_id"
        placeholder="Select mailing filter"
        mode="single"
        :required="false"
        :searchable="true"
        :can-deselect="true"
        :can-clear="true"
        :resolve-on-load="true"
        :min-chars="2"
        :delay="150"
        :loading="loadingMailingFilters"
        :options="async (query) => await fetchMailingFilters(query)"
      />

      <CheckboxField
        v-if="segmentationAttribute === 'exclude_clickers_advertiser' && !isSurvey"
        v-model="$store.state.form.exclude_clickers_advertiser"
        label="Exclude advertiser clickers"
        name="exclude_clickers_advertiser"
        help="In last 30 days"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <CheckboxField
        v-if="segmentationAttribute === 'exclude_non_clickers'"
        v-model="$store.state.form.exclude_non_clickers"
        label="Exclude non-clickers"
        name="exclude_non_clickers"
        help="In last 60 days"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <MultiSelectField
        v-if="segmentationAttribute === 'exclude_clickers_mailing_ids' && $store.state.form.advertiser_id && !isSurvey"
        v-model="$store.state.form.exclude_clickers_mailing_ids"
        label="Exclude clickers on mailings"
        name="exclude_clickers_mailing_ids"
        placeholder="Select mailing(s)"
        mode="tags"
        :required="false"
        :searchable="true"
        :can-deselect="true"
        :can-clear="true"
        :resolve-on-load="true"
        :min-chars="2"
        :delay="150"
        :loading="loadingAdvertiserMailings"
        :options="async (query) => await fetchAdvertiserMailings(query)"
      />

      <CheckboxField
        v-if="segmentationAttribute === 'include_clickers_advertiser' && !isSurvey"
        v-model="$store.state.form.include_clickers_advertiser"
        label="Include advertiser clickers"
        name="include_clickers_advertiser"
        help="In last 60 days"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <CurrencyField
        v-if="segmentationAttribute === 'minimum_balance'"
        v-model="$store.state.form.minimum_balance"
        :options="balanceCurrency"
        label="Minimum Balance"
        name="minimum_balance"
        help="Greater or equal than"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <MultiSelectField
        v-if="segmentationAttribute === 'include_referred_by_members'"
        v-model="$store.state.form.include_referred_by_members"
        label="Must be referred by"
        name="include_referred_by_members"
        placeholder="Select one or more members"
        help="A list of members of which the member must be referred by"
        mode="tags"
        :required="false"
        :can-clear="false"
        :resolve-on-load="$store.state.form.include_referred_by_members.length > 0"
        :min-chars="2"
        :delay="150"
        :loading="loadingIncludeReferredByMembers"
        :options="async (query) => await fetchMembers(query, 'include')"
      />

      <MultiSelectField
        v-if="segmentationAttribute === 'exclude_referred_by_members'"
        v-model="$store.state.form.exclude_referred_by_members"
        label="Must NOT be referred by"
        name="exclude_referred_by_members"
        placeholder="Select one or more members"
        help="A list of members of which the member must NOT be referred by"
        mode="tags"
        :required="false"
        :can-clear="false"
        :resolve-on-load="$store.state.form.exclude_referred_by_members.length > 0"
        :min-chars="2"
        :delay="150"
        :loading="loadingExcludeReferredByMembers"
        :options="async (query) => await fetchMembers(query, 'exclude')"
      />

      <InputField
        v-if="segmentationAttribute === 'members_with_transaction_in_past_days'"
        v-model="$store.state.form.members_with_transaction_in_past_days"
        type="number"
        step="1"
        min="1"
        label="Members with transaction in last X days"
        name="members_with_transaction_in_past_days"
        placeholder="Leave empty to disable"
        help="Members with a transaction(despite commission, cashback, hidden, status, etc.) in last X days"
        :required="false"
        :disabled="$store.state.mailingData.isSent"
      />

      <template v-if="segmentationAttribute === 'members_approved_cashback_for_advertiser_types_in_past_days'">
        <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
          <div class="col-span-2">
            <div class="flex justify-between sm:justify-start">
              <label class="block font-medium text-gray-700 mt-px">
                Members with approved cashback for advertiser types
              </label>
              <span class="text-sm text-gray-500 sm:text-xs ml-1 mt-px">Optional</span>
            </div>
            <p class="block mt-2 text-sm text-gray-400">
              A list of advertiser types a member needs to have a approved cashback in the last X days.
              In case the number of days is empty there will be no time restriction.
            </p>
          </div>
          <div class="relative flex space-x-2 max-w-xl w-full mt-1 sm:mt-0 col-span-2">
            <MultiSelectField
              v-model="$store.state.form.members_approved_cashback_for_advertiser_types_in_past_days.advertiser_types"
              name="members_approved_cashback_for_advertiser_types_in_past_days.advertiser_types"
              placeholder="Select advertiser type(s)"
              class="w-full"
              mode="tags"
              :inline="true"
              :required="false"
              :searchable="false"
              :can-deselect="true"
              :can-clear="true"
              :resolve-on-load="true"
              :loading="loadingAdvertiserTypes"
              :options="fetchAdvertiserTypes"
              :disabled="$store.state.mailingData.isSent"
            />
            <InputField
              v-model="$store.state.form.members_approved_cashback_for_advertiser_types_in_past_days.days"
              type="number"
              step="1"
              min="1"
              name="members_approved_cashback_for_advertiser_types_in_past_days.days"
              placeholder="Number of days"
              :inline="true"
              :required="false"
              :disabled="$store.state.mailingData.isSent"
            />
          </div>
        </div>
      </template>

      <template v-if="segmentationAttribute === 'members_approved_cashback_for_webshop_categories_in_past_days'">
        <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
          <div class="col-span-2">
            <div class="flex justify-between sm:justify-start">
              <label class="block font-medium text-gray-700 mt-px">
                Members with approved cashback for webshop categories
              </label>
              <span class="text-sm text-gray-500 sm:text-xs ml-1 mt-px">Optional</span>
            </div>
            <p class="block mt-2 text-sm text-gray-400">
              A list of webshop categories a member needs to have a approved cashback in the last X days.
              In case the number of days is empty there will be no time restriction.
            </p>
          </div>
          <div class="relative flex space-x-2 max-w-xl w-full mt-1 sm:mt-0 col-span-2">
            <MultiSelectField
              v-model="$store.state.form.members_approved_cashback_for_webshop_categories_in_past_days.webshop_categories"
              name="members_approved_cashback_for_webshop_categories_in_past_days.webshop_categories"
              placeholder="Select webshop category(s)"
              class="w-full"
              mode="tags"
              :inline="true"
              :required="false"
              :searchable="false"
              :can-deselect="true"
              :can-clear="true"
              :resolve-on-load="true"
              :loading="loadingWebshopCategories"
              :options="fetchWebshopCategories"
              :disabled="$store.state.mailingData.isSent"
            />
            <InputField
              v-model="$store.state.form.members_approved_cashback_for_webshop_categories_in_past_days.days"
              type="number"
              step="1"
              min="1"
              name="members_approved_cashback_for_webshop_categories_in_past_days.days"
              placeholder="Number of days"
              :inline="true"
              :required="false"
              :disabled="$store.state.mailingData.isSent"
            />
          </div>
        </div>
      </template>

      <template v-if="segmentationAttribute === 'members_approved_cashback_for_free_actions_in_past_days'">
        <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
          <div class="col-span-2">
            <div class="flex justify-between sm:justify-start">
              <label class="block font-medium text-gray-700 mt-px">
                Members with approved cashback for free actions
              </label>
              <span class="text-sm text-gray-500 sm:text-xs ml-1 mt-px">Optional</span>
            </div>
            <p class="block mt-2 text-sm text-gray-400">
              A list of free actions a member needs to have a approved cashback in the last X days.
              In case the free actions are empty a cashback for any free action will be used.
              In case the number of days is empty there will be no time restriction.
            </p>
          </div>
          <div class="relative flex space-x-2 max-w-xl w-full mt-1 sm:mt-0 col-span-2">
            <MultiSelectField
              v-model="$store.state.form.members_approved_cashback_for_free_actions_in_past_days.free_actions"
              name="members_approved_cashback_for_free_actions_in_past_days.free_actions"
              placeholder="Select free action(s)"
              class="w-full"
              mode="tags"
              :inline="true"
              :required="false"
              :can-deselect="true"
              :can-clear="true"
              :resolve-on-load="true"
              :searchable="true"
              :min-chars="2"
              :delay="150"
              :loading="loadingFreeActions"
              :options="async (query) => await fetchFreeActions(query)"
              :disabled="$store.state.mailingData.isSent"
            />
            <InputField
              v-model="$store.state.form.members_approved_cashback_for_free_actions_in_past_days.days"
              type="number"
              step="1"
              min="1"
              name="members_approved_cashback_for_free_actions_in_past_days.days"
              placeholder="Number of days"
              :inline="true"
              :required="false"
              :disabled="$store.state.mailingData.isSent"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import ExcludeWave from './Survey/ExcludeWave.vue';

export default {
  components: {
    ExcludeWave,
  },

  props: {
    isSurvey: {
      type: Boolean,
      required: true,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },

    allowedTypesForSubscriptionSegmentation: {
      type: Array,
      required: true,
    },

    segmentationAttributes: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const percentiles = Array.from({length: 20}, (_, i) => 5 + (i * 5));

    return {
      percentiles: percentiles.map((percentile) => ({id: percentile, name: percentile})),
    };
  },

  data() {
    const balanceCurrency = {
      currency: this.countryCurrency.iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    return {
      loadingMailingFilters: false,
      loadingPercentileDetails: false,
      percentileDetails: null,
      loadingAdvertiserMailings: false,
      loadingIncludeReferredByMembers: false,
      loadingExcludeReferredByMembers: false,
      loadingAdvertiserTypes: false,
      loadingWebshopCategories: false,
      loadingFreeActions: false,
      balanceCurrency: balanceCurrency,
    };
  },

  watch: {
    '$store.state.form.notification_type': function () {
      if (!this.isSubscriptionSegmentationAllowed()) {
        this.$store.state.form.subscription_types = null;
      }
    },

    '$store.state.form.mailing_filter_id': function () {
      this.fetchPercentileDetails();
    },
  },

  mounted() {
    if (this.$store.state.form.mailing_filter_id) {
      this.fetchPercentileDetails();
    }
  },

  methods: {
    isSubscriptionSegmentationAllowed() {
      return this.allowedTypesForSubscriptionSegmentation.includes(this.$store.state.form.notification_type);
    },

    async fetchMailingFilters(query) {
      this.loadingMailingFilters = true;

      let fetchInitialSelectedAdvertiser = query === null;
      if (fetchInitialSelectedAdvertiser) {
        return await axios.get(this.route('admin.api.mailing-filters.find', {id: this.$store.state.form.mailing_filter_id}))
          .then(({data}) => {
            this.loadingMailingFilters = false;
            return data;
          });
      }

      return await axios.get(this.route('admin.api.mailing-filters.search.name', {name: query}))
        .then(({data}) => {
          this.loadingMailingFilters = false;
          return data;
        });
    },

    async fetchPercentileDetails() {
      this.loadingPercentileDetails = true;

      return axios.get(this.route('admin.api.mailing-filters.percentile.details', [this.$store.state.form.mailing_filter_id]))
        .then(({data}) => {
          this.percentileDetails = data;
          this.loadingPercentileDetails = false;
        });
    },

    async fetchAdvertiserMailings(query) {
      this.loadingAdvertiserMailings = true;

      const fetchInitialSelectedValue = query === null && this.$store.state.form.exclude_clickers_mailing_ids;
      if (fetchInitialSelectedValue) {
        return await axios.get(this.route('admin.api.advertisers.mailings', {
          advertiser: this.$store.state.form.advertiser_id,
          ids: this.$store.state.form.exclude_clickers_mailing_ids,
        }))
          .then(({data}) => {
            this.loadingAdvertiserMailings = false;
            return data;
          });
      }

      return await axios.get(this.route('admin.api.advertisers.mailings', {advertiser: this.$store.state.form.advertiser_id, query: query}))
        .then(({data}) => {
          this.loadingAdvertiserMailings = false;
          return data;
        });
    },

    async fetchMembers(query, type) {
      const dataVariable = type === 'include' ? 'include_referred_by_members' : 'exclude_referred_by_members';
      const loadingVariable = type === 'include' ? 'loadingIncludeReferredByMembers' : 'loadingExcludeReferredByMembers';

      this[loadingVariable] = true;

      const fetchInitialSelectedValue = query === null && this.$store.state.form[dataVariable];
      if (fetchInitialSelectedValue) {
        return await axios.get(this.route('admin.api.members.find', {
          ids: this.$store.state.form[dataVariable],
        }))
            .then(({data}) => {
              this[loadingVariable] = false;
              return data;
            });
      }

      return await axios.get(this.route('admin.api.members.search.name', {name: query}))
        .then(({data}) => {
          this[loadingVariable] = false;
          return data;
        });
    },

    async fetchAdvertiserTypes() {
      this.loadingAdvertiserTypes = true;

      return await axios.get(this.route('admin.api.advertisers.types'))
        .then(({data}) => {
          this.loadingAdvertiserTypes = false;
          return data;
        });
    },

    async fetchWebshopCategories() {
      this.loadingWebshopCategories = true;

      return await axios.get(this.route('admin.api.webshop-category.find'))
        .then(({data}) => {
          this.loadingWebshopCategories = false;
          return data;
        });
    },

    async fetchFreeActions(query) {
      this.loadingFreeActions = true;

      const fetchInitialSelectedValue = query === null && this.$store.state.form.members_approved_cashback_for_free_actions_in_past_days.free_actions;
      if (fetchInitialSelectedValue) {
        return await axios.get(this.route('admin.api.free-action.find', {
          ids: this.$store.state.form.members_approved_cashback_for_free_actions_in_past_days.free_actions,
        }))
          .then(({data}) => {
            this.loadingFreeActions = false;
            return data;
          });
      }

      return await axios.get(this.route('admin.api.free-action.search.name', {name: query}))
        .then(({data}) => {
          this.loadingFreeActions = false;
          return data;
        });
    },
  },
};
</script>
