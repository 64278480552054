<template>
  <CrudIndex
    title="Deleted members"
    row-route-path="admin.deleted-members.show"
    :columns="['Member ID', 'Name', 'Username', 'Email']"
    :actions="true"
    :menu-items="[
      {name: 'Show', url: (item) => route('admin.deleted-members.show', item.id), enabled: true},
    ]"
    :data="deletedMembers.data"
    :paginator="deletedMembers.meta.pagination"
    :filters="[
      {type: 'search', fields: [
        {label: 'Member ID', value: 'id'},
        {label: 'First name', value: 'first_name'},
        {label: 'Last name', value: 'last_name'},
        {label: 'Email', value: 'email'},
        {label: 'Bank', value: 'bank'},
        {label: 'Click ID', value: 'click_id'},
        {label: 'Share ID', value: 'share_id'},
      ]},
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Created At', value: 'created_at'}]
      },
    ]"
  >
    <template #name="{ row }">
      {{ row.first_name }} {{ row.last_name }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    deletedMembers: {
      type: Object,
      required: true,
    },
  },
};
</script>
