<template>
  <div>
    <MultiSelectField
      v-model="$store.state.form.survey_id"
      label="Survey"
      name="survey_id"
      placeholder="Select a Survey"
      mode="single"
      :searchable="true"
      :can-deselect="false"
      :can-clear="false"
      :resolve-on-load="true"
      :min-chars="2"
      :delay="150"
      :loading="loadingSurveys"
      :options="async (query) => await fetchSurveys(query)"
      @select="surveyChanged"
    />

    <MultiSelectField
      ref="surveyWaveInput"
      v-model="$store.state.form.survey_wave_id"
      label="Survey Wave"
      name="survey_wave_id"
      placeholder="Select a Survey Wave"
      mode="single"
      :searchable="false"
      :can-deselect="true"
      :can-clear="true"
      :resolve-on-load="$store.state.form.survey_wave_id"
      :disabled="!$store.state.form.survey_id"
      :loading="loadingSurveyWaves"
      :options="$store.state.mailingData.surveyWaves"
    />

    <div
      v-if="loadingDuration"
      class="text-center"
    >
      <i class="far fa-spin fa-spinner text-xl" />
    </div>
    <InputField
      v-else
      v-model="$store.state.form.duration"
      type="number"
      label="Duration"
      name="duration"
      min="0"
      :required="false"
      help="In minutes."
      :disabled="!$store.state.form.survey_id"
    />

    <RequiredProfileQuestions
      v-model="$store.state.form.required_questions"
      label="Required Questions"
      help="Required questions to ask in the Mailing Survey. If the member does not meet the criteria, a pre_screen_out status will be assigned."
      :required="false"
    />
  </div>
</template>

<script>
import axios from 'axios';
import RequiredProfileQuestions from './Survey/RequiredProfileQuestions.vue';

export default {
  components: {RequiredProfileQuestions},
  data() {
    return {
      loadingSurveys: false,
      loadingSurveyWaves: false,
      loadingDuration: false,
    };
  },

  methods: {
    surveyChanged(surveyId, selectedOption) {
      surveyId = parseInt(surveyId);
      if (isNaN(surveyId)) {
        return;
      }

      this.$nextTick(() => {
        this.$refs.surveyWaveInput.clear();
        this.$store.state.form.survey_wave_id = selectedOption?.id || 0;

        if (!this.$store.state.mailingData.duration) {
          // only prefill if there is no duration yet (i.e. if a new mailing is created)
          this.prefillDuration(surveyId);
        }

        this.loadSurveyWaves(surveyId);
      });
    },

    async fetchSurveys(query) {
      this.loadingSurveys = true;

      let fetchInitialSelectedAdvertiser = query === null;
      if (fetchInitialSelectedAdvertiser) {
        const fetchedData = await axios.get(this.route('admin.api.surveys.find', {id: this.$store.state.form.survey_id}))
          .then(({data}) => {
            this.loadingSurveys = false;
            return data;
          });

        this.surveyChanged(this.$store.state.form.survey_id, {
          id: this.$store.state.form.survey_wave_id,
        });

        return fetchedData;
      }

      return await axios.get(this.route('admin.api.surveys.search.name', {name: query}))
        .then(({data}) => {
          this.loadingSurveys = false;
          return data;
        });
    },

    loadSurveyWaves(surveyId) {
      this.loadingSurveyWaves = true;

      return axios.get(this.route('admin.api.survey-waves.find.survey', [surveyId]))
        .then(({data}) => {
          if (Object.keys(this.$store.state.mailingData.surveyWaves).length > 0) {
            this.$store.state.form.exclude_waves = [];
          }
          this.$store.state.mailingData.surveyWaves = data;

          this.loadingSurveyWaves = false;
        });
    },

    prefillDuration(surveyId) {
      this.loadingDuration = true;

      return axios.get(this.route('admin.api.surveys.show', [surveyId]))
        .then(({data}) => {
          this.$store.state.form.duration = data?.duration ?? null;
          this.loadingDuration = false;
        });
    },
  },
};
</script>
