<template>
  <CrudEdit
    :title="`Member #${member.id}`"
    sub-title="Member Profile"
    :index-route="route('admin.members.edit', member.id)"
    :update-route="route('admin.members.profile.update', member.id)"
  >
    <template #cards>
      <MemberProfileForm
        :genders="genders"
        :picture-url="member.pictureUrl"
        :education-levels="educationLevels"
        :username="member.username"
      />
    </template>
  </CrudEdit>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import MemberProfileForm from './Form.vue';


export default {
  components: {
    MemberProfileForm,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    educationLevels: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      gender: props.member.gender,
      birth_date: props.member.birth_date,
      zipcode: props.member.zipcode,
      city: props.member.city,
      province: props.member.province,
      municipality: props.member.municipality,
      education: props.member.education,
      picture: null,
    });
    useStore().commit('form/setForm', form);

    return {
      form,
    };
  },
};
</script>

