<template>
  <CrudIndex
    :title="category.description"
    create-route-path="admin.tag-categories.item.create"
    edit-route-path="admin.tag-categories.item.edit"
    row-route-path="admin.tag-categories.item.edit"
    delete-route-path="admin.tag-categories.item.destroy"
    :route-parameters="[category.id]"
    :columns="['id', 'description']"
    :actions="true"
    :data="tags.data"
    :paginator="tags.meta.pagination"
    :filters="[
      {type: 'search', fields: [{label: 'Description', value: 'description'}]},
    ]"
  />
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },

    tags: {
      type: Object,
      required: true,
    },
  },
};
</script>
