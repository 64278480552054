<template>
  <div class="col-span-2">
    <MultiSelectField
      v-model="item.province"
      name="province_item_province"
      placeholder="Select province(s)"
      mode="tags"
      :required="true"
      :inline="true"
      :options="provinces"
    />
  </div>
  <div class="col-span-2 flex">
    <label
      v-for="(label, key) of mailingFilterItemOperators"
      :key="`operator-${key}`"
      class="mr-2 flex items-center"
    >
      <input
        v-model="item.operator"
        :value="key"
        type="radio"
        class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
      <span class="ml-2">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import {ref} from 'vue';
import Base from '../Base';

export default {
  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const item = ref(props.modelValue);
    item.value.province = props.modelValue?.province ? Array.isArray(props.modelValue.province) ? props.modelValue?.province : [props.modelValue.province] :  [];
    item.value.operator = props.modelValue?.operator || Object.keys(props.mailingFilterItemOperators).reverse()[0];

    return {item};
  },
};
</script>
