<template>
  <CrudCreate
    title="Webshop Category - Create"
    sub-title="Webshop Category"
    :index-route="route('admin.webshop-categories.index')"
    :store-route="route('admin.webshop-categories.store')"
  >
    <template #cards>
      <CategoryForm
        :countries="countries"
        :minimum-media-size="minimumMediaSize"
      />
    </template>
  </CrudCreate>
</template>

<script>
import CategoryForm from './Form.vue';

export default {
  components: {CategoryForm},

  props: {
    countries: {
      type: Object,
      required: true,
    },

    minimumMediaSize: {
      type: Array,
      required: true,
    },
  },
};
</script>
