<template>
  <CrudToggleCard>
    <template #header>
      <span class="font-medium">
        Cashback
      </span>
    </template>

    <template #content>
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Free
        </h3>
      </div>

      <div>
        <InputField
          v-if="useDefaultCashbackFreePercentage"
          v-model="defaultFreePercentage"
          :disabled="true"
          label="Percentage (free)"
          name="default_cashback_free_percentage"
          help="Default country percentage"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultCashbackFreePercentage = false">
              <span
                class="material-icons md-18"
                title="Don't use default config"
              >edit</span>
            </DefaultButton>
          </template>
        </InputField>

        <InputField
          v-else
          v-model="$store.state.form.cashback_free_percentage"
          label="Percentage (free)"
          name="cashback_free_percentage"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultFreePercentage">
              <span
                class="material-icons md-18"
                title="Use default config"
              >edit_off</span>
            </DefaultButton>
          </template>
        </InputField>
      </div>

      <div>
        <CurrencyAndPointsField
          v-model="$store.state.form.cashback_free_fixed"
          :options="currencyOptionsFree"
          :points-conversion-ratio="pointsConversionRatio"
          label="Fixed (free)"
          name="cashback_free_fixed"
          :required="false"
        />
      </div>

      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Premium
        </h3>
      </div>

      <div>
        <InputField
          v-if="useDefaultCashbackPremiumPercentage"
          v-model="defaultPremiumPercentage"
          :disabled="true"
          label="Percentage (premium)"
          name="default_cashback_premium_percentage"
          help="Default country percentage"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultCashbackPremiumPercentage = false">
              <span
                class="material-icons md-18"
                title="Don't use default config"
              >edit</span>
            </DefaultButton>
          </template>
        </InputField>

        <InputField
          v-else
          v-model="$store.state.form.cashback_premium_percentage"
          label="Percentage (premium)"
          name="cashback_premium_percentage"
        >
          <template #extra>
            <DefaultButton @click.prevent="useDefaultPremiumPercentage">
              <span
                class="material-icons md-18"
                title="Use default config"
              >edit_off</span>
            </DefaultButton>
          </template>
        </InputField>
      </div>

      <div>
        <CurrencyAndPointsField
          v-model="$store.state.form.cashback_premium_fixed"
          :options="currencyOptionsPremium"
          :points-conversion-ratio="pointsConversionRatio"
          label="Fixed (premium)"
          name="cashback_premium_fixed"
          :required="false"
        />
      </div>
    </template>
  </CrudToggleCard>
</template>

<script>
import {ref} from 'vue';
import CurrencyAndPointsField from '../../Components/CurrencyAndPointsField.vue';

export default {
  components: {
    CurrencyAndPointsField,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },

    defaultCashbackFreePercentage: {
      type: String,
      required: true,
    },

    defaultCashbackPremiumPercentage: {
      type: String,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const currencyOptionsFree = {
      currency: props.program.cashback_free_currency_iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };
    const currencyOptionsPremium = {
      currency: props.program.cashback_premium_currency_iso,
      precision: {min: window.Tenant.decimal_scale, max: window.Tenant.decimal_scale},
    };

    return {
      currencyOptionsFree,
      currencyOptionsPremium,
      defaultFreePercentage: props.defaultCashbackFreePercentage,
      defaultPremiumPercentage: props.defaultCashbackPremiumPercentage,
    };
  },

  data() {
    return {
      useDefaultCashbackFreePercentage: ref(this.$store.state.form.cashback_free_percentage === null),
      useDefaultCashbackPremiumPercentage: ref(this.$store.state.form.cashback_premium_percentage === null),
    };
  },

  methods: {
    useDefaultFreePercentage() {
      this.useDefaultCashbackFreePercentage = true;
      this.$store.state.form.cashback_free_percentage = null;
    },

    useDefaultPremiumPercentage() {
      this.useDefaultCashbackPremiumPercentage = true;
      this.$store.state.form.cashback_premium_percentage = null;
    },
  },
};

</script>
