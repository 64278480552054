<template>
  <VariablesModal
    v-model:open="trackingUrlVariablesModalOpen"
    :variables="replaceableTrackingUrlVariables"
  />

  <RoutesModal
    v-model:open="routesModalOpen"
  />

  <VariablesModal
    v-model:open="subjectVariablesModalOpen"
    :variables="subjectReplaceableAttributes"
  />

  <CrudToggleCard :default-open="true">
    <template #header>
      <span class="font-medium">
        General
      </span>
    </template>

    <template #content>
      <GeneralSection
        :is-duplicate="isDuplicate"
        :is-survey="isSurvey"
        :country-currency="countryCurrency"
        :notification-types="notificationTypes"
        :reward-types="rewardTypes"
        :default-click-reward-value="defaultClickRewardValue"
        :default-view-reward-value="defaultViewRewardValue"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard
    v-if="isSurvey"
    :default-open="true"
  >
    <template #header>
      <span class="font-medium">
        Survey
      </span>
    </template>

    <template #content>
      <SurveySection />
    </template>
  </CrudToggleCard>

  <CrudToggleCard :default-open="true">
    <template #header>
      <span class="font-medium">
        Content
      </span>
    </template>

    <template #content>
      <ContentSection
        :is-survey="isSurvey"
        :templates="templates"
        :commission-model-display-options="commissionModelDisplayOptions"
        :mailbanners="mailbanners"
        @openTrackingUrlVariablesModal="trackingUrlVariablesModalOpen = true"
        @openRoutesModal="routesModalOpen = true"
        @openSubjectVariablesModal="subjectVariablesModalOpen = true"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard :default-open="true">
    <template #header>
      <span class="flex items-center font-medium">
        Segmentation

        <span class="text-xs text-gray-400 ml-2">
          Members:
          <span
            v-if="$store.state.mailingData.calculatingMembers"
            class="material-icons md-12 animate-spin"
          >
            autorenew
          </span>
          <span v-else>
            {{ $store.state.mailingData.calculatedMembers }}
          </span>
        </span>
      </span>
    </template>

    <template #content>
      <SegmentationSection
        :is-survey="isSurvey"
        :country-currency="countryCurrency"
        :genders="genders"
        :subscription-types="subscriptionTypes"
        :allowed-types-for-subscription-segmentation="allowedTypesForSubscriptionSegmentation"
        :segmentation-attributes="segmentationAttributes"
        :survey-statuses="surveyStatuses"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard
    v-if="!isSurvey"
    :default-open="true"
  >
    <template #header>
      <span class="font-medium">
        Controlling
      </span>
    </template>

    <template #content>
      <ControllingSection
        :administrators="administrators"
      />
    </template>
  </CrudToggleCard>

  <CrudToggleCard
    v-if="!isSurvey"
    :default-open="true"
  >
    <template #header>
      <span class="font-medium">
        Multi advertisers
      </span>
    </template>

    <template #content>
      <MultiAdvertisers
        :multi-advertiser-media-types="multiAdvertiserMediaTypes"
        @openTrackingUrlVariablesModal="trackingUrlVariablesModalOpen = true"
        @openRoutesModal="routesModalOpen = true"
      />
    </template>
  </CrudToggleCard>
</template>

<script>
import {useStore} from 'vuex';
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import axios from 'axios';
import GeneralSection from './FormSections/General.vue';
import SurveySection from './FormSections/Survey.vue';
import ContentSection from './FormSections/Content.vue';
import SegmentationSection from './FormSections/Segmentation.vue';
import ControllingSection from './FormSections/Controlling.vue';
import MultiAdvertisers from './FormSections/MultiAdvertisers.vue';
import VariablesModal from '../../Components/Mailing/VariablesModal.vue';
import RoutesModal from '../../Components/RoutesModal.vue';

export default {
  components: {
    RoutesModal, VariablesModal,
    GeneralSection,
    SurveySection,
    ContentSection,
    SegmentationSection,
    ControllingSection,
    MultiAdvertisers,
  },

  props: {
    isSurvey: {
      type: Boolean,
      required: true,
    },

    isDuplicate: {
      type: Boolean,
      required: false,
      default: false,
    },

    mailing: {
      type: Object,
      required: false,
      default: null,
    },

    countryCurrency: {
      type: Object,
      required: true,
    },

    defaultScheduledAt: {
      type: String,
      required: false,
      default: null,
    },

    rewardTypes: {
      type: Array,
      required: true,
    },

    defaultClickRewardValue: {
      type: String,
      required: true,
    },

    defaultViewRewardValue: {
      type: String,
      required: true,
    },

    notificationTypes: {
      type: Array,
      required: true,
    },

    subscriptionTypes: {
      type: Array,
      required: true,
    },

    allowedTypesForSubscriptionSegmentation: {
      type: Array,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },

    commissionModelDisplayOptions: {
      type: Array,
      required: true,
    },

    planner: {
      type: Object,
      required: true,
    },

    administrators: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    prefillAdvertiserId: {
      type: Number,
      required: false,
      default: null,
    },

    prefillProgramId: {
      type: String,
      required: false,
      default: null,
    },

    prefillSurveyId: {
      type: Number,
      required: false,
      default: null,
    },

    prefillSurveyWaveId: {
      type: Number,
      required: false,
      default: null,
    },

    prefillNotificationType: {
      type: String,
      required: false,
      default: null,
    },

    prefillCommissionModelDisplay: {
      type: String,
      required: false,
      default: null,
    },

    prefillFinalControl: {
      type: Boolean,
      required: false,
      default: null,
    },

    prefillFinalControllerId: {
      type: Boolean,
      required: false,
      default: null,
    },

    mailbanners: {
      type: Object,
      required: false,
      default: null,
    },

    typesWithoutClickReward: {
      type: Array,
      required: true,
    },

    replaceableTrackingUrlVariables: {
      type: Array,
      required: true,
    },

    replaceableMemberAttributes: {
      type: Array,
      required: true,
    },

    replaceableSurveyAttributes: {
      type: Array,
      required: true,
    },

    segmentationAttributes: {
      type: Array,
      required: true,
    },

    advertisers: {
      type: Object,
      required: false,
      default: null,
    },

    multiAdvertiserMediaTypes: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Array,
      required: true,
    },

    defaultExcludeReferredByMembers: {
      type: Array,
      required: true,
    },

    autoRemailDelayDays: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const hasOneTemplate = props.templates && props.templates.length === 1;

    let selectedTemplate = null;
    if (hasOneTemplate) {
      selectedTemplate = ref(props.templates[0].id);
    }

    const excludeWaves = [];
    if (props.mailing?.exclude_waves) {
      for (const [surveyWaveId, surveyStatuses] of Object.entries(props.mailing?.exclude_waves)) {
        excludeWaves.push({
          survey_wave_id: surveyWaveId,
          survey_statuses: surveyStatuses.split(','),
        });
      }
    }

    const membersApprovedCashbackForFreeActionsInPastDays =
      props.mailing?.members_approved_cashback_for_free_actions_in_past_days ?? {free_actions: [], days: null};
    if (!('free_actions' in membersApprovedCashbackForFreeActionsInPastDays)) {
      membersApprovedCashbackForFreeActionsInPastDays.free_actions = [];
    }

    const form = useForm({
      advertiser_id: props.prefillAdvertiserId ?? props.mailing?.advertiser_id ?? null,
      program_id: props.prefillProgramId ?? props.mailing?.program_id ?? null,
      survey_id:  props.prefillSurveyId ?? props.mailing?.survey_id ?? null,
      survey_wave_id:  props.prefillSurveyWaveId ?? props.mailing?.survey_wave_id ?? null,
      name: props.mailing?.name ?? null,
      scheduled_at: props.isDuplicate ? props.defaultScheduledAt : (props.mailing?.scheduled_at ?? props.defaultScheduledAt ?? null),
      notification_type: props.prefillNotificationType ?? props.mailing?.notification_type ?? null,
      reward_type: props.mailing?.reward_type ?? props.rewardTypes[0]?.id ?? null,
      reward_value: props.mailing?.reward_value ?? (props.isSurvey ? '0.00' : null),
      reward_currency_id: props.countryCurrency.id,
      expected_ecpc: props.mailing?.expected_ecpc ?? null,
      tracking_url: props.mailing?.tracking_url ?? null,
      template: props.mailing?.template ?? selectedTemplate,
      pre_header_text: props.mailing?.pre_header_text ?? null,
      custom_text: props.mailing?.custom_text ?? '',
      subject: props.mailing?.subject ?? null,
      logo_media_id: props.mailing?.logo_media_id ?? null,
      media_id: props.mailing?.media_id ?? null,
      html_media_id: props.mailing?.html_media_id ?? null,
      tracking_pixel: props.mailing?.tracking_pixel ?? null,
      tracking_pixel_2: props.mailing?.tracking_pixel_2 ?? null,
      conditions: props.mailing?.conditions ?? null,
      text: props.mailing?.text ?? '',
      button_text: props.mailing?.button_text ?? null,
      commission_model_display: props.prefillCommissionModelDisplay ?? props.mailing?.commission_model_display ?? null,
      commission_model_ids: props.mailing?.commission_model_ids ?? [],
      show_max_cashback: props.mailing?.show_max_cashback ?? true,
      show_commission_model_table: props.mailing?.show_commission_model_table ?? true,
      gender: props.mailing?.gender ?? null,
      minimum_age: props.mailing?.minimum_age ?? null,
      maximum_age: props.mailing?.maximum_age ?? null,
      percentiles: props.mailing?.percentiles ?? [],
      subscription_types: props.mailing?.subscription_types ?? [],
      exclude_waves: excludeWaves,
      mailing_filter_id: props.mailing?.mailing_filter_id ?? null,
      planner_id: props.mailing?.planner_id ?? props.planner.id,
      first_controller_id: props.mailing?.first_controller_id ?? null,
      final_controller_id: props.prefillFinalControllerId ?? props.mailing?.final_controller_id ?? null,
      advertiser_agreements: props.mailing?.advertiser_agreements ?? null,
      type: props.isDuplicate ? 'duplicate' : (props.mailing?.type ?? 'new'),
      mailbanner_id: props.mailing?.mailbanner_id ?? null,
      is_manual_reward: props.mailing?.is_manual_reward ?? false,
      allow_as_limited: props.mailing?.allow_as_limited ?? false,
      route_outside_platform: props.mailing?.route_outside_platform ?? false,
      hide_on_platform: props.mailing?.hide_on_platform ?? false,
      include_referred_by_members: props.mailing?.include_referred_by_members ?? [],
      exclude_referred_by_members: props.mailing?.exclude_referred_by_members ?? props.defaultExcludeReferredByMembers ?? [],
      required_questions: props.mailing?.required_questions ?? [],
      duration: props.mailing?.duration ?? null,
      exclude_clickers_advertiser: props.mailing?.exclude_clickers_advertiser ?? null,
      exclude_non_clickers: props.mailing?.exclude_non_clickers ?? (props.isSurvey ? true : null),
      exclude_clickers_mailing_ids: props.mailing?.exclude_clickers_mailing_ids ?? null,
      include_clickers_advertiser: props.mailing?.include_clickers_advertiser ?? null,
      minimum_balance: props.mailing?.minimum_balance ?? null,
      show_advertiser_logo: props.mailing?.show_advertiser_logo ?? true,
      advertisers: props.advertisers ? props.advertisers : {},
      advertiser_ids: props.advertisers ? Object.keys(props.advertisers) : [],
      must_auto_remail: props.mailing?.must_auto_remail ?? false,
      members_with_transaction_in_past_days: props.mailing?.members_with_transaction_in_past_days ?? null,
      members_approved_cashback_for_advertiser_types_in_past_days: props.mailing?.members_approved_cashback_for_advertiser_types_in_past_days ?? {
        advertiser_types: [],
        days: null,
      },
      members_approved_cashback_for_webshop_categories_in_past_days: props.mailing?.members_approved_cashback_for_webshop_categories_in_past_days ?? {
        webshop_categories: [],
        days: null,
      },
      members_approved_cashback_for_free_actions_in_past_days: membersApprovedCashbackForFreeActionsInPastDays,
    });

    useStore().commit('form/setForm', form);

    // register custom module to keep track of certain mailingData across sections (keep it separated from the formdata)
    useStore().unregisterModule('mailingData');
    useStore().registerModule('mailingData', {
      namespaced: true,
      state: () => ({
        advertiserId: props.mailing?.advertiser_id ?? null,
        advertiserName: props.mailing?.advertiser_name ?? null,
        advertiserLogoId: props.mailing?.advertiser_logo_media_id ?? null,
        programId: props.mailing?.program_id ?? null,
        programName: props.mailing?.program_name ?? null,
        surveyId: props.mailing?.survey_id ?? null,
        surveyWaveId: props.mailing?.survey_wave_id ?? null,
        programCommissionModels: props.mailing?.program_commission_models ?? null,
        originalRewardType: form.reward_type,
        originalRewardValue: form.reward_value,
        originalRewardCurrency: form.reward_currency_id,
        calculatingMembers: false,
        calculatedMembers: 0,
        typesWithoutClickReward: props.typesWithoutClickReward,
        first_control: !!(props.mailing?.first_controller_id || null),
        final_control: !!(props.prefillFinalControl || props.mailing?.final_controller_id || null),
        isSent: (props.mailing?.is_sent || false) && !props.isDuplicate,
        surveyWaves: {},
        duration: props.mailing?.duration ?? null,
        hasOneTemplate: hasOneTemplate,
        autoRemailDelayDays: props.autoRemailDelayDays,
      }),
    });
  },

  data() {
    return {
      trackingUrlVariablesModalOpen: ref(false),
      routesModalOpen: ref(false),
      subjectVariablesModalOpen: ref(false),
    };
  },

  computed: {
    subjectReplaceableAttributes() {
      let replaces = this.replaceableMemberAttributes;

      if (this.isSurvey) {
        replaces = replaces.concat(this.replaceableSurveyAttributes);
      }

      return replaces;
    },
  },

  watch: {
    '$store.state.form.advertiser_id': function () {
      this.calculateMembers();
    },

    '$store.state.form.notification_type': function () {
      this.calculateMembers();
    },

    '$store.state.form.gender': function () {
      this.calculateMembers();
    },

    '$store.state.form.minimum_age': function () {
      this.calculateMembers();
    },

    '$store.state.form.maximum_age': function () {
      this.calculateMembers();
    },

    '$store.state.form.percentiles': function () {
      this.calculateMembers();
    },

    '$store.state.form.subscription_types': function () {
      this.calculateMembers();
    },

    '$store.state.form.exclude_waves': function () {
      this.calculateMembers();
    },

    '$store.state.form.mailing_filter_id': function () {
      this.calculateMembers();
    },

    '$store.state.form.exclude_clickers_advertiser': function () {
      this.calculateMembers();
    },

    '$store.state.form.exclude_clickers_mailing_ids': function () {
      this.calculateMembers();
    },

    '$store.state.form.include_clickers_advertiser': function () {
      this.calculateMembers();
    },

    '$store.state.form.minimum_balance': function () {
      this.calculateMembers();
    },

    '$store.state.form.include_referred_by_members': function () {
      this.calculateMembers();
    },

    '$store.state.form.exclude_referred_by_members': function () {
      this.calculateMembers();
    },

    '$store.state.form.members_with_transaction_in_past_days': function () {
      this.calculateMembers();
    },

    '$store.state.form.members_approved_cashback_for_advertiser_types_in_past_days': {
      deep: true,
      handler: function () {
        this.calculateMembers();
      },
    },

    '$store.state.form.members_approved_cashback_for_webshop_categories_in_past_days': {
      deep: true,
      handler: function () {
        this.calculateMembers();
      },
    },

    '$store.state.form.members_approved_cashback_for_free_actions_in_past_days': {
      deep: true,
      handler: function () {
        this.calculateMembers();
      },
    },
  },

  mounted() {
    this.calculateMembers();
  },

  methods: {
    calculateMembers() {
      if (!this.$store.state.form.advertiser_id) {
        this.$store.state.mailingData.calculatedMembers = 'select advertiser first';
        return;
      }

      if (!this.$store.state.form.notification_type) {
        this.$store.state.mailingData.calculatedMembers = 'select notification_type first';
        return;
      }

      this.$store.state.mailingData.calculatingMembers = true;
      this.$store.state.mailingData.calculatedMembers = 0;

      const payload = {
        advertiser_id: this.$store.state.form.advertiser_id,
        scheduled_at: this.$store.state.form.scheduled_at,
        notification_type: this.$store.state.form.notification_type,
        mailing_filter_id: this.$store.state.form.mailing_filter_id,
      };

      for (const segmentationAttribute of this.segmentationAttributes) {
        if (!Object.keys(this.$store.state.form).includes(segmentationAttribute)) {
          continue;
        }

        payload[segmentationAttribute] = this.$store.state.form[segmentationAttribute];
      }

      axios.post(this.route('admin.api.mailing.filter.calculate'), payload)
        .then((response) => {
          this.$store.state.mailingData.calculatedMembers = response.data.count;
        })
        .catch((error) => {
          this.$store.state.mailingData.calculatedMembers = error.response?.data?.message || error.message;
        })
        .finally(() => {
          this.$store.state.mailingData.calculatingMembers = false;
        });
    },
  },
};
</script>
