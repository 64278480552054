<template>
  <CrudEdit
    title="Program Term Item"
    :sub-title="generateSubTitle()"
    :index-route="route('admin.term-categories.show', [category.id])"
    :update-route="route('admin.term-categories.term-item.update', [category.id, item.id])"
  >
    <template #form>
      <ErrorList :fields="['description']" />

      <ItemForm />
    </template>
  </CrudEdit>
</template>

<script>

import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import ItemForm from './Form.vue';

export default {
  components: {
    ItemForm,
  },

  props: {
    category: {
      type: Object,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      description: props.item.description,
    });

    useStore().commit('form/setForm', form);
  },

  methods: {
    generateSubTitle() {
      let subTitle = 'Program Term Category - ';

      if (this.item.description.length > 75) {
        return subTitle + this.item.description.substr(0, 75) + '...';
      }

      return subTitle + this.item.description;
    },
  },
};
</script>
