<template>
  <CrudCreate
    title="FAQ Category - Create"
    sub-title="FAQ"
    :index-route="route('admin.faq-categories.index')"
    :store-route="route('admin.faq-categories.store')"
  >
    <template #form>
      <FaqCategoryForm :countries="countries" />
    </template>
  </CrudCreate>
</template>

<script>
import FaqCategoryForm from './Form.vue';

export default {
  components: {FaqCategoryForm},

  props: {
    countries: {
      type: Object,
      required: true,
    },
  },
};
</script>
