<template>
  <CrudCard>
    <template #header>
      <span class="font-medium">
        Settings
      </span>
    </template>

    <template #content>
      <SelectField
        v-model="$store.state.form.type"
        label="Type"
        name="type"
        placeholder="Select a type"
        :items="channelTypes.map((type) => ({id: type, name: type}))"
      />

      <InputField
        v-model="$store.state.form.average_turnover_approval_rate"
        label="Average turnover approval rate"
        name="average_turnover_approval_rate"
        :required="false"
      >
        <template #extra>
          %
        </template>
      </InputField>

      <InputField
        v-model="$store.state.form.network_abbreviation"
        label="Network Abbreviation"
        name="network_abbreviation"
        :required="false"
      />
    </template>
  </CrudCard>

  <CrudCard>
    <template #header>
      <span class="font-medium">
        Notes
      </span>
    </template>

    <template #content>
      <TextareaField
        v-model="$store.state.form.notes"
        label="Notes"
        name="notes"
        :required="false"
      />
    </template>
  </CrudCard>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

export default {
  props: {
    channelTypes: {
      type: Array,
      required: true,
    },

    channel: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      average_turnover_approval_rate: props.channel.average_turnover_approval_rate,
      notes: props.channel.notes,
      type: props.channel.type,
      network_abbreviation: props.channel.network_abbreviation,
    });

    useStore().commit('form/setForm', form);
  },
};
</script>
