<template>
  <CrudCreate
    title="Role - Create"
    sub-title="Roles"
    :index-route="route('admin.roles.index')"
    :store-route="route('admin.roles.store')"
  >
    <template #form>
      <RoleForm />
    </template>
  </CrudCreate>
</template>

<script>
import RoleForm from './Form.vue';

export default {
  components: {
    RoleForm,
  },
};
</script>
