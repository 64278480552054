<template>
  <CrudToggleCard :default-open="true">
    <template #header>
      <span class="font-medium">
        General
      </span>
    </template>

    <template #content>
      <InputField
        v-model="$store.state.form.name"
        label="Name"
        name="name"
        :required="true"
      />

      <div class="grid gap-1 md:gap-4 items-center sm:grid-cols-6 py-6 first:pt-0 last:pb-0">
        <div class="col-span-2">
          <div class="flex justify-between sm:justify-start">
            Member count
          </div>
        </div>
        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <i
            v-if="loadingMemberCount"
            class="far fa-spin fa-spinner"
          />
          <span v-else>{{ memberCount }}</span>

          <p
            v-if="$page.props.errors['member_count']"
            class="mt-1 text-sm text-red-600"
            v-text="$page.props.errors['member_count']"
          />
        </div>
        <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-2">
          <DefaultButton
            type="button"
            class="p-2"
            @click="calculateMemberCount"
          >
            <i class="far fa-calculator" />
          </DefaultButton>
        </div>
      </div>
    </template>
  </CrudToggleCard>

  <MailingFilterForm
    v-model="$store.state.form.query"
    :genders="genders"
    :provinces="provinces"
    :survey-statuses="surveyStatuses"
    :mailing-filter-items="mailingFilterItems"
    :mailing-filter-item-explanations="mailingFilterItemExplanations"
    :mailing-filter-item-file-fields="mailingFilterItemFileFields"
    :mailing-filter-item-operators="mailingFilterItemOperators"
  />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import {ref} from 'vue';
import axios from 'axios';
import MailingFilterForm from './../../Components/Mailing/Filters/Form.vue';

export default {
  components: {MailingFilterForm},

  props: {
    mailingFilter: {
      type: Object,
      required: false,
      default: null,
    },

    selectedCountryId: {
      type: Number,
      required: true,
    },

    genders: {
      type: Object,
      required: true,
    },

    provinces: {
      type: Array,
      required: true,
    },

    surveyStatuses: {
      type: Object,
      required: true,
    },

    mailingFilterItems: {
      type: Object,
      required: true,
    },

    mailingFilterItemExplanations: {
      type: Object,
      required: true,
    },

    mailingFilterItemFileFields: {
      type: Object,
      required: true,
    },

    mailingFilterItemOperators: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      id: props.mailingFilter?.id,
      country_id: props.mailingFilter?.country_id ?? props.selectedCountryId,
      name: props.mailingFilter?.name ?? '',
      query: props.mailingFilter?.query ?? [],
      action: null,
    });

    useStore().commit('form/setForm', form);

    const loadingMemberCount = ref(false);
    const memberCount = ref(null);
    const memberCountTimeout = ref(null);

    return {loadingMemberCount, memberCount, memberCountTimeout};
  },

  watch: {
    '$store.state.form.query': {
      deep: true,
      handler() {
        this.calculateMemberCount();
      },
    },
  },

  methods: {
    calculateMemberCount() {
      clearTimeout(this.memberCountTimeout);
      this.loadingMemberCount = true;
      this.memberCount = null;
      this.$page.props.errors['member_count'] = null;

      this.memberCountTimeout = setTimeout(() => {
        axios.post(this.route('admin.api.mailing.filter.calculate-filter'), {
          mailing_filter_id: this.$store.state.form.id,
          query: this.$store.state.form.query,
        })
          .then((response) => {
            this.memberCount = response.data.count;
          })
          .catch((error) => {
            this.memberCount = 'unknown';
            this.$page.props.errors['member_count'] = error.response?.data?.message || error.message;
          })
          .finally(() => {
            this.loadingMemberCount = false;
          });
      }, 500);
    },
  },
};
</script>
