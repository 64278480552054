<template>
  <CrudIndex
    title="Payouts - Review"
    row-route-path="admin.payouts.show"
    :columns="['ID', 'Member', 'Payout type', 'Receiver name', 'Receiver account', 'Value', 'Pending reasons', 'Request date', 'Is doubt']"
    :actions="true"
    :data="payouts.data"
    :paginator="payouts.meta.pagination"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'created_at'}],
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Payout type',
        value: 'type',
        multiple: true,
        items: filterablePayoutTypes,
      },
      {
        type: 'dropdown',
        operator: 'equals',
        label: 'Is doubt',
        value: 'is_doubt',
        multiple: false,
        items: [
          {name: 'Yes', value: '1'},
          {name: 'No', value: '0'},
        ],
      },
    ]"
    :order-columns="{
      'ID': 'id',
      'Member': 'member_id',
      'Value': 'value',
      'Request date': 'created_at',
    }"
    :order="order"
    :menu-items="[
      {name: 'Details', icon: 'info', url: (item) => route('admin.payouts.show', item.id), enabled: true},
      {name: 'Approve', icon: 'approval', action: (item) => approve(item.id), enabled: true},
      {name: 'Toggle doubt', icon: 'question_mark', action: (item) => toggleDoubt(item.id), enabled: true},
    ]"
  >
    <template #member="{ row }">
      <a
        :href="route('admin.members.edit', row.member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.member_id }}
      </a>
    </template>

    <template #receiver_name="{ row }">
      {{ row.payout_receiver_name }}
    </template>

    <template #receiver_account="{ row }">
      {{ row.payout_receiver_account }}
    </template>

    <template #is_doubt="{ row }">
      <span
        v-if="row.is_doubt"
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        Yes
      </span>

      <span
        v-else
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        No
      </span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    payouts: {
      type: Object,
      required: true,
    },

    payoutTypes: {
      type: Array,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let filterablePayoutTypes = [];
    for (const payoutType of props.payoutTypes) {
      filterablePayoutTypes.push({name: payoutType, value: payoutType});
    }

    return {
      filterablePayoutTypes: filterablePayoutTypes,
    };
  },

  methods: {
    approve(payoutId) {
      this.$inertia.patch(this.route('admin.payouts.review.process', [payoutId]), {status: 'approved'});
    },

    toggleDoubt(payoutId) {
      this.$inertia.patch(this.route('admin.payouts.review.toggle-doubt', [payoutId]));
    },
  },
};
</script>
