<template>
  <div class="col-span-2">
    <MultiSelectField
      :model-value="item.profile_question_id"
      name="profile_question_id"
      placeholder="Select profile question"
      mode="single"
      :required="true"
      :inline="true"
      :searchable="true"
      :can-deselect="false"
      :can-clear="false"
      :resolve-on-load="true"
      :min-chars="2"
      :delay="150"
      :loading="loading"
      :options="async (query) => await fetchProfileQuestions(query)"
      @change="value => item.profile_question_id = parseInt(value)"
      @select="selectProfileQuestion"
    />
  </div>
  <div>
    <div class="flex mt-2">
      <label
        v-for="(label, key) of mailingFilterItemOperators"
        :key="`operator-${key}`"
        class="mr-2 flex items-center"
      >
        <input
          v-model="item.operator"
          :value="key"
          type="radio"
          class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
        >
        <span class="ml-2">{{ label }}</span>
      </label>
    </div>
  </div>
  <div v-if="profileQuestion">
    <label
      v-for="answers of profileQuestion.answers"
      :key="`answers-${answers.id}`"
      class="mr-2 flex items-center"
    >
      <input
        v-model="item.answers"
        :value="answers.id"
        type="checkbox"
        class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
      <span class="ml-2">{{ answers.answer }}</span>
    </label>
    <label class="mr-2 flex items-center">
      <input
        v-model="item.answers"
        value="unanswered"
        type="checkbox"
        class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
      >
      <span class="ml-2">Unanswered</span>
    </label>
  </div>
  <div v-else />
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import Base from '../Base';

export default {
  mixins: [Base],

  props: {
    modelValue: {
      required: true,
      type: Object,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const loading = ref(false);
    const profileQuestion = ref(null);
    const item = ref(props.modelValue);
    item.value.profile_question_id = props.modelValue?.profile_question_id || null;
    item.value.operator = props.modelValue?.operator || Object.keys(props.mailingFilterItemOperators).reverse()[0];
    item.value.answers = props.modelValue?.answers || [];

    return {loading, profileQuestion, item};
  },

  methods: {
    fetchProfileQuestions(query) {
      this.loading = true;

      let fetchInitial = query === null;
      if (fetchInitial) {
        return axios.get(this.route('admin.api.profile-questions.all-questions'))
          .then(({data}) => {
            this.loading = false;
            this.profileQuestion = data[0];

            return data.map(v => {
              v.value = v.id;
              v.label = v.question;
              return v;
            });
          });
      }

      return axios.get(this.route('admin.api.profile-questions.search.question', {question: query}))
        .then(({data}) => {
          this.loading = false;

          return data.map(v => {
            v.value = v.id;
            v.label = v.question;
            return v;
          });
        });
    },

    selectProfileQuestion(id, selectedOption) {
      this.profileQuestion = selectedOption;
      this.item.answers = [];
    },
  },
};
</script>
