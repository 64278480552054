<template>
  <CrudIndex
    :title="`Notes: #${mailingId}`"
    :route-parameters="[mailingId]"
    create-route-path="admin.mailings.notes.create"
    :columns="['ID', 'Note', 'Created by', 'Updated by']"
    :data="notes.data"
    :paginator="notes.meta.pagination"
    :actions="true"
    :menu-items="[
      {name: 'Edit', url: (item) => route('admin.mailings.notes.edit', [mailingId, item.id]), enabled: true},
    ]"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.mailings.edit', mailingId)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Mailing
      </InertiaLink>
    </template>

    <template #note="{ item }">
      <span class="whitespace-pre">{{ item }}</span>
    </template>

    <template #created_by="{ item, row }">
      <span v-if="item">{{ item.name }}</span>
      <span v-else>-</span>
      ({{ row.created_at }})
    </template>

    <template #updated_by="{ item, row }">
      <span v-if="item">
        {{ item.name }} ({{ row.updated_at }})
        <InertiaLink :href="route('admin.mailings.notes.history', [row.mailing_id, row.id])">
          <span
            class="material-icons md-24 text-indigo-600 hover:text-indigo-900"
            title="Show history"
          >
            history
          </span>
        </InertiaLink>
      </span>
      <span v-else>-</span>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    mailingId: {
      type: Number,
      required: true,
    },

    notes: {
      type: Object,
      required: true,
    },
  },
};
</script>
