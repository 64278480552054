<template>
  <CrudIndex
    title="Duplicate members"
    row-route-path="admin.members.edit"
    :columns="['ID', 'Member ID', 'Name', 'Username', 'Email', 'Registration date', 'Open payouts', 'Bank accounts', 'Last login', 'Reason', 'Duplicate with']"
    :data="members.data"
    :actions="true"
    :menu-items="[
      {name: 'Hide member', action: (item) => hideMember(item.id), enabled: true},
      {name: `Skip (${skipDays} days)`, action: (item) => skip(item.id), enabled: true},
    ]"
    :paginator="members.meta.pagination"
    :filters="[
      {type: 'search', fields: [
        {label: 'Member ID', value: 'member_id'},
        {label: 'First name', value: 'member.first_name'},
        {label: 'Last name', value: 'member.last_name'},
        {label: 'Email', value: 'member.email'},
        {label: 'IP', value: 'member.registration_ip'},
        {label: 'IBAN', value: 'iban'},
      ]},
    ]"
  >
    <template #member_id="{ item }">
      <a
        :href="route('admin.members.edit', item)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ item }}
      </a>
    </template>

    <template #name="{ row }">
      {{ row.first_name }} {{ row.last_name }}
    </template>

    <template #bank_accounts="{ item }">
      <ul>
        <li
          v-for="(account, index) of item"
          :key="index"
        >
          <PayoutAccountData :data="account" />
        </li>
      </ul>
    </template>

    <template #duplicate_with="{ row }">
      <a
        :href="route('admin.members.edit', row.duplicated_with_member_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        #{{ row.duplicated_with_member_id }}
      </a>
    </template>
  </CrudIndex>
</template>

<script>
import PayoutAccountData from '../../Components/PayoutAccountData.vue';

export default {
  components: {
    PayoutAccountData,
  },

  props: {
    members: {
      type: Object,
      required: true,
    },

    requestFilter: {
      type: Object,
      required: true,
    },

    skipDays: {
      type: Number,
      required: true,
    },
  },

  methods: {
    hideMember(duplicateMemberId) {
      this.$inertia.post(this.route('admin.duplicate-members.hide-member', [duplicateMemberId]));
    },

    skip(duplicateMemberId) {
      this.$inertia.post(this.route('admin.duplicate-members.skip', [duplicateMemberId]));
    },
  },
};
</script>
