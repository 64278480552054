<template>
  <CrudIndex
    :title="`Mailings ${selectedAdvertiser ? 'for ' + selectedAdvertiser.name : ''}`"
    row-route-path="admin.mailings.edit"
    :columns="['ID', 'Name', 'Advertiser', 'Scheduled at', 'Sent at', 'Status', 'Notification type', 'Planner', 'Controller']"
    :actions="true"
    :menu-items="[
      {name: 'Duplicate', icon: 'content_copy', url: (item) => route('admin.mailings.duplicate', item.id), enabled: (item) => item.can_duplicate},
      {name: 'Duplicate multiple', icon: 'dynamic_feed', url: (item) => route('admin.mailings.duplicate-multiple', item.id), enabled: (item) => item.can_duplicate},
      {name: 'Notes', icon: 'notes', url: (item) => route('admin.mailings.notes.index', item.id), enabled: true},
      {name: 'Preview', icon: 'preview', url: (item) => item.preview_url, target: '_blank', enabled: true},
      {name: 'Email preview', icon: 'forward_to_inbox', action: (item) => openEmailPreviewModal(item.id, item.email_preview_url), enabled: true},
      {name: 'Edit', icon: 'edit', url: (item) => route('admin.mailings.edit', item.id), enabled: (item) => 'canEdit' in item ? item.canEdit : true},
      {name: 'Delete', icon: 'delete_forever', action: (item) => destroy(item.id), enabled: (item) => 'canDelete' in item ? item.canDelete : true},
    ]"
    :data="mailings.data"
    :paginator="mailings.meta.pagination"
    :filters="[
      {
        type: 'search',
        fields: [
          {label: 'Name', value: 'name'},
        ],
      },
      {
        type: 'dropdown',
        operator: 'equals-array',
        label: 'Notification type',
        value: 'notification_type',
        items: notificationTypes,
        multiple: true,
      },
    ]"
  >
    <template #before-create>
      <InertiaLink
        v-if="selectedAdvertiser"
        :href="route('admin.advertisers.edit', selectedAdvertiser.id)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Advertiser
      </InertiaLink>

      <DefaultButton
        classes="text-white bg-indigo-600 hover:bg-indigo-700"
        :class="hasSelectedCountry() ? '' : 'cursor-not-allowed disabled'"
        :title="hasSelectedCountry() ? '' : 'No country selected'"
        :href="hasSelectedCountry() ? (selectedAdvertiser ? route('admin.mailings.create', {advertiser: selectedAdvertiser.id}) : route('admin.mailings.create')) : '#'"
      >
        Create
      </DefaultButton>
    </template>

    <template #advertiser="{ row }">
      <a
        :href="route('admin.advertisers.edit', row.advertiser_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.advertiser_name }}
      </a>
    </template>

    <template #status="{ item, row }">
      <MailingStatus
        :mailing-id="row.id"
        :status="item"
        :hold-reason="row.hold_reason"
        :allowed-statuses="allowedStatuses"
      />
    </template>

    <template #planner="{ row }">
      {{ row.planner_name }}
    </template>
    <template #controller="{ row }">
      {{ row.controller_name }}
    </template>
  </CrudIndex>

  <EmailPreviewModal
    v-if="emailPreviewMailingId"
    v-model:mailing-id="emailPreviewMailingId"
    v-model:email-preview-url="emailPreviewUrl"
  />
</template>

<script>
import EmailPreviewModal from '../../Components/Mailing/EmailPreviewModal.vue';
import MailingStatus from '../../Components/Mailing/MailingStatus.vue';

export default {
  components: {MailingStatus, EmailPreviewModal},

  props: {
    mailings: {
      type: Object,
      required: true,
    },

    allowedStatuses: {
      type: Array,
      required: true,
    },

    selectedAdvertiser: {
      type: Object,
      required: false,
      default: null,
    },

    notificationTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      emailPreviewMailingId: null,
      emailPreviewUrl: null,
    };
  },

  methods: {
    openEmailPreviewModal(mailingId, emailPreviewUrl) {
      this.emailPreviewMailingId = mailingId;
      this.emailPreviewUrl = emailPreviewUrl;
    },

    destroy(mailingId) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$inertia.delete(this.route('admin.mailings.destroy', mailingId));
      }
    },
  },
};
</script>
