<template>
  <CrudIndex
    :title="`Duplicates: #${memberId}`"
    :columns="['Member ID', 'Email', 'Registration date', 'Open payouts', 'Bank accounts', 'Last login', 'Login count', 'Reasons']"
    :data="duplicates"
    :actions="false"
  >
    <template #before-create>
      <InertiaLink
        :href="route('admin.members.edit', memberId)"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Member
      </InertiaLink>
    </template>

    <template #member_id="{ item }">
      <a
        :href="route('admin.members.edit', item)"
        target="_blank"
        class="text-indigo-600 hover:text-indigo-900"
      >
        {{ item }}
      </a>
    </template>

    <template #bank_accounts="{ item }">
      <ul>
        <li
          v-for="(account, index) of item"
          :key="index"
        >
          <PayoutAccountData :data="account" />
        </li>
      </ul>
    </template>

    <template #reasons="{ row }">
      <ul>
        <li
          v-for="reason in row.reasons"
          :key="reason"
        >
          {{ reason }}
        </li>
      </ul>
    </template>
  </CrudIndex>
</template>

<script>
import PayoutAccountData from '../../Components/PayoutAccountData.vue';

export default {
  components: {PayoutAccountData},
  props: {
    memberId: {
      type: Number,
      required: true,
    },

    duplicates: {
      type: Object,
      required: true,
    },
  },
};
</script>
