<template>
  <CrudIndex
    :title="`Network revenue statistics (${totalRevenue})`"
    :columns="['Name', ...objectColumns]"
    :actions="false"
    :data="networkRevenueStatistics"
    :filters="[
      {
        type: 'dropdown',
        operator: 'custom',
        label: 'Year',
        value: 'year',
        items: years.map((type) => ({name: type, value: type})),
        multiple: false,
      },
    ]"
  >
    <template #after-create>
      <InertiaLink
        :href="route('admin.network-revenue-statistics.index')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Show Extensive
      </InertiaLink>

      <InertiaLink
        :href="route('admin.network-revenue-statistics.network-guarantees.index')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Network Guarantees
      </InertiaLink>

      <InertiaLink
        :href="route('admin.network-revenue-statistics.manual-networks.create')"
        class="inline-flex ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Create Manual Network
      </InertiaLink>
    </template>

    <template
      v-for="column in objectColumns"
      :key="column"
      #[column.toLowerCase()]="{ item }"
    >
      <p :class="{ 'font-extrabold': item.pending_amount <= 0 && item.accepted_amount > 0 }">
        {{ item.value }}
      </p>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    totalRevenue: {
      type: String,
      required: true,
    },

    networkRevenueStatistics: {
      type: Object,
      required: true,
    },

    years: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      objectColumns: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'],
    };
  },
};
</script>
