<template>
  <CrudEdit
    :title="`${channel.channel_name} - ${channel.network_name}`"
    sub-title="Affiliwings networks"
    :index-route="route('admin.affiliwings-networks.index')"
    :update-route="route('admin.affiliwings-networks.update', channel.id)"
  >
    <template #cards>
      <AffiliwingsNetworkForm
        :channel-types="channelTypes"
        :channel="channel"
      />
    </template>
  </CrudEdit>
</template>

<script>
import AffiliwingsNetworkForm from './Form.vue';

export default {
  components: {AffiliwingsNetworkForm},

  props: {
    channelTypes: {
      type: Array,
      required: true,
    },

    channel: {
      type: Object,
      required: true,
    },
  },
};
</script>
