<template>
  <CrudIndex
    title="Scratch stats"
    :columns="['Sold cards', 'Unique members', 'Money spent', 'Money awarded']"
    :actions="true"
    :data="totalStats"
    :filters="[
      {
        type: 'date-range',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Date', value: 'balance_mutations.created_at'}],
      },
    ]"
  />

  <CrudIndex
    title="Per day"
    :columns="['Date', 'Sold cards', 'Unique members', 'Money spent', 'Money awarded']"
    :data="dayStats.data"
    :paginator="dayStats.meta.pagination"
  />

  <SiteHead
    title="Scratch stats"
  />
</template>

<script>
export default {
  props: {
    totalStats: {
      type: Object,
      required: true,
    },

    dayStats: {
      type: Object,
      required: true,
    },
  },
};
</script>
