<template>
  <CrudIndex
    title="On hold transactions"
    row-route-path="admin.transactions-on-hold.show"
    :columns="['ID', 'Transaction datetime', 'Network', 'Network transaction ID', 'Status', 'On hold reason', 'Transaction currency', 'Program', 'Clickout']"
    :actions="true"
    :data="transactions.data"
    :paginator="transactions.meta.pagination"
    :menu-items="[
      {name: 'Process again', action: (item) => process(item.id), enabled: true},
    ]"
  >
    <template #network="{ row }">
      {{ row.network_name }} ({{ row.account_name }}, {{ row.channel_name }})
    </template>

    <template #status="{ item }">
      <span
        class="px-2 py-1 text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
        :title="item.status === 'DISAPPROVED' ? item.disapproval_reason : ''"
        :class="{
          'bg-green-100 text-green-800' : item.status === 'APPROVED',
          'bg-red-100 text-red-800' : item.status === 'DISAPPROVED',
          'bg-yellow-100 text-yellow-800' : item.status === 'PENDING',
        }"
      >
        {{ item.status }}
      </span>
    </template>

    <template #transaction_currency="{ item }">
      <span v-if="item">{{ item.iso }}</span>
      <span v-else>n/a</span>
    </template>

    <template #program="{ row }">
      <a
        :href="route('admin.programs.edit', row.clickout.program_id)"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        {{ row.clickout.program_name }}
      </a>
    </template>

    <template #clickout="{ item }">
      <a
        :href="route('admin.members.clickouts.show', [item.member_id, item.id])"
        class="text-indigo-600 hover:text-indigo-900"
        target="_blank"
        @click.stop
      >
        Clickout #{{ item.id }}
      </a>
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    transactions: {
      type: Object,
      required: true,
    },
  },

  methods: {
    process(transactionId) {
      this.$inertia.patch(this.route('admin.transactions-on-hold.process', [transactionId]));
    },
  },
};
</script>
