<template>
  <CrudCard>
    <template #content>
      <InputField
        :model-value="translation.group"
        label="Group"
        name="group"
        :disabled="true"
      />

      <InputField
        :model-value="translation.key"
        label="Key"
        name="key"
        :disabled="true"
      />

      <TextareaField
        :model-value="source.translation"
        label="Source"
        name="source"
        :required="false"
        rows="10"
        :wider="true"
        :disabled="true"
      />

      <TextareaField
        v-model="$store.state.form.translation"
        label="Translation"
        name="translation"
        :required="false"
        rows="10"
        :wider="true"
        :count-chars="true"
      />
    </template>
  </CrudCard>
</template>

<script setup>
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';

const props = defineProps({
  locale: {
    type: String,
    required: true,
  },

  source: {
    type: Object,
    required: true,
  },

  translation: {
    type: Object,
    required: true,
  },
});

const form = useForm({
  locale: props.locale,
  translation: props.translation.translation,
});

useStore().commit('form/setForm', form);
</script>
