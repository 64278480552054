<template>
  <div class="grid gap-1 md:gap-4 items-center grid-cols-6 py-6 first:pt-0 last:pb-0">
    <div class="col-span-2">
      <div class="flex justify-between sm:justify-start">
        <label
          class="block font-medium text-gray-700 mt-px"
          v-text="label"
        />

        <span
          v-if="!required"
          class="text-sm text-gray-500 sm:text-xs ml-1 mt-px"
        >Optional</span>
      </div>

      <p
        v-if="help"
        class="block mt-2 text-sm text-gray-400"
        v-text="help"
      />
    </div>

    <template
      v-for="(item, index) in items"
      :key="item.key"
    >
      <RequiredProfileQuestion
        v-model="items[index]"
        :index="index"
        @update:model-value="updated"
        @delete="remove(index)"
      />

      <div class="col-span-2" />
    </template>

    <div class="relative max-w-xl w-full mt-1 sm:mt-0 col-span-4">
      <DefaultButton
        class="!ml-0"
        @click.prevent="addRequiredQuestion"
      >
        <span
          class="material-icons md-18"
          title="Add Profile Question"
        >add</span>
      </DefaultButton>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import RequiredProfileQuestion from './RequiredProfileQuestion.vue';

export default {
  components: {RequiredProfileQuestion},

  props: {
    label: {
      type: String,
      required: true,
    },

    required: {
      type: Boolean,
      required: false,
    },

    help: {
      type: String,
      required: false,
      default: null,
    },

    modelValue: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    props.modelValue.map((item) => {
      item.key = Math.random().toString(36).substr(2, 5);

      return item;
    });

    const items = ref(props.modelValue);

    return {items};
  },

  methods: {
    updated() {
      this.$emit('update:modelValue', this.items);
    },

    addRequiredQuestion () {
      const key = Math.random().toString(36).substr(2, 5);
      this.items.push({key: key, profile_question_id: null, profile_answer_ids: []});

      this.updated();
    },

    remove(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>
