<template>
  <CrudIndex
    title="News"
    create-route-path="admin.news.create"
    edit-route-path="admin.news.edit"
    row-route-path="admin.news.edit"
    delete-route-path="admin.news.destroy"
    :columns="['ID', 'Country', 'Title', 'Start At', 'End At']"
    :actions="true"
    :data="news.data"
    :paginator="news.meta.pagination"
    :filters="[
      {
        type: 'date-range',
        label: 'Start at',
        timezone: $page.props.auth.user.timezone,
        fields: [{label: 'Start At', value: 'start_at'}],
      },
      {
        type: 'dropdown',
        operator: 'status',
        label: 'Status',
        value: 'status',
        items: [
          {name: 'Future', value: 'pending'},
          {name: 'Active', value: 'active'},
          {name: 'Ended', value: 'ended'},
        ],
        multiple: false,
      }
    ]"
  >
    <template #country="{ row }">
      {{ row.country.code }}
    </template>
  </CrudIndex>
</template>

<script>
export default {
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
};
</script>
