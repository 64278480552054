<template>
  <CrudCreate
    title="Cashback"
    sub-title="Member - Clickouts"
    :index-route="route('admin.members.clickouts.index', clickout.member_id)"
    :store-route="route('admin.cashbacks.store')"
  >
    <template #cards>
      <CashbackForm
        :clickout="clickout"
        :points-conversion-ratio="pointsConversionRatio"
        :commission-models="commissionModels"
        :cashback-percentage="cashbackPercentage"
      />

      <Member :member="clickout.member" />
    </template>
  </CrudCreate>
</template>

<script>
import CashbackForm from './Form.vue';
import Member from '../../Components/Member.vue';

export default {
  components: {
    CashbackForm,
    Member,
  },

  props: {
    clickout: {
      type: Object,
      required: true,
    },

    pointsConversionRatio: {
      type: String,
      required: true,
    },

    commissionModels: {
      type: Object,
      required: true,
    },

    cashbackPercentage: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
