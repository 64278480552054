<template>
  <HistoryTable
    :title="`Member history #${member.id}`"
    :history="history"
  />
</template>

<script>
import HistoryTable from '../../Components/HistoryTable.vue';

export default {
  components: {
    HistoryTable,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    history: {
      type: Array,
      required: true,
    },
  },
};
</script>
