<template>
  <CrudEdit
    title="Mailing filter file - Edit"
    sub-title="Mailing filter files"
    :index-route="route('admin.mailing-filter-files.index')"
    :update-route="route('admin.mailing-filter-files.update', mailingFilterFile.id)"
  >
    <template #form>
      <MailingFilterFileForm
        :mailing-filter-file="mailingFilterFile"
        :selected-country-id="selectedCountryId"
        :file-max-size-in-kb="fileMaxSizeInKb"
        :file-max-rows="fileMaxRows"
      />
    </template>
  </CrudEdit>
</template>

<script>
import MailingFilterFileForm from './Form.vue';

export default {
  components: {
    MailingFilterFileForm,
  },

  props: {
    mailingFilterFile: {
      type: Object,
      required: true,
    },

    selectedCountryId: {
      type: Number,
      required: true,
    },

    fileMaxSizeInKb: {
      type: Number,
      required: true,
    },

    fileMaxRows: {
      type: Number,
      required: true,
    },
  },
};
</script>
